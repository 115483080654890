<template>
  <div class="page-wrapper">
    <!-- start header -->
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Liste des Demandes</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.html">Home</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li class="active">Liste des Demandes</li>
              </ol>
            </div>
          </div>
          <!-- start table content -->
          <div class="row">
            <div class="col-md-12">
              <div class="card card-box">
                <div class="card-head">
                  <header>
                    <h4>Liste des demandes</h4>
                  </header>
                  <div class="tools">
                    <input
                      type="text"
                      placeholder="Recherche..."
                      v-model="searchQuery"
                      @input="fetchDemandes"
                    />
                    <select v-model="perPage" @change="fetchDemandes">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </select>
                  </div>
                </div>
                <div class="card-body">
                  <a :href="'/demande-materiel'" class="btn btn-primary mb-3">Ajouter +</a>
                  <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
                  <table class="table table-striped">
                    <thead>
                      <tr>
        <th>Demande N°</th>
        <th>Date de Soumission</th>
        <th>Urgence</th>
        <th>Statut</th>
        <th>Observations</th>
        <th>Détails</th>
        <th>Actions</th>
      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="demande in demandes" :key="demande.id">
                        <td>{{ demande.id }}</td>
                        <td>{{ formatDate(demande.created_at) }}</td>
                        <td>
    <select 
      v-model="demande.urgence" 
      @change="updateUrgence(demande.id, demande.urgence)"
      :class="['urgence-select', `urgence-${demande.urgence}`]"
    >
      <option value="basse" class="urgence-basse">Basse</option>
      <option value="moyenne" class="urgence-moyenne">Moyenne</option>
      <option value="haute" class="urgence-haute">Haute</option>
    </select>
  </td>
                        <td>
                          <span :class="getStatutClass(demande.statut)">{{ formatStatut(demande.statut) }}</span>
                        </td>
                        <td>
    <div class="observation-indicator" :class="{ 'has-observation': demande.observations }">
      <i class="fa fa-comment-dots observation-icon" 
         :class="{ 
           'active': demande.observations, 
           'inactive': !demande.observations 
         }"
         @click="demande.observations && showObservationTooltip($event, demande.observations)"
         :title="demande.observations || 'Aucune observation'"></i>
      <span class="observation-badge" v-if="demande.observations">1</span>
    </div>
  </td>
                        <td>
                          <i @click="showDetails(demande.id)" class="fa fa-info-circle text-info cursor-pointer" style="font-size: 20px;"></i>
                        </td>
                        <td>
                          <router-link :to="{ name: 'EditDemande', params: { id: demande.id } }" class="tblEditBtn">
                            <i class="fa fa-pencil"></i>
                          </router-link>
                          <a class="tblDelBtn" @click="deleteDemande(demande.id)">
                            <i class="fa fa-trash-o"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <paginate-component
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                    @pagechanged="fetchDemandes"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- end table content -->
        </div>
      </div>
    </div>
    <appafterContent />
    <appfooter />

    <div v-if="isModalVisible" class="modal-overlay">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Détails de la Demande N°{{ selectedDemande.id }}</h2>
          <div class="modal-actions">
            <i @click="printDetails" class="fa fa-print action-icon" title="Imprimer"></i>
            <i @click="downloadDetails" class="fa fa-download action-icon" title="Télécharger"></i>
          </div>
          <button @click="closeModal" class="close-button">&times;</button>
        </div>
        <div class="modal-body">
          <div class="modal-section">
            <div class="modal-column left-column">
              <h3>Informations de la Demande</h3>
              <p><strong>Type de demande:</strong> {{ formatTypeDemande(selectedDemande.type_demande) }}</p>
              <p><strong>Statut:</strong> <span :class="getStatutClass(selectedDemande.statut)">{{ formatStatut(selectedDemande.statut) }}</span></p>
              <p><strong>Date de soumission:</strong> {{ formattedDate }}</p>
              <p><strong>Chef de département:</strong> {{ selectedDemande.user.prenom }} {{ selectedDemande.user.nom }}</p>
              <p><strong>Département:</strong> {{ selectedDemande.user.departement.nom_departement }}</p>
            </div>
            <div class="modal-column right-column">
              <h3>Détails des Matériels et Consommables</h3>
              <div v-if="selectedDemande.demande_materiels && selectedDemande.demande_materiels.length > 0">
                <strong>Matériels:</strong>
                <ul>
                  <li v-for="materiel in selectedDemande.demande_materiels" :key="materiel.id">
                    <i class="fa fa-cube"></i> {{ materiel.libelle }} - {{ materiel.quantite }} - {{ materiel.description }}
                  </li>
                </ul>
              </div>
              <div v-if="selectedDemande.demande_consommables && selectedDemande.demande_consommables.length > 0">
                <strong>Consommables:</strong>
                <ul>
                  <li v-for="consommable in selectedDemande.demande_consommables" :key="consommable.id">
                    <i class="fa fa-flask"></i> {{ consommable.libelle }} - {{ consommable.quantite }} - {{ consommable.description }}
                  </li>
                </ul>
              </div>
              <div v-if="selectedDemande.observations" class="observation-section">
          <h3>

            <i class="fa fa-comment-dots"></i>
            Observations
          </h3>
          <div class="observation-content">
            {{ selectedDemande.observations }}
          </div>
        </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "moment/locale/fr";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";
import Swal from 'sweetalert2';

export default {
  components: {
    appheader,
    appfooter,
    appnavbar,
    appafterContent,
    PaginateComponent,
  },
  data() {
    return {
      demandes: [],
      is_loading: true,
      errorMessage: "",
      searchQuery: "",
      perPage: 15,
      currentPage: 1,
      totalPages: 1,
      isModalVisible: false,
      selectedDemande: {},
      formattedDate: "",
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  created() {
    this.fetchDemandes();
  },
  methods: {
     formatUrgence(urgence) {
      return urgence.charAt(0).toUpperCase() + urgence.slice(1);
    },
    getUrgenceClass(urgence) {
      switch (urgence) {
        case 'basse':
          return 'urgence-basse';
        case 'moyenne':
          return 'urgence-moyenne';
        case 'haute':
          return 'urgence-haute';
        default:
          return '';
      }
    },
    
    async updateUrgence(id, urgence) {
      try {
        const response = await axios.patch(
          `http://localhost:8000/api/demandes/${id}/urgence`,
          { urgence },
          { headers: { Authorization: `Bearer ${this.token}` } }
        );

        if (response.data.statut === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Succès',
            text: 'Niveau d\'urgence mis à jour',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Erreur lors de la mise à jour du niveau d\'urgence',
        });
        // Restaurer l'ancienne valeur en cas d'erreur
        this.fetchDemandes(this.currentPage);
      }
    },
    formatDate(date) {
      return moment(date).locale('fr').format('DD/MM/YYYY');
    },
    formatStatut(statut) {
      return statut.replace(/_/g, ' ').replace(/\b\w/g, char => char.toLowerCase());
    },
    formatTypeDemande(type) {
      return type === 'both' ? 'Matériel et Consommable' : type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toLowerCase());
    },
    async fetchDemandes(page = 1) {
      this.is_loading = true;
      const headers = { Authorization: `Bearer ${this.token}` };
      const params = {
        search: this.searchQuery,
        per_page: this.perPage,
        page: page,
      };

      try {
        const response = await axios.get("http://localhost:8000/api/demandes", { headers, params });
        this.demandes = response.data.demandes.data;
        this.totalPages = response.data.demandes.last_page;
        this.currentPage = response.data.demandes.current_page;
      } catch (error) {
        console.error("Erreur lors de la récupération des demandes:", error);
        this.errorMessage = "Une erreur est survenue lors de la récupération des demandes.";
      } finally {
        this.is_loading = false;
      }
    },
    async deleteDemande(id) {
      const confirmation = await Swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Vous ne pourrez pas annuler cela!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimez-le!'
      });

      if (confirmation.isConfirmed) {
        const headers = { Authorization: `Bearer ${this.token}` };
        try {
          const response = await axios.delete(`http://localhost:8000/api/demandes/${id}`, { headers });
          if (response.data.statut === 200) {
            Swal.fire('Succès!', 'Demande supprimée avec succès', 'success');
            this.fetchDemandes(this.currentPage); 
          } else {
            Swal.fire('Erreur!', 'Une erreur est survenue lors de la suppression de la demande', 'error');
          }
        } catch (error) {
          Swal.fire('Erreur!', 'Une erreur est survenue lors de la suppression de la demande', 'error');
        }
      }
    },
    async showDetails(id) {
      try {
        const response = await axios.get(`http://localhost:8000/api/demandes/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        console.log("Données reçues du backend:", response.data);
        this.selectedDemande = response.data.demande;
        console.log("selectedDemande après affectation:", this.selectedDemande);
        this.formattedDate = this.formatDate(this.selectedDemande.created_at);
        this.isModalVisible = true;
      } catch (error) {
        console.error("Erreur complète:", error);
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des détails de la demande', 'error');
      }
    },
    showObservationTooltip(event, observation) {
      Swal.fire({
        title: 'Observation',
        text: observation,
        icon: 'info',
        confirmButtonText: 'Fermer',
        customClass: {
          container: 'observation-tooltip'
        }
      });
    },
    closeModal() {
      this.isModalVisible = false;
    },
    printDetails() {
      const printContent = this.generatePrintContent();
      const printWindow = window.open('', '_blank', 'height=600,width=800');
      printWindow.document.write(printContent);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    },
    downloadDetails() {
      const content = this.generateDownloadContent();
      const element = document.createElement('a');
      const file = new Blob([content], {type: 'text/html'});
      element.href = URL.createObjectURL(file);
      element.download = `demande_${this.selectedDemande.id}.html`;
      document.body.appendChild(element);
      element.click();
    },
    generatePrintContent() {
      return `
        <!DOCTYPE html>
        <html lang="fr">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Demande N°${this.selectedDemande.id}</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              line-height: 1.6;
              color: #333;
              max-width: 800px;
              margin: 0 auto;
              padding: 20px;
            }
            h1, h2 {
              color: #2c3e50;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 12px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
              font-weight: bold;
            }
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;
            }
            .logo {
              font-size: 24px;
              font-weight: bold;
            }
            .demande-info {
              text-align: right;
            }
            .status {
              display: inline-block;
              padding: 5px 10px;
              border-radius: 4px;
              font-weight: bold;
            }
            .status-en_attente { background-color: #ffeeba; color: #856404; }
            .status-en_cours { background-color: #b8daff; color: #004085; }
            .status-reçu { background-color: #c3e6cb; color: #155724; }
            .status-rejete { background-color: #f5c6cb; color: #721c24; }
          </style>
        </head>
        <body>
          <div class="header">
            <div class="logo">CFPT-SJ</div>
            <div class="demande-info">
              <h1>Demande N°${this.selectedDemande.id}</h1>
              <p>Date: ${this.formattedDate}</p>
            </div>
          </div>

          <h2>Informations de la Demande</h2>
          <table>
            <tr>
              <th>Type de demande</th>
              <td>${this.formatTypeDemande(this.selectedDemande.type_demande)}</td>
            </tr>
            <tr>
              <th>Statut</th>
              <td><span class="status status-${this.selectedDemande.statut}">${this.formatStatut(this.selectedDemande.statut)}</span></td>
            </tr>
            <tr>
              <th>Chef de département</th>
              <td>${this.selectedDemande.user.prenom} ${this.selectedDemande.user.nom}</td>
            </tr>
            <tr>
              <th>Département</th>
              <td>${this.selectedDemande.user.departement.nom_departement}</td>
            </tr>
          </table>

          ${this.selectedDemande.type_demande === 'materiel' || this.selectedDemande.type_demande === 'both' ? `
            <h2>Matériels</h2>
            <table>
              <thead>
                <tr>
                  <th>Libellé</th>
                  <th>Quantité</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                ${this.selectedDemande.demande_materiels.map(m => `
                  <tr>
                    <td>${m.libelle}</td>
                    <td>${m.quantite}</td>
                    <td>${m.description}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
          ` : ''}

          ${this.selectedDemande.type_demande === 'consommable' || this.selectedDemande.type_demande === 'both' ? `
            <h2>Consommables</h2>
            <table>
              <thead>
                <tr>
                  <th>Libellé</th>
                  <th>Quantité</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                ${this.selectedDemande.demande_consommables.map(c => `
                  <tr>
                    <td>${c.libelle}</td>
                    <td>${c.quantite}</td>
                    <td>${c.description}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
          ` : ''}
        </body>
        </html>
      `;
    },
    generateDownloadContent() {
      return this.generatePrintContent();
    },
    getStatutClass(statut) {
      switch (statut) {
        case 'en_attente':
          return 'text-warning';
        case 'validé':
          return 'text-success';
        case 'en_cours':
          return 'text-info';
        case 'reçu':
          return 'text-orange';
        case 'rejete':
          return 'text-danger';
        default:
          return '';
      }
    }
  },
};
</script>

<style scoped>
.non-specifie {
  color: gray !important; 
  font-style: italic;
}
.color {
   color: rgb(245, 210, 10) !important;
}
.cursor-pointer {
  cursor: pointer;
}
.text-danger {
  color: red;
}
.text-info {
  color: #17a2b8;
}
.text-warning {
  color: orange;
  font-weight: bold;
}
.text-success {
  color: green;
  font-weight: bold;
}

.urgence-select {
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-weight: 500;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 1em;
  padding-right: 32px;
}

/* Styles pour le select selon l'urgence sélectionnée */
.urgence-basse {
  background-color: #d4edda;
  color: #28a745;
}

.urgence-moyenne {
  background-color: #fff3cd;
  color: #ffc107;
}

.urgence-haute {
  background-color: #f8d7da;
  color: #dc3545;
}

/* Styles pour les options du select */
.urgence-select option {
  font-weight: 500;
  padding: 8px;
}

.urgence-select option[value="basse"] {
  background-color: #d4edda;
  color: #28a745;
}

.urgence-select option[value="moyenne"] {
  background-color: #fff3cd;
  color: #ffc107;
}

.urgence-select option[value="haute"] {
  background-color: #f8d7da;
  color: #dc3545;
}

/* Hover effect */
.urgence-select:hover {
  border-color: #999;
}

/* Focus effect */
.urgence-select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.3);
}

/* Styles pour le modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 1200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.observation-indicator {
  position: relative;
  display: inline-block;
}

.observation-indicator.has-observation {
  cursor: pointer;
}

.observation-icon {
  font-size: 1.2em;
  transition: all 0.3s ease;
}

.observation-icon.active {
  color: #3498db;
}

.observation-icon.active:hover {
  color: #2980b9;
  transform: scale(1.1);
}

.observation-icon.inactive {
  color: #bdc3c7;
  cursor: default;
}

.observation-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #e74c3c;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.7em;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.observation-section {
  margin-top: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
  border-left: 4px solid #3498db;
}

.observation-section h3 {
  color: #2c3e50;
  font-size: 1.2em;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.observation-content {
  background: white;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  line-height: 1.6;
  white-space: pre-wrap;
}

/* Style pour le tooltip SweetAlert2 */
:deep(.observation-tooltip) {
  z-index: 1100;
}

:deep(.observation-tooltip .swal2-popup) {
  border-radius: 12px;
  padding: 20px;
}

:deep(.observation-tooltip .swal2-title) {
  color: #2c3e50;
  font-size: 1.4em;
}

:deep(.observation-tooltip .swal2-content) {
  color: #34495e;
  font-size: 1.1em;
  line-height: 1.6;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-size: 16px;
}

.modal-section {
  display: flex;
  width: 100%;
}

.modal-column {
  width: 48%;
}

.left-column {
  border-right: 1px solid #ddd;
  padding-right: 20px;
}

.right-column {
  padding-left: 20px;
}

.modal-body strong {
  display: inline-block;
  margin-bottom: 5px;
}

.modal-body ul {
  list-style-type: none;
  padding: 0;
}

.modal-body li {
  margin-bottom: 5px;
}
.modal-actions {
  display: flex;
  gap: 20px;
}

.action-icon {
  font-size: 20px;
  cursor: pointer;
  color: #333;
}
.text-orange {
  color: blue;
  font-weight: bold;
}           
</style>