<template>
  <div class="page-wrapper">
    <!-- Header -->
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
        />
      </div>
    </div>

    <!-- Navbar -->
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <!-- Page content -->
      <div class="page-content-wrapper">
        <div class="page-content" v-if="!viewListe">
          <!-- Page title and breadcrumb -->
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Historique des paiements</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a
                    class="parent-item"
                    :href="'/dashboardCaissier'"
                    >Tableau de Bord</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">
                  Informations Eleves &nbsp;<a class="parent-item"></a>
                </li>
              </ol>
            </div>
          </div>

          <!-- Form -->
          <div class="row">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Information</header>
                  <button
                    id="panel-button"
                    class="mdl-button mdl-js-button mdl-button--icon pull-right"
                    data-upgraded=",MaterialButton"
                  >
                    <i class="material-icons">more_vert</i>
                  </button>
                  <ul
                    class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                    data-mdl-for="panel-button"
                  >
                    <li class="mdl-menu__item">
                      <i class="material-icons">assistant_photo</i>Action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">print</i>Another action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">favorite</i>Something else here
                    </li>
                  </ul>
                </div>

                <div class="card-body row">
                  <!-- Form inputs -->
                  <div class="col-lg-6 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                    >
                      <label class="mdl-textfield__label" for="txtMatricule">
                        Numéro KAIROS
                      </label>
                      <input
                        class="mdl-textfield__input"
                        type="text"
                        id="txtMatricule"
                        v-model="search_query"
                        @input="performSearch"
                      />
                      <span class="erreur">{{ id_eleve_erreur }}</span>
                    </div>
                  </div>

                  <!-- Select academic year -->
                  <div class="col-lg-6 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                    >
                      <label
                        class="mdl-textfield__label"
                        for="directionSelect"
                        v-show="!form.id_annee_academique"
                      >
                        Choisissez l'année academique
                      </label>
                      <select
                        class="mdl-textfield__input"
                        id="directionSelect"
                        readonly
                        tabIndex="-1"
                        v-model="form.id_annee_academique"
                        @change="validatedata('annee_academique')"
                      >
                        <option
                          v-for="annee_academique in annee_academiques"
                          :value="annee_academique.id"
                        >
                          {{ annee_academique.intitule }}
                        </option>
                      </select>
                      <span class="erreur">{{
                        id_annee_accademique_erreur
                      }}</span>
                    </div>
                  </div>

                  <!-- List of students -->
                  <div
                    v-for="(eleve, key) in eleves"
                    :key="key"
                    @click="selectEleve(eleve)"
                  >
                    <a href="#">
                      {{ eleve.user.nom }} {{ eleve.user.prenom }}</a
                    >
                  </div>

                  <!-- Submit and cancel buttons -->
                  <div class="col-lg-12 p-t-20 text-center">
                    <button
                      type="submit"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                      @click.prevent="validerAvantAjout()"
                    >
                      Appliquer
                    </button>
                    <button
                      type="button"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                      @click="resetForm"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="page-content-wrapper" v-show="viewListe">
      <listeHistoriquePaiementEleve />
    </div>
    <appfooter />
  </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import { mapState } from "vuex";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import listeHistoriquePaiementEleve from "../historiquePaiementScolarite/listeHistoriquePaiementEleve.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    listeHistoriquePaiementEleve,
  },
  minxins:[permissionMixin],
  name: "HistoriquementPaiementEleve",
  data() {
    return {
      datahistoriquePaiementsEleve: [],
      filieres: [],
      form: new Form({
        id_eleve: "",
        id_annee_academique: "",
        montant: '', 
      }),
      eleves: [],
      annee_academiques: [],
      search_query: "",
      selectedEleve: {
        id: "",
        nom: "",
        prenom: "",
        classe: "",
        adresse: "",
        date_naissance: "",
        photo: "",
        id_kairos: "",
      },
      id_annee_accademique_erreur: "",
      id_mois_erreur: "",
      montant_erreur: "",
      id_eleve_erreur: "",
      viewListe: false,
    };
  },
  computed: {
    ...mapState([
      "isLoggedIn",
      "userNom",
      "userPrenom",
      "userIdrole",
      "userPhoto",
      "userRole",
    ]),
  },
  mounted() {
    this.get_annee_academique();
    bus.on("ancienFiltre", (eventData) => {
      this.viewListe = eventData.viewListe;
      this.getHistoriquePaiementsEleve();
    });
  },
  methods: {
    async soumettre() {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    
    // Créer l'objet paiement dans le format attendu
    const paiement = {
        id_mois: this.form.id_mois,
        id_annee_academique: this.form.id_annee_academique,
        montant: this.form.montant // Assurez-vous d'avoir cette valeur
    };

    const formData = new FormData();
    formData.append('id_eleve', this.form.id_eleve);
    formData.append('paiements', JSON.stringify([paiement])); // Envoyer comme tableau
    formData.append('mode_paiement', 'Cash'); // ou la valeur appropriée
    formData.append('type_recouvrement', 'Etudiant');
    formData.append('reference', ''); // si nécessaire

    try {
        const response = await axios.post('/paiement/store', formData, { headers });
        
        if (response.data.statut === 200) {
            Swal.fire({
                icon: 'success',
                title: 'Succès',
                text: 'Le paiement a été enregistré avec succès'
            });
            this.viewListe = true;
            const eventData = {
                eleve_non_payers: response.data.paiement,
                viewListe: true,
            };
            bus.emit("nouveauFiltre", eventData);
        }
    } catch (error) {
        console.error('Erreur complète:', error);
        
        if (error.response?.data?.message) {
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: error.response.data.message
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Une erreur est survenue lors de l\'enregistrement'
            });
        }
    }
},
    async get_annee_academique() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get("/annee_academique/index", {
          headers,
        });
        this.annee_academiques = response.data.annee_academique;
      } catch (error) {
        Swal.fire(
          "Erreur!",
          "Une erreur est survenue lors de la recuperation des année_academiques",
          "error"
        );
      }
    },
    validerAvantAjout() {
      const isIdChampValid = this.validatedataOld();
      if (isIdChampValid) {
        return;
      }
      this.historiquePaiementsEleve(
        this.selectedEleve.id_kairos,
        this.form.id_annee_academique
      );
    },
    validatedata(champ) {
      switch (champ) {
        case "annee_academique":
          this.id_annee_academique_erreur = "";
          if (this.form.id_annee_academique === "") {
            this.id_annee_academique_erreur =
              "Vous avez oublié de sélectionner  l'année académique";
            return true;
          }
          break;
        case "id_eleve":
          this.id_eleve_erreur = "";
          if (this.form.id_eleve === "") {
            this.id_eleve_erreur = "Matricule invalide ";
            return true;
          }
          break;
        default:
          break;
      }
    },
    validatedataOld() {
      this.id_annee_accademique_erreur = "";
      this.id_eleve_erreur = "";
      let j = 0;
      if (this.form.id_annee_academique === "") {
        this.id_annee_academique_erreur =
          "Vous avez oublié de sélectionner l'année académique'";
        j = 1;
      }
      if (this.form.id_eleve === "") {
        this.id_eleve_erreur = "Matricule invalide ";
        j = 1;
      }
      return j === 1;
    },

    async historiquePaiementsEleve(id_kairos, id_annee_academique) {
  try {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    
    // Log des paramètres envoyés
    console.log('Paramètres envoyés:', {
      id_kairos,
      id_annee_academique
    });

    const response = await axios.get("/historique/filtre/eleve", {
      params: {
        id_kairos: id_kairos,
        id_annee_academique: id_annee_academique,
      },
      headers: headers,
    });

    // Log de la réponse
    console.log('Réponse reçue:', response.data);

    if (response.data.statut === 404) {
      Swal.fire({
        icon: 'info',
        title: 'Information',
        text: response.data.message
      });
      return;
    }

    if (response.data.statut === 200) {
     // Activer l'affichage de la liste
     this.viewListe = true;
          
          // Envoyer les données à la liste
          const eventData = {
            eleve_non_payers: response.data.historique_paiements,
            viewListe: true,
          };
          bus.emit("nouveauFiltre", eventData);
        } else {
          Swal.fire({
            icon: 'info',
            title: 'Information',
            text: 'Aucun paiement trouvé pour cet élève'
          });
        }

  
  } catch (error) {
    console.error("Erreur complète:", error);
    console.error("Détails de la réponse:", error.response?.data);
    
    Swal.fire({
      icon: 'error',
      title: 'Erreur',
      text: 'Une erreur est survenue lors de la récupération des données'
    });
  }
},

    async performSearch() {
      if (!this.search_query || this.search_query.length < 2) {
        this.eleves = [];
        return;
    }
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      this.id_eleve_erreur = "";
      try {
        const response = await axios.get("/recherche/eleve", {
          params: { query: this.search_query },
          headers: headers,
        });
        if (response.data && response.data.length > 0) {
            this.eleves = response.data;
        } else {
            this.eleves = [];
            Swal.fire({
                icon: 'info',
                title: 'Information',
                text: 'Aucun élève trouvé avec ce numéro KAIROS',
                confirmButtonText: 'OK'
            });
        }
      } catch (error) {
        console.error("Erreur de recherche:", error);
        this.eleves = [];
        
        if (error.response && error.response.status === 500) {
            Swal.fire({
                icon: 'warning',
                title: 'Aucun résultat',
                text: 'Aucun élève ne correspond à la recherche',
                confirmButtonText: 'OK'
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Une erreur est survenue lors de la recherche',
                confirmButtonText: 'OK'
            });
        }
    }
    },
    selectEleve(eleve) {
      this.form.id_eleve = eleve.user.id;
      this.search_query = eleve.id_kairos;
      this.selectedEleve.id_kairos = eleve.id_kairos;
      this.selectedEleve.nom = eleve.user.nom;
      this.eleves = [];
    },
    resetForm() {
      this.form.id_eleve = "";
      this.selectedEleve.id = "";
      this.selectedEleve.nom = "";
      this.selectedEleve.prenom = "";
      this.selectedEleve.photo = "";
      this.search_query = "";
      this.id_annee_accademique_erreur = "";
    },
  },
};
</script>
