<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole" :userRole="userRole" />

      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Emploi du temps</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;
                  <a class="parent-item" href="/dashboard">Tableau de Bord</a>&nbsp;
                  <i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Emploi du temps</li>
              </ol>
            </div>
          </div>

          <!-- Departments Tabs -->
          <div class="row">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Départements</header>
                </div>
                <div class="card-body">
                  <ul class="nav nav-pills department-tabs">
                    <li v-for="departement in departements" :key="departement.id" class="nav-item"
                        :class="{ 'active': selectedDepartement === departement.id }">
                      <a class="nav-link" @click="selectDepartement(departement.id)">
                        <!-- Icônes dynamiques pour chaque département -->
                        <span v-if="departement.nom_departement === 'Informatique'">
                          <i class="fa fa-laptop-code"></i>
                        </span>
                        <span v-if="departement.nom_departement === 'Electrique'">
                          <i class="fa fa-bolt"></i>
                        </span>
                        <span v-if="departement.nom_departement === 'Mecanique'">
                          <i class="fa fa-cogs"></i>
                        </span>
                        <span v-if="departement.nom_departement === 'Civil'">
                          <i class="fa fa-building"></i>
                        </span>
                        {{ departement.nom_departement }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- Unité de Formation Selection -->
          <div class="row" v-if="filieres.length > 0">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Unités de Formation</header>
                </div>
                <div class="card-body row">
                  <div class="col-lg-4" v-for="filiere in filieres" :key="filiere.id" @click="selectFiliere(filiere.id)">
                    <div class="card">
                      <div class="card-body">
                        <div class="card-icon">
                          <i class="fa fa-graduation-cap"></i> <!-- Icône FontAwesome -->
                        </div>
                        <h4 class="card-title">{{ filiere.nom_unite_formation }}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Classes Selection -->
          <div class="row" v-if="classes.length > 0">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Classes</header>
                </div>
                <div class="card-body row">
                  <div class="col-lg-4" v-for="classe in classes" :key="classe.id" @click="redirectToEmploiDuTemps(classe.id)">
                    <div class="card">
                      <div class="card-body">
                        <div class="card-icon">
                          <i class="fa fa-chalkboard-teacher"></i>
                        </div>
                        <h4 class="card-title">{{ classe.type_formation.intitule }} {{ classe.nom_classe }}{{ classe.niveau }} {{ classe.type_classe }}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Emploi du Temps -->
          <div class="row" v-if="emploiDuTemps.length > 0">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Emploi du Temps</header>
                </div>
                <div class="card-body">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Jour</th>
                        <th>Heure</th>
                        <th>Matière</th>
                        <th>Professeur</th>
                        <th>Salle</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(schedule, index) in emploiDuTemps" :key="index">
                        <td>{{ schedule.jour }}</td>
                        <td>{{ schedule.heure_debut }} - {{ schedule.heure_fin }}</td>
                        <td>{{ schedule.matiere }}</td>
                        <td>{{ schedule.professeur }}</td>
                        <td>{{ schedule.salle }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <appafterContent />
    </div>

    <appfooter />
  </div>
</template>

<script>
import bus from '../../eventBus';
import axios from '@/axios';
import Form from 'vform';
import Swal from 'sweetalert2';
import flatPickr from 'vue-flatpickr-component';
//import 'flatpickr/dist/flatpickr.css';
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
  },
  data() {
    return {
      departements: [],
      filieres: [],
      classes: [],
      emploiDuTemps: [],
      selectedDepartement: null,
      selectedFiliere: null,
      selectedClasse: null
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  methods: {
    async get_departement() {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get("/departement/all", { headers });
        this.departements = response.data.departement;
      } catch (error) {
        Swal.fire("Erreur!", "Récupération des départements impossible", "error");
      }
    },

    async get_filiere(id) {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get(`/find/filieres/${id}`, { headers });
        this.filieres = response.data.filiere;
        this.selectedDepartement = id;
        this.classes = [];
        this.emploiDuTemps = [];
      } catch (error) {
        Swal.fire("Erreur!", "Récupération des filières impossible", "error");
      }
    },

    async get_classe(id) {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get(`/find/classes/${id}`, { headers });
        this.classes = response.data.classe;
        this.selectedFiliere = id;
        this.emploiDuTemps = [];
      } catch (error) {
        Swal.fire("Erreur!", "Récupération des classes impossible", "error");
      }
    },


    async get_emploi_du_temps(idClasse) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      const params = {
        annee_academique: this.form.annee_academique || "",
        id_classe: idClasse,
        id_semestre: this.form.id_semestre || ""
      };

      this.$data.calendarOptions.events = {
        url: "http://localhost:8000/api/get/emploi",
        method: "GET",
        extraParams: params,
        failure: error => {
          console.error("Erreur lors du chargement de l'emploi du temps :", error.message);
        }
      };

      setTimeout(() => {
        if (this.$refs.fullCalendar && this.$refs.fullCalendar.getApi()) {
          this.$refs.fullCalendar.getApi().refetchEvents();
        }
      }, 500);
    },
    // Redirige vers l'emploi du temps si disponible
    async redirectToEmploiDuTemps(idClasse) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      try {
        // Vérifier si un emploi du temps existe pour cette classe
        const response = await axios.get(`/classes/${idClasse}/has-emploi-du-temps`, { headers });
        const hasEmploiDuTemps = response.data.hasEmploiDuTemps; // Booléen : true ou false

        if (hasEmploiDuTemps) {
          // Redirection si un emploi du temps existe
          window.location.href = `/emploi-du-temps?id_classe=${idClasse}`;
        } else {
          // Alerte si aucun emploi du temps n'est disponible
          Swal.fire({
            icon: "warning",
            title: "Pas d'emploi du temps",
            text: "Aucun emploi du temps n'est disponible pour cette classe.",
          });
        }
      } catch (error) {
        console.error("Erreur lors de la vérification de l'emploi du temps :", error.message);
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: "Impossible de vérifier la disponibilité de l'emploi du temps.",
        });
      }
    },

    selectDepartement(id) {  
      this.filieres = [];
      this.classes = [];
      this.emploiDuTemps = [];
      this.get_filiere(id);
    },

    selectFiliere(id) {
      this.classes = [];
      this.emploiDuTemps = [];
      this.get_classe(id);
    },

    selectClasse(id) {
      this.emploiDuTemps = [];
      this.get_emploi_du_temps(id);
    }
  },

  async showEmploiDuTemps(classe) {
    this.selectedClasse = classe;

    // Stockez éventuellement des informations pour la classe sélectionnée (si nécessaire)
    localStorage.setItem("selectedClasse", JSON.stringify(classe));

    // Redirection vers la page d'emploi du temps après une courte pause
    setTimeout(() => {
      window.location.href = `/emploi-du-temps?id_classe=${classe.id}`;
    }, 1500);
  },

  mounted() {
    this.get_departement();
    const params = new URLSearchParams(window.location.search);
    const idClasse = params.get("id_classe");

    if (idClasse) {
      this.get_emploi_du_temps(idClasse); // Charge les emplois du temps pour la classe
    }
  }
};
</script>

<style scoped>
/* Conteneur pour aligner les cartes horizontalement */
.card-container {
  display: flex;
  flex-wrap: nowrap; /* Pas de retour à la ligne */
  overflow-x: auto; /* Scroll horizontal si nécessaire */
  gap: 20px; /* Espacement entre les cartes */
  padding: 20px;
}

/* Style des cartes des départements */
.department-card {
  background-color: #ffffff; /* Fond blanc */
  border-radius: 10px; /* Coins arrondis */
  border: 1px solid #e0e0e0; /* Bordure douce */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Ombre subtile */
  padding: 25px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 250px; /* Hauteur uniforme pour chaque carte */
}

/* Animation au survol */
.department-card:hover {
  transform: translateY(-10px); /* Légère élévation */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Ombre plus marquée */
  border-color: #007bff;
}


/* Icône dans les cartes */
.card-icon {
  font-size: 3rem; /* Taille de l'icône */
  color: #007bff; /* Couleur de l'icône */
  margin-bottom: 15px;
}

/* Titre des cartes */
.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333; /* Texte sombre */
  margin-top: 10px;
  text-transform: uppercase; /* Texte en majuscules */
  line-height: 1.4;
}

/* Couleurs personnalisées pour chaque département */
.department-1 {
  border-left: 5px solid #007bff; /* Bleu pour le département 1 */
}

.department-2 {
  border-left: 5px solid #28a745; /* Vert pour le département 2 */
}

.department-3 {
  border-left: 5px solid #ffc107; /* Jaune pour le département 3 */
}

.department-4 {
  border-left: 5px solid #dc3545; /* Rouge pour le département 4 */
}

/* Scroll personnalisé */
.card-container::-webkit-scrollbar {
  height: 8px;
}

.card-container::-webkit-scrollbar-thumb {
  background: #007bff;
  border-radius: 4px;
}

/* Style général pour les tabs de départements */
.department-tabs {
  display: flex;
  justify-content: start;
  padding-left: 0;
  margin-bottom: 15px;
  border-bottom: 2px solid #007bff;
}

.department-tabs .nav-item {
  margin-right: 15px;
  border-radius: 8px;
}

.department-tabs .nav-link {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Style des icônes */
.department-tabs .nav-link i {
  margin-right: 10px; /* Espacement entre l'icône et le texte */
  font-size: 1.5rem; /* Taille de l'icône */
  color: #007bff; /* Couleur de l'icône */
}

/* Active state (when the tab is selected) */
.department-tabs .nav-item.active .nav-link {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

/* Hover state (when the user hovers over a tab) */
.department-tabs .nav-item:hover .nav-link {
  background-color: #007bff;
  color: white;
}

/* Style pour le titre des départements */
.card-head header {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
  padding: 15px 0;
  text-align: center;
}



.card-container::-webkit-scrollbar-track {
  background: #f0f0f0;
}
</style>
