<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userPhoto="userPhoto"
          :userNom="userNom"
          :userPrenom="userPrenom"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Liste des Commandes</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="/">Accueil</a>&nbsp;<i class="fa fa-angle-right"></i></li>
                <li class="active">Commandes</li>
              </ol>
            </div>
          </div>

          <!-- Tableau des commandes -->
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-head">
                  <header>Liste des Commandes</header>
                </div>
                
                <div class="card-body">
                  <div class="col-md-12 text-right">
                    <router-link 
                      to="/commandes/create"
                      class="btn btn-primary mb-3"
                    >
                      <i class="fa fa-plus"></i> Nouvelle Commande
                    </router-link>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-striped custom-table">
                      <thead>
                        <tr>
                          <th>Référence</th>
                          
                          <th>Date</th>
                          <th>Statut</th>
                          <th>Demandes</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="commande in commandes" :key="commande.id" class="hover-effect">
                          <td>{{ commande.reference_commande }}</td>
                         
                          <td>{{ formatDate(commande.date_commande) }}</td>
                          <td>
                            <span :class="['status-badge', getStatusClass(commande.statut)]">
                              {{ commande.statut }}
                            </span>
                          </td>
                          <td>
                            <span class="badge badge-info">
                              {{ commande.nombre_demandes }} demande(s)
                            </span>
                          </td>
                          <td class="actions-column">
  <i class="fa fa-eye action-icon view-icon" 
     @click="openDetailsModal(commande)" 
     title="Voir les détails"></i>
  <i class="fa fa-calculator action-icon count-icon" 
     @click="openCountModal(commande)" 
     title="Voir le comptage"></i>
  <i class="fa fa-edit action-icon edit-icon" 
     @click="openEditModal(commande)" 
     title="Modifier"></i>
  <i class="fa fa-trash action-icon delete-icon" 
     @click="confirmDelete(commande.id)" 
     title="Supprimer"></i>
</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Comptage -->
<Teleport to="body">
  <div v-if="showCountModal" class="modal-overlay" @click.self="showCountModal = false">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <div class="modal-title">
            <h4>Comptage total - Commande #{{ selectedCommande?.reference_commande }}</h4>
            <div class="modal-actions">
              <i @click="printCount" class="fa fa-print action-icon" title="Imprimer"></i>
              <i @click="downloadCount" class="fa fa-download action-icon" title="Télécharger"></i>
            </div>
          </div>
          <button class="modal-close" @click="showCountModal = false">&times;</button>
        </div>
        
        <div class="modal-body">
          <!-- Matériels -->
          <div class="section-card" v-if="Object.keys(calculateMaterialTotals(selectedDemandes)).length">
            <h5 class="section-title">
              <i class="fas fa-tools"></i> Total des Matériels
            </h5>
            <div class="count-table-wrapper">
              <table class="count-table">
                <thead>
                  <tr>
                    <th>Libellé</th>
                    <th>Quantité totale</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(total, libelle) in calculateMaterialTotals(selectedDemandes)" :key="libelle">
                    <td>{{ libelle }}</td>
                    <td>
                      <span class="quantity-badge">{{ total.quantite }}</span>
                    </td>
                    <td>{{ total.description || '-' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Consommables -->
          <div class="section-card" v-if="Object.keys(calculateConsumableTotals(selectedDemandes)).length">
            <h5 class="section-title">
              <i class="fas fa-box"></i> Total des Consommables
            </h5>
            <div class="count-table-wrapper">
              <table class="count-table">
                <thead>
                  <tr>
                    <th>Libellé</th>
                    <th>Quantité totale</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(total, libelle) in calculateConsumableTotals(selectedDemandes)" :key="libelle">
                    <td>{{ libelle }}</td>
                    <td>
                      <span class="quantity-badge">{{ total.quantite }}</span>
                    </td>
                    <td>{{ total.description || '-' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</Teleport>

  <!-- Modal Détails -->
  <Teleport to="body">
    <div v-if="showDetailsModal" class="modal-overlay" @click.self="showDetailsModal = false">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h4>Détails de la commande #{{ selectedCommande?.reference_commande }}</h4>
            <div class="modal-actions">
      <i @click="printDetails" class="fa fa-print action-icon" title="Imprimer"></i>
      <i @click="downloadDetails" class="fa fa-download action-icon" title="Télécharger"></i>
    </div>
            <button class="modal-close" @click="showDetailsModal = false">&times;</button>
          </div>
          
          <div class="modal-body">
            <!-- Informations de la commande -->
            <div class="section-card">
              <h5 class="section-title">
                <i class="fa fa-shopping-cart"></i> Informations de la commande
              </h5>
              <div class="info-grid">
                <div class="info-item">
                  <span class="info-label">Date de commande</span>
                  <span class="info-value">{{ formatDate(selectedCommande?.date_commande) }}</span>
                </div>
                <div class="info-item">
                  <span class="info-label">Statut</span>
                  <span :class="['status-badge', getStatusClass(selectedCommande?.statut)]">
                    {{ selectedCommande?.statut }}
                  </span>
                </div>
              </div>
            </div>

           

            <!-- Liste des demandes -->
            <div class="section-card" v-if="selectedDemandes.length">
              <h5 class="section-title">
                <i class="fa fa-list"></i> Demandes associées ({{ selectedDemandes.length }})
              </h5>
              
              <div class="demands-accordion">
                <div v-for="demande in selectedDemandes" :key="demande.id" class="demand-item">
                  <div class="demand-header" @click="toggleDemand(demande.id)">
                    <div class="demand-title">
                      <span class="demand-number">Demande #{{ demande.id }}</span>
                      <span :class="['status-badge', getStatusClass(demande.statut)]">
                        {{ demande.statut }}
                      </span>
                    </div>
                    <div class="demand-user">
                      <i class="fa fa-user"></i> {{ demande.user?.nom }} {{ demande.user?.prenom }}
                      <span class="department-badge">
                        <i class="fa fa-building"></i> {{ demande.user?.departement?.nom_departement }}
                      </span>
                    </div>
                    <i class="fa" :class="expandedDemands.includes(demande.id) ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                  </div>
                  
                  <div v-show="expandedDemands.includes(demande.id)" class="demand-details">
                    <!-- Matériels demandés -->
                    <div v-if="demande.materiels?.length" class="demand-section">
                      <h6 class="demand-section-title">Matériels demandés</h6>
                      <div class="items-grid">
                        <div v-for="materiel in demande.materiels" :key="materiel.id" class="item-card">
                          <div class="item-header">
                            <span class="item-title">{{ materiel.libelle }}</span>
                            <span class="item-quantity">Qté: {{ materiel.quantite }}</span>
                          </div>
                          <p class="item-description">{{ materiel.description }}</p>
                        </div>
                      </div>
                    </div>

                    <!-- Consommables demandés -->
                    <div v-if="demande.consommables?.length" class="demand-section">
                      <h6 class="demand-section-title">Consommables demandés</h6>
                      <div class="items-grid">
                        <div v-for="consommable in demande.consommables" :key="consommable.id" class="item-card">
                          <div class="item-header">
                            <span class="item-title">{{ consommable.libelle }}</span>
                            <span class="item-quantity">Qté: {{ consommable.quantite }}</span>
                          </div>
                          <p class="item-description">{{ consommable.description }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>

  <!-- Modal Modification -->
  <Teleport to="body">
    <div v-if="showEditModal" class="modal-overlay" @click.self="showEditModal = false">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h4>Modifier la commande</h4>
            <button class="modal-close" @click="showEditModal = false">&times;</button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateCommande">
             
              <div class="form-group">
                <label>Statut</label>
                <select v-model="editForm.statut" class="form-control">
                  <option value="envoyé">Envoyé</option>
                  <option value="livré">Livré</option>
                  <option value="non livré">Non livré</option>
                </select>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary">Enregistrer</button>
                <button type="button" class="btn btn-secondary" @click="showEditModal = false">Annuler</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script>
import appheader from "../layout/header.vue";
import appnavbar from "../layout/navbar.vue";
import axios from "@/axios";
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/fr';

export default {
  name: "CommandeListComponent",
  components: {
    appheader,
    appnavbar
  },
  data() {
    return {
      commandes: [],
      isLoading: false,
      showDetailsModal: false,
      showEditModal: false,
      selectedCommande: null,
      showCountModal: false,
    materielsCount: {},
    consommablesCount: {},
      selectedDemandes: [],
     
      expandedDemands: [], // Pour l'accordéon des demandes
      editForm: {
     
        statut: ''
      }
    };
  },
  
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    }
  },

  mounted() {
  console.log('Composant monté, appel de fetchCommandes');
  this.fetchCommandes();
},

  methods: {
    formatDate(date) {
      return moment(date).locale('fr').format('DD/MM/YYYY');
    },

    getStatusClass(statut) {
      const classes = {
        'envoyé': 'status-envoye',
        'livré': 'status-livre',
        'non livré': 'status-non-livre',
        'en_attente': 'status-en-attente',
        'en_cours': 'status-en-cours',
        'validé': 'status-valide',
        'reçu': 'status-recu'
      };
      return classes[statut] || '';
    },

    toggleDemand(id) {
      const index = this.expandedDemands.indexOf(id);
      if (index === -1) {
        this.expandedDemands.push(id);
      } else {
        this.expandedDemands.splice(index, 1);
      }
    },
    async openCountModal(commande) {
    try {
      this.isLoading = true;
      const response = await axios.get(`/commandes/${commande.id}`, {
        headers: { Authorization: `Bearer ${this.token}` }
      });
      
      if (response.data.statut === 200) {
        this.selectedCommande = response.data.commande;
        this.selectedDemandes = response.data.demandes;
        
        // Calculer les totaux
        const materielsCount = this.calculateMaterialTotals(this.selectedDemandes);
        const consommablesCount = this.calculateConsumableTotals(this.selectedDemandes);
        
        this.showCountModal = true;
      }
    } catch (error) {
      console.error('Erreur lors du chargement des détails:', error);
      Swal.fire('Erreur', 'Impossible de charger les détails', 'error');
    } finally {
      this.isLoading = false;
    }
  },
  calculateMaterialTotals(demandes) {
    const totals = {};
    demandes.forEach(demande => {
      demande.materiels?.forEach(materiel => {
        if (!totals[materiel.libelle]) {
          totals[materiel.libelle] = {
            quantite: 0,
            description: materiel.description
          };
        }
        totals[materiel.libelle].quantite += materiel.quantite;
      });
    });
    return totals;
  },

  calculateConsumableTotals(demandes) {
    const totals = {};
    demandes.forEach(demande => {
      demande.consommables?.forEach(consommable => {
        if (!totals[consommable.libelle]) {
          totals[consommable.libelle] = {
            quantite: 0,
            description: consommable.description
          };
        }
        totals[consommable.libelle].quantite += consommable.quantite;
      });
    });
    return totals;
  },

  async fetchCommandes() {
  try {
    this.isLoading = true;
    const response = await axios.get('/commandes', {
      headers: { Authorization: `Bearer ${this.token}` }
    });
    
    console.log('Response brute:', response.data); // Pour voir la réponse complète
    
    if (response.data.statut === 200) {
      this.commandes = response.data.commandes;
      console.log('Commandes après traitement:', this.commandes);
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des commandes:', error);
    Swal.fire('Erreur', 'Impossible de charger les commandes', 'error');
  } finally {
    this.isLoading = false;
  }
},

   

    async openDetailsModal(commande) {
      try {
        this.isLoading = true;
        const response = await axios.get(`/commandes/${commande.id}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        
        if (response.data.statut === 200) {
          this.selectedCommande = response.data.commande;
          this.selectedDemandes = response.data.demandes;
          this.showDetailsModal = true;
        }
      } catch (error) {
        console.error('Erreur lors du chargement des détails:', error);
        Swal.fire('Erreur', 'Impossible de charger les détails', 'error');
      } finally {
        this.isLoading = false;
      }
    },

    openEditModal(commande) {
      this.selectedCommande = commande;
      this.editForm = {
        statut: commande.statut
      };
      this.showEditModal = true;
    },
    printCount() {
  const printContent = this.generateCountContent();
  const printWindow = window.open('', '_blank');
  printWindow.document.write(printContent);
  printWindow.document.close();
  printWindow.focus();
  printWindow.print();
  printWindow.close();
    },

downloadCount() {
  const content = this.generateCountContent();
  const blob = new Blob([content], { type: 'text/html' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `bon_commande_${this.selectedCommande.reference_commande}.html`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
},
async openCountModal(commande) {
  try {
    this.isLoading = true;
    const response = await axios.get(`/commandes/${commande.id}`, {
      headers: { Authorization: `Bearer ${this.token}` }
    });
    
    if (response.data.statut === 200) {
      this.selectedCommande = response.data.commande;
      this.selectedDemandes = response.data.demandes;
      
      // Calculer les totaux et les stocker
      this.materielsCount = this.calculateMaterialTotals(this.selectedDemandes);
      this.consommablesCount = this.calculateConsumableTotals(this.selectedDemandes);
      
      this.showCountModal = true;
    }
  } catch (error) {
    console.error('Erreur lors du chargement des détails:', error);
    Swal.fire('Erreur', 'Impossible de charger les détails', 'error');
  } finally {
    this.isLoading = false;
  }
},

generateCountContent() {
  const materielsCount = this.materielsCount;
  const consommablesCount = this.consommablesCount;
  return `
    <!DOCTYPE html>
    <html>
    <head>
      <title>Bon de Commande - ${this.selectedCommande.reference_commande}</title>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
      <style>
        body {
          font-family: 'Arial', sans-serif;
          line-height: 1.6;
          color: #2d3748;
          max-width: 1200px;
          margin: 0 auto;
          padding: 40px;
        }

        .header {
          text-align: center;
          padding: 30px;
          background: #f8fafc;
          border-radius: 15px;
          margin-bottom: 40px;
          border-bottom: 5px solid #3182ce;
        }

        .logo {
          font-size: 24px;
          font-weight: bold;
          color: #2b6cb0;
          margin-bottom: 20px;
        }

        .document-title {
          font-size: 28px;
          margin: 20px 0;
          color: #1a202c;
        }

        .reference {
          font-size: 18px;
          color: #4a5568;
          margin-bottom: 20px;
        }

        .fournisseur-info {
          background: white;
          padding: 25px;
          border-radius: 10px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          margin-bottom: 30px;
        }

        .fournisseur-title {
          font-size: 20px;
          color: #2b6cb0;
          margin-bottom: 15px;
          padding-bottom: 10px;
          border-bottom: 2px solid #e2e8f0;
        }

        .info-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
        }

        .info-item {
          padding: 10px;
        }

        .info-label {
          font-weight: bold;
          color: #4a5568;
          margin-bottom: 5px;
        }

        .section {
          background: white;
          padding: 25px;
          border-radius: 10px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          margin-bottom: 30px;
        }

        .section-title {
          font-size: 20px;
          color: #2b6cb0;
          margin-bottom: 20px;
          padding-bottom: 10px;
          border-bottom: 2px solid #e2e8f0;
        }

        table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }

        th {
          background: #f7fafc;
          padding: 12px;
          text-align: left;
          font-weight: bold;
          color: #4a5568;
          border-bottom: 2px solid #e2e8f0;
        }

        td {
          padding: 12px;
          border-bottom: 1px solid #e2e8f0;
        }

        .quantity {
          background: #ebf8ff;
          color: #2b6cb0;
          padding: 5px 10px;
          border-radius: 15px;
          font-weight: bold;
        }

        .footer {
          text-align: center;
          margin-top: 40px;
          padding-top: 20px;
          border-top: 2px solid #e2e8f0;
          color: #718096;
        }

        .signature-section {
          margin-top: 50px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 40px;
        }

        .signature-box {
          border-top: 2px solid #e2e8f0;
          padding-top: 10px;
          text-align: center;
        }

        .total-count {
          font-weight: bold;
          color: #2b6cb0;
          font-size: 16px;
          margin-top: 20px;
        }
      </style>
    </head>
    <body>
      <div class="header">
        <div class="logo">CFPT-SJ</div>
        <h1 class="document-title">Bon de Commande</h1>
        <div class="reference">Référence: ${this.selectedCommande.reference_commande}</div>
        <div class="date">Date: ${this.formatDate(this.selectedCommande.date_commande)}</div>
      </div>

      <div class="fournisseur-info">
        <h2 class="fournisseur-title">
          <i class="fas fa-building"></i> Informations du Fournisseur
        </h2>
        <div class="info-grid">
          <div class="info-item">
            <div class="info-label">Nom:</div>
            <div>${this.selectedCommande.fournisseur?.nom || '-'}</div>
          </div>
          <div class="info-item">
            <div class="info-label">Téléphone:</div>
            <div>${this.selectedCommande.fournisseur?.telephone || '-'}</div>
          </div>
          <div class="info-item">
            <div class="info-label">Email:</div>
            <div>${this.selectedCommande.fournisseur?.email || '-'}</div>
          </div>
        </div>
      </div>

      ${Object.keys(this.materielsCount).length ? `
        <div class="section">
          <h2 class="section-title">
            <i class="fas fa-tools"></i> Matériels à Commander
          </h2>
          <table>
            <thead>
              <tr>
                <th>Libellé</th>
                <th>Description</th>
                <th>Quantité</th>
              </tr>
            </thead>
            <tbody>
              ${Object.entries(this.materielsCount).map(([libelle, data]) => `
                <tr>
                  <td><strong>${libelle}</strong></td>
                  <td>${data.description || '-'}</td>
                  <td><span class="quantity">${data.quantite}</span></td>
                </tr>
              `).join('')}
            </tbody>
          </table>
          <div class="total-count">
            Total articles: ${Object.keys(this.materielsCount).length}
          </div>
        </div>
      ` : ''}

      ${Object.keys(this.consommablesCount).length ? `
        <div class="section">
          <h2 class="section-title">
            <i class="fas fa-box"></i> Consommables à Commander
          </h2>
          <table>
            <thead>
              <tr>
                <th>Libellé</th>
                <th>Description</th>
                <th>Quantité</th>
              </tr>
            </thead>
            <tbody>
              ${Object.entries(this.consommablesCount).map(([libelle, data]) => `
                <tr>
                  <td><strong>${libelle}</strong></td>
                  <td>${data.description || '-'}</td>
                  <td><span class="quantity">${data.quantite}</span></td>
                </tr>
              `).join('')}
            </tbody>
          </table>
          <div class="total-count">
            Total articles: ${Object.keys(this.consommablesCount).length}
          </div>
        </div>
      ` : ''}

      <div class="signature-section">
        <div class="signature-box">
          <p>Signature du Responsable</p>
          <div style="height: 60px;"></div>
          <p>Date: ${this.formatDate(new Date())}</p>
        </div>
        <div class="signature-box">
          <p>Signature du Fournisseur</p>
          <div style="height: 60px;"></div>
          <p>Date: ...............................</p>
        </div>
      </div>

      <div class="footer">
        <p>
          <i class="far fa-calendar"></i>
          Document généré le ${this.formatDate(new Date())}
        </p>
        <p>CFPT-SJ - Système de Gestion des Commandes</p>
      </div>
    </body>
    </html>
  `;
},
    

async updateCommande() {
      try {
        this.isLoading = true;
        const response = await axios.put(`/commandes/${this.selectedCommande.id}`, {
          statut: this.editForm.statut
        }, {
          headers: { Authorization: `Bearer ${this.token}` }
        });

        if (response.data.statut === 200) {
          await this.fetchCommandes();
          this.showEditModal = false;
          Swal.fire('Succès', 'Commande mise à jour avec succès', 'success');
        }
      } catch (error) {
        console.error('Erreur lors de la mise à jour:', error);
        Swal.fire('Erreur', 'Impossible de mettre à jour la commande', 'error');
      } finally {
        this.isLoading = false;
      }
    },
    printDetails() {
    const printContent = this.generatePrintContent();
    const printWindow = window.open('', '_blank');
    printWindow.document.write(printContent);
    printWindow.document.close();
    printWindow.focus();
      printWindow.print();
      printWindow.close();
  },

  downloadDetails() {
    const content = this.generatePrintContent();
    const blob = new Blob([content], { type: 'text/html' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `commande_${this.selectedCommande.reference_commande}.html`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  },

  generatePrintContent() {
  return `
    <!DOCTYPE html>
    <html>
    <head>
      <title>Commande ${this.selectedCommande.reference_commande}</title>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
      <style>
        body {
          font-family: 'Arial', sans-serif;
          line-height: 1.6;
          color: #2d3748;
          max-width: 1200px;
          margin: 0 auto;
          padding: 40px;
          background: #f8fafc;
        }

        .document-header {
          background: linear-gradient(135deg, #2b6cb0, #4299e1);
          padding: 40px;
          border-radius: 20px;
          margin-bottom: 40px;
          text-align: center;
          color: white;
          box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
        }

        .logo-text {
          font-size: 28px;
          font-weight: bold;
          margin-bottom: 20px;
          letter-spacing: 2px;
        }

        .document-title {
          font-size: 32px;
          margin: 20px 0;
          font-weight: 700;
        }

        .reference {
          font-size: 18px;
          opacity: 0.9;
          margin: 15px 0;
        }

        .status-badge {
          display: inline-block;
          padding: 10px 25px;
          border-radius: 50px;
          font-weight: 600;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 1px;
          margin-top: 15px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        }

        .status-envoye { background: #fefcbf; color: #975a16; }
        .status-livre { background: #c6f6d5; color: #276749; }
        .status-non-livre { background: #fed7d7; color: #9b2c2c; }

        .section {
          background: white;
          border-radius: 15px;
          padding: 30px;
          margin-bottom: 30px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
          border: 1px solid #e2e8f0;
        }

        .section-title {
          display: flex;
          align-items: center;
          gap: 12px;
          color: #2b6cb0;
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 25px;
          padding-bottom: 15px;
          border-bottom: 2px solid #e2e8f0;
        }

        .items-table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0;
          margin-top: 20px;
        }

        .items-table th {
          background: #f7fafc;
          color: #4a5568;
          font-weight: 600;
          padding: 15px;
          text-align: left;
          border-bottom: 2px solid #e2e8f0;
        }

        .items-table td {
          padding: 15px;
          border-bottom: 1px solid #e2e8f0;
        }

        .items-table tr:hover {
          background: #f8fafc;
        }

        .quantity-badge {
          background: #ebf8ff;
          color: #2b6cb0;
          padding: 6px 14px;
          border-radius: 15px;
          font-weight: 600;
          font-size: 14px;
          display: inline-block;
        }

        .demandeur-info {
          background: #f8fafc;
          padding: 20px;
          border-radius: 12px;
          margin-bottom: 25px;
          border: 1px solid #e2e8f0;
        }

        .demandeur-name {
          font-size: 18px;
          font-weight: 600;
          color: #2d3748;
          margin-bottom: 10px;
        }

        .departement-badge {
          background: #e9ecef;
          color: #495057;
          padding: 5px 12px;
          border-radius: 8px;
          font-size: 14px;
        }

        .footer {
          text-align: center;
          margin-top: 50px;
          padding: 30px;
          background: white;
          border-radius: 15px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
        }
      </style>
    </head>
    <body>
      <div class="document-header">
        <div class="logo-text">CFPT-SJ</div>
        <h1 class="document-title">Bon de Commande</h1>
        <div class="reference">
          <i class="fas fa-hashtag"></i>
          Référence: ${this.selectedCommande.reference_commande}
        </div>
        <span class="status-badge status-${this.selectedCommande.statut}">
          <i class="fas fa-circle"></i>
          ${this.selectedCommande.statut}
        </span>
      </div>

      ${this.selectedDemandes.map(demande => `
        <div class="section">
          <div class="section-title">
            <i class="fas fa-file-alt"></i>
            Demande #${demande.id}
          </div>
          
          <div class="demandeur-info">
            <div class="demandeur-name">
              <i class="fas fa-user"></i>
              ${demande.user?.nom} ${demande.user?.prenom}
            </div>
            <span class="departement-badge">
              <i class="fas fa-building"></i>
              ${demande.user?.departement?.nom_departement}
            </span>
          </div>

          ${demande.materiels?.length ? `
            <div class="section-title">
              <i class="fas fa-tools"></i>
              Matériels
            </div>
            <table class="items-table">
              <thead>
                <tr>
                  <th>Libellé</th>
                  <th>Quantité</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                ${demande.materiels.map(materiel => `
                  <tr>
                    <td>
                      <i class="fas fa-cube"></i>
                      ${materiel.libelle}
                    </td>
                    <td>
                      <span class="quantity-badge">
                        ${materiel.quantite}
                      </span>
                    </td>
                    <td>${materiel.description || '-'}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
          ` : ''}

          ${demande.consommables?.length ? `
            <div class="section-title">
              <i class="fas fa-box"></i>
              Consommables
            </div>
            <table class="items-table">
              <thead>
                <tr>
                  <th>Libellé</th>
                  <th>Quantité</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                ${demande.consommables.map(consommable => `
                  <tr>
                    <td>
                      <i class="fas fa-box-open"></i>
                      ${consommable.libelle}
                    </td>
                    <td>
                      <span class="quantity-badge">
                        ${consommable.quantite}
                      </span>
                    </td>
                    <td>${consommable.description || '-'}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
          ` : ''}
        </div>
      `).join('')}

      <div class="footer">
        <p>
          <i class="far fa-calendar"></i>
          Document généré le ${this.formatDate(new Date())}
        </p>
        <p>CFPT-SJ - Système de Gestion des Commandes</p>
      </div>
    </body>
    </html>
  `;
},

    async confirmDelete(id) {
      const result = await Swal.fire({
        title: 'Êtes-vous sûr ?',
        text: "Cette action est irréversible !",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimer',
        cancelButtonText: 'Annuler'
      });

      if (result.isConfirmed) {
        await this.deleteCommande(id);
      }
    },

    async deleteCommande(id) {
      try {
        this.isLoading = true;
        const response = await axios.delete(`/commandes/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        });

        if (response.data.statut === 200) {
          await this.fetchCommandes();
          Swal.fire('Succès', 'Commande supprimée avec succès', 'success');
        }
      } catch (error) {
        console.error('Erreur lors de la suppression:', error);
        Swal.fire('Erreur', 'Impossible de supprimer la commande', 'error');
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.status-badge {
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

.status-envoye { background: #fff8e1; color: #ff8f00; }
.status-livre { background: #e8f5e9; color: #2e7d32; }
.status-non-livre { background: #ffebee; color: #c62828; }
.status-en-attente { background: #e3f2fd; color: #1976d2; }
.status-en-cours { background: #f3e5f5; color: #7b1fa2; }
.status-valide { background: #e8f5e9; color: #2e7d32; }
.status-recu { background: #e0f2f1; color: #00695c; }

.section-card {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.section-title {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.section-title i {
  color: #2196F3;
}
.count-icon { 
  color: #9C27B0; 
  background: #f3e5f5;
}

.count-table-wrapper {
  overflow-x: auto;
}

.count-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 1rem;
}

.count-table th {
  background: #f8f9fa;
  padding: 12px;
  text-align: left;
  font-weight: 600;
  color: #333;
  border-bottom: 2px solid #dee2e6;
}

.count-table td {
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
}

.count-table tr:hover {
  background: #f8f9fa;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.info-item {
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.info-label {
  display: block;
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.info-value {
  font-weight: 500;
  color: #333;
}

.demands-accordion {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.demand-item {
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
}

.demand-header {
  padding: 1rem;
  background: #f8f9fa;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.demand-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.demand-user {
  color: #666;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.department-badge {
  background: #e3f2fd;
  color: #1976d2;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
}

.demand-details {
  padding: 1rem;
  background: white;
}

.items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.item-card {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid #eee;
}

.item-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.item-title {
  font-weight: 500;
  color: #333;
}

.item-quantity {
  color: #2196F3;
  font-weight: 500;
}

.item-description {
  color: #666;
  font-size: 0.9rem;
  margin: 0;
}
.modal-title {
  display: flex;
  align-items: center;
  gap: 20px;
}

.modal-actions {
  display: flex;
  gap: 15px;
}

.modal-actions .action-icon {
  font-size: 1.2rem;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.3s ease;
  cursor: pointer;
}

.modal-actions .fa-print {
  color: #2196F3;
  background: #e3f2fd;
}

.modal-actions .fa-download {
  color: #4CAF50;
  background: #e8f5e9;
}

.modal-actions .action-icon:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrapper {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-container {
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.modal-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.action-icon {
  cursor: pointer;
  margin: 0 5px;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.view-icon { color: #2196F3; }
.edit-icon { color: #FFC107; }
.delete-icon { color: #F44336; }

.action-icon:hover {
  transform: scale(1.2);
}

.badge {
  padding: 0.4em 0.6em;
  font-size: 0.85em;
  border-radius: 0.25rem;
}

.badge-info {
  background-color: #17a2b8;
  color: white;
}

.hover-effect:hover {
  background: #f8fafc;
}

/* Loading Spinner */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>