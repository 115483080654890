<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
        :userRole="userRole" />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class=" pull-left">
                <div class="page-title">Recouvrement des Hébergements</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'dashboardCaissier'">Tableau de
                    Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li><a class="parent-item" :href="'liste-hebergement'">Liste hébergement</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li class="active">Recouvrement &nbsp;<a class="parent-item"></a>&nbsp;<i
                    class="fa fa-angle-right"></i> </li>
              </ol>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>État des Recouvrements</header>
                  <div class="tools">
                    <button class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect btn-primary"
                      @click="imprimerRecouvrement">
                      <i class="material-icons">print</i> Imprimer
                    </button>
                  </div>
                </div>

                <div class="card-body row">
                  <!-- Filtres -->
                  <div class="col-lg-4 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label">Filtrer par Chambre</label>
                      <select class="mdl-textfield__input" v-model="selectedChambre">
                        <option value="">Toutes les chambres</option>
                        <option v-for="chambre in chambres" :key="chambre.id" :value="chambre.id">
                          {{ chambre.intitule }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-4 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label">Filtrer par Mois</label>
                      <select class="mdl-textfield__input" v-model="selectedMois">
                        <option value="">Tous les mois</option>
                        <option v-for="(mois, index) in moisDisponibles" 
                                :key="index" 
                                :value="index + 1">
                          {{ mois }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-4 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label">Filtrer par Statut</label>
                      <select class="mdl-textfield__input" v-model="selectedStatut">
                        <option value="">Tous les statuts</option>
                        <option value="non_paye">Non payé</option>
                        <option value="partiel">Partiellement payé</option>
                        <option value="paye">Payé</option>
                      </select>
                    </div>
                  </div>

                  <!-- Tableau -->
                  <div class="col-lg-12 p-t-20">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Chambre</th>
                            <th>Étudiant</th>
                            <th>Montant Total</th>
                            <th>Montant Payé</th>
                            <th>Reste à Payer</th>
                            <th>Statut</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="hebergement in hebergementsFiltres" :key="hebergement.id">
                            <td>{{ hebergement.chambre?.intitule }}</td>
                            <td>{{ hebergement.eleve?.user?.nom }} {{ hebergement.eleve?.user?.prenom }}</td>
                            <td>{{ formatMontant(hebergement.montant_total_requis) }} FCFA</td>
                            <td>{{ formatMontant(hebergement.montant_total_paye) }} FCFA</td>
                            <td>{{ formatMontant(hebergement.montant_total_requis - hebergement.montant_total_paye) }} FCFA</td>
                            <td>
                              <span class="badge" :class="getStatusClass(hebergement)">
                                {{ getStatusText(hebergement) }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <appfooter />
    </div>
  </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import { mapState, mapActions } from "vuex";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
  },
  mixins: [permissionMixin],
  name: "recouvrementHebergementComponent",
  data() {
    return {
      chambres: [],
      hebergements: [],
      selectedChambre: '',
      selectedStatut: '',
      selectedMois: '',
      MONTANT_MENSUEL: 30000,
      MONTANT_TOTAL_REQUIS: 210000, // 7 mois * 30000
      moisDisponibles: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    }
  },
  computed: {
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
    hebergementsFiltres() {
      let result = this.hebergements;

      // Filtre par chambre
      if (this.selectedChambre) {
        result = result.filter(h => h.id_chambre === this.selectedChambre);
      }

      // Filtre par mois
      if (this.selectedMois) {
        const moisIndex = parseInt(this.selectedMois);
        result = result.filter(h => {
          const montantPaye = Number(h.montant_total_paye) || 0;
          const moisPayes = Math.floor(montantPaye / this.MONTANT_MENSUEL);
          return moisPayes < moisIndex; // N'a pas payé ce mois
        });
      }

      // Filtre par statut
      if (this.selectedStatut) {
        result = result.filter(h => {
          const montantPaye = Number(h.montant_total_paye) || 0;

          switch(this.selectedStatut) {
            case 'non_paye':
              return montantPaye === 0;
            case 'paye':
              return montantPaye === this.MONTANT_TOTAL_REQUIS;
            case 'partiel':
              return montantPaye > 0 && montantPaye < this.MONTANT_TOTAL_REQUIS;
            default:
              return true;
          }
        });
      }
       return result;
    }
  },
  async created() {
    await this.get_permission();
    this.loadData();
  },
  methods: {
    ...mapActions(["login", "logout"]),
    async loadData() {
      try {
        const token = localStorage.getItem("token");
        
        if (this.hasReadPermission("Hebergement")) {
          // Charger les chambres
          const chambreResponse = await axios.get('/chambre/index', {
            headers: { Authorization: `Bearer ${token}` }
          });
          
          // Charger les hébergements
          const hebergementResponse = await axios.get('/hebergement/index', {
            headers: { Authorization: `Bearer ${token}` }
          });

          if (chambreResponse.data.statut === 200) {
            this.chambres = chambreResponse.data.chambre;
          }

          if (hebergementResponse.data.statut === 200) {
            this.hebergements = hebergementResponse.data.hebergement;
          }
        } else {
          showDialog3("Vous n'avez pas la permission requise.");
        }

      } catch (error) {
        console.error("Erreur lors du chargement des données:", error);
        showDialog3("Erreur lors du chargement des données");
      }
    },
    getStatusText(hebergement) {
      const montantPaye = Number(hebergement.montant_total_paye) || 0;
      const montantRequis = Number(hebergement.montant_total_requis) || this.MONTANT_TOTAL_REQUIS;

      if (montantPaye === 0) {
        return 'Non payé';
      } else if (montantPaye === montantRequis) {
        return 'Entièrement payé';
      } else {
        return 'Partiellement payé';
      }
    },
    getStatusClass(hebergement) {
      const status = this.getStatusText(hebergement);
      return {
        'bg-success': status === 'Entièrement payé',
        'bg-danger': status === 'Non payé',
        'bg-warning': status === 'Partiellement payé'
      };
    },
    formatMontant(montant) {
      return new Intl.NumberFormat('fr-FR').format(montant || 0);
    },
    imprimerRecouvrement() {
      const bodyContent = document.body.innerHTML;
      const currentDate = new Date().toLocaleDateString('fr-FR', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });

      let printContent = `
        <div class="print-container">
          <div class="print-header">
            <div class="header-top">
              <h1>CFPT SÉNÉGAL-JAPON</h1>
            </div>
            <div class="header-bottom">
              <h2>ÉTAT DES RECOUVREMENTS DES HÉBERGEMENTS</h2>
              <p>du ${currentDate}</p>
            </div>
          </div>

          <table class="compact-table">
            <thead>
              <tr>
                <th class="col-mois">MOIS</th>
                <th class="col-chambre">CHAMBRE</th>
                <th class="col-etudiant">ÉTUDIANT</th>
                <th class="col-montant">MONTANT PAYÉ</th>
                <th class="col-reste">RESTE À PAYER</th>
                <th class="col-statut">STATUT</th>
              </tr>
            </thead>
            <tbody>
              ${this.hebergementsFiltres.map(h => {
                const moisPayes = Math.floor((Number(h.montant_total_paye) || 0) / this.MONTANT_MENSUEL);
                const status = this.getStatusText(h);
                return `
                  <tr>
                    <td class="col-mois">${moisPayes}/7</td>
                    <td class="col-chambre">${h.chambre?.intitule || ''}</td>
                    <td class="col-etudiant">${h.eleve?.user?.nom || ''} ${h.eleve?.user?.prenom || ''}</td>
                    <td class="col-montant">${this.formatMontant(h.montant_total_paye)}</td>
                    <td class="col-reste">${this.formatMontant(h.montant_total_requis - h.montant_total_paye)}</td>
                    <td class="col-statut ${status.toLowerCase().replace(' ', '-')}">${status}</td>
                  </tr>
                `;
              }).join('')}
            </tbody>
          </table>

          <div class="print-footer">
            <div class="page-info">Page <span class="page-num"></span></div>
          </div>
        </div>
      `;

      const printStyles = `
        <style>
          @media print {
            @page {
              margin: 1.5cm 1cm;
              size: A4;  /* Format portrait A4 */
            }

            body {
              font-family: Arial, sans-serif;
              font-size: 10pt;
              line-height: 1.3;
              color: #000;
              background: #fff;
              margin: 0;
              padding: 0;
            }

            .print-container {
              width: 100%;
              max-width: 100%;
            }

            .print-header {
              text-align: center;
              margin-bottom: 20px;
            }

            .header-top h1 {
              font-size: 16pt;
              font-weight: bold;
              margin: 0 0 10px 0;
              color: #000;
            }

            .header-bottom h2 {
              font-size: 14pt;
              margin: 0 0 5px 0;
              font-weight: bold;
            }

            .header-bottom p {
              font-size: 10pt;
              margin: 0 0 10px 0;
              font-style: italic;
            }

            .compact-table {
              width: 100%;
              border-collapse: collapse;
              font-size: 9pt;
            }

            .compact-table th {
              background-color: #f2f2f2;
              border: 1px solid #ddd;
              padding: 5px;
              text-align: left;
              font-weight: bold;
              white-space: nowrap;
            }

            .compact-table td {
              border: 1px solid #ddd;
              padding: 4px 5px;
              vertical-align: top;
            }

            /* Optimisation des largeurs de colonnes pour A4 portrait */
            .col-mois { width: 8%; text-align: center; }
            .col-chambre { width: 15%; }
            .col-etudiant { width: 27%; }
            .col-montant, .col-reste { width: 18%; text-align: right; }
            .col-statut { width: 14%; text-align: center; }

            /* Styles pour les statuts */
            .non-paye { color: #d32f2f; }
            .partiellement-paye { color: #f57c00; }
            .paye { color: #388e3c; }

            /* Alternance de couleurs pour les lignes */
            .compact-table tr:nth-child(even) {
              background-color: #f9f9f9;
            }

            /* Gestion des sauts de page */
            thead { display: table-header-group; }
            tfoot { display: table-footer-group; }
            
            /* Éviter les sauts de page au milieu des lignes */
            tr { page-break-inside: avoid; }
            
            /* Numérotation des pages */
            .print-footer {
              position: running(footer);
              text-align: center;
              font-size: 9pt;
              padding-top: 5px;
            }
            
            .page-num:after {
              content: counter(page);
            }
            
            @page {
              @bottom-center {
                content: element(footer);
              }
            }
          }
        </style>
      `;

      document.body.innerHTML = printStyles + printContent;
      window.print();
      document.body.innerHTML = bodyContent;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    }
  }
}
</script>

<style scoped>
.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  min-width: 120px;
  height: 32px;
}

.bg-success {
  background-color: rgba(46, 125, 50, 0.1) !important;
  color: #2e7d32 !important;
  border: 1px solid #2e7d32;
}

.bg-danger {
  background-color: rgba(211, 47, 47, 0.1) !important;
  color: #d32f2f !important;
  border: 1px solid #d32f2f;
}

.bg-warning {
  background-color: rgba(255, 152, 0, 0.1) !important;
  color: #f57c00 !important;
  border: 1px solid #f57c00;
}

.table td {
  text-align: center !important;
  vertical-align: middle !important;
}

.table th {
  text-align: center !important;
  vertical-align: middle !important;
}

.tools {
  float: right;
  margin-top: -5px;
}

.btn-primary {
  background-color: #4CAF50;
  color: white;
  margin-right: 10px;
}

.btn-primary:hover {
  background-color: #45a049;
}

.material-icons {
  vertical-align: middle;
  margin-right: 5px;
}
</style>

