<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userPhoto="userPhoto"
          :userNom="userNom"
          :userPrenom="userPrenom"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Liste des Fournisseurs</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="/">Accueil</a>&nbsp;<i class="fa fa-angle-right"></i></li>
                <li class="active">Fournisseurs</li>
              </ol>
            </div>
          </div>
          <!-- Filtres par secteur -->
          <div class="filters-container mb-3">
  <button 
    class="filter-btn" 
    :class="{ active: selectedSecteur === 'all' }"
    @click="selectedSecteur = 'all'"
  >
    Tous
  </button>
  <button 
    v-for="secteur in secteurs" 
    :key="secteur.id"
    class="filter-btn"
    :class="{ active: selectedSecteur === secteur.id }"
    @click="selectedSecteur = secteur.id"
  >
    {{ secteur.nom }}
  </button>
</div>

          <!-- Actions -->
         

          <!-- Liste des fournisseurs -->
          <div class="card">
            <div class="card-header">
              <h4>Liste des Fournisseurs</h4>
            </div>

            <div class="card-body">
              <div class="col-md-12 text-right mb-3">
            <router-link :to="{name: 'FournisseursCreate'}" class="btn btn-primary mb-3">
              <i class="fa fa-plus"></i> Nouveau Fournisseur
            </router-link>
          </div>
              <div class="table-responsive">
                <table class="table table-striped" v-if="fournisseurs.length > 0">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nom</th>
                      <th>Téléphone</th>
                      <th>Email</th>
                      <th>Secteur d'activité</th>
                      <th>Statut</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="fournisseur in filteredFournisseurs" :key="fournisseur.id">
                      
                      <td>{{ fournisseur.id }}</td>
                      <td v-if="!isEditing || editingFournisseur.id !== fournisseur.id">
                        {{ fournisseur.nom }}
                      </td>
                      <td v-if="isEditing && editingFournisseur.id === fournisseur.id">
                        <input v-model="editingFournisseur.nom" class="form-control" required />
                      </td>
                      <td>{{ fournisseur.telephone }}</td>
                      <td>{{ fournisseur.email }}</td>
                      <td>{{ fournisseur.secteur_activite ? fournisseur.secteur_activite.nom : '' }}</td>
                      <td>
                        <span :class="['status-badge', fournisseur.statut === 'actif' ? 'status-actif' : 'status-inactif']">
                          {{ fournisseur.statut }}
                        </span>
                      </td>
                      <td>
                        <i class="fa fa-eye action-icon view-icon" 
                           @click="showDetails(fournisseur)" 
                           title="Voir les détails"></i>
                        <i class="fa fa-edit action-icon edit-icon" 
                           @click="editFournisseur(fournisseur)" 
                           title="Modifier"></i>
                        <i class="fa fa-trash action-icon delete-icon" 
                           @click="confirmDelete(fournisseur.id)" 
                           title="Supprimer"></i>
                      </td>
                    </tr>
                    <tr v-if="isEditing && editingFournisseur.id === fournisseur.id">
                      <td colspan="7">
                        <form @submit.prevent="updateFournisseur(editingFournisseur.id)">
                          <div class="form-group">
                            <label>Nom</label>
                            <input v-model="editingFournisseur.nom" class="form-control" required />
                          </div>
                          <div class="form-group">
                            <label>Téléphone</label>
                            <input v-model="editingFournisseur.telephone" class="form-control" required />
                          </div>
                          <div class="form-group">
                            <label>Email</label>
                            <input v-model="editingFournisseur.email" class="form-control" required />
                          </div>
                          <div class="form-group">
                            <label>Adresse</label>
                            <input v-model="editingFournisseur.adresse" class="form-control" />
                          </div>
                          <div class="form-group">
                            <label>Secteur d'activité</label>
                            <input v-model="editingFournisseur.secteur_activite" class="form-control" required />
                          </div>
                          <div class="form-group">
                            <label>Produits/Services</label>
                            <textarea v-model="editingFournisseur.produits_services" class="form-control" required></textarea>
                          </div>
                          <div class="form-group">
                            <label>Nom du contact</label>
                            <input v-model="editingFournisseur.nom_contact" class="form-control" />
                          </div>
                          <div class="form-group">
                            <label>Téléphone du contact</label>
                            <input v-model="editingFournisseur.telephone_contact" class="form-control" />
                          </div>
                          <div class="form-group">
                            <label>Statut</label>
                            <select v-model="editingFournisseur.statut" class="form-control" required>
                              <option value="actif">Actif</option>
                              <option value="inactif">Inactif</option>
                            </select>
                          </div>
                          <button type="submit" class="btn btn-primary">Enregistrer</button>
                          <button type="button" class="btn btn-secondary" @click="cancelEdit">Annuler</button>
                        </form>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="text-center p-3">
                  Aucun fournisseur trouvé.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Détails du Fournisseur -->
    <!-- Détails du Fournisseur -->
<div v-if="showDetailsModal" class="modal-overlay" @click.self="showDetailsModal = false">
  <div class="modal-wrapper">
    <div class="modal-container">
      <div class="modal-header">
        <div class="header-content">
          <h3 class="modal-title">{{ selectedFournisseur?.nom }}</h3>
          <span :class="['status-badge', selectedFournisseur?.statut === 'actif' ? 'status-actif' : 'status-inactif']">
            {{ selectedFournisseur?.statut }}
          </span>
        </div>
        <button class="modal-close" @click="showDetailsModal = false">
          <i class="fas fa-times"></i>
        </button>
      </div>
      
      <div class="modal-body">
        <div class="info-section">
          <h4 class="section-title">
            <i class="fas fa-info-circle"></i>
            Informations générales
          </h4>
          <div class="info-grid">
            <div class="info-item">
              <i class="fas fa-building"></i>
              <div class="info-content">
                <span class="info-label">Entreprise</span>
                <span class="info-value">{{ selectedFournisseur?.nom }}</span>
              </div>
            </div>
            <div class="info-item">
              <i class="fas fa-phone"></i>
              <div class="info-content">
                <span class="info-label">Téléphone</span>
                <span class="info-value">{{ selectedFournisseur?.telephone }}</span>
              </div>
            </div>
            <div class="info-item">
              <i class="fas fa-envelope"></i>
              <div class="info-content">
                <span class="info-label">Email</span>
                <span class="info-value">{{ selectedFournisseur?.email }}</span>
              </div>
            </div>
            <div class="info-item">
              <i class="fas fa-map-marker-alt"></i>
              <div class="info-content">
                <span class="info-label">Adresse</span>
                <span class="info-value">{{ selectedFournisseur?.adresse || '-' }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="info-section">
          <h4 class="section-title">
            <i class="fas fa-briefcase"></i>
            Informations professionnelles
          </h4>
          <div class="info-grid">
            <div class="info-item">
              <i class="fas fa-industry"></i>
              <div class="info-content">
                <span class="info-label">Secteur d'activité</span>
                <span class="info-value">{{ selectedFournisseur?.secteur_activite?.nom || '-' }}</span>
              </div>
            </div>
            <div class="info-item full-width">
              <i class="fas fa-box"></i>
              <div class="info-content">
                <span class="info-label">Produits/Services</span>
                <span class="info-value">{{ selectedFournisseur?.produits_services }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="info-section">
          <h4 class="section-title">
            <i class="fas fa-user"></i>
            Contact principal
          </h4>
          <div class="info-grid">
            <div class="info-item">
              <i class="fas fa-user-tie"></i>
              <div class="info-content">
                <span class="info-label">Nom du contact</span>
                <span class="info-value">{{ selectedFournisseur?.nom_contact || '-' }}</span>
              </div>
            </div>
            <div class="info-item">
              <i class="fas fa-phone-alt"></i>
              <div class="info-content">
                <span class="info-label">Téléphone du contact</span>
                <span class="info-value">{{ selectedFournisseur?.telephone_contact || '-' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import appheader from "../layout/header.vue";
import appnavbar from "../layout/navbar.vue";
import axios from "@/axios";
import Swal from 'sweetalert2';

export default {
  name: 'FournisseurComponent',
  components: {
    appheader,
    appnavbar
  },
  data() {
  return {
    fournisseurs: [],
    secteurs: [], // Ajout du tableau pour les secteurs d'activité
    selectedFournisseur: null,
    selectedSecteur: 'all', // Pour suivre le secteur sélectionné
    showDetailsModal: false,
    isEditing: false,
    editingFournisseur: {
      nom: '',
      telephone: '',
      email: '',
      adresse: '',
      secteur_activite_id: '', // Utilisation de l'ID du secteur
      produits_services: '',
      nom_contact: '',
      telephone_contact: '',
      statut: 'actif'
    },
    isLoading: false,
  }
},
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
    filteredFournisseurs() {
    console.log('Selected Secteur:', this.selectedSecteur); // Pour déboguer
    if (this.selectedSecteur === 'all') {
      return this.fournisseurs;
    }
    return this.fournisseurs.filter(f => {
      console.log('Fournisseur secteur:', f.secteur_activite); // Pour déboguer
      return f.secteur_activite && f.secteur_activite.id === this.selectedSecteur;
    });
  }
  },
  mounted() {
    this.fetchFournisseurs();
    this.fetchSecteurs();
  },
  methods: {
   

    showDetails(fournisseur) {
      this.selectedFournisseur = fournisseur;
      this.showDetailsModal = true;
    },

    editFournisseur(fournisseur) {
    this.$router.push({ name: 'FournisseursEdit', params: { id: fournisseur.id } });
},

    async updateFournisseur(id) {
      try {
        this.isLoading = true;
        const response = await axios.put(`/fournisseurs/${id}`, this.editingFournisseur, {
          headers: { Authorization: `Bearer ${this.token}` }
        });

        if (response.status === 200) {
          await Swal.fire('Succès', 'Fournisseur modifié avec succès', 'success');
          this.isEditing = false;
          this.fetchFournisseurs();
        }
      } catch (error) {
        console.error('Erreur lors de la modification:', error);
        Swal.fire('Erreur', 'Impossible de modifier le fournisseur', 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async fetchSecteurs() {
  try {
    const response = await axios.get('/secteurs-activite', {
      headers: { Authorization: `Bearer ${this.token}` }
    });
    console.log('Réponse API secteurs:', response.data); // Pour déboguer

    if (response.data.statut === 200) { // Notez le changement de 'status' à 'statut'
      this.secteurs = response.data.secteurs;
      console.log('Secteurs chargés:', this.secteurs); // Pour déboguer
    }
  } catch (error) {
    console.error('Erreur lors du chargement des secteurs:', error);
    Swal.fire('Erreur', 'Impossible de charger les secteurs d\'activité', 'error');
  }
},
    async fetchFournisseurs() {
  try {
    const response = await axios.get('/fournisseurs', {
      headers: { Authorization: `Bearer ${this.token}` }
    });
    console.log('Réponse API:', response.data); // Pour déboguer
    this.fournisseurs = response.data.fournisseurs || [];
  } catch (error) {
    console.error('Erreur lors de la récupération des fournisseurs:', error);
    Swal.fire('Erreur', 'Impossible de charger les fournisseurs', 'error');
  }
},

    cancelEdit() {
      this.isEditing = false;
      this.editingFournisseur = {};
    },

    async confirmDelete(id) {
      const result = await Swal.fire({
        title: 'Êtes-vous sûr ?',
        text: "Cette action est irréversible !",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimer',
        cancelButtonText: 'Annuler'
      });

      if (result.isConfirmed) {
        await this.deleteFournisseur(id);
      }
    },

    async deleteFournisseur(id) {
      try {
        const response = await axios.delete(`/fournisseurs/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        });

        if (response.status === 200) {
          await Swal.fire('Succès', 'Fournisseur supprimé avec succès', 'success');
          this.fetchFournisseurs();
        }
      } catch (error) {
        console.error('Erreur lors de la suppression:', error);
        Swal.fire('Erreur', 'Impossible de supprimer le fournisseur', 'error');
      }
    }
  }
}
</script>

<style scoped>
/* Styles de base */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrapper {
  background: white;
  border-radius: 16px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  width: 85%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-container {
  padding: 0;
}

.modal-header {
  background: #f8f9fa;
  padding: 20px 30px;
  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-right: 20px;
}

.modal-title {
  font-size: 1.5rem;
  color: #2c3e50;
  margin: 0;
  font-weight: 600;
}

.modal-close {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: none;
  background: #fff;
  color: #666;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.modal-close:hover {
  background: #f1f1f1;
  transform: rotate(90deg);
}

.modal-body {
  padding: 30px;
}

/* Sections d'information */
.info-section {
  margin-bottom: 30px;
}

.section-title {
  font-size: 1.2rem;
  color: #2c3e50;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.section-title i {
  color: #0040ffa9;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.info-item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.info-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
}

.info-item i {
  color: #0040ffa9;
  font-size: 1.2rem;
  padding-top: 3px;
}

.info-content {
  flex: 1;
}

/* Filtres */
.filters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
}

.filter-btn {
  padding: 8px 16px;
  border: 2px solid #e9ecef;
  border-radius: 20px;
  background: white;
  color: #495057;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.filter-btn:hover {
  background: #e9ecef;
}

.filter-btn.active {
  background: #0040ffa9;
  color: white;
  border-color: #0040ffa9;
}

/* Actions et statuts */
.action-icon {
  cursor: pointer;
  margin: 0 5px;
  padding: 5px;
  transition: all 0.3s ease;
}

.view-icon { color: #2196F3; }
.edit-icon { color: #FFC107; }
.delete-icon { color: #F44336; }

.action-icon:hover {
  transform: scale(1.2);
}

.status-badge {
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
}

.status-actif {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.status-inactif {
  background-color: #ffebee;
  color: #c62828;
}

/* Labels et valeurs */
.info-label {
  display: block;
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.info-value {
  display: block;
  color: #2c3e50;
  font-weight: 500;
  font-size: 1.1rem;
}

.full-width {
  grid-column: 1 / -1;
}

/* Scrollbar personnalisée */
.modal-wrapper::-webkit-scrollbar {
  width: 8px;
}

.modal-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.modal-wrapper::-webkit-scrollbar-thumb {
  background: #0040ffa9;
  border-radius: 4px;
}

.modal-wrapper::-webkit-scrollbar-thumb:hover {
  background: #0036d8;
}
</style>