<template>
    <div class="page-wrapper">
      <div class="page-header navbar navbar-fixed-top">
        <div class="page-header-inner">
          <appheader
            :userPhoto="userPhoto"
            :userNom="userNom"
            :userPrenom="userPrenom"
          />
        </div>
      </div>
      <div class="page-container">
        <appnavbar
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
          :userIdrole="userIdrole"
          :userRole="userRole"
        />
        <div class="page-content-wrapper">
          <div class="page-content">
            <div class="page-bar">
              <div class="page-title-breadcrumb">
                <div class="pull-left">
                  <div class="page-title">{{ isEditing ? 'Modifier un Stock Matériel' : 'Créer un Stock Matériel' }}</div>
                </div>
                <ol class="breadcrumb page-breadcrumb pull-right">
                  <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="/">Accueil</a>&nbsp;<i class="fa fa-angle-right"></i></li>
                  <li class="active">{{ isEditing ? 'Modifier' : 'Créer' }}</li>
                </ol>
              </div>
            </div>
  
            <div class="card">
              <div class="card-header">
                <h4>Informations du Stock Matériel</h4>
              </div>
              <div class="card-body">
                <form class="form-horizontal" @submit.prevent="submitForm">
                  <div class="row">
                    <div class="col-lg-12 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label">Libellé *</label>
                        <input 
                          class="mdl-textfield__input" 
                          type="text" 
                          v-model="formData.libelle" 
                          :class="{ 'is-invalid': errors.libelle }"
                        />
                        <span class="error-message" v-if="errors.libelle">{{ errors.libelle }}</span>
                      </div>
                    </div>
                    <div class="col-lg-12 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label">Marque *</label>
                        <input 
                          class="mdl-textfield__input" 
                          type="text" 
                          v-model="formData.marque" 
                          :class="{ 'is-invalid': errors.marque }"
                        />
                        <span class="error-message" v-if="errors.marque">{{ errors.marque }}</span>
                      </div>
                    </div>
                    <div class="col-lg-12 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label">Quantité Disponible *</label>
                        <input 
                          class="mdl-textfield__input" 
                          type="number" 
                          v-model="formData.quantite_disponible" 
                          :class="{ 'is-invalid': errors.quantite_disponible }"
                        />
                        <span class="error-message" v-if="errors.quantite_disponible">{{ errors.quantite_disponible }}</span>
                      </div>
                    </div>
                    <div class="col-lg-12 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label">Type de Matériel *</label>
                        <select 
                          class="mdl-textfield__input" 
                          v-model="formData.id_type_materiel"
                          :class="{ 'is-invalid': errors.id_type_materiel }"
                        >
                          <option value="">Sélectionnez un type</option>
                          <option v-for="type in typeMateriels" :key="type.id" :value="type.id">
                            {{ type.intitule}}
                          </option>
                        </select>
                        <span class="error-message" v-if="errors.id_type_materiel">{{ errors.id_type_materiel }}</span>
                      </div>
                    </div>
                    <div class="col-lg-12 p-t-20 text-center">
                      <button
                        type="submit"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                        :disabled="isLoading"
                      >
                        {{ isLoading ? "Veuillez patienter..." : (isEditing ? "Modifier" : "Ajouter") }}
                        <div v-show="isLoading" class="mdl-spinner mdl-js-spinner is-active"></div>
                      </button>
                      <button
                        type="button"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                        @click="resetForm"
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
  
            <div class="card mt-4">
              <div class="card-header">
                <h4>Liste des Stocks Matériels</h4>
              </div>
              <div class="card-body">
                <div v-if="stocks.length === 0">Aucun stock matériel trouvé.</div>
                <table class="table table-striped" v-else>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Libellé</th>
                      <th>Marque</th>
                      <th>Quantité Disponible</th>
                      <th>Type de Matériel</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="stock in stocks" :key="stock.id">
                      <td>{{ stock.id }}</td>
                      <td>{{ stock.libelle }}</td>
                      <td>{{ stock.marque }}</td>
                      <td>{{ stock.quantite_disponible }}</td>
                      <td>{{ stock.type_materiel?.intitule }}</td>
                      <td>
                        <a class="tblEditBtn" @click="editStock(stock)">
                          <i class="fa fa-pencil" title="Modifier"></i>
                        </a>
                        <a class="tblDelBtn" @click="confirmDelete(stock.id)">
                          <i class="fa fa-trash-o" title="Supprimer" style="color: red;"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import appheader from "../layout/header.vue";
  import appnavbar from "../layout/navbar.vue";
  import axios from "@/axios";
  import Swal from 'sweetalert2';
  
  export default {
    name: "StockMaterielComponent",
    components: {
      appheader,
      appnavbar,
    },
    data() {
      return {
        formData: {
          libelle: '',
          marque: '',
          quantite_disponible: '',
          id_type_materiel: ''
        },
        errors: {
          libelle: '',
          marque: '',
          quantite_disponible: '',
          id_type_materiel: ''
        },
        isLoading: false,
        stocks: [],
        typeMateriels: [],
        isEditing: false,
        currentStockId: null,
      };
    },
    computed: {
      token() {
        return localStorage.getItem("token");
      },
      userNom() {
        return this.$store.state.userNom;
      },
      userPrenom() {
        return this.$store.state.userPrenom;
      },
      userIdrole() {
        return this.$store.state.userIdrole;
      },
      userPhoto() {
        return this.$store.state.userPhoto;
      },
      userRole() {
        return this.$store.state.userRole;
      },
    },
    mounted() {
      this.fetchStocks();
      this.fetchTypeMateriels();
    },
    methods: {
      validateForm() {
        let isValid = true;
        this.errors = {
          libelle: '',
          marque: '',
          quantite_disponible: '',
          id_type_materiel: ''
        };
  
        if (!this.formData.libelle) {
          this.errors.libelle = 'Le libellé est requis';
          isValid = false;
        }
        if (!this.formData.marque) {
          this.errors.marque = 'La marque est requise';
          isValid = false;
        }
        if (!this.formData.quantite_disponible) {
          this.errors.quantite_disponible = 'La quantité est requise';
          isValid = false;
        }
        if (!this.formData.id_type_materiel) {
          this.errors.id_type_materiel = 'Le type de matériel est requis';
          isValid = false;
        }
  
        return isValid;
      },
  
      async submitForm() {
        if (!this.validateForm()) return;
  
        this.isLoading = true;
  
        try {
          const url = this.isEditing 
            ? `/stocks-materiels/${this.currentStockId}`
            : '/stocks-materiels';
  
          const response = await axios({
            method: this.isEditing ? 'put' : 'post',
            url: url,
            data: this.formData,
            headers: { Authorization: `Bearer ${this.token}` }
          });
  
          if (response.status === 201 || response.status === 200) {
            Swal.fire('Succès!', 
              this.isEditing ? 'Stock modifié avec succès' : 'Stock ajouté avec succès', 
              'success'
            );
            this.resetForm();
            this.fetchStocks();
          }
        } catch (error) {
          console.error('Erreur:', error);
          Swal.fire('Erreur!', 'Une erreur est survenue', 'error');
        } finally {
          this.isLoading = false;
        }
      },
  
      resetForm() {
        this.formData = {
          libelle: '',
          marque: '',
          quantite_disponible: '',
          id_type_materiel: ''
        };
        this.errors = {
          libelle: '',
          marque: '',
          quantite_disponible: '',
          id_type_materiel: ''
        };
        this.isEditing = false;
        this.currentStockId = null;
      },
  
      async fetchStocks() {
        try {
          const response = await axios.get('/stocks-materiels', {
            headers: { Authorization: `Bearer ${this.token}` }
          });
          console.log('Réponse stocks:', response.data);
          
          if (response.data.statut === 200 && response.data.stocks) {
            this.stocks = response.data.stocks;
            console.log('Stocks chargés:', this.stocks);
          } else {
            console.error('Erreur format réponse:', response.data);
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des stocks:', error);
        }
      },
  
      async fetchTypeMateriels() {
        try {
          const response = await axios.get('/type_materiel/index', {
            headers: { Authorization: `Bearer ${this.token}` }
          });
          console.log('Types de matériel récupérés:', response.data.type_materiel);
          this.typeMateriels = response.data.type_materiel;
        } catch (error) {
          console.error('Erreur lors de la récupération des types de matériel:', error);
        }
      },
  
      editStock(stock) {
        this.formData = {
          libelle: stock.libelle,
          marque: stock.marque,
          quantite_disponible: stock.quantite_disponible,
          id_type_materiel: stock.id_type_materiel
        };
        this.currentStockId = stock.id;
        this.isEditing = true;
      },
  
      async confirmDelete(id) {
        const result = await Swal.fire({
          title: 'Êtes-vous sûr?',
          text: "Cette action est irréversible!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, supprimer!',
          cancelButtonText: 'Annuler'
        });
  
        if (result.isConfirmed) {
          await this.deleteStock(id);
        }
      },
  
      async deleteStock(id) {
        try {
          await axios.delete(`/stocks-materiels/${id}`, {
            headers: { Authorization: `Bearer ${this.token}` }
          });
          Swal.fire('Supprimé!', 'Le stock a été supprimé.', 'success');
          this.fetchStocks();
        } catch (error) {
          console.error('Erreur lors de la suppression:', error);
          Swal.fire('Erreur!', 'Une erreur est survenue lors de la suppression.', 'error');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .error-message {
    color: #dc3545;
    font-size: 12px;
    margin-top: 4px;
  }
  
  .is-invalid {
    border-color: #dc3545 !important;
  }
  
  .mdl-textfield {
    width: 100%;
  }
  
  .mdl-textfield__input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .tblEditBtn, .tblDelBtn {
    cursor: pointer;
    margin: 0 5px;
  }
  </style>