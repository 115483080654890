<template>
  <div class="page-wrapper">
    <!-- start header -->
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userPhoto="userPhoto"
          :userNom="userNom"
          :userPrenom="userPrenom"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />
      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Créer un Matériel</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.html">Home</a>&nbsp;<i class="fa fa-angle-right"></i></li>
                <li class="active">Créer un Matériel</li>
              </ol>
            </div>
          </div>
          
          <div class="card">
            <div class="card-header">
              <h3>Informations du Matériel</h3>
            </div>
            <div class="card-body">
              <form class="form-horizontal" @submit.prevent="soumettre">
                <div class="row">
                  <div class="col-lg-12 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="libelle">Libellé</label>
                      <input class="mdl-textfield__input" type="text" id="libelle" v-model="form.libelle" />
                      <span v-if="errors.libelle" class="erreur">{{ errors.libelle }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="quantite">Quantité</label>
                      <input class="mdl-textfield__input" type="number" id="quantite" v-model.number="form.quantite" min="0" />
                      <span v-if="errors.quantite" class="erreur">{{ errors.quantite }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="marque">Marque</label>
                      <input class="mdl-textfield__input" type="text" id="marque" v-model="form.marque" />
                      <span v-if="errors.marque" class="erreur">{{ errors.marque }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="date-entree">Date d'Entrée</label>
                      <input class="mdl-textfield__input" type="date" id="date-entree" v-model="form.date_entree" />
                      <span v-if="errors.date_entree" class="erreur">{{ errors.date_entree }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="id_etat">État</label>
                      <select class="mdl-textfield__input" id="id_etat" v-model="form.id_etat">
                        <option value="" disabled></option>
                        <option v-for="etat in etats" :key="etat.id" :value="etat.id">{{ etat.intitule }}</option>
                      </select>
                      <span v-if="errors.id_etat" class="erreur">{{ errors.id_etat }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="id_type_materiel">Type de Matériel</label>
                      <select class="mdl-textfield__input" id="id_type_materiel" v-model="form.id_type_materiel">
                        <option value="" disabled></option>
                        <option v-for="type in types" :key="type.id" :value="type.id">{{ type.intitule }}</option>
                      </select>
                      <span v-if="errors.id_type_materiel" class="erreur">{{ errors.id_type_materiel }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
      <label class="mdl-textfield__label" for="id_commande">Commande</label>
      <select class="mdl-textfield__input" id="id_commande" v-model="form.id_commande">
        <option value="" disabled></option>
        <option v-for="commande in commandes" :key="commande.id" :value="commande.id">
          Ref {{ commande.reference_commande }}
        </option>
      </select>
      <span v-if="errors.id_commande" class="erreur">{{ errors.id_commande }}</span>
    </div>
  </div>
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="id_departement">Département</label>
                      <select class="mdl-textfield__input" id="id_departement" v-model="form.id_departement">
                        <option value="" disabled></option>
                        <option v-for="departement in departements" :key="departement.id" :value="departement.id">{{ departement.nom_departement }}</option>
                      </select>
                      <span v-if="errors.id_departement" class="erreur">{{ errors.id_departement }}</span>
                    </div>
                  </div>
                  <div class="col-lg-12 p-t-20 text-center">
                    <button
                      type="submit"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                    >
                      Enregistrer
                    </button>
                    <button
                      type="button"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                      @click="resetForm"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- end form content -->
        </div> <!-- end page-content -->
      </div> <!-- end page-content-wrapper -->
    </div> <!-- end page-container -->
  </div> <!-- end page-wrapper -->
</template>

<script>
import appheader from "../layout/header.vue";
import appnavbar from "../layout/navbar.vue";
import axios from "@/axios";

export default {
  name: "MaterielCreateComponent",
  components: {
    appheader,
    appnavbar,
  },
  data() {
    return {
      form: {
        libelle: '',
        quantite: 0,
        marque: '',
        date_entree: '',
        id_etat: '',
        id_type_materiel: '',
        id_commande: '',
        id_departement: '',
      },
      etats: [],
      types: [],
      commandes: [],
      departements: [],
      errors: {},
      is_loading: false,
    };
  },
  computed: {
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  mounted() {
    this.getEtats();
    this.getTypes();
    this.getCommandes();
    this.getDepartements();
  },
  methods: {
    validateData() {
      this.errors = {};
      if (!this.form.libelle) this.errors.libelle = "Le libellé est obligatoire.";
      if (this.form.quantite === null || this.form.quantite < 0) this.errors.quantite = "La quantité doit être positive.";
      if (!this.form.marque) this.errors.marque = "La marque est obligatoire.";
      if (!this.form.date_entree) this.errors.date_entree = "La date d'entrée est obligatoire.";
      if (!this.form.id_etat) this.errors.id_etat = "L'état est obligatoire.";
      if (!this.form.id_type_materiel) this.errors.id_type_materiel = "Le type de matériel est obligatoire.";
      if (!this.form.id_commande) this.errors.id_commande = "La commande est obligatoire.";
      if (!this.form.id_departement) this.errors.id_departement = "Le département est obligatoire.";
      return Object.keys(this.errors).length === 0;
    },

    async soumettre() {
      if (!this.validateData()) return;

      
  this.is_loading = true;
  const token = localStorage.getItem("token");
  console.log('Token récupéré:', token); // Log pour vérifier le jeton

  const headers = { Authorization: `Bearer ${token}` };
  console.log('En-têtes de la requête:', headers); // Log pour vérifier les en-têtes

  const dataToSend = { ...this.form };
  console.log('Données à envoyer:', dataToSend); // Log pour vérifier les données envoyées

  try {
    const response = await axios.post('http://localhost:8000/api/materiel/store', dataToSend, { headers });
    console.log('Réponse du serveur:', response);
        if (response.status === 200) {
          this.resetForm();
          this.$router.push('/materiels');
        }
      } catch (error) {
        console.error('Erreur lors de la création du matériel:', error);
      } finally {
        this.is_loading = false;
      }
    },

    resetForm() {
      this.form = {
        libelle: '',
        quantite: 0,
        marque: '',
        date_entree: '',
        id_etat: '',
        id_type_materiel: '',
        id_commande: '',
        id_departement: '',
      };
      this.errors = {};
    },

    async getEtats() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get('http://localhost:8000/api/etats', { headers });
        this.etats = response.data.etats;
      } catch (error) {
        console.error('Erreur lors de la récupération des états:', error);
      }
    },

    async getTypes() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get("type_materiel/index", { headers });
        this.types = response.data.type_materiel;
      } catch (error) {
        console.error('Erreur lors de la récupération des types de matériel:', error);
      }
    },

    async getCommandes() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get('http://localhost:8000/api/commandes', { headers });
        if (response.data.statut === 200) {
          this.commandes = response.data.commandes;
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des commandes:', error);
      }
    },

    async getDepartements() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get("/departement/all", { headers });
        this.departements = response.data.departement;
      } catch (error) {
        console.error('Erreur lors de la récupération des départements:', error);
      }
    },
  },
};
</script>

<style scoped>
.erreur {
  color: red;
}
</style>