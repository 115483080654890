<template>
  <div class="edit-fournisseur">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />
      <div class="page-content-wrapper">
        <div class="page-content">
          <h2>Modifier le Fournisseur</h2>
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="submitForm" class="edit-form">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Nom</label>
                    <input v-model="formData.nom" class="form-control" required />
                  </div>
                  <div class="form-group col-md-6">
                    <label>Téléphone</label>
                    <input v-model="formData.telephone" class="form-control" required />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Email</label>
                    <input v-model="formData.email" class="form-control" required />
                  </div>
                  <div class="form-group col-md-6">
                    <label>Adresse</label>
                    <input v-model="formData.adresse" class="form-control" />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Secteur d'activité</label>
                    <input v-model="formData.secteur_activite" class="form-control" required />
                  </div>
                  <div class="form-group col-md-6">
                    <label>Statut</label>
                    <select v-model="formData.statut" class="form-control" required>
                      <option value="actif">Actif</option>
                      <option value="inactif">Inactif</option>
                    </select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Produits/Services</label>
                    <textarea v-model="formData.produits_services" class="form-control" required></textarea>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Nom du contact</label>
                    <input v-model="formData.nom_contact" class="form-control" />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Téléphone du contact</label>
                    <input v-model="formData.telephone_contact" class="form-control" />
                  </div>
                </div>
                <div class="form-buttons">
                  <button type="submit" class="btn btn-primary">Enregistrer</button>
                  <router-link :to="{ name: 'FournisseursList' }" class="btn btn-secondary">Annuler</router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import appheader from "../layout/header.vue";
import appnavbar from "../layout/navbar.vue";
import Swal from 'sweetalert2';

export default {
  name: 'FournisseurEditComponent',
  components: {
    appheader,
    appnavbar
  },
  data() {
    return {
      formData: {
        nom: '',
        telephone: '',
        email: '',
        adresse: '',
        secteur_activite: '',
        produits_services: '',
        nom_contact: '',
        telephone_contact: '',
        statut: 'actif'
      },
      isLoading: false
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    }
  },
  mounted() {
    this.fetchFournisseur();
  },
  methods: {
    async fetchFournisseur() {
      const id = this.$route.params.id;
      try {
        const response = await axios.get(`/fournisseurs/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        this.formData = response.data;
      } catch (error) {
        console.error('Erreur lors de la récupération du fournisseur:', error);
        Swal.fire('Erreur', 'Impossible de charger le fournisseur', 'error');
      }
    },
    async submitForm() {
      this.isLoading = true;
      const id = this.$route.params.id;
      try {
        const response = await axios.put(`/fournisseurs/${id}`, this.formData, {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        if (response.status === 200) {
          await Swal.fire('Succès', 'Fournisseur modifié avec succès', 'success');
          this.$router.push({ name: 'FournisseursList' });
        }
      } catch (error) {
        console.error('Erreur lors de la modification:', error);
        Swal.fire('Erreur', 'Impossible de modifier le fournisseur', 'error');
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped>
.edit-fournisseur {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.form-group {
  flex: 1;
  min-width: 250px; /* Largeur minimale pour les champs */
  margin-right: 15px; /* Espacement entre les colonnes */
}

.form-group:last-child {
  margin-right: 0; /* Supprime l'espacement pour le dernier élément */
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-control:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.form-buttons {
  margin-top: 20px;
}

.btn {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn-secondary:hover {
  background-color: #5a6268;
}
</style>