<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Liste des Matériels</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.html">Home</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li class="active">Liste des Matériels</li>
              </ol>
            </div>
          </div>

          <!-- Formulaire de modification -->
          <div v-if="selectedMateriel" class="card">
            <div class="card-header">
              <h3>Modifier le Matériel</h3>
            </div>
            <div class="card-body">
              <form class="form-horizontal" @submit.prevent="updateMateriel">
                <div class="row">
                  <div class="col-lg-6 p-t-20">
                    <div :class="['mdl-textfield', 'mdl-js-textfield', 'mdl-textfield--floating-label', 'txt-full-width', { 'is-dirty': selectedMateriel.libelle }]">
                      <label class="mdl-textfield__label" for="libelle">Libellé</label>
                      <input class="mdl-textfield__input" type="text" id="libelle" v-model="selectedMateriel.libelle" />
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div :class="['mdl-textfield', 'mdl-js-textfield', 'mdl-textfield--floating-label', 'txt-full-width', { 'is-dirty': selectedMateriel.quantite }]">
                      <label class="mdl-textfield__label" for="quantite">Quantité</label>
                      <input class="mdl-textfield__input" type="number" id="quantite" v-model="selectedMateriel.quantite" />
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div :class="['mdl-textfield', 'mdl-js-textfield', 'mdl-textfield--floating-label', 'txt-full-width', { 'is-dirty': selectedMateriel.marque }]">
                      <label class="mdl-textfield__label" for="marque">Marque</label>
                      <input class="mdl-textfield__input" type="text" id="marque" v-model="selectedMateriel.marque" />
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div :class="['mdl-textfield', 'mdl-js-textfield', 'mdl-textfield--floating-label', 'txt-full-width', { 'is-dirty': selectedMateriel.date_entree }]">
                      <label class="mdl-textfield__label" for="date_entree">Date d'Entrée</label>
                      <input class="mdl-textfield__input" type="date" id="date_entree" v-model="selectedMateriel.date_entree" />
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div :class="['mdl-textfield', 'mdl-js-textfield', 'mdl-textfield--floating-label', 'txt-full-width', { 'is-dirty': selectedMateriel.id_etat }]">
                      <label class="mdl-textfield__label" for="id_etat">État</label>
                      <select class="mdl-textfield__input" id="id_etat" v-model="selectedMateriel.id_etat">
                        <option value="" disabled></option>
                        <option v-for="etat in etats" :key="etat.id" :value="etat.id">{{ etat.intitule }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div :class="['mdl-textfield', 'mdl-js-textfield', 'mdl-textfield--floating-label', 'txt-full-width', { 'is-dirty': selectedMateriel.id_type_materiel }]">
                      <label class="mdl-textfield__label" for="id_type_materiel">Type de Matériel</label>
                      <select class="mdl-textfield__input" id="id_type_materiel" v-model="selectedMateriel.id_type_materiel">
                        <option value="" disabled></option>
                        <option v-for="type in types" :key="type.id" :value="type.id">{{ type.intitule }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
<div :class="['mdl-textfield', 'mdl-js-textfield', 'mdl-textfield--floating-label', 'txt-full-width', { 'is-dirty': selectedMateriel.id_commande }]">
  <label class="mdl-textfield__label" for="id_commande">Commande</label>
  <select class="mdl-textfield__input" id="id_commande" v-model="selectedMateriel.id_commande">
    <option value="" disabled></option>
    <option v-for="commande in commandes" :key="commande.id" :value="commande.id">
      Commande #{{ commande.id }} - {{ commande.reference_commande }}
    </option>
  </select>
</div>
</div>
                  <div class="col-lg-6 p-t-20">
                    <div :class="['mdl-textfield', 'mdl-js-textfield', 'mdl-textfield--floating-label', 'txt-full-width', { 'is-dirty': selectedMateriel.id_departement }]">
                      <label class="mdl-textfield__label" for="id_departement">Département</label>
                      <select class="mdl-textfield__input" id="id_departement" v-model="selectedMateriel.id_departement">
                        <option value="" disabled></option>
                        <option v-for="departement in departements" :key="departement.id" :value="departement.id">{{ departement.nom_departement }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-12 p-t-20 text-center">
                    <button
                      type="submit"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                    >
                      Enregistrer
                    </button>
                    <button
                      type="button"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                      @click="resetForm"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          
          <div class="row">
            <div class="col-md-12">
              <div class="card card-box">
                <div class="card-head">
                  <header>
                    <h4>Liste des matériels</h4>
                  </header>
                  <div class="tools">
                    <input
                      type="text"
                      placeholder="Recherche..."
                      v-model="searchQuery"
                      @input="fetchMateriels"
                    />
                    <select v-model="perPage" @change="fetchMateriels">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </select>
                  </div>
                </div>
                <div class="card-body">
                  <a :href="'/materiel-create'" class="btn btn-primary mb-3">Ajouter +</a>
                  <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
                  <div v-if="is_loading">Chargement...</div>
                  <div v-if="materiels.length === 0 && !is_loading && searchQuery">Matériel inexistant.</div>
                  <div v-if="materiels.length === 0 && !is_loading && !searchQuery">Aucun matériel trouvé.</div>
                  <table v-else class="table table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Libellé</th>
                        <th>Quantité</th>
                        <th>Marque</th>
                        <th>État</th>
                        <th>Type de Matériel</th>
                        <th>Commande</th>
                        <th>Département</th>
                        <th>Date d'Entrée</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="materiel in materiels" :key="materiel.id">
                        <td>{{ materiel.id }}</td>
                        <td>{{ materiel.libelle }}</td>
                        <td>{{ materiel.quantite }}</td>
                        <td>{{ materiel.marque }}</td>
                        <td>{{ materiel.etat.intitule }}</td>
                        <td>{{ materiel.type_materiel.intitule }}</td>
                        <td>{{ materiel.commande ? `${materiel.commande.reference_commande}` : '-' }}</td>
                        <td>{{ materiel.departement.nom_departement }}</td>
                        <td>{{ formatDate(materiel.date_entree) }}</td>
                        <td>
                          <a @click="editMateriel(materiel)" class="tblEditBtn">
                            <i class="fa fa-pencil" title="Modifier"></i>
                          </a>
                          <a @click="confirmDelete(materiel.id)" class="tblDelBtn">
                            <i class="fa fa-trash-o" title="Supprimer" style="color: red;"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <paginate-component
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                    :total="total"
                    :perPage="perPage"
                    @pagechanged="changePage"
                    style="margin-top: 20px;"
                  />
                  <!-- Debug info -->
                  <div v-if="materiels.length > 0" style="margin-top: 10px; font-size: 12px; color: #666;">
                    Page {{ currentPage }} sur {{ totalPages }} ({{ total }} éléments)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <appafterContent />
    <appfooter />
  </div>
</template>

<script>
import axios from "@/axios"; // Assurez-vous que le chemin est correct
import appheader from "../layout/header.vue";
import appnavbar from "../layout/navbar.vue";
import appfooter from "../layout/footer.vue";
import appafterContent from "../layout/afterContent.vue";
import PaginateComponent from "@/components/paginate/PaginateComponent.vue"; // Chemin corrigé
import Swal from 'sweetalert2';

export default {
  components: {
    appheader,
    appnavbar,
    appfooter,
    appafterContent,
    PaginateComponent,
  },
  data() {
    return {
      materiels: [],
      is_loading: true,
      errorMessage: "",
      searchQuery: "",
      perPage: 10,
      currentPage: 1,
      total: 0,
      selectedMateriel: null,
      etats: [],
      types: [],
      commandes: [],
      departements: [],
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.perPage);
    },
    token() {
      return localStorage.getItem("token");
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  created() {
    this.fetchMateriels();
    this.getEtats();
    this.getTypes();
    this.getCommandes();
    this.getDepartements();
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return ''; // Vérifiez si la date est définie
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return new Date(dateString).toLocaleDateString('fr-FR', options); // Formate la date au format jour/mois/année
    },
    async fetchMateriels() {
      this.is_loading = true;
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      try {
        console.log('Fetching page:', this.currentPage);
        console.log('Current perPage:', this.perPage);
        const response = await axios.get('http://localhost:8000/api/materiel/search', {
          headers,
          params: {
            search: this.searchQuery,
            per_page: this.perPage,
            page: this.currentPage
          }
        });
        if (response.data.statut === 200) {
          console.log('Full API Response:', response.data.materiels);
          this.materiels = response.data.materiels.data;
          this.total = response.data.materiels.total;
          console.log('Response data:', {
            current_page: response.data.materiels.current_page,
            last_page: response.data.materiels.last_page,
            per_page: response.data.materiels.per_page,
            total: response.data.materiels.total,
            data_length: response.data.materiels.data.length
          });
          console.log('Component state:', {
            currentPage: this.currentPage,
            totalPages: this.totalPages,
            perPage: this.perPage,
            total: this.total
          });
          this.errorMessage = "";
        } else {
          this.errorMessage = response.data.message;
          console.error('API Error:', response.data.message);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des matériels:', error);
        this.errorMessage = 'Une erreur est survenue lors de la récupération des matériels.';
      } finally {
        this.is_loading = false;
      }
    },
    async getEtats() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get('http://localhost:8000/api/etats', { headers });
        this.etats = response.data.etats;
        console.log('États:', this.etats); // Vérifiez les données ici
      } catch (error) {
        console.error('Erreur lors de la récupération des états:', error);
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des états', 'error');
      }
    },
    async getTypes() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get("http://localhost:8000/api/type_materiel/index", { headers });
        this.types = response.data.type_materiel;
        console.log('Types de matériel:', this.types); 
      } catch (error) {
        console.error('Erreur lors de la récupération des types de matériel:', error);
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des types de matériel', 'error');
      }
    },
    async getCommandes() {
const token = localStorage.getItem("token");
const headers = { Authorization: `Bearer ${token}` };
try {
  const response = await axios.get('http://localhost:8000/api/commandes', { headers });
  if (response.data.statut === 200) {
    this.commandes = response.data.commandes;
    console.log('Commandes:', this.commandes);
  }
} catch (error) {
  console.error('Erreur lors de la récupération des commandes:', error);
  Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des commandes', 'error');
}
},
    async getDepartements() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get("http://localhost:8000/api/departement/all", { headers });
        this.departements = response.data.departement; // Assurez-vous que c'est bien 'departement'
        console.log('Départements:', this.departements); // Vérifiez les données ici
      } catch (error) {
        console.error('Erreur lors de la récupération des départements:', error);
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des départements', 'error');
      }
    },
    editMateriel(materiel) {
      this.selectedMateriel = { ...materiel };
    },
    async updateMateriel() {
const token = localStorage.getItem("token");
const headers = { Authorization: `Bearer ${token}` };

try {
  const materielData = {
    ...this.selectedMateriel,
    id_commande: this.selectedMateriel.id_commande // Assurez-vous que cette propriété est envoyée
  };

  const response = await axios.post(
    `http://localhost:8000/api/materiel/update/${this.selectedMateriel.id}`, 
    materielData, 
    { headers }
  );

  if (response.data.statut === 200) {
    Swal.fire('Succès!', 'Matériel mis à jour avec succès', 'success');
    this.fetchMateriels();
    this.selectedMateriel = null;
  }
} catch (error) {
        console.error('Erreur lors de la mise à jour du matériel:', error);
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la mise à jour du matériel', 'error');
      }
    },
    resetForm() {
      this.selectedMateriel = null;
    },
    async confirmDelete(id) {
      const result = await Swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Vous ne pourrez pas revenir en arrière!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimer!'
      });

      if (result.isConfirmed) {
        this.deleteMateriel(id);
      }
    },
    async deleteMateriel(id) {
      const headers = { Authorization: `Bearer ${this.token}` };
      try {
        await axios.delete(`http://localhost:8000/api/materiel/delete/${id}`, { headers });
        Swal.fire('Succès!', 'Matériel supprimé avec succès', 'success');
        this.fetchMateriels(this.currentPage); // Récupérer à nouveau les matériels
      } catch (error) {
        console.error('Erreur lors de la suppression du matériel:', error);
      }
    },
    changePage(page) {
      console.log('changePage called with:', page);
      console.log('Current page:', this.currentPage);
      console.log('Total pages:', this.totalPages);
      
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        console.log('Fetching page:', this.currentPage);
        this.fetchMateriels();
      } else {
        console.warn('Page out of range:', page);
      }
    }
  }
};
</script>

<style scoped>
.container {
padding: 20px;
}

.tblEditBtn {
margin-right: 10px;
cursor: pointer;
}

.tblDelBtn {
cursor: pointer;
}

.mdl-textfield.is-dirty .mdl-textfield__label {
  display: none;
}
</style>




















