<template>
  <div class="page-content-wrapper" v-if="editModal">
    <div class="page-content">
      <div class="page-bar">
        <div class="page-title-breadcrumb">
          <div class="pull-left">
            <div class="page-title">Modifier Paiement</div>
          </div>
          <ol class="breadcrumb page-breadcrumb pull-right">
            <li>
              <i class="fa fa-home"></i>&nbsp;
              <a class="parent-item" href="/dashboard">Accueil</a>&nbsp;
              <i class="fa fa-angle-right"></i>
            </li>
            <li>
              <a class="parent-item" href="/liste-paiement-facture">Paiement</a>&nbsp;
              <i class="fa fa-angle-right"></i>
            </li>
            <li class="active">Modifier</li>
          </ol>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 col-sm-12">
          <div class="card card-box">
            <div class="card-head">
              <header>Modifier un paiement</header>
            </div>
            <div class="card-body" id="bar-parent">
              <form class="form-horizontal">
                <div class="form-body">
                  <!-- Montant total -->
                  <div class="col-lg-12 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" v-show="!form.montant_total">Montant Total</label>
                      <input class="mdl-textfield__input" type="number" v-model="form.montant_total" readonly>
                    </div>
                  </div>

                  <!-- Montant à payer -->
                  <div class="col-lg-12 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" v-show="!form.montant_payer">Montant à payer</label>
                      <input class="mdl-textfield__input" type="number" v-model="form.montant_payer" 
                        @input="validatedata('montant_payer')">
                      <span class="erreur">{{ montant_payer_erreur }}</span>
                    </div>
                  </div>

                  <!-- Date facture -->
                  <div class="col-lg-12 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" v-show="!form.date_facture">Date Facture</label>
                      <flat-pickr v-model="form.date_facture" class="mdl-textfield__input"
                        @input="validatedata('date_facture')"></flat-pickr>
                      <span class="erreur">{{ date_facture_erreur }}</span>
                    </div>
                  </div>

                  <!-- Boutons -->
                  <div class="col-lg-12 p-t-20 text-center">
                    <button type="button"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary"
                      @click="validerAvantUpdate">
                      Modifier
                    </button>
                    <button type="button"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                      @click="resetForm">
                      Annuler
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import Swal from "sweetalert2";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import permissionMixin from "@/permission";

export default {
  name: "editPaiementComponent",
  components: {
    flatPickr
  },
  mixins: [permissionMixin],
  data() {
    return {
      editModal: false,
      idPaiement: null,
      form: new Form({
        id: '',
        date_facture: '',
        id_facture: '',
        montant_payer: '',
        montant_total: '',
        partenaire: '',
        reliquat: ''
      }),
      montant_payer_erreur: '',
      date_facture_erreur: ''
    }
  },
  created() {
    bus.on('paiementModifier', (eventData) => {
      console.log("Données reçues:", eventData);
      if (eventData && eventData.paiement) {
        this.monterToupdate(eventData.paiement);
      }
    });
  },
  methods: {
    monterToupdate(paiement) {
      console.log("Montage des données:", paiement);
      this.idPaiement = paiement.id;
      this.editModal = true;

      this.form.id = paiement.id;
      this.form.date_facture = paiement.date_facture;
      this.form.id_facture = paiement.id_facture;
      this.form.montant_payer = paiement.montant_payer;
      this.form.montant_total = paiement.montant_total;
      this.form.partenaire = paiement.partenaire;
      this.form.reliquat = paiement.reliquat;

      this.$nextTick(() => {
        componentHandler.upgradeAllRegistered();
      });
    },

    validatedata(champ) {
      switch (champ) {
        case "montant_payer":
          this.montant_payer_erreur = "";
          if (!this.form.montant_payer) {
            this.montant_payer_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "date_facture":
          this.date_facture_erreur = "";
          if (!this.form.date_facture) {
            this.date_facture_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
      }
      return false;
    },

    validerAvantUpdate() {
      if (!this.validatedata('montant_payer') && !this.validatedata('date_facture')) {
        this.update_paiement(this.idPaiement);
      }
    },

    async update_paiement(id) {
      if (!this.hasUpdatePermission("Paiement Scolarité")) {
        Swal.fire({
          icon: 'warning',
          title: 'Permission refusée',
          text: "Vous n'avez pas la permission requise"
        });
        return;
      }

      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const formData = new FormData();

        formData.append('montant_payer', this.form.montant_payer);
        formData.append('date_facture', this.form.date_facture);
        formData.append('id_facture', this.form.id_facture);

        const response = await axios.post(`/paiement/update/${id}`, formData, { headers });

        if (response.data.statut === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Succès',
            text: 'Paiement modifié avec succès'
          });

          bus.emit('paiementAjoutee');
          this.resetForm();
        }
      } catch (error) {
        console.error("Erreur lors de la modification:", error);
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la modification'
        });
      }
    },

    resetForm() {
      this.form.reset();
      this.editModal = false;
      bus.emit('paiementDejaModifier', { editModal: false });
    }
  }
}
</script>

<style scoped>
.erreur {
  color: red;
  font-size: 12px;
}
</style>