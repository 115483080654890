<template>
  <div>
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="row">
            <div class="col-md-12">
              <!-- BEGIN PAGE TITLE & BREADCRUMB-->
              <h3 class="page-title">
                Transactions de Stock
                <small>Historique des mouvements de stock</small>
              </h3>
              <ul class="page-breadcrumb breadcrumb">
                <li>
                  <i class="fa fa-home"></i>
                  <span>Stock</span>
                  <i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <span>Transactions</span>
                </li>
              </ul>
              <!-- END PAGE TITLE & BREADCRUMB-->
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-12">
              <div class="portlet box blue">
                <div class="portlet-title">
                  <div class="caption">
                    <i class="fa fa-exchange"></i>
                    Transactions
                  </div>
                </div>
                <div class="portlet-body">
                  <!-- Filtres -->
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="control-label">Source</label>
                        <select v-model="sourceFilter" class="form-control">
                          <option value="">Toutes les sources</option>
                          <option value="stock">Stock</option>
                          <option value="commande">Commande</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="control-label">Type</label>
                        <select v-model="typeFilter" class="form-control">
                          <option value="">Tous les types</option>
                          <option value="materiel">Matériel</option>
                          <option value="consommable">Consommable</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <!-- Loading -->
                  <div v-if="loading" class="text-center">
                    <div class="loading-message">
                      <div class="block-spinner-bar"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>
                    </div>
                  </div>

                  <!-- Tableau des transactions -->
                  <div v-else class="table-responsive">
                    <table class="table table-striped table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Type</th>
                          <th>Libellé</th>
                          <th>Quantité</th>
                          <th>Source</th>
                          <th>Département</th>
                          <th>Utilisateur</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="transactions.length === 0">
                          <td colspan="7" class="text-center">Aucune transaction trouvée</td>
                        </tr>
                        <tr v-else v-for="transaction in transactions" :key="transaction.id">
                          <td>{{ formatDate(transaction.date_entree || transaction.created_at) }}</td>
                          <td>
                            <span :class="{'label': true, 'label-info': transaction.type === 'materiel', 'label-success': transaction.type === 'consommable'}">
                              {{ transaction.type }}
                            </span>
                          </td>
                          <td>{{ transaction.libelle }}</td>
                          <td>{{ transaction.quantite }}</td>
                          <td>
                            <span :class="{'label': true, 'label-primary': transaction.source === 'stock', 'label-warning': transaction.source === 'commande'}">
                              {{ transaction.source }}
                            </span>
                          </td>
                          <td>{{ transaction.nom_departement }}</td>
                          <td>{{ transaction.nom }} {{ transaction.prenom }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- Pagination -->
                  <paginate-component
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                    @pagechanged="changePage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appheader from "../layout/header.vue";
import appnavbar from "../layout/navbar.vue";
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    appheader,
    appnavbar,
    PaginateComponent,
  },
  data() {
    return {
      transactions: [],
      sourceFilter: "",
      typeFilter: "",
      currentPage: 1,
      perPage: 10,
      total: 0,
      loading: false
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
    totalPages() {
      return Math.ceil(this.total / this.perPage);
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    async fetchTransactions() {
      this.loading = true;
      try {
        console.log('Fetching page:', this.currentPage);
        const response = await axios.get(`http://localhost:8000/api/stock/transactions`, {
          headers: { Authorization: `Bearer ${this.token}` },
          params: {
            page: this.currentPage,
            per_page: this.perPage,
            source: this.sourceFilter,
            type: this.typeFilter
          }
        });
        console.log('API Response:', response.data);
        this.transactions = response.data.data;
        this.total = response.data.total;
        console.log('Total pages:', this.totalPages);
      } catch (error) {
        console.error('Erreur lors de la récupération des transactions:', error);
      } finally {
        this.loading = false;
      }
    },
    changePage(page) {
      console.log('changePage called with:', page);
      console.log('Current totalPages:', this.totalPages);
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchTransactions();
      } else {
        console.log('Page out of range:', page);
      }
    }
  },
  created() {
    this.fetchTransactions();
  },
  watch: {
    sourceFilter() {
      this.currentPage = 1;
      this.fetchTransactions();
    },
    typeFilter() {
      this.currentPage = 1;
      this.fetchTransactions();
    }
  }
};
</script>

<style scoped>
.page-content {
  margin: 0px 20px 20px 235px;
  padding: 20px 10px 10px;
  background-color: #fff;
}

.page-content-wrapper {
  margin-top: 46px;
}

.page-container {
  margin-top: 0;
  padding: 0;
  position: relative;
}

.portlet.box.blue {
  border: 1px solid #3598dc;
  border-top: 0;
  margin-top: 20px;
}

.portlet.box > .portlet-body {
  background-color: #fff;
  padding: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.label {
  display: inline-block;
  padding: 4px 8px;
  font-size: 11px;
  font-weight: 600;
  border-radius: 3px;
}

.label-info {
  background-color: #3598dc;
  color: white;
}

.label-success {
  background-color: #26a69a;
  color: white;
}

.label-primary {
  background-color: #337ab7;
  color: white;
}

.label-warning {
  background-color: #f1c40f;
  color: white;
}

.loading-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.block-spinner-bar {
  display: inline-block;
}

.block-spinner-bar > div {
  background-color: #3598dc;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
  margin: 0 3px;
}

.block-spinner-bar .bounce1 {
  animation-delay: -0.32s;
}

.block-spinner-bar .bounce2 {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
}

.pagination-wrapper {
  text-align: right;
  margin-top: 15px;
}

.breadcrumb {
  background-color: #f5f5f5;
  padding: 8px 15px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.breadcrumb > li {
  display: inline-block;
}

.breadcrumb > li + li:before {
  content: "/";
  padding: 0 5px;
  color: #ccc;
}

.page-title {
  margin-top: 0;
  margin-bottom: 20px;
}

.page-title small {
  font-size: 14px;
  color: #777;
  margin-left: 10px;
}

.table-responsive {
  margin-top: 15px;
}

.table > thead > tr > th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.text-center {
  text-align: center;
}
</style>

