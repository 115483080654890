<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader />
      </div>
    </div>
    <div class="page-container">
      <appnavbar />

      <div class="page-content-wrapper" v-if="viewListe">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="/dashboardCaissier">Tableau de
                    bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Recouvrement</li>
              </ol>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="tabbable-line">
                <!-- Boutons -->
                <ul class="nav customtab nav-tabs" role="tablist">
                  <button @click="filtre()"
                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-info">
                    Filtre
                  </button>
                  <div class="paiemen" style="display: flex" v-if="hasData">
                    <button class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-success"
                      style="width: 150px; margin-left: 10%;" @click="imprimerEnPDF()">
                      <i class="fi fi-rr-print" style="color: white"></i>
                      <span>Imprimer</span>
                    </button>
                  </div>
                </ul>

                <!-- Contenu -->
                <div class="tab-content">
                  <div class="tab-pane active fontawesome-demo" id="tab1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box" id="contenu-a-imprimer">
                          <!-- En-tête -->
                          <div class="card-head" v-if="nom_classe_selected">
                            <header>
                              Liste recouvrement
                              {{ nom_classe_selected.nom_classe }}
                              {{ nom_classe_selected.niveau }}
                              {{ nom_classe_selected.type_classe }}
                              <div class="annee-scolaire">
                                Année scolaire : {{ annee_selected?.intitule }}
                              </div>
                            </header>
                          </div>

                          <!-- Corps -->
                          <div class="card-body">
                            <div v-if="hasData">
                              <table class="table table-striped table-bordered">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>Matricule</th>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Mois Impayés</th>
                                    <th>Montant Mensuel</th>
                                    <th>Cumul Arriérés</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(eleve, index) in eleve_non_payers" :key="eleve.id_eleve">
                                    <td>{{ index + 1 }}</td>
                                    <td class="patient-img">
                                      <img :src="getImageUrl(eleve.photo)" alt="Photo" />
                                    </td>
                                    <td>{{ eleve.matricule }}</td>
                                    <td>{{ eleve.nom }}</td>
                                    <td>{{ eleve.prenom }}</td>
                                    <td>
                                      <div class="mois-impayes">
                                        <strong>{{ eleve.mois_payes }}/{{ eleve.total_mois }}</strong>
                                        <div class="mois-liste">
                                          Mois impayés : {{ eleve.liste_mois_impayes.join(', ') }}
                                        </div>
                                      </div>
                                    </td>
                                    <td>{{ formatMontant(eleve.montant_mensuel) }} FCFA</td>
                                    <td class="text-danger fw-bold">
                                      {{ formatMontant(eleve.montant_arrieres) }} FCFA
                                    </td>
                                  </tr>
                                  <!-- Total -->
                                  <tr class="table-info">
                                    <td colspan="7" class="text-right">
                                      <strong>Total Général des Arriérés</strong>
                                    </td>
                                    <td class="text-danger fw-bold">
                                      {{ formatMontant(calculerTotalArrieres()) }} FCFA
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div v-else class="alert alert-info">
                              Aucun élève avec des arriérés n'a été trouvé pour cette classe.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../eventBus";
import html2pdf from "html2pdf.js";
import axios from "@/axios";
import appheader from "../layout/header.vue";
import appnavbar from "../layout/navbar.vue";

export default {
  components: {
    appnavbar,
    appheader,
  },
  name: "listeRecouvrementComponent",
  
  data() {
    return {
      eleve_non_payers: [],
      nom_classe_selected: null,
      annee_selected: null,
      viewListe: false
    };
  },

  computed: {
    hasData() {
      return this.eleve_non_payers && this.eleve_non_payers.length > 0;
    }
  },

  mounted() {
    bus.on("nouveauFiltre", (eventData) => {
      console.log("Données reçues:", eventData);
      
      if (eventData) {
        this.eleve_non_payers = eventData.eleve_non_payers || [];
        this.nom_classe_selected = eventData.nom_classe_selected || null;
        this.annee_selected = eventData.annee_selected || null;
        this.viewListe = eventData.viewListe || false;
      }

      console.log("État après mise à jour:", {
        eleve_non_payers: this.eleve_non_payers,
        viewListe: this.viewListe
      });
    });
  },

  methods: {
    formatMontant(montant) {
      if (!montant) return '0';
      return montant.toLocaleString('fr-FR');
    },

    calculerTotalArrieres() {
      return this.eleve_non_payers.reduce((total, eleve) => 
        total + (eleve.montant_arrieres || 0), 0);
    },

    getImageUrl(url) {
      if (!url) return '';
      return `${process.env.VUE_APP_API_URL}/storage/${url}`;
    },

    filtre() {
      bus.emit("ancienFiltre", { viewListe: false });
    },

    imprimerEnPDF() {
      const element = document.getElementById("contenu-a-imprimer");
      if (!element) return;

      const nomClasse = this.nom_classe_selected?.nom_classe || 'classe';
      const dateStr = new Date().toLocaleDateString('fr-FR').replace(/\//g, '-');
      
      const options = {
        margin: 10,
        filename: `recouvrement_${nomClasse}_${dateStr}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { 
          scale: 2,
          useCORS: true,
          logging: true
        },
        jsPDF: { 
          unit: 'mm', 
          format: 'a4', 
          orientation: 'landscape'
        },
        pagebreak: { mode: 'avoid-all' }
      };

      html2pdf().set(options).from(element).save();
    }
  }
};
</script>

<style scoped>
.patient-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.mois-impayes {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mois-liste {
  font-size: 0.85em;
  color: #6c757d;
  font-style: italic;
}

.text-danger {
  color: #dc3545;
}

.text-right {
  text-align: right;
}

.fw-bold {
  font-weight: bold;
}

.table-info {
  background-color: #e2e3e5;
}

.btn-success {
  background-color: #28a745;
  color: white;
  transition: all 0.3s ease;
}

.btn-success:hover {
  background-color: #218838;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.btn-info {
  background-color: #17a2b8;
  color: white;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  text-align: center;
}

.annee-scolaire {
  font-size: 0.9em;
  color: #666;
  margin-top: 5px;
}

@media print {
  .page-header,
  .page-sidebar,
  .nav,
  .btn {
    display: none !important;
  }
  
  .page-content {
    margin: 0 !important;
    padding: 0 !important;
  }

  .card-box {
    border: none !important;
    box-shadow: none !important;
  }
}
</style>

<style scoped>
.patient-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.text-danger {
  color: #dc3545;
}

.text-right {
  text-align: right;
}

.fw-bold {
  font-weight: bold;
}

.table-info {
  background-color: #e2e3e5;
}

.btn-success {
  background-color: #28a745;
  color: white;
}

.btn-info {
  background-color: #17a2b8;
  color: white;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
</style>
