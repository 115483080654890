<template>
  <div class="page-wrapper" id="printSection">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <div class="hide-on-print">
          <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
        </div>
      </div>
    </div>
    <div class="page-container">
      <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
        :userRole="userRole" />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Facture</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard-saf'">Tableau de
                    bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" :href="'liste-facture'">Facture</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
              </ol>
            </div>
          </div>
          <div class="row print-head">
            <div class="col-md-12">
              <div class="white-box print-content">
                <div class="row">
                  <div>
                    <div style="display: flex; align-items: flex-start; gap: 8px; margin-bottom: 8px;">
                      <img style="width: 55px; height: auto;" :src="'/assets/img/logoCFPT--clr.png'" alt="logo-left" />
                      <p class="text-muted" style="font-size: 9px; margin: 0; padding: 0; line-height: 1.4;">
                        REPUBLIQUE DU SENEGAL<br />
                        MINISTERE DE LA FORMATION PROFESSIONNELLE<br />
                        DE L'APPRENTISSAGE ET DE L'INSERTION<br />
                        CENTRE DE FORMATION PROFESSIONNELLE ET TECHNIQUE SENEGAL - JAPON
                      </p>
                      <!-- <img src="/assets/img/logo_right.png" alt="logo-right" class="logo-right" /> -->
                    </div>
                  </div>
                </div>
                <h4 class="pull-right">
                  <p class="m-t-10">
                    <b>Dakar, le :</b> <i class="fa fa-calendar"></i>
                    {{ formattedDate }}
                  </p>
                </h4>
                <div class="row justify-content-left mt-4" style="width: 100%;">
                  <h3 class="pull-right text-center">
                    <b>FACTURE
                      {{ convertToUpperCase(this.factureProforma.type) }} N° {{ this.factureProforma.id }}{{
            formatDateFac(this.factureProforma.created_at) }}</b>
                  </h3>

                  <div class="col-md-6">
                    <!-- Utilisez la taille de colonne souhaitée -->
                    <div class="pull-left text-left">
                      <address>
                        <h5>
                          <strong class="font-bold addr-font-h4">OBJET :</strong>
                          {{ this.locations.designation }}
                          {{ this.salles.intitule }}
                        </h5>

                        <h5>
                          <strong class="font-bold addr-font-h4">CLIENT :</strong>
                          {{ this.partenaires.nom_partenaire }}
                        </h5>
                        <h5>
                          <strong class="font-bold addr-font-h4">TEL :</strong>
                          {{ this.partenaires.contact }}
                        </h5>
                        <h5>
                          <strong class="font-bold addr-font-h4">Periode :</strong>
                          {{ FacTime(this.reservations.date_debut) }} au
                          {{ FacTime(this.reservations.date_fin) }}
                        </h5>
                      </address>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="table-responsive m-t-40 m-l-40">
                      <table class="table table-hover invoice-table">
                        <thead>
                          <tr>
                            <th style="white-space: nowrap; text-align: center;">DESIGNATION</th>
                            <th style="white-space: nowrap; text-align: center;">Nombre de jours</th>
                            <th style="white-space: nowrap; text-align: center;">Montant/Jour</th>
                            <th style="white-space: nowrap; text-align: center;">Cout</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <!--  <td class="text-center">1</td> -->
                            <td class="text-right">
                              {{ this.locations.designation }}
                              {{ this.salles.intitule }}
                            </td>
                            <td class="text-right">
                              {{ this.locations.nombre_jour }}
                            </td>
                            <td class="text-right">
                              {{ this.locations.montant_jour }}
                            </td>
                            <!-- <td class="text-right">#IN-345609865</td> -->
                            <td class="text-right">
                              {{
            this.locations.montant_jour *
            this.locations.nombre_jour
          }}
                            </td>
                          </tr>
                          <tr v-if="this.partenaires.exoneration === 0">
                            <td colspan="3" class="text-right black-text bold">MONTANT HT</td>
                            <td>
                              {{
            this.locations.montant_jour *
            this.locations.nombre_jour
          }}
                            </td>
                          </tr>

                          <tr v-if="this.partenaires.exoneration === 0">
                            <td colspan="3" class="text-right black-text bold">TVA (18 %)</td>
                            <td>
                              {{
            (this.locations.montant_jour *
              this.locations.nombre_jour * 18) / 100
          }}
                            </td>
                          </tr>

                          <tr v-if="this.partenaires.exoneration === 0" class="black-text bold">
                            <td colspan="3" class="text-right">MONTANT TTC</td>
                            <td>
                              {{
            ((this.locations.montant_jour *
              this.locations.nombre_jour * 18) / 100) + (this.locations.montant_jour *
                this.locations.nombre_jour)
          }}
                            </td>
                          </tr>

                          <tr v-if="this.partenaires.exoneration === 1" class="black-text bold">
                            <td colspan="3" class="text-right">MONTANT TTC</td>
                            <td>
                              {{
            this.locations.montant_jour * this.locations.nombre_jour
          }}
                            </td>
                          </tr>

                          <tr v-if="this.factureProforma.type === 'Solde'" class="black-text bold">
                            <td colspan="3" class="text-right">RELIQUAT</td>
                            <td>
                              {{
            (this.locations.montant_jour *
              this.locations.nombre_jour) - (
              this.factureProforma.montant_payer)
          }}
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="signature" v-if="this.partenaires.exoneration === 0">
                      <strong class="black-text" style="font-size: 12px">
                        Arrêtée La Présente Facture {{ this.factureProforma.type }} à la Somme de :
                        {{
            convertNumberToWords(
              ((this.locations.montant_jour *
                this.locations.nombre_jour * 18) / 100) + (this.locations.montant_jour *
                  this.locations.nombre_jour)
            )
          }} FCFA
                      </strong>
                    </div>
                    <div class="signature" v-if="this.partenaires.exoneration === 1">
                      <strong class="black-text" style="font-size: 12px">
                        Arrêtée La Présente Facture {{ this.factureProforma.type }} à la Somme de :
                        {{
            convertNumberToWords(
              this.locations.montant_jour *
              this.locations.nombre_jour
            )
          }} FCFA
                      </strong>
                    </div>
                    <hr /><br>

                    <div class="signature mt-4">Signature: _______________</div>
                    <div class="pull-right text-right"></div>

                    <div class="clearfix"></div>
                    <hr />

                    <footer class="print-footer"
                      style="bottom: 0; text-align: center; width: 100%; padding: 0px; border-top: 1px solid #050505;">
                      <div class="row">
                        <div class="">
                          <p style="margin-bottom: 5px; font-size: 10px;">SUD FIDAK CICES VDN -BP 8411 DAKAR-YOFF -
                            Tél.:+221 33 869.82.82 - +221 33 827.62.70.</p>
                          <p style="margin-bottom: 5px;font-size: 10px;">Site / www.cfpt-sj.sn -email :
                            cfptsj@orange.snCode Banque : SN011 Code Guichet : 01017 Compte Bancaire N°022004505689,</p>
                          <p style="margin-bottom: 5px; font-size: 10px;">RIB: 59 BIC: SGSNSNDA3X NINEA: 0061972150R0
                          </p>
                        </div>
                      </div>
                    </footer>


                  </div>
                </div>
              </div>
            </div>

            <div class="text-right hide-on-print">
              <a class="btn btn-danger" @click="goBack()">
                Retour
              </a>
              <button @click="handlePrint" class="btn btn-default btn-outline" type="button">
                <span><i class="fa fa-print"></i> Imprimer</span>
              </button>
            </div>

          </div>
        </div>
      </div>
      <appafterContent />
    </div>
    <appfooter />

  </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import FormulaireModification from "./editFacture.vue";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import { mapState, mapActions } from "vuex";
import permissionMixin from "@/permission";

export default {
  name: "factureCompenent",
  components: {
    FormulaireModification,
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
  },
  mixins: [permissionMixin],
  data() {
    return {
      form: new Form({
        designation: "",
        nombre_jour: "",
        montant_jour: "",
        date_location: "",
        id_partenaire: "",
        id_salle: "",
        id_user: "",
      }),
      factureProforma: [],
      locations: [],
      salles: [],
      reservations: [],
      partenaires: [],
      idUser: "",
      editModal: false,

      activePhase: 1,
      idUser: "",
      userEnCoursDeModification: null,
      factureId: "",
      formattedDate: "",
    };
  },
  computed: {
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  async created() {
    console.log('Component created - initializing...');
    await this.get_permission();
    this.get_facture(this.$route.params.id);
    bus.on("factureAjoutee", () => {
      this.get_facture(this.$route.params.id);
    });

    bus.on("factureDejaModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.get_facture(this.$route.params.id);
    });
    this.factureId = this.$route.params.id;
    this.formatCurrentDate();
  },
  /*   mounted() {
      this.get_facture(this.$route.params.id);
      bus.on("factureAjoutee", () => {
        this.get_facture(this.$route.params.id);
      });
  
      bus.on("factureDejaModifier", (eventData) => {
        this.editModal = eventData.editModal;
        this.get_facture(this.$route.params.id);
      });
      this.factureId = this.$route.params.id;
      this.formatCurrentDate();
    }, */

  methods: {
    ...mapActions(["login", "logout"]),

    handlePrint() {
      const printContent = document.getElementById('printSection');
      if (!printContent) {
        console.error('Section d\'impression non trouvée');
        return;
      }
      console.log('Contenu à imprimer:', printContent.innerHTML);

      console.log('Données avant impression:', {
        factureProforma: this.factureProforma,
        locations: this.locations,
        partenaires: this.partenaires
      });
      if (this.factureProforma && this.locations) {
        window.print();
      } else {
        Swal.fire(
          "Attention",
          "Veuillez attendre le chargement complet des données avant d'imprimer",
          "warning"
        );
      }
    },

    async get_facture(id) {
      console.log('Début get_facture avec id:', id);
  console.log('Début get_facture avec id:', id);
  const token = localStorage.getItem("token");
  const headers = { Authorization: `Bearer ${token}` };
  if (this.hasReadPermission("Facture")) {
    try {
      const response = await axios.post(`/get/facture/${id}`, {}, { headers });
      console.log('Réponse reçue:', response.data);
      
      this.factureProforma = response.data.facture;
      console.log('FactureProforma:', this.factureProforma);

      this.locations = this.factureProforma.location;
      console.log('Locations:', this.locations);

      this.partenaires = this.locations.partenaire;
      console.log('Partenaires:', this.partenaires);
      
      this.salles = this.locations.salle;
      console.log('Salles:', this.salles);
      
      this.reservations = this.factureProforma.reservation;
      console.log('Reservations:', this.reservations);
    } catch (error) {
      console.error('Erreur complète:', error);
      Swal.fire(
        "Erreur!",
        "Une erreur est survenue lors de la récupération des factures",
        "error"
      );
    }
  } else {
    console.log('Permission refusée pour Facture');
    showDialog3("Vous n'avez pas la permission requise.");
  }
},

    FacTime(dateTime) {
      // Utilisez une fonction pour formater la date
      return this.Fac(new Date(dateTime));
    },

    convertToUpperCase(word) {
      if (typeof word === "string") {
        return word.toUpperCase();
      } else {
        return "";
      }
    },

    goBack() {
      // Utilisez la méthode Vue.js $router pour revenir en arrière
      //this.resetForm()
      this.$router.go(-1);
    },

    formatDateFac(date) {
      const dateObj = new Date(date);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      return year + month + day;
    },

    convertNumberToWords(number) {
      // Tableaux de nombres et de mots
      const units = [
        "",
        "Un",
        "Deux",
        "Trois",
        "Quatre",
        "Cinq",
        "Six",
        "Sept",
        "Huit",
        "Neuf",
      ];
      const teens = [
        "Dix",
        "Onze",
        "Douze",
        "Treize",
        "Quatorze",
        "Quinze",
        "Seize",
        "Dix-Sept",
        "Dix-Huit",
        "Dix-Neuf",
      ];
      const tens = [
        "",
        "Dix",
        "Vingt",
        "Trente",
        "Quarante",
        "Cinquante",
        "Soixante",
        "Soixante-Dix",
        "Quatre-Vingt",
        "Quatre-Vingt-Dix",
      ];
      const thousands = ["", "Mille", "Million", "Milliard", "Billion", "Trillion"];

      function convertToWords(number) {
        if (number === 0) return "Zéro";

        let result = "";
        let i = 0;

        while (number > 0) {
          if (number % 1000 !== 0) {
            let groupInWords = convertHundreds(number % 1000);
            // Si le premier chiffre du groupe est zéro, on ignore le "Un"
            if (number % 1000 >= 100 && number % 1000 < 200) {
              groupInWords = groupInWords.substring(3); // Ignore "Un"
            }
            result = groupInWords + (i > 0 ? " " + thousands[i] : "") + " " + result;
          }
          number = Math.floor(number / 1000);
          i++;
        }

        return result.trim();
      }




      // Fonction pour convertir les centaines en mots
      function convertHundreds(number) {
        let result = "";

        if (number >= 100) {
          result += units[Math.floor(number / 100)] + " Cent ";
          number %= 100;
        }

        if (number >= 20) {
          result += tens[Math.floor(number / 10)] + " ";
          number %= 10;
        }

        if (number >= 10) {
          result += teens[number - 10] + " ";
          return result;
        }

        if (number > 0) {
          result += units[number] + " ";
        }

        return result;
      }

      return convertToWords(number);
    },

    Fac(date) {
      const day = date.getDate();
      const monthNumber = date.getMonth() + 1;
      const year = date.getFullYear();

      // Tableau des trois premières lettres des mois en français
      const monthAbbreviations = [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Aoû",
        "Sep",
        "Oct",
        "Nov",
        "Déc",
      ];

      // Obtenez les trois premières lettres du mois correspondant au numéro du mois
      const month = monthAbbreviations[monthNumber - 1];

      return `${day} ${month} ${year}`;
    },

    formatCurrentDate() {
      // Obtenir la date actuelle
      const today = new Date();
      // Options pour formater la date
      const options = { day: "numeric", month: "long", year: "numeric" };
      // Formater la date en "14 Juin 2017"
      const formattedDate = today.toLocaleDateString("fr-FR", options);
      // Mettre à jour la variable de date formatée
      this.formattedDate = formattedDate;
    },
  },
};
</script>
<style>
@page {
  size: A4;
  margin: 0;
}

/* Styles généraux */
@media screen {
  .hide-on-print {
    display: block;
  }
}

/* Styles pour l'impression */
@media print {
  html, body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }

  * {
    visibility: visible !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .page-wrapper {
    position: relative;
    width: 210mm;
    min-height: 297mm;
    margin: 0 auto;
    padding: 8mm;
    padding-top: 2mm;
    visibility: visible !important;
    page-break-after: avoid;
  }

  .hide-on-print {
    display: none !important;
  }

  .print-content {
    display: block !important;
    visibility: visible !important;
  }

  .white-box {
    padding: 0;
    margin: 0;
    box-shadow: none;
    display: block !important;
    visibility: visible !important;
    position: static !important;
  }

  .print-head {
    margin-bottom: 3mm;
    page-break-inside: avoid;
  }

  .white-box {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  img {
    max-height: 40px;
    margin-bottom: 2mm;
  }

  .print-footer {
    position: fixed;
    bottom: 20mm;
    width: calc(100% - 40mm);
    margin: 0 auto;
    page-break-inside: avoid;
  }

  .table-responsive {
    margin-top: 3mm;
    page-break-inside: avoid;
  }

  .invoice-table {
    margin-top: 5mm;
    page-break-inside: avoid;
    display: table !important;
    visibility: visible !important;
    border-collapse: collapse !important;
  }

  .invoice-table th,
  .invoice-table td {
    border: 1px solid #000 !important;
    padding: 8px !important;
  }
}

/* Styles normaux */
.invoice-table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table th,
.invoice-table td {
  border: 2px solid #050505;
  padding: 1%;
  text-align: center;
}

.invoice-table th {
  background-color: #f2f2f2;
}

.black-text {
  color: black !important;
}

.print-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  border-top: 1px solid #050505;
  text-align: center;
}

.print-head {
  position: relative;
  width: 100%;
}
</style>
