<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Liste des Consommables</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.html">Home</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li class="active">Liste des Consommables</li>
              </ol>
            </div>
          </div>

          <!-- Formulaire de modification -->
          <div v-if="selectedConsommable" class="card">
            <div class="card-header">
              <h3>Modifier le Consommable</h3>
            </div>
            <div class="card-body">
              <form class="form-horizontal" @submit.prevent="updateConsommable">
                <div class="row">
                  <div class="col-lg-6 p-t-20">
                    <div :class="['mdl-textfield', 'mdl-js-textfield', 'mdl-textfield--floating-label', 'txt-full-width', { 'is-dirty': selectedConsommable.libelle }]">
                      <label class="mdl-textfield__label" for="libelle">Libellé</label>
                      <input class="mdl-textfield__input" type="text" id="libelle" v-model="selectedConsommable.libelle" />
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div :class="['mdl-textfield', 'mdl-js-textfield', 'mdl-textfield--floating-label', 'txt-full-width', { 'is-dirty': selectedConsommable.quantite }]">
                      <label class="mdl-textfield__label" for="quantite">Quantité</label>
                      <input class="mdl-textfield__input" type="number" id="quantite" v-model="selectedConsommable.quantite" />
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div :class="['mdl-textfield', 'mdl-js-textfield', 'mdl-textfield--floating-label', 'txt-full-width', { 'is-dirty': selectedConsommable.marque }]">
                      <label class="mdl-textfield__label" for="marque">Marque</label>
                      <input class="mdl-textfield__input" type="text" id="marque" v-model="selectedConsommable.marque" />
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div :class="['mdl-textfield', 'mdl-js-textfield', 'mdl-textfield--floating-label', 'txt-full-width', { 'is-dirty': selectedConsommable.date_entree }]">
                      <label class="mdl-textfield__label" for="date_entree">Date d'Entrée</label>
                      <input class="mdl-textfield__input" type="date" id="date_entree" v-model="selectedConsommable.date_entree" />
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div :class="['mdl-textfield', 'mdl-js-textfield', 'mdl-textfield--floating-label', 'txt-full-width', { 'is-dirty': selectedConsommable.id_etat }]">
                      <label class="mdl-textfield__label" for="id_etat">État</label>
                      <select class="mdl-textfield__input" id="id_etat" v-model="selectedConsommable.id_etat">
                        <option value="" disabled></option>
                        <option v-for="etat in etats" :key="etat.id" :value="etat.id">{{ etat.intitule }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
    <div :class="['mdl-textfield', 'mdl-js-textfield', 'mdl-textfield--floating-label', 'txt-full-width', { 'is-dirty': selectedConsommable.id_commande }]">
      <label class="mdl-textfield__label" for="id_commande">Commande</label>
      <select class="mdl-textfield__input" id="id_commande" v-model="selectedConsommable.id_commande">
        <option value="" disabled></option>
        <option v-for="commande in commandes" :key="commande.id" :value="commande.id">
          Commande #{{ commande.id }} - {{ commande.reference_commande }}
        </option>
      </select>
    </div>
  </div>
                  <div class="col-lg-6 p-t-20">
                    <div :class="['mdl-textfield', 'mdl-js-textfield', 'mdl-textfield--floating-label', 'txt-full-width', { 'is-dirty': selectedConsommable.id_departement }]">
                      <label class="mdl-textfield__label" for="id_departement">Département</label>
                      <select class="mdl-textfield__input" id="id_departement" v-model="selectedConsommable.id_departement">
                        <option value="" disabled></option>
                        <option v-for="departement in departements" :key="departement.id" :value="departement.id">{{ departement.nom_departement }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-12 p-t-20 text-center">
                    <button
                      type="submit"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                    >
                      Enregistrer
                    </button>
                    <button
                      type="button"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                      @click="resetForm"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <!-- Liste des consommables -->
          <div class="row">
            <div class="col-md-12">
              <div class="card card-box">
                <div class="card-head">
                  <header>
                    <h4>Liste des consommables</h4>
                  </header>
                  <div class="tools">
                    <input
                      type="text"
                      placeholder="Recherche..."
                      v-model="searchQuery"
                      @input="fetchConsommables"
                    />
                    <select v-model="perPage" @change="fetchConsommables">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </select>
                  </div>
                </div>
                <div class="card-body">
                  <a :href="'/consommable-create'" class="btn btn-primary mb-3">Ajouter +</a>
                  <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
                  <div v-if="is_loading">Chargement...</div>
                  <div v-if="!consommables || consommables.length === 0 && !is_loading && searchQuery">Consommable inexistant.</div>
                  <div v-if="!consommables || consommables.length === 0 && !is_loading && !searchQuery">Aucun consommable trouvé.</div>
                  <table v-else class="table table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Libellé</th>
                        <th>Quantité</th>
                        <th>Marque</th>
                        <th>État</th>
                        <th>Département</th>
                        <th>Commande</th>
                        <th>Date d'Entrée</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="consommable in consommables" :key="consommable.id">
                        <td>{{ consommable.id }}</td>
                        <td>{{ consommable.libelle }}</td>
                        <td>{{ consommable.quantite }}</td>
                        <td>{{ consommable.marque }}</td>
                        <td>{{ consommable.etat ? consommable.etat.intitule : '-' }}</td>
                        <td>{{ consommable.departement ? consommable.departement.nom_departement : '-' }}</td>
                        <td>{{ consommable.commande ? consommable.commande.reference_commande : '-' }}</td>
                        <td>{{ formatDate(consommable.date_entree) }}</td>
                        <td>
                          <a @click="editConsommable(consommable)" class="tblEditBtn">
                            <i class="fa fa-pencil" title="Modifier"></i>
                          </a>
                          <a @click="confirmDelete(consommable.id)" class="tblDelBtn">
                            <i class="fa fa-trash-o" title="Supprimer" style="color: red;"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <paginate-component
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                    :total="total"
                    :perPage="perPage"
                    @pagechanged="changePage"
                  />
                  <!-- Debug info -->
                  <div v-if="consommables.length > 0" style="margin-top: 10px; font-size: 12px; color: #666;">
                    Page {{ currentPage }} sur {{ totalPages }} ({{ total }} éléments)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <appafterContent />
    <appfooter />
  </div>
</template>

<script>
import axios from "@/axios";
import appheader from "../layout/header.vue";
import appnavbar from "../layout/navbar.vue";
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";
import appfooter from "../layout/footer.vue";
import appafterContent from "../layout/afterContent.vue";
import Swal from 'sweetalert2';

export default {
  components: {
    appheader,
    appnavbar,
    appfooter,
    appafterContent,
    PaginateComponent,
  },
  data() {
    return {
      consommables: [],
      is_loading: true,
      errorMessage: "",
      searchQuery: "",
      perPage: 10,
      currentPage: 1,
      total: 0,
      selectedConsommable: null,
      etats: [],
      departements: [],
       commandes: [],
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.perPage);
    },
    token() {
      return localStorage.getItem("token");
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  created() {
    this.fetchConsommables();
    this.getEtats();
    this.getDepartements();
    this.getCommandes();
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return '';
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return new Date(dateString).toLocaleDateString('fr-FR', options);
    },
    async fetchConsommables() {
    this.is_loading = true;
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };

    try {
      const response = await axios.get('http://localhost:8000/api/consommable/search', {
        headers,
        params: {
          search: this.searchQuery,
          per_page: this.perPage,
          page: this.currentPage
        }
      });

      if (response.data.statut === 200) {
        this.consommables = response.data.consommables.data;
        this.total = response.data.consommables.total;

        // Si la page actuelle dépasse le nombre total de pages, revenez à la dernière page valide
        if (this.currentPage > this.totalPages) {
          this.currentPage = this.totalPages;
          this.fetchConsommables(); // Récupérer à nouveau les données pour la nouvelle page
        }

        this.errorMessage = "";
      } else {
        this.errorMessage = response.data.message;
      }
    }finally {
      this.is_loading = false;
    }
  },

  async deleteConsommable(id) {
    const headers = { Authorization: `Bearer ${this.token}` };
    try {
      await axios.delete(`http://localhost:8000/api/consommable/delete/${id}`, { headers });
      Swal.fire('Succès!', 'Consommable supprimé avec succès', 'success');
      this.fetchConsommables(); // Récupérer à nouveau les consommables
    } catch (error) {
      console.error('Erreur lors de la suppression du consommable:', error);
    }
  },
    async getEtats() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get('http://localhost:8000/api/etats', { headers });
        this.etats = response.data.etats;
      } catch (error) {
        console.error('Erreur lors de la récupération des états:', error);
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des états', 'error');
      }
    },
    async getDepartements() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get("http://localhost:8000/api/departement/all", { headers });
        this.departements = response.data.departement;
      } catch (error) {
        console.error('Erreur lors de la récupération des départements:', error);
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des départements', 'error');
      }
    },
    async getCommandes() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get('http://localhost:8000/api/commandes', { headers });
        if (response.data.statut === 200) {
          this.commandes = response.data.commandes;
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des commandes:', error);
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des commandes', 'error');
      }
    },

    editConsommable(consommable) {
      this.selectedConsommable = { 
        ...consommable,
        id_commande: consommable.commande ? consommable.commande.id : null // Assurer la bonne valeur
      };
    },

    async updateConsommable() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const dataToSend = {
          ...this.selectedConsommable,
          id_commande: this.selectedConsommable.id_commande // S'assurer que id_commande est inclus
        };

        const response = await axios.post(
          `http://localhost:8000/api/consommable/update/${this.selectedConsommable.id}`, 
          dataToSend, 
          { headers }
        );

        if (response.data.statut === 200) {
          Swal.fire('Succès!', 'Consommable mis à jour avec succès', 'success');
          this.fetchConsommables();
          this.selectedConsommable = null;
        }
      } catch (error) {
        console.error('Erreur lors de la mise à jour du consommable:', error);
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la mise à jour du consommable', 'error');
      }
    },
    resetForm() {
      this.selectedConsommable = null;
    },
    async confirmDelete(id) {
      const result = await Swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Vous ne pourrez pas revenir en arrière!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimer!'
      });

      if (result.isConfirmed) {
        this.deleteConsommable(id);
      }
    },
    changePage(page) {
      console.log('changePage called with:', page);
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.fetchConsommables();
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin: 20px;
}
.mdl-textfield.is-dirty .mdl-textfield__label {
  display: none;
}
</style>

