<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
        :userRole="userRole" />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Historique des Paiements Hébergement</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'dashboardCaissier'">Tableau de
                    Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Historique des Paiements</li>
              </ol>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-head">
                  <header>Historique des Paiements</header>
                </div>
                <div class="card-body">
                  <!-- Recherche d'élève -->
                  <div class="form-group" v-show="searchMatricule">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                      <input 
                        type="text" 
                        class="form-control" 
                        v-model="search_query"
                        @input="performSearch" 
                        placeholder="Rechercher par matricule"
                      />
                    </div>
                  </div>

                  <!-- Liste des élèves trouvés -->
                  <div class="search-results" v-if="eleves.length > 0">
                    <div 
                      v-for="(eleve, key) in eleves" 
                      :key="key" 
                      @click="selectEleve(eleve)"
                      class="search-item mb-2"
                    >
                      <div class="d-flex align-items-center p-2 rounded hover-shadow">
                        <img 
                          :src="getImageUrl(eleve.user.photo)" 
                          class="rounded-circle mr-3"
                          style="width: 40px; height: 40px; object-fit: cover;"
                          alt="Photo de l'élève"
                        />
                        <div>
                          <strong>{{ eleve.user.nom }} {{ eleve.user.prenom }}</strong>
                          <br>
                          <small class="text-muted">Matricule: {{ eleve.user.matricule }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                    <!-- Message si pas d'hébergement -->
    <div v-if="!hebergement && selectedEleve" class="alert alert-warning text-center mt-4">
      <i class="fas fa-exclamation-triangle me-2"></i>
      Cet étudiant n'a pas souscrit à un hébergement.
    </div>

                  <!-- Affichage de l'historique -->
                  <div v-if="selectedEleve" class="mt-4">
                    <div class="row mb-4">
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-3 text-center">
                                <img 
                                  :src="getImageUrl(selectedEleve.photo)" 
                                  class="rounded-circle mb-3"
                                  style="width: 150px; height: 150px; object-fit: cover;"
                                  alt="Photo de l'élève"
                                />
                              </div>
                              <div class="col-md-9">
                                <h4 class="card-title">Informations de l'élève</h4>
                                <div class="row">
                                  <div class="col-md-6">
                                    <p><strong>Nom:</strong> {{ selectedEleve.nom }}</p>
                                    <p><strong>Prénom:</strong> {{ selectedEleve.prenom }}</p>
                                    <p><strong>Matricule:</strong> {{ selectedEleve.matricule }}</p>
                            
                                    <p><strong>Année Scolaire:</strong> {{ hebergement?.annee_scolaire?.intitule|| '2023-2024' }}</p>
                                  </div>
                                  <div class="col-md-6">
                                    <p><strong>Classe:</strong> {{ selectedEleve.classe }}</p>
                                    <p><strong>Chambre:</strong> {{ hebergement?.chambre?.intitule || 'Non assignée' }}</p>
                                    <p><strong>Total Payé:</strong> {{ formatMontant(hebergement?.montant_total_paye || 0) }} FCFA</p>
                                    <p><strong>Reste à Payer:</strong> {{ formatMontant(getMontantRestant()) }} FCFA</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title">État des Paiements Mensuels</h4>
                        <div class="table-responsive">
                          <table class="table table-hover">
                            <thead class="bg-light">
                              <tr>
                                <th>Mois</th>
                                <th >Statut</th>
                                <th>Montant</th>
                                <!-- //<th>État</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(mois, index) in listeMois" :key="index">
                                <td>
                                  <i class="fa fa-calendar-alt mr-2"></i>
                                  {{ mois }}
                                </td>
                                <td class="text-center align-middle">
    <span 
      class="status-badge"
      :class="getMoisStatusText(index + 1) === 'Payé' ? 'status-paid' : 'status-unpaid'"
    >
      {{ getMoisStatusText(index + 1) }}
    </span>
  </td>
                                <td>{{ formatMontant(MONTANT_MENSUEL) }} FCFA</td>
                                
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Bouton de retour -->
          <div class="text-center mt-4 mb-4">
            <button 
              class="btn btn-primary px-4"
              @click="retour"
            >
              Retour
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import { mapState } from "vuex";
import Appheader from "../layout/header.vue";
import Appfooter from "../layout/footer.vue";
import Appnavbar from "../layout/navbar.vue";

export default {
  name: 'HistoriqueHebergement',
  components: {
    'appnavbar': Appnavbar,
    'appfooter': Appfooter,
    'appheader': Appheader
  },
  data() {
    return {
      search_query: "",
      eleves: [],
      selectedEleve: null,
      hebergement: null,
      searchMatricule: true,
      listeMois: [
        'Janvier', 'Février', 'Mars', 'Avril',
        'Mai', 'Juin', 'Juillet'
      ],
      MONTANT_MENSUEL: 30000
    };
  },
  computed: {
    ...mapState([
      'userNom',
      'userPrenom',
      'userPhoto',
      'userIdrole',
      'userRole'
    ])
  },
  methods: {
    getImageUrl(url) {
      return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : '/assets/img/default-avatar.jpg';
    },

    async performSearch() {
      if (this.search_query.length < 2) return;
      
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/recherche/eleve", {
          params: { query: this.search_query },
          headers: { Authorization: `Bearer ${token}` }
        });
        this.eleves = response.data;
      } catch (error) {
        console.error(error);
        showDialog3("Erreur lors de la recherche");
      }
    },

    async selectEleve(eleve) {
      try {
        const token = localStorage.getItem("token");
        
        // Récupérer les données d'hébergement
        const response = await axios.get(`/hebergement/eleve/${eleve.id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        console.log("Réponse hébergement:", response.data);
        
        if (response.data.success) {
          this.hebergement = response.data.hebergement;
          
          try {
            // Route correcte pour la chambre
            const chambreResponse = await axios.get(`/chambre/show/${this.hebergement.id_chambre}`, {
              headers: { Authorization: `Bearer ${token}` }
            });

            // Route correcte pour l'année académique
            const anneeResponse = await axios.get(`/annee_academique/show/${this.hebergement.id_annee}`, {
              headers: { Authorization: `Bearer ${token}` }
            });

            console.log("Réponse chambre:", chambreResponse.data);
            console.log("Réponse année:", anneeResponse.data);

            // Mettre à jour l'hébergement avec les détails
            if (chambreResponse.data.statut === 200) {
              this.hebergement.chambre = chambreResponse.data.chambre;
            }

            if (anneeResponse.data.statut === 200) {
              this.hebergement.annee_academique = anneeResponse.data.annee_academique;
            }

          } catch (error) {
            console.error("Erreur lors du chargement des relations:", error.response?.data);
          }

          console.log("Hébergement final:", this.hebergement);
        } else {
          showDialog3(response.data.message);
          this.hebergement = null;
        }
        
        // Mettre à jour selectedEleve
        this.selectedEleve = {
          id: eleve.id,
          nom: eleve.user.nom,
          prenom: eleve.user.prenom,
          matricule: eleve.user.matricule,
          photo: eleve.user.photo,
          classe: eleve.inscription[0]?.classe?.type_classe + " " + 
                  eleve.inscription[0]?.classe?.nom_classe + " " + 
                  eleve.inscription[0]?.classe?.niveau
        };
        
        this.eleves = [];
        this.searchMatricule = false;

      } catch (error) {
        console.error("Erreur complète:", error.response?.data);
        showDialog3(error.response?.data?.message || "Erreur lors de la récupération des données d'hébergement");
      }
    },

    getMoisStatus(moisIndex) {
      const moisPayes = Math.floor((this.hebergement?.montant_total_paye || 0) / this.MONTANT_MENSUEL);
      return {
        'badge badge-success': moisIndex <= moisPayes,
        'badge badge-danger': moisIndex > moisPayes
      };
    },

    getMoisStatusText(moisIndex) {
      const moisPayes = Math.floor((this.hebergement?.montant_total_paye || 0) / this.MONTANT_MENSUEL);
      return moisIndex <= moisPayes ? 'Payé' : 'Non payé';
    },

    getMontantRestant() {
      const montantTotal = 7 * this.MONTANT_MENSUEL;
      return montantTotal - (this.hebergement?.montant_total_paye || 0);
    },

    formatMontant(montant) {
      return new Intl.NumberFormat('fr-FR').format(montant);
    },

    getMoisIcon(moisIndex) {
      const moisPayes = Math.floor((this.hebergement?.montant_total_paye || 0) / this.MONTANT_MENSUEL);
      return moisIndex <= moisPayes ? 'fa fa-check-circle' : 'fa fa-times-circle';
    },

    getMoisIconStyle(moisIndex) {
      const moisPayes = Math.floor((this.hebergement?.montant_total_paye || 0) / this.MONTANT_MENSUEL);
      return {
        color: moisIndex <= moisPayes ? '#28a745' : '#dc3545',
        fontSize: '20px'
      };
    },

    retour() {
      this.$router.go(-1) // Retourne à la page précédente
      // OU
      // this.$emit('fermer') // Si vous voulez émettre un événement vers le parent
    }
  }
};
</script>

<style scoped>
.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  min-width: 120px;
  height: 32px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.bg-success {
  background-color: rgba(46, 125, 50, 0.1) !important;
  color: #2e7d32 !important;
  border: 1px solid #2e7d32;
}

.bg-danger {
  background-color: rgba(211, 47, 47, 0.1) !important;
  color: #d32f2f !important;
  border: 1px solid #d32f2f;
}

/* Ajout pour centrer la colonne Statut */
.table td {
  text-align: center !important;
  vertical-align: middle !important;
}

.table th {
  text-align: center !important;
}

.status-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  min-width: 120px;
  height: 32px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.status-paid {
  background-color: rgba(46, 125, 50, 0.1);
  color: #2e7d32;
  border: 1px solid #2e7d32;
}

.status-unpaid {
  background-color: rgba(211, 47, 47, 0.1);
  color: #d32f2f;
  border: 1px solid #d32f2f;
}

/* Assurez-vous que la cellule du tableau est bien centrée */
td {
  vertical-align: middle !important;
  height: 50px;
}
.table td {
  padding: 12px;
}

/* Style du tableau pour un meilleur alignement */
.table {
  vertical-align: middle;
}

.badge-success {
  background-color: #e8f5e9;
  color: #28a745;
  border: 1px solid #28a745;
}

.badge-danger {
  background-color: #ffebee;
  color: #dc3545;
  border: 1px solid #dc3545;
}

.hover-shadow:hover {
  background-color: #f8f9fa;
  cursor: pointer;
  transition: all 0.3s ease;
}

.search-item {
  border: 1px solid #dee2e6;
  border-radius: 8px;
}

.card {
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.card-title {
  color: #2196F3;
  font-weight: 500;
  margin-bottom: 20px;
}

.table th {
  font-weight: 600;
  color: #495057;
}

.table td {
  vertical-align: middle;
}

.input-group-text {
  background-color: #2196F3;
  color: white;
  border: none;
}

.form-control:focus {
  border-color: #2196F3;
  box-shadow: 0 0 0 0.2rem rgba(33,150,243,.25);
}

.btn-primary {
  background-color: #2196F3;
  border-color: #2196F3;
  padding: 8px 25px;
  font-weight: 500;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background-color: #1976D2;
  border-color: #1976D2;
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}
alert {
  padding: 20px;
  border-radius: 8px;
  font-size: 16px;
  margin: 20px 0;
}

.alert-warning {
  background-color: #fff3e0;
  color: #e65100;
  border: 1px solid #ffe0b2;
}

.fas {
  margin-right: 8px;
}
</style>
