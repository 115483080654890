<template>
    <div class="page-wrapper">
      <!-- En-tête et navigation -->
      <div class="page-header navbar navbar-fixed-top">
        <div class="page-header-inner">
          <appheader
            :userPhoto="userPhoto"
            :userNom="userNom"
            :userPrenom="userPrenom"
          />
        </div>
      </div>
      
      <!-- Contenu principal -->
      <div class="page-container">
        <appnavbar
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
          :userIdrole="userIdrole"
          :userRole="userRole"
        />
        
        <div class="page-content-wrapper">
          <div class="page-content">
            <!-- Fil d'Ariane -->
            <div class="page-bar">
              <div class="page-title-breadcrumb">
                <div class="pull-left">
                  <div class="page-title">Créer un Fournisseur</div>
                </div>
                <ol class="breadcrumb page-breadcrumb pull-right">
                  <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="/">Accueil</a>&nbsp;<i class="fa fa-angle-right"></i></li>
                  <li><router-link :to="{name: 'FournisseursList'}">Fournisseurs</router-link>&nbsp;<i class="fa fa-angle-right"></i></li>
                  <li class="active">Créer</li>
                </ol>
              </div>
            </div>
  
            <!-- Formulaire -->
            <div class="card">
              <div class="card-header">
                <h4>Informations du Fournisseur</h4>
              </div>
              <div class="card-body">
                <form class="form-horizontal" @submit.prevent="validateAndSubmit">
                  <div class="row">
                    <!-- Nom -->
                    <div class="col-lg-6 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label">Nom du Fournisseur *</label>
                        <input 
                          class="mdl-textfield__input" 
                          :class="{ 'is-invalid': errors.nom }"
                          type="text" 
                          v-model="formData.nom"
                        >
                        <span class="error-message" v-if="errors.nom">{{ errors.nom }}</span>
                      </div>
                    </div>
  
                    <!-- Téléphone -->
                    <div class="col-lg-6 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label">Téléphone *</label>
                        <input 
                          class="mdl-textfield__input"
                          :class="{ 'is-invalid': errors.telephone }"
                          type="text" 
                          v-model="formData.telephone"
                        >
                        <span class="error-message" v-if="errors.telephone">{{ errors.telephone }}</span>
                      </div>
                    </div>
  
                    <!-- Email -->
                    <div class="col-lg-6 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label">Email *</label>
                        <input 
                          class="mdl-textfield__input"
                          :class="{ 'is-invalid': errors.email }"
                          type="email" 
                          v-model="formData.email"
                        >
                        <span class="error-message" v-if="errors.email">{{ errors.email }}</span>
                      </div>
                    </div>
  
                    <!-- Adresse -->
                    <div class="col-lg-6 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label">Adresse</label>
                        <input 
                          class="mdl-textfield__input" 
                          type="text" 
                          v-model="formData.adresse"
                        >
                      </div>
                    </div>
  
                    <!-- Secteur d'activité -->
                    <div class="col-lg-6 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label">Secteur d'activité *</label>
                        <select 
                          class="mdl-textfield__input"
                          :class="{ 'is-invalid': errors.secteur_activite_id }"
                          v-model="formData.secteur_activite_id"
                        >
                          
                          <option 
                            v-for="secteur in secteurs" 
                            :key="secteur.id" 
                            :value="secteur.id"
                          >
                            {{ secteur.nom }}
                          </option>
                        </select>
                        <span class="error-message" v-if="errors.secteur_activite_id">
                          {{ errors.secteur_activite_id }}
                        </span>
                      </div>
                    </div>
  
                    <!-- Suite dans le prochain message... -->
                                       <!-- Produits/Services -->
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label">Produits/Services *</label>
                      <textarea 
                        class="mdl-textfield__input"
                        :class="{ 'is-invalid': errors.produits_services }"
                        v-model="formData.produits_services"
                      ></textarea>
                      <span class="error-message" v-if="errors.produits_services">{{ errors.produits_services }}</span>
                    </div>
                  </div>

                  <!-- Nom du contact -->
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label">Nom du contact</label>
                      <input 
                        class="mdl-textfield__input" 
                        type="text" 
                        v-model="formData.nom_contact"
                      >
                    </div>
                  </div>

                  <!-- Téléphone du contact -->
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label">Téléphone du contact</label>
                      <input 
                        class="mdl-textfield__input" 
                        type="text" 
                        v-model="formData.telephone_contact"
                      >
                    </div>
                  </div>

                  <!-- Statut -->
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label">Statut *</label>
                      <select 
                        class="mdl-textfield__input"
                        :class="{ 'is-invalid': errors.statut }"
                        v-model="formData.statut"
                      >
                        <option value="actif">Actif</option>
                        <option value="inactif">Inactif</option>
                      </select>
                      <span class="error-message" v-if="errors.statut">{{ errors.statut }}</span>
                    </div>
                  </div>

                  <!-- Boutons -->
                  <div class="col-lg-12 p-t-20 text-center">
                    <button 
                      type="submit" 
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-primary" 
                      :disabled="isLoading"
                    >
                      {{ isLoading ? 'Création en cours...' : 'Créer le fournisseur' }}
                    </button>
                    <router-link 
                      :to="{name: 'FournisseursList'}" 
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                    >
                      Annuler
                    </router-link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appheader from "../layout/header.vue";
import appnavbar from "../layout/navbar.vue";
import axios from "@/axios";
import Swal from 'sweetalert2';

export default {
  name: 'FournisseurCreateComponent',
  components: {
    appheader,
    appnavbar
  },

  data() {
    return {
      formData: {
        nom: '',
        telephone: '',
        email: '',
        adresse: '',
        secteur_activite_id: '',
        produits_services: '',
        nom_contact: '',
        telephone_contact: '',
        statut: 'actif'
      },
      errors: {
        nom: '',
        telephone: '',
        email: '',
        secteur_activite_id: '',
        produits_services: '',
        statut: ''
      },
      secteurs: [],
      isLoading: false
    }
  },

  // Suite dans le prochain message...
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    }
  },

  mounted() {
    this.fetchSecteurs(); // Chargement des secteurs au montage du composant
  },

  methods: {
    async fetchSecteurs() {
      try {
        const response = await axios.get('/secteurs-activite', {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        
        if (response.data.statut === 200) {
          this.secteurs = response.data.secteurs;
        }
      } catch (error) {
        console.error('Erreur lors du chargement des secteurs:', error);
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Impossible de charger les secteurs d\'activité'
        });
      }
    },

    validateForm() {
      let isValid = true;
      this.resetErrors();

      if (!this.formData.nom) {
        this.errors.nom = 'Le nom du fournisseur est requis';
        isValid = false;
      }

      if (!this.formData.telephone) {
        this.errors.telephone = 'Le téléphone est requis';
        isValid = false;
      }

      if (!this.formData.email) {
        this.errors.email = 'L\'email est requis';
        isValid = false;
      } else if (!this.validateEmail(this.formData.email)) {
        this.errors.email = 'Veuillez entrer une adresse email valide';
        isValid = false;
      }

      if (!this.formData.secteur_activite_id) {
        this.errors.secteur_activite_id = 'Le secteur d\'activité est requis';
        isValid = false;
      }

      if (!this.formData.produits_services) {
        this.errors.produits_services = 'Les produits/services sont requis';
        isValid = false;
      }

      if (!this.formData.statut) {
        this.errors.statut = 'Le statut est requis';
        isValid = false;
      }

      return isValid;
    },

    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },

    resetErrors() {
      this.errors = {
        nom: '',
        telephone: '',
        email: '',
        secteur_activite_id: '',
        produits_services: '',
        statut: ''
      };
    },

    async validateAndSubmit() {
      if (this.validateForm()) {
        await this.submitForm();
      }
    },

    async submitForm() {
      try {
        this.isLoading = true;
        const response = await axios.post('/fournisseurs', this.formData, {
          headers: { Authorization: `Bearer ${this.token}` }
        });

        if (response.status === 201) {
          await Swal.fire({
            icon: 'success',
            title: 'Succès!',
            text: 'Fournisseur créé avec succès'
          });
          this.$router.push({name: 'FournisseursList'});
        }
      } catch (error) {
        console.error('Erreur:', error);
        Swal.fire({
          icon: 'error',
          title: 'Erreur!',
          text: 'Une erreur est survenue lors de la création du fournisseur'
        });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>

.mdl-textfield__input.is-invalid {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}


</style>