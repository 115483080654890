<template>
  <div class="page-wrapper">
    <!-- start header -->
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userPhoto="userPhoto"
          :userNom="userNom"
          :userPrenom="userPrenom"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />
      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">{{ isEditing ? 'Modifier un État' : 'Créer un État' }}</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.html">Home</a>&nbsp;<i class="fa fa-angle-right"></i></li>
                <li class="active">{{ isEditing ? 'Modifier' : 'Créer' }}</li>
              </ol>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h4>Informations de l'État</h4>
            </div>
            <div class="card-body">
              <form class="form-horizontal" @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-lg-12 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="intitule">Intitulé</label>
                      <input 
                        class="mdl-textfield__input" 
                        type="text" 
                        id="intitule" 
                        v-model="intitule" 
                        :placeholder="isEditing ? '' : 'Entrez l\'intitulé'" 
                      />
                      <span class="erreur">{{ errorMessage }}</span>
                    </div>
                  </div>
                  <div class="col-lg-12 p-t-20 text-center">
                    <button
                      type="submit"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                      :disabled="isLoading"
                    >
                      {{ isLoading ? "Veuillez patienter..." : (isEditing ? "Modifier" : "Ajouter") }}
                      <div v-show="isLoading" class="mdl-spinner mdl-js-spinner is-active"></div>
                    </button>
                    <button
                      type="button"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                      @click="resetForm"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- end form content -->

          <!-- start list content -->
          <div class="card mt-4">
            <div class="card-header">
              <h4>Liste des États</h4>
            </div>
            <div class="card-body">
              <div v-if="etats.length === 0">Aucun état trouvé.</div>
              <table class="table table-striped" v-else>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Intitulé</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="etat in etats" :key="etat.id">
                    <td>{{ etat.id }}</td>
                    <td>{{ etat.intitule }}</td>
                    <td>
                      <a class="tblEditBtn" @click="editEtat(etat)">
                        <i class="fa fa-pencil" title="Modifier"></i>
                      </a>
                      <a class="tblDelBtn" @click="confirmDelete(etat.id)">
                        <i class="fa fa-trash-o" title="Supprimer" style="color: red;"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- end list content -->
        </div> <!-- end page-content -->
      </div> <!-- end page-content-wrapper -->
    </div> <!-- end page-container -->
  </div> <!-- end page-wrapper -->
</template>

<script>
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import axios from "@/axios"; // Assurez-vous que le chemin est correct
import Swal from 'sweetalert2';

export default {
  name: "EtatComponent",
  components: {
    appnavbar,
    appfooter,
    appheader,
  },
  data() {
    return {
      intitule: '',
      errorMessage: '',
      isLoading: false,
      etats: [], // Liste des états
      isEditing: false, // Indique si nous sommes en mode édition
      currentEtatId: null, // ID de l'état actuellement modifié
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  mounted() {
    this.fetchEtats(); // Récupérer la liste des états lors du montage
  },
  methods: {
    async submitForm() {
      this.isLoading = true;
      this.errorMessage = '';

      try {
        const url = this.isEditing 
          ? `http://localhost:8000/api/etats/${this.currentEtatId}` // Utiliser PUT pour la mise à jour
          : 'http://localhost:8000/api/etats';

        const response = await axios({
          method: this.isEditing ? 'put' : 'post', // Utiliser PUT pour la mise à jour
          url: url,
          data: {
            intitule: this.intitule
          },
          headers: { Authorization: `Bearer ${this.token}` }
        });

        if (response.status === 201 || response.status === 200) {
          Swal.fire('Succès!', this.isEditing ? 'État modifié avec succès' : 'État ajouté avec succès', 'success');
          this.resetForm();
          this.fetchEtats(); // Mettre à jour la liste après ajout ou modification
        }
      } catch (error) {
        this.errorMessage = 'Erreur lors de l\'ajout ou de la modification de l\'état.';
        console.error('Détails de l\'erreur:', error.response ? error.response.data : error);
      } finally {
        this.isLoading = false;
      }
    },
    resetForm() {
      this.intitule = '';
      this.errorMessage = '';
      this.isEditing = false;
      this.currentEtatId = null;
    },
    async fetchEtats() {
      try {
        const response = await axios.get('http://localhost:8000/api/etats', {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        this.etats = response.data.etats; // Assurez-vous que la clé correspond à la réponse de l'API
      } catch (error) {
        console.error('Erreur lors de la récupération des états:', error);
      }
    },
    async confirmDelete(id) {
      const result = await Swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Vous ne pourrez pas revenir en arrière!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimer!'
      });

      if (result.isConfirmed) {
        this.deleteEtat(id);
      }
    },
    async deleteEtat(id) {
      try {
        await axios.delete(`http://localhost:8000/api/etats/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        Swal.fire('Succès!', 'État supprimé avec succès', 'success');
        this.fetchEtats(); // Mettre à jour la liste après suppression
      } catch (error) {
        console.error('Erreur lors de la suppression de l\'état:', error);
        Swal.fire('Erreur!', 'Erreur lors de la suppression de l\'état.', 'error');
      }
    },
    editEtat(etat) {
      this.intitule = etat.intitule; // Remplir le formulaire avec les données de l'état
      this.currentEtatId = etat.id; // Stocker l'ID de l'état à modifier
      this.isEditing = true; // Passer en mode édition
    }
  },
};
</script>

<style scoped>
.container {
  margin: 20px;
}
</style>