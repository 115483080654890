<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
        :userRole="userRole" />

      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="encaissement-container">
            <div class="filters-section">
              <div class="filter-group">
                <label>Période :</label>
                <select v-model="periodeSelected" @change="loadEncaissements">
                  <option value="journalier">Journalier</option>
                  <option value="mensuel">Mensuel</option>
                  <option value="annuel">Annuel</option>
                </select>
              </div>

              <div class="filter-group" v-if="periodeSelected === 'journalier'">
                <label>Date :</label>
                <input type="date" v-model="dateSelected" @change="loadEncaissements">
              </div>

              <div class="filter-group" v-if="periodeSelected === 'mensuel'">
                <label>Mois :</label>
                <input type="month" v-model="moisSelected" @change="loadEncaissements">
              </div>

              <div class="filter-group" v-if="periodeSelected === 'annuel'">
                <label>Année :</label>
                <select v-model="anneeSelected" @change="loadEncaissements">
                  <option v-for="annee in annees" :key="annee" :value="annee">{{ annee }}</option>
                </select>
              </div>

              <div class="filter-group">
                <label>Type :</label>
                <select v-model="typeSelected" @change="loadEncaissements">
                  <option value="tous">Tous</option>
                  <option value="hebergement">Hébergement</option>
                  <option value="partenaire">Partenaire</option>
                </select>
              </div>

              <button class="btn-imprimer" @click="imprimerEtatEncaissement">
                <i class="fas fa-print"></i> Imprimer
              </button>
            </div>

            <div class="table-section">
              <table class="data-table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>RÉFÉRENCE</th>
                    <th>TYPE</th>
                    <th>DÉTAILS</th>
                    <th>MODE PAIEMENT</th>
                    <th>MONTANT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(encaissement, index) in encaissementsFiltres" :key="index">
                    <td>{{ formatDate(encaissement.date) }}</td>
                    <td>{{ encaissement.reference }}</td>
                    <td>{{ encaissement.type }}</td>
                    <td>
                      <template v-if="encaissement.type.includes('Hébergement')">
                        {{ encaissement.eleve }} - {{ encaissement.chambre }}
                      </template>
                      <template v-else-if="encaissement.type === 'Partenaire'">
                        {{ encaissement.partenaire }}
                      </template>
                    </td>
                    <td>{{ encaissement.mode_paiement }}</td>
                    <td class="montant">{{ formatMontant(encaissement.montant) }} FCFA</td>
                  </tr>
                  <tr v-if="encaissementsFiltres.length === 0">
                    <td colspan="6" class="no-data">Aucun encaissement trouvé pour cette période</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="summary-section">
              <div class="summary-card">
                <div class="summary-title">Total des encaissements</div>
                <div class="summary-amount">{{ formatMontant(totalEncaissements) }} FCFA</div>
              </div>
              <div class="summary-card">
                <div class="summary-title">Total espèces</div>
                <div class="summary-amount">{{ formatMontant(totalEspeces) }} FCFA</div>
              </div>
              <div class="summary-card">
                <div class="summary-title">Total chèques</div>
                <div class="summary-amount">{{ formatMontant(totalCheques) }} FCFA</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-footer">
      <div class="page-footer-inner">
        <appfooter />
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import { mapState, mapGetters, mapActions } from "vuex";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import permissionMixin from "@/permission";

export default {
  name: 'EtatEncaissementComponent',
  components: {
    appnavbar,
    appfooter,
    appheader,
  },
  mixins: [permissionMixin],
  data() {
    return {
     
      periodeSelected: 'journalier',
      dateSelected: new Date().toISOString().substr(0, 10),
      moisSelected: new Date().toISOString().substr(0, 7),
      anneeSelected: new Date().getFullYear(),
      typeSelected: 'tous',
      encaissements: [],
      annees: [],
      loading: false,
      error: null
    }
  },
  computed: {
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
    encaissementsFiltres() {
      let filteredData = [...this.encaissements];
      
      if (this.typeSelected === 'hebergement') {
        filteredData = filteredData.filter(e => e.type.includes('Hébergement'));
      } else if (this.typeSelected === 'partenaire') {
        filteredData = filteredData.filter(e => e.type === 'Partenaire');
      }
      
      return filteredData;
    },
    totalEncaissements() {
      return this.encaissementsFiltres.reduce((total, e) => total + Number(e.montant), 0);
    },
    totalEspeces() {
      return this.encaissementsFiltres
        .filter(e => e.mode_paiement.toLowerCase() === 'espèces')
        .reduce((total, e) => total + Number(e.montant), 0);
    },
    totalCheques() {
      return this.encaissementsFiltres
        .filter(e => e.mode_paiement.toLowerCase() === 'chèque')
        .reduce((total, e) => total + Number(e.montant), 0);
    }
  },
  created() {
    
    
    // Générer les années pour le filtre (5 ans en arrière jusqu'à l'année actuelle)
    const currentYear = new Date().getFullYear();
    for (let i = currentYear - 5; i <= currentYear; i++) {
      this.annees.push(i);
    }
    
    this.loadEncaissements();
  },
  methods: {
    ...mapActions(["fetchUser"]),
    loadEncaissements() {
      this.loading = true;
      this.error = null;
      
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      
      let endpoint = '';
      let params = {};
      
      // Déterminer les paramètres selon la période sélectionnée
      if (this.periodeSelected === 'journalier') {
        params.date = this.dateSelected;
      } else if (this.periodeSelected === 'mensuel') {
        params.mois = this.moisSelected;
      } else if (this.periodeSelected === 'annuel') {
        params.annee = this.anneeSelected;
      }
      
      // Déterminer l'endpoint selon le type sélectionné
      if (this.typeSelected === 'hebergement') {
        endpoint = '/encaissements/hebergement';
      } else if (this.typeSelected === 'partenaire') {
        endpoint = '/encaissements/partenaire';
      } else {
        endpoint = '/encaissements/all';
      }
      
      axios.get(endpoint, { params, headers })
        .then(response => {
          this.encaissements = response.data;
          this.loading = false;
        })
        .catch(error => {
          this.error = "Erreur lors du chargement des encaissements";
          this.loading = false;
          console.error(error);
        });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    },
    formatMontant(montant) {
      return new Intl.NumberFormat('fr-FR').format(montant);
    },
    imprimerEtatEncaissement() {
      const bodyContent = document.body.innerHTML;
      const currentDate = new Date().toLocaleDateString('fr-FR', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
      
      let titreType = '';
      if (this.typeSelected === 'hebergement') {
        titreType = ' - HÉBERGEMENT';
      } else if (this.typeSelected === 'partenaire') {
        titreType = ' - PARTENAIRES';
      }
      
      let titrePeriode = '';
      if (this.periodeSelected === 'journalier') {
        titrePeriode = `DU ${this.formatDate(this.dateSelected)}`;
      } else if (this.periodeSelected === 'mensuel') {
        const date = new Date(this.moisSelected);
        titrePeriode = `DU MOIS DE ${date.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })}`;
      } else if (this.periodeSelected === 'annuel') {
        titrePeriode = `DE L'ANNÉE ${this.anneeSelected}`;
      }

      let printContent = `
        <div class="print-container">
          <div class="print-header">
            <div class="header-top">
              <h1>CFPT SÉNÉGAL-JAPON</h1>
            </div>
            <div class="header-bottom">
              <h2>ÉTAT DES ENCAISSEMENTS${titreType}</h2>
              <h3>${titrePeriode}</h3>
              <p>Imprimé le ${currentDate}</p>
            </div>
          </div>
          
          <div class="print-summary">
            <div class="summary-item">
              <div class="summary-label">Total des encaissements</div>
              <div class="summary-value">${this.formatMontant(this.totalEncaissements)} FCFA</div>
            </div>
            <div class="summary-item">
              <div class="summary-label">Total espèces</div>
              <div class="summary-value">${this.formatMontant(this.totalEspeces)} FCFA</div>
            </div>
            <div class="summary-item">
              <div class="summary-label">Total chèques</div>
              <div class="summary-value">${this.formatMontant(this.totalCheques)} FCFA</div>
            </div>
          </div>
          
          <table class="print-table">
            <thead>
              <tr>
                <th>DATE</th>
                <th>RÉFÉRENCE</th>
                <th>TYPE</th>
                <th>DÉTAILS</th>
                <th>MODE PAIEMENT</th>
                <th>MONTANT</th>
              </tr>
            </thead>
            <tbody>
      `;
      
      if (this.encaissementsFiltres.length === 0) {
        printContent += `
          <tr>
            <td colspan="6" class="no-data">Aucun encaissement trouvé pour cette période</td>
          </tr>
        `;
      } else {
        this.encaissementsFiltres.forEach(encaissement => {
          let details = '';
          if (encaissement.type.includes('Hébergement')) {
            details = `${encaissement.eleve} - ${encaissement.chambre}`;
          } else if (encaissement.type === 'Partenaire') {
            details = encaissement.partenaire;
          }
          
          printContent += `
            <tr>
              <td>${this.formatDate(encaissement.date)}</td>
              <td>${encaissement.reference}</td>
              <td>${encaissement.type}</td>
              <td>${details}</td>
              <td>${encaissement.mode_paiement}</td>
              <td class="montant">${this.formatMontant(encaissement.montant)} FCFA</td>
            </tr>
          `;
        });
      }
      
      printContent += `
            </tbody>
          </table>
          
          <div class="print-footer">
            <div id="footer">Page <span class="page-number"></span></div>
          </div>
        </div>
      `;
      
      const printStyles = `
        <style>
          @page {
            size: A4;
            margin: 1.5cm 1cm;
          }
          
          body {
            font-family: Arial, sans-serif;
            font-size: 12px;
            line-height: 1.4;
          }
          
          .print-container {
            width: 100%;
          }
          
          .print-header {
            text-align: center;
            margin-bottom: 20px;
          }
          
          .header-top h1 {
            font-size: 18px;
            margin: 0;
            font-weight: bold;
          }
          
          .header-bottom h2 {
            font-size: 16px;
            margin: 10px 0 5px;
          }
          
          .header-bottom h3 {
            font-size: 14px;
            margin: 5px 0;
          }
          
          .header-bottom p {
            font-size: 12px;
            margin: 5px 0 0;
            font-style: italic;
          }
          
          .print-summary {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            border: 1px solid #ddd;
            padding: 10px;
          }
          
          .summary-item {
            flex: 1;
            text-align: center;
          }
          
          .summary-label {
            font-weight: bold;
            margin-bottom: 5px;
          }
          
          .summary-value {
            font-size: 14px;
          }
          
          .print-table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
          }
          
          .print-table th {
            background-color: #1a237e;
            color: white;
            padding: 8px;
            text-align: left;
            font-weight: normal;
          }
          
          .print-table td {
            padding: 8px;
            border-bottom: 1px solid #ddd;
          }
          
          .print-table tr:nth-child(even) {
            background-color: #f9f9f9;
          }
          
          .montant {
            text-align: right;
            font-family: monospace;
          }
          
          .no-data {
            text-align: center;
            padding: 20px;
            font-style: italic;
          }
          
          .print-footer {
            text-align: center;
            font-size: 10px;
            position: running(footer);
          }
          
          .page-number:before {
            content: counter(page);
          }
          
          @page {
            @bottom-center {
              content: element(footer);
            }
          }
        </style>
      `;

      document.body.innerHTML = printStyles + printContent;
      window.print();
      document.body.innerHTML = bodyContent;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    }
  }
}
</script>

<style scoped>
.page-wrapper {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.page-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.page-container {
  display: flex;
  height: calc(100vh - 106px);
  margin-top: 60px;
}
.sidebar {
  width: 235px;
  height: 100%;
}

.content-area {
  flex: 1;
  height: 100%;
  overflow: auto;
}

.page-content-wrapper {
  flex: 1;
  margin-left: 0; /* Supprime la marge à gauche */
  padding: 0;
  height: 100%;
  overflow: auto;
}

.page-content {
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  background-color: #f5f7fa; /* Couleur de fond comme dans l'image */
}

.page-footer {
  height: 46px;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e7ecf1;
  z-index: 10;
}

.page-footer-inner {
  padding: 15px 20px;
  text-align: center;
}

.encaissement-container {
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 5px;
  height: 100%;
  overflow: auto;
}

.filters-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.filter-group {
  display: flex;
  flex-direction: column;
  min-width: 150px;
}

.filter-group label {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
}

.filter-group select,
.filter-group input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  min-width: 150px;
}

.btn-imprimer {
  padding: 8px 15px;
  background-color: #1a237e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  align-self: flex-end;
  margin-left: auto;
}

.btn-imprimer:hover {
  background-color: #303f9f;
}

.table-section {
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th {
  background-color: #1a237e;
  color: white;
  padding: 12px 15px;
  text-align: left;
  font-weight: normal;
}

.data-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
}

.data-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.data-table tr:hover {
  background-color: #f1f1f1;
}

.montant {
  text-align: right;
  font-family: monospace;
  font-weight: 500;
}

.no-data {
  text-align: center;
  padding: 30px;
  color: #666;
  font-style: italic;
}

.summary-section {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.summary-card {
  flex: 1;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.summary-title {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.summary-amount {
  font-size: 18px;
  font-weight: bold;
  color: #1a237e;
}

@media (max-width: 768px) {
  .page-content-wrapper  {
     margin-left: 0;
  }
   .summary-section {
    flex-direction: column;
  }
  .filters-section {
    flex-direction: column;
  }
}
</style>
