<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
        :userRole="userRole" />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <!-- start page content -->
        <div class="page-content" v-if="!this.editModal">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Liste des utilisateurs</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard'">Accueil</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" :href="'/liste-utilisateur'">Utilisateurs</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li class="active">Liste utilisateur</li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="tabbable-line">
                <ul class="nav customtab nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a href="#tab2" class="nav-link active" data-bs-toggle="tab">
                      Personnel Administratif</a>
                  </li>
                  <li class="nav-item">
                    <a href="#tab1" class="nav-link" data-bs-toggle="tab">Formateurs</a>
                  </li>

                  <li class="nav-item">
                    <a href="#tab3" class="nav-link" data-bs-toggle="tab">Personnel d'appui</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane fontawesome-demo" id="tab1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Tous les formateurs</header>
                            <div class="tools">
                              <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                              <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                              <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6 col-sm-6 col-6">
                                <div class="btn-group">
                                  <a :href="'/create-utilisateur'" id="addRow" class="btn btn-primary">
                                    Ajouter
                                    <i class="fa fa-plus text-white"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length">
                                  <label class="me-2">Afficher</label>
                                  <select name="example47_length" aria-controls="example47" class="form-select-sm"
                                    @change="updatePerPage" v-model="limitQueryFormateur">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2">Recherche :</label>
                                  <input type="text" id="searchInput" placeholder="" aria-controls="example47"
                                    v-model="searchQuery" @input="get_formateur(currentPageFormateur)" />
                                </div>
                              </div>
                            </div>

                            <!--  -->

                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="example47" style="width: 100%">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Photo</th>
                                  <th>Matricule</th>
                                  <th>Prénom</th>
                                  <th>Nom</th>
                                  <th>Email</th>
                                  <th>Téléphone</th>
                                  <th>Unité de formation</th>
                                  <th>Département</th>
                                  <th>Statut</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="odd gradeX" v-for="(
                                    utilisateur, index
                                  ) in utilisateurProf">
                                  <td class="patient-img">
                                    {{ index + 1 }}
                                  </td>
                                  <td class="patient-img">
                                    <img :src="getImageUrl(utilisateur.photo)" alt="Etu" />
                                  </td>
                                  <td>{{ utilisateur.matricule }}</td>
                                  <td class="left">{{ utilisateur.prenom }}</td>
                                  <td class="left">{{ utilisateur.nom }}</td>
                                  <td>
                                    <a :href="'mailto:' + utilisateur.email">{{ utilisateur.email }}
                                    </a>
                                  </td>
                                  <td>
                                    <a :href="'tel:' + utilisateur.telephone">{{
          utilisateur.telephone
        }}</a>
                                  </td>
                                  <td class="left">
                                    {{ utilisateur.filiere }}
                                  </td>

                                  <td>{{ utilisateur.departement }}</td>

                                  <td class="text-center align-middle" v-if="utilisateur.status === '1'">
                                    <span class="label label-sm label-success">
                                      Actif
                                    </span>
                                  </td>
                                  <td class="text-center align-middle" v-if="utilisateur.status === '0'"
                                    @click="activUser(utilisateur)">
                                    <a class="label label-sm label-danger">
                                      Inactif
                                    </a>
                                  </td>

                                  <td>
                                    <a title="Modifier" class="tblEditBtn" @click="openModal(utilisateur)">
                                      <i class="fa fa-pencil"></i>
                                    </a>
                                    <a title="Desactiver" class="tblDelBtn" @click="deleteUtilisateur(utilisateur)">
                                      <i class="fa fa-trash-o"></i>
                                    </a>

                                    <button title="Assigner matière" @click="assignerMatiere(utilisateur)" type="button"
                                      class="tblDetailBtn border-0 " data-bs-toggle="modal"
                                      data-bs-target="#exampleModal1">
                                      <i class="fa fa-book"></i>
                                    </button>

                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <div class="row" v-if="this.utilisateurProf &&
          this.utilisateurProf.length > 0
          ">
                              <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info" id="datable_1_info" role="status" aria-live="polite">
                                  Affichage de {{ from_beginf }} à
                                  {{ to_beginf }} sur {{ totalf }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div class="dataTables_paginate paging_simple_numbers" id="datable_1_paginate">
                                  <paginate-component :total-pages="total_pagef" :total="totalf" :per-page="per_pagef"
                                    :current-page="current_pagef" @pagechanged="pagPageFormateur"></paginate-component>
                                </div>
                              </div>
                            </div>

                            <!-- fenetre modal -->
                            <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h3 class="modal-title" id="addEventTitle">Assigner Matiere A {{
          this.formateurNomFind }}</h3>
                                    <!-- <h5 class="modal-title" id="editEventTitle">Partenaire</h5> -->
                                    <button @click="resetForm()" type="button" class="btn-close" data-bs-dismiss="modal"
                                      aria-label="Close"></button>
                                  </div>
                                  <div class="modal-body">

                                    <div class="card-body row">
                                      <h5 class="modal-title" id="editEventTitle">Matieres Assignées</h5>
                                      <div class="col-6" v-for="(matieres, index) in matiereProfesseurFind">
                                        <div class="form-group">
                                          <label></label>
                                          <input type="text" class="form-control datetimepicker"
                                            placeholder="Start Date" name="starts_at" id="starts-at"
                                            v-model="matieres.matiere.intitule" disabled>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="card-body row" v-for="(matiereProf, index) in form_matiere.matieres"
                                      :key="index">

                                      <div class="header d-flex justify-content-end">
                                        <svg class="cursor-pointer" @click.prevent="duplicateMatiere(index)"
                                          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path fill="green"
                                            d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                        </svg>
                                        <svg v-show="form_matiere.matieres.length > 1" class="cursor-pointer ml-2"
                                          @click.prevent="removeMatiere(index)" xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24" width="24" height="24">
                                          <path fill="none" d="M0 0h24v24H0z" />
                                          <path fill="#EC4899"
                                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                        </svg>
                                      </div>


                                      <div class="row">
                                        <div class="col-md-12 mb-4">
                                          <label v-show="!matiereProf.id_matiere">Matiere {{ index + 1 }}</label>
                                          <select class="form-select" id="categorySelect"
                                            v-model="matiereProf.id_matiere">
                                            <option v-for="(matiere, index) in matieres" :value="matiere.id"
                                              :key="index"> {{ matiere.intitule }} </option>
                                          </select>
                                          <span class="erreur" v-if="matiereProf.id_matiere_erreur">{{  matiereProf.id_matiere_erreur }}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer bg-whitesmoke pr-0">
                                    <button type="submit" v-if="!this.editModal"
                                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                                      @click.prevent="validerAvantAjout()">
                                      Enregistrer
                                    </button>
                                    <button type="button" id="close" @click="resetForm()"
                                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                                      data-bs-dismiss="modal">Fermer</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- End fenetre modal -->


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane active fontawesome-demo" id="tab2">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Tout le personnel administratif</header>
                            <div class="tools">
                              <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                              <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                              <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6 col-sm-6 col-6">
                                <div class="btn-group">
                                  <a :href="'/create-utilisateur'" id="addRow" class="btn btn-primary">
                                    Ajouter
                                    <i class="fa fa-plus text-white"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <!--  -->

                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length">
                                  <label class="me-2">Afficher</label>
                                  <select name="example47_length" aria-controls="example47" class="form-select-sm"
                                    @change="updatePerPage" v-model="limitQueryAdmin">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2">Recherche :</label>
                                  <input type="text" id="searchInput" placeholder="" aria-controls="example47"
                                    v-model="searchQuery" @input=" this.get_personneladmin(this.currentPageAdmin)" />
                                </div>
                              </div>
                            </div>

                            <!--  -->
                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="exemple1" style="width: 100%">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Photo</th>
                                  <th>Matricule</th>
                                  <th>Prénom</th>
                                  <th>Nom</th>
                                  <th>Email</th>
                                  <th>Téléphone</th>
                                  <th>Fonction</th>
                                  <th>Service</th>
                                  <th>Statut</th>

                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="odd gradeX" v-for="(utilisateur, index) in utilisateurs">
                                  <td class="patient-img">
                                    {{ index + 1 }}
                                  </td>
                                  <td class="patient-img">
                                    <img :src="getImageUrl(utilisateur.photo)" alt="Etu" />
                                  </td>
                                  <td>{{ utilisateur.matricule }}</td>
                                  <td class="left">{{ utilisateur.prenom }}</td>
                                  <td class="left">{{ utilisateur.nom }}</td>
                                  <td>
                                    <a :href="'mailto:' + utilisateur.email">{{ utilisateur.email }}</a>
                                  </td>
                                  <td>
                                    <a :href="'tel:' + utilisateur.telephone">{{ utilisateur.telephone }}</a>
                                  </td>
                                  <td class="left"> {{ utilisateur.fonction }} </td>
                                  <td>{{ utilisateur.nom_service }}</td>

                                  <td class="text-center align-middle" v-if="utilisateur.status === '1'">
                                    <span class="label label-sm label-success">
                                      Actif
                                    </span>
                                  </td>

                                  <td class="text-center align-middle" v-if="utilisateur.status === '0'"
                                    @click="activUser(utilisateur)">
                                    <a title="Activer" class="label label-sm label-danger">
                                      Inactif
                                    </a>
                                  </td>

                                  <td>
                                    <a title="Modifier" class="tblEditBtn" @click="openModal(utilisateur)">
                                      <i class="fa fa-pencil"></i>
                                    </a>
                                    <a title="Desactiver" class="tblDelBtn" @click="deleteUtilisateur(utilisateur)">
                                      <i class="fa fa-trash-o"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <div class="row" v-if="this.utilisateurs && this.utilisateurs.length > 0">
                              <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info" id="datable_1_info" role="status" aria-live="polite">
                                  Affichage de {{ from_begin }} à
                                  {{ to_begin }} sur {{ total }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div class="dataTables_paginate paging_simple_numbers" id="datable_1_paginate">
                                  <paginate-component :total-pages="total_page" :total="total" :per-page="per_page"
                                    :current-page="current_page" @pagechanged="pagPage"></paginate-component>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fontawesome-demo" id="tab3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Tout le personnel Appui</header>
                            <div class="tools">
                              <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                              <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                              <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6 col-sm-6 col-6">
                                <div class="btn-group">
                                  <a :href="'/create-utilisateur'" id="addRow" class="btn btn-primary">
                                    Ajouter
                                    <i class="fa fa-plus text-white"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <!--  -->

                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length">
                                  <label class="me-2">Afficher</label>
                                  <select name="example47_length" aria-controls="example47" class="form-select-sm"
                                    @change="updatePerPage" v-model="limitQueryApui">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2">Recherche :</label>
                                  <input type="text" id="searchInput" placeholder="" aria-controls="example47"
                                    v-model="searchQuery" @input=" this.get_personnelapui(this.currentPageApui)" />
                                </div>
                              </div>
                            </div>

                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="exemple1" style="width: 100%">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Photo</th>
                                  <th>Matricule</th>
                                  <th>Prénom</th>
                                  <th>Nom</th>
                                  <th>Email</th>
                                  <th>Téléphone</th>
                                  <th>Fonction</th>
                                  <th>Service</th>
                                  <th>Statut</th>

                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="odd gradeX" v-for="(
                                    utilisateur, index
                                  ) in utilisateursPer">
                                  <td class="patient-img">
                                    {{ index + 1 }}
                                  </td>
                                  <td class="patient-img">
                                    <img :src="getImageUrl(utilisateur.photo)" alt="Etu" />
                                  </td>
                                  <td>{{ utilisateur.matricule }}</td>
                                  <td class="left">{{ utilisateur.prenom }}</td>
                                  <td class="left">{{ utilisateur.nom }}</td>
                                  <td>
                                    <a :href="'mailto:' + utilisateur.email">{{ utilisateur.email }}</a>
                                  </td>
                                  <td>
                                    <a :href="'tel:' + utilisateur.telephone">{{ utilisateur.telephone }}</a>
                                  </td>
                                  <td class="left">
                                    {{ utilisateur.fonction }}
                                  </td>
                                  <td>{{ utilisateur.nom_service }}</td>
                                  <td class="text-center align-middle" v-if="utilisateur.status === '1'">
                                    <span class="label label-sm label-success">
                                      Actif
                                    </span>
                                  </td>
                                  <td class="text-center align-middle" v-if="utilisateur.status === '0'"
                                    @click="activUser(utilisateur)">
                                    <a title="Activer" class="label label-sm label-danger">
                                      Inactif
                                    </a>
                                  </td>

                                  <td>
                                    <a title="Modifier" class="tblEditBtn" @click="openModal(utilisateur)">
                                      <i class="fa fa-pencil"></i>
                                    </a>
                                    <a title="Desactiver" class="tblDelBtn" @click="deleteUtilisateur(utilisateur)">
                                      <i class="fa fa-trash-o"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <div class="row" v-if="this.utilisateursPer && this.utilisateursPer.length > 0">
                              <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info" id="datable_1_info" role="status" aria-live="polite">
                                  Affichage de {{ from_beginap }} à
                                  {{ to_beginap }} sur {{ totalap }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div class="dataTables_paginate paging_simple_numbers" id="datable_1_paginate">
                                  <paginate-component :total-pages="total_pageap" :total="totalap"
                                    :per-page="per_pageap" :current-page="current_pageap"
                                    @pagechanged="pagPageApui"></paginate-component>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content-wrapper" v-show="editModal">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Modifier Utilisateur</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard'">Tableau de
                    Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" :href="'/liste-utilisateur'">Utilisateur</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li class="active">Modifier Utilisateur</li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Information</header>
                  <button id="panel-button" class="mdl-button mdl-js-button mdl-button--icon pull-right"
                    data-upgraded=",MaterialButton">
                    <i class="material-icons">more_vert</i>
                  </button>
                  <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                    data-mdl-for="panel-button">
                    <li class="mdl-menu__item">
                      <i class="material-icons">assistant_photo</i>Action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">print</i>Another action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">favorite</i>Something else here
                    </li>
                  </ul>
                </div>
                <div class="card-body row">
                  <FormulaireModification />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <appafterContent />
    </div>
    <appfooter />
  </div>
</template>

<script>
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import FormulaireModification from "./editUtilisateur.vue";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import { mapState, mapActions } from "vuex";
import permissionMixin from "@/permission";

export default {
  name: "listeUserCompenent",
  components: {
    FormulaireModification,
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    PaginateComponent,
  },
  mixins: [permissionMixin],
  data() {
    return {
      limitQueryApui: 5,
      limitQueryFormateur: 5,
      limitQueryAdmin: 5,

      total_page: 0,
      total: 0,
      per_page: 0,
      current_page: 1,
      from_begin: 0,
      to_begin: 0,
      is_loading: false,

      total_pagef: 0,
      totalf: 0,
      per_pagef: 0,
      current_pagef: 1,
      from_beginf: 0,
      to_beginf: 0,
      is_loadingF: false,

      total_pageap: 0,
      totalap: 0,
      per_pageap: 0,
      current_pageap: 1,
      from_beginap: 0,
      to_beginap: 0,
      is_loadingAP: false,
      searchQuery: "",

      form_matiere: new Form({
        matieres: [
          {

            id_matiere: "",
            id_matiere_erreur: "",

          },
        ],
      }),


      form: new Form({
        nom: "",
        prenom: "",
        genre: "",
        adresse: "",
        telephone: "",
        email: "",
        date_naissance: "",
        lieu_naissance: "",
        nationalite: "",
        id_role: "",
        id_specialite: "",
        id_departement: "",
        id_service: "",
        type: "",
        situation_matrimoniale: "",
      }),
      utilisateurs: [],
      formateursFind: "",
      formateurNomFind: "",
      matieres: [],
      matiereProfesseurFind: [],
      permissions: [],
      utilisateurProf: [],
      utilisateursPer: [],
      idUser: "",
      editModal: false,
      currentPageAdmin: 1,
      currentPageApui: 1,
      currentPageFormateur: 1,

      lastPageAdmin: 1,
      lastPageApui: 1,
      lastPageFormateur: 1,

      activePhase: 1,
      idUser: "",
      userEnCoursDeModification: null,

      endpoints: {
        formateur: "/user/getFormateur",
        personnelAdmin: "/user/getPersonnelAdmin",
        personnelApui: "/user/getPersonnelApui",
      },
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  async created() {
    await this.get_permission();
    this.get_matiere();
    this.getUsers();


    bus.on("utilisateurAjoutee", () => {
      this.getUsers();
    });

    bus.on("userDejaModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.getUsers();
    });
  },

  methods: {
    ...mapActions(["login", "logout"]),

    pagPage(pagination) {
      this.current_page = pagination;
      this.get_personneladmin(this.currentPageAdmin);
    },
    pagPageApui(pagination) {
      this.current_pageap = pagination;
      this.get_personnelapui(this.currentPageApui);
    },
    pagPageFormateur(pagination) {
      this.current_pagef = pagination;
      this.get_formateur(this.currentPageFormateur);
    },

    /*     async getUsers() {
      await Promise.all([
      this.get_permission(),
        this.get_personneladmin(this.currentPageAdmin),
        this.get_formateur(this.currentPageFormateur),
        this.get_personnelapui(this.currentPageApui),
      ]);
    }, */
    get_matiere() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Utilisateur")) {
        axios
          .get("/matiere/index", { headers })
          .then((response) => {
            this.matieres = response.data.matiere;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des dernieres matieres",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },
    async getUsers() {
      await this.get_permission(); // Attendre que get_permission soit terminée
      await Promise.all([
        this.get_personneladmin(this.currentPageAdmin),
        this.get_formateur(this.currentPageFormateur),
        this.get_personnelapui(this.currentPageApui),
      ]);
    },
    updatePerPage(event) {
      // Récupérer la nouvelle valeur sélectionnée
      const newPerPage = parseInt(event.target.value);

      // Appeler la méthode pour recharger les données avec la nouvelle quantité par page
      this.reloadDataWithPerPage(newPerPage);
    },

    reloadDataWithPerPage(newPerPage) {
      // Mettre à jour la limite de la requête avec la nouvelle quantité par page
      if (this.limitQueryAdmin !== 5) {
        this.limitQueryAdmin = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_personneladmin(this.currentPageAdmin);
      }
      if (this.limitQueryApui !== 5) {
        this.limitQueryApui = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_personnelapui(this.currentPageApui);
      }
      if (this.limitQueryFormateur !== 5) {
        this.limitQueryFormateur = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_formateur(this.currentPageFormateur);
      }
    },

    async fetchUsers(endpoint, page, filterCondition, perPage) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const response = await axios.get(endpoint, {
          params: {
            page: page,
            per_page: perPage,
          },
          headers: headers,
        });

        const users = response.data.users.data;

        const filteredUsers = users.filter((user) => {
          return (
            filterCondition(user) &&
            Object.values(user)
              .reduce((acc, value) => {
                if (typeof value === "string") {
                  acc.push(value.toLowerCase());
                }
                return acc;
              }, [])
              .some((value) => value.includes(this.searchQuery.toLowerCase()))
          );
        });

        return {
          filteredUsers: filteredUsers.map((user) => ({
            photo: user.photo,
            adresse: user.adresse,
            date_naissance: user.date_naissance,
            lieu_naissance: user.lieu_naissance,
            id: user.id,
            genre: user.genre,
            id_role: user.id_role,
            nationalite: user.nationalite,
            matricule: user.matricule,
            prenom: user.prenom,
            nom: user.nom,
            status: user.status,
            email: user.email,
            telephone: user.telephone,
            editModal: true,
            fonction: user.role.intitule,
            nom_service: user.personnel_admin_appui
              ? user.personnel_admin_appui
                .map((ele) => ele.service.nom_service)
                .join(", ")
              : "",
            filiere: user.formateur
              ? user.formateur
                .map((ele) => ele.unite_de_formation.nom_unite_formation)
                .join(", ")
              : "",
            id_filiere: user.formateur
              ? user.formateur
                .map((ele) => ele.unite_de_formation.id)
                .join(", ")
              : "",
            id_departement: user.formateur
              ? user.formateur
                .map((eles) => eles.unite_de_formation.departement.id)
                .join(", ")
              : "",
            departement: user.formateur
              ? user.formateur
                .map(
                  (eles) =>
                    eles.unite_de_formation.departement.nom_departement
                )
                .join(", ")
              : "",
          })),
          page: {
            // Autres données supplémentaires que vous souhaitez retourner
            total_page: response.data.users.last_page,
            total: response.data.users.total,
            per_page: response.data.users.per_page,
            current_page: response.data.users.current_page,
            from_begin: response.data.users.from,
            to_begin: response.data.users.to,
          },
        };
      } catch (error) {
        console.log(error);
        Swal.fire(
          "Erreur!",
          "Une erreur est survenue lors de la récupération des utilisateurs",
          "error"
        );
        return [];
      }
    },

    async get_formateur(page) {
      this.currentPageFormateur = page;
      const filterCondition = (user) => user.role.intitule === "Formateur";
      const perPage = this.limitQueryFormateur;

      //const hasReadPermission = this.permissions.some(permission => permission.read === 1 && permission.fonctionnalite.intitule === "Utilisateur");
      if (this.hasReadPermission("Utilisateur")) {
        const usersResponse = await this.fetchUsers(
          "/user/getFormateur",
          this.current_pagef,
          filterCondition,
          perPage
        );

        this.utilisateurProf = usersResponse.filteredUsers;
        this.total_pagef = usersResponse.page.total_page;
        this.totalf = usersResponse.page.total;
        this.per_pagef = usersResponse.page.per_page;
        this.current_pagef = usersResponse.page.current_page;
        this.from_beginf = usersResponse.page.from_begin;
        this.to_beginf = usersResponse.page.to_begin;
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3(
          "Vous n'avez pas la permission de lire cette fonctionnalité."
        );
      }
      // }
    },

    async get_personneladmin(page) {
      this.currentPageAdmin = page;
      const filterCondition = (user) =>
        user.role.categorie_personnel === "Personnel Administratif";
      const perPage = this.limitQueryAdmin;

      //const hasReadPermission = this.permissions.some(permission => permission.read === 1 && permission.fonctionnalite.intitule === "Utilisateur");

      if (this.hasReadPermission("Utilisateur")) {
        const usersResponse = await this.fetchUsers(
          "/user/getPersonnelAdmin",
          this.current_page,
          filterCondition,
          perPage
        );
        this.utilisateurs = usersResponse.filteredUsers;

        this.total_page = usersResponse.page.total_page;
        this.total = usersResponse.page.total;
        this.per_page = usersResponse.page.per_page;
        this.current_page = usersResponse.page.current_page;
        this.from_begin = usersResponse.page.from_begin;
        this.to_begin = usersResponse.page.to_begin;
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3(
          "Vous n'avez pas la permission de lire cette fonctionnalité."
        );
      }
    },

    async get_personnelapui(page) {
      this.currentPageApui = page;
      const filterCondition = (user) =>
        user.role.categorie_personnel === "Personnel d'appui" ||
        user.role.categorie_personnel === "Personnel Appui";
      const perPage = this.limitQueryApui;

      //const hasReadPermission = this.permissions.some(permission => permission.read === 1 && permission.fonctionnalite.intitule === "Utilisateur");

      if (this.hasReadPermission("Utilisateur")) {
        const usersResponse = await this.fetchUsers(
          "/user/getPersonnelApui",
          this.current_pageap,
          filterCondition,
          perPage
        );
        this.utilisateursPer = usersResponse.filteredUsers;

        this.total_pageap = usersResponse.page.total_page;
        this.totalap = usersResponse.page.total;
        this.per_pageap = usersResponse.page.per_page;
        this.current_pageap = usersResponse.page.current_page;
        this.from_beginap = usersResponse.page.from_begin;
        this.to_beginap = usersResponse.page.to_begin;
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3(
          "Vous n'avez pas la permission de lire cette fonctionnalité."
        );
      }
    },

    async toggleUserStatus(user) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.put(`/user/toggle-status/${user.id}`, {
          headers,
        });

        if (response.data.status === 200) {
          // Succès, mettez à jour la liste des utilisateurs ou affichez un message
          this.get_personneladmin(this.currentPageAdmin);
          this.get_formateur(this.currentPageFormateur);
          this.get_personnelapui(this.currentPageApui);
        } else {
          // Échec, affichez un message d'erreur
          console.error(response.data.message);
        }
      } catch (error) {
        // Gestion des erreurs
        console.error(error);
      }
    },

    changement() {
      this.searchQuery = "";
    },

    async activUser(user) {
      Swal.fire({
        title: "Êtes-vous sûr de vouloir réactiver cet utilisateur?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
      }).then((result) => {
        console.log(result)
        if (this.hasDeletePermission("Utilisateur")) {
          if (result.value) {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            axios
              .delete(`/user/delete/${user.id}`, { headers })
              .then((resp) => {
                showDialog6("Utilisateur réactiver avec succès");
                this.get_personneladmin(this.currentPageAdmin);
                this.get_formateur(this.currentPageFormateur);
                this.get_personnelapui(this.currentPageApui);
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        } else {
          // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
          showDialog3(
            "Vous n'avez pas la permission de lire cette fonctionnalité."
          );
        }
      });
    },

    async deleteUtilisateur(user) {
      if (user.status === "1") {
        const confirmation = Swal.fire({
          title: "Êtes-vous sûr de vouloir désactiver cet utilisateur?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
        }).then((result) => {
          if (this.hasDeletePermission("Utilisateur")) {
            if (result.value) {
              const token = localStorage.getItem("token");
              const headers = { Authorization: `Bearer ${token}` };
              try {
                axios.delete(`/user/delete/${user.id}`, { headers });
                showDialog6("Utilisateur désactivé avec succès");
                this.get_personneladmin(this.currentPageAdmin);
                this.get_formateur(this.currentPageFormateur);
                this.get_personnelapui(this.currentPageApui);
              } catch (error) {
                showDialog3(
                  "Une erreur est survenue lors de la suppression de l'utilisateur"
                );
                console.error(
                  "Erreur lors de la suppression de l'utilisateur :",
                  error
                );
              }
            }
          } else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3(
              "Vous n'avez pas la permission de lire cette fonctionnalité."
            );
          }
        });
      } else {
        showDialog3("Cet utilisateur est déjà inactif");
      }
    },

    openModal(utilisateur) {
      this.editModal = true;
      const eventData = {
        utilisateur: utilisateur,
      };
      bus.emit("userModifier", eventData);
    },

    getImageUrl(url) {
      //return url ? `${window.location.origin}/storage/${url}` : '';
      //return url ? `http://127.0.0.1:8000/storage/${url}` : "";
      return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
    },

    resetForm() {
      this.form_matiere.matieres.forEach(matiere => {
        matiere.id_matiere = "";
        matiere.id_matiere_erreur = "";
      });
      this.formateursFind = "";
      this.formateurNomFind = "";
      this.matieres = [];
      this.get_matiere();

    },


    duplicateMatiere(index) {
      const matiere = this.form_matiere.matieres[index];
      const newMatiere = {
        ...matiere,
        id_matiere: "",

        id_matiere_erreur: "",
      };

      this.form_matiere.matieres.splice(index + 1, 0, newMatiere);

      this.$nextTick(() => {
        this.reinitializeMDL();
      });
    },


    reinitializeMDL() {
      window.componentHandler.upgradeDom();
    },

    removeMatiere(index) {
      if (this.form_matiere.matieres.length > 1) {
        this.form_matiere.matieres.splice(index, 1);
      }
    },

    async assignerMatiere(partenaire) {

      await this.find_professeur(partenaire.id)
      await this.find_matiere_professeur(this.formateursFind)
      this.formateurNomFind = partenaire.nom + " " + partenaire.prenom
      // console.log(this.matiereProfesseurFind)

    },

    async find_professeur(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Utilisateur")) {
        await axios
          .get("/formateur/find/professeur/" + id, { headers })
          .then((response) => {
            this.formateursFind = response.data.formateur.id;
            //console.log(this.formateursFind)
            // this.validatedata("id_filiere");
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "une erreur est survenue lors de la recuperation du formateur",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },
    async find_matiere_professeur(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Utilisateur")) {
        await axios
          .get("/formateur/find/matiere/professeur/" + id, { headers })
          .then((response) => {
            this.matiereProfesseurFind = response.data.matiereformateur;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "une erreur est survenue lors de la recuperation des matières du formateur",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    async soumettreMatiereAssignerProfesseur() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();
      formdata.append('id_formateur', this.formateursFind);
      formdata.append("matiereProfessur", JSON.stringify(this.form_matiere.matieres));

      if (this.hasCreatePermission("Utilisateur")) {
        try {
          const create_store = await axios.post("/matiere/formateur/store", formdata, {
            headers,
          });
        //  $('#exampleModal1').modal('hide');
         

         // showDialog16("Matiere assignée avec succès");
         $('#exampleModal1').modal('hide');
            Swal.fire({
              position:" bottom-end",
              title: "Matiere assignée avec succès",
              showConfirmButton: false,
              icon: "success",
              timer: 1500,
            }).then((result) => {
             // if (result.value) {
                this.find_matiere_professeur(this.formateursFind);
                this.get_matiere();
                $('#exampleModal1').modal('show');
             // }
            });

         /*  await this.find_matiere_professeur(this.formateursFind)
          this.get_matiere();
          setTimeout(() => { }, 1500);
          $('#exampleModal1').modal('show');
          this.resetForm(); */

        } catch (e) {
          if (409 === e.response.data.statut) {
            $('#exampleModal1').modal('hide');
            Swal.fire({
              title: "Oops...",
              text: e.response.data.message,
              icon: "error",
              footer: "<a href>Reessayer s'il vous plait</a>",

            }).then((result) => {
              if (result.value) {
                this.find_matiere_professeur(this.formateursFind);
                this.get_matiere();
                $('#exampleModal1').modal('show');
              }
            });
          } else {
            showDialog3("Une erreur est survenue lors de l'enregistrement");
          }
        }
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    validatedata(champ) {
      // Variable pour vérifier la présence d'erreurs
      let hasError = false;

      // Validation spécifique en fonction du champ
      switch (champ) {
        case "id_matiere":
          this.form_matiere.matieres.forEach((matiere, index) => {
            matiere.id_matiere_erreur = "";
            if (matiere.id_matiere === "") {
              matiere.id_matiere_erreur = "Vous avez oublié de sélectionner une matiere " + (index + 1);
              hasError = true;
              return hasError;
            }
          });
          break;

        default:
          break;
      }
    },

    validatedataOld() {
      // Réinitialiser toutes les erreurs
      this.form_matiere.matieres.forEach(matiere => {
        matiere.id_matiere_erreur = "";

      });

      // Variable pour vérifier la présence d'erreurs
      let hasError = false;

      // Validation des champs
      this.form_matiere.matieres.forEach((matiere, index) => {
        if (matiere.id_matiere === "") {
          matiere.id_matiere_erreur = "Vous avez oublié de sélectionner une matiere " + (index + 1) + ".";
          hasError = true;
        }

      });
      return hasError;
    },

    validerAvantAjout() {
      const isVerifIdValid = this.validatedataOld();

      if (isVerifIdValid === true) {
        this.etatForm = false;
        this.editModal = false;

        return 0;
      } else {
        this.soumettreMatiereAssignerProfesseur();
        this.etatForm = true;
        this.editModal = false;
      }
    },


  },
};
</script>

<style scoped>
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.paginate_button {
  cursor: pointer;
  padding: 6px 12px;
  /*  margin: 0 2px; */
  font-size: 14px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 4px;
}

.paginate_button.previous,
.paginate_button.next {
  background-color: rgb(238, 234, 234);
  color: rgb(172, 170, 170);
}

.paginate_button:hover {
  background-color: white !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button,
.current-page {
  box-sizing: border-box;
  display: inline-block;
  padding: 0.7em 0.5em;
  margin: 0;
  /* Ajustez la marge */
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  cursor: hand;
  color: white !important;
  border: 1px solid transparent;
  border-radius: 2px;
  vertical-align: middle;
  line-height: 1;
  /* pour que les boutons aient la même hauteur */
}

.current-page {
  font-size: 14px;
  background-color: rgb(39, 147, 230) !important;
  color: #fff;
  margin: 0;
}
</style>