
<template>
    <div class="page-wrapper">

        <div class="page-header navbar navbar-fixed-top">
            <div class="page-header-inner">
                <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
            </div>
        </div>
        <div class="page-container">
            <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
                :userRole="userRole" />

            <!-- start page content -->
            <div class="page-content-wrapper">
                <div class="page-content">
                    <div class="page-bar">
                        <div class="page-title-breadcrumb">
                            <div class=" pull-left">
                                <div class="page-title">Emploi du temps </div>
                            </div>
                            <ol class="breadcrumb page-breadcrumb pull-right">
                                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'dashboard'">Tableau
                                        de
                                        Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                                </li>
                                <li class="active">Emploi Du Temps</li>
                            </ol>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-16 col-sm-12">
                            <div class="card">

                                <div class="card-head d-flex align-items-center justify-content-between">
                                    <header>Emploi du temps </header>

                                    <div class="d-flex align-items-center">
                                        <span class="me-3">Classe:</span>
                                        <select class="form-select" id="classSelect" v-model="form.id_classe"
                                            @change="validatedata('classe')">
                                            <option value="">Toutes les classes</option>
                                            <option v-for="classe in classes" :value="classe.id" :key="classe.id">
                                                {{ classe.type_formation.intitule }}{{
                                                    classe.nom_classe }} {{ classe.niveau }} {{ classe.type_classe }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <span class="me-3">Semestre:</span>
                                        <select class="form-select" id="classSelect" v-model="form.id_semestre"
                                            @change="validatedata('semestre')">
                                            <option value="">Toutes les semestres</option>
                                            <option v-for="semestre in semestres" :value="semestre.id"
                                                :key="semestre.id">
                                                {{
                                                    semestre.intitule }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="modal-footer bg-whitesmoke pr-0">

                                        <!--  <button class="btn btn-primary" @click="downloadSchedule">Télécharger</button> -->
                                        <button class="btn btn-secondary" @click="printWeeklySchedule">Imprimer</button>
                                        <button class="btn btn-primary" @click="enableEditMode">
                                            Modifier les Cours
                                        </button>
                                    </div>
                                </div>
                                <!-- Main calendar display with hours column -->
                                <div class="card-body">
                                    <div class="panel-body">
                                        <div class="has-toolbar">
                                            <FullCalendar ref="fullCalendar" :options="calendarOptions" />
                                            <!--  ref="fullCalendar" :options="calendarOptions"    -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <appafterContent />
        </div>
        <appfooter />

    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" :inert="!isModalOpen" :form="form">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addEventTitle">Ajouter Cours</h5>
                    <h5 class="modal-title" id="editEventTitle">Modifier Cours</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form class="">
                        <input type="hidden" id="id" name="id">
                        <div class="row">
                            <div class="col-md-12 mb-4">
                                <label for="cheese">Cours</label>
                                <select class="form-select" id="categorySelect" readonly tabIndex="-1"
                                    v-model="form.id_cours" @change="validatedata('id_cours')">
                                    <option value="">Selectionner le Cours</option>
                                    <option v-for="(cour, index) in cours" :value="cour.id" :key="index">
                                        {{ cour.matiere.intitule }}
                                        {{ cour.formateur.user.nom }}
                                        {{ cour.classe.nom_classe }}
                                        <!--  {{ cour.salle.intitule }} -->
                                        {{ cour.semestre.intitule }}
                                    </option>
                                </select>
                                <span class="erreur">{{ this.id_cours_erreur }}</span>
                            </div>
                            <div class="col-md-12 mb-4">
                                <label for="cheese">Annee Academique</label>
                                <select class="form-select" id="academicYearSelect" v-model="form.id_annee_academique"
                                    @change="validatedata('annee_academique')">
                                    <option value="">Toutes les années académiques</option>
                                    <option v-for="annee_academique in annee_academiques" :value="annee_academique.id"
                                        :key="annee_academique.id">{{ annee_academique.intitule }}</option>
                                </select>
                                <span class="erreur">{{ this.annee_academique_erreur }}</span>
                            </div>
                            <div class="col-md-12 mb-4">
                                <label for="cheese">Selectionner la salle</label>
                                <select class="form-select" id="academicYearSelect" v-model="form.id_salle"
                                    @change="validatedata('id_salle')">
                                    <option value="">Toutes les salles</option>
                                    <option v-for="salle in salles" :value="salle.id" :key="salle.id">{{ salle.intitule
                                        }}</option>
                                </select>
                                <span class="erreur">{{ this.id_salle_erreur }}</span>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label>Date Début</label>
                                    <input type="date" class="form-control datetimepicker" placeholder="Start Date"
                                        name="starts_at" id="starts-at" v-model="form.date_debut">
                                    <span class="erreur">{{ this.date_debut_erreur }}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Date Fin</label>
                                    <input type="date" class="form-control datetimepicker" placeholder="Start Date"
                                        name="starts_at" id="starts-at" v-model="form.date_fin">
                                    <span class="erreur">{{ this.date_fin_erreur }}</span>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label>Heure Début</label>
                                    <input class="form-control datetimepicker" type="time" id="starts-at"
                                        v-model="form.heure_debut" />
                                    <span class="erreur">{{ this.heure_debut_erreur }}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Heure Fin</label>
                                    <input class="form-control datetimepicker" type="time" id="starts-at"
                                        v-model="form.heure_fin" />
                                    <span class="erreur">{{ this.heure_fin_erreur }}</span>
                                </div>
                            </div>



                        </div>
                        <div class="modal-footer bg-whitesmoke pr-0">
                            <button type="button" class="btn btn-round btn-primary" id="add-event"
                                @click.prevent="validerAvantAjout()">Ajouter</button>
                           
                            <!--   <button type="button" class="btn btn-round btn-primary"
                                id="edit-event">Modifier</button> -->
                            <button type="button" id="close" class="btn btn-danger"
                                data-bs-dismiss="modal">Fermer</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
   
</template>

<script>
import bus from '../../eventBus';
import axios from '@/axios';
import Form from 'vform';

import flatPickr from 'vue-flatpickr-component';
//import 'flatpickr/dist/flatpickr.css';
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
//import calen from "../../../public/assets/js/pages/calendar/calendar.min.js"

import FullCalendar from '@fullcalendar/vue3';
import interactionPlugin from '@fullcalendar/interaction';
import dayGrid from '@fullcalendar/daygrid';
import timeGrid from '@fullcalendar/timegrid';




export default {
    props: {
        form: {
            type: Object,
            default: () => { }
        }
    },
    components: {
        appnavbar,
        appfooter,
        appheader,
        appafterContent,
        flatPickr,
        FullCalendar,
    },
    props: ['cour'],
    name: "EmploiDuTempsComponent",

    data() {
        return {
            isModalOpen: false,

            /* calendarOptions:{
                plugins:[dayGrid, interactionPlugin, timeGrid],
                initialView: 'timeGridWeek',
                locale: 'fr',
                headerToolbar:{
                    left: 'prev,next,today',
                    center: 'title',
                    right: 'dayGridMonth, timeGridWeek, timeGridDay',
                },
                height: "auto",
                allDaySlot: false,
                slotMinTime: '08:00:00',
                slotMaxTime: '18:00:00',
                dateClick: this.handleDateClick,

            }, */
            calendarOptions: {
                plugins: [dayGrid, interactionPlugin, timeGrid],
                initialView: 'timeGridWeek',
                locale: 'fr',
                eventContent: this.renderEventContent,
                eventClick: this.handleEditEvent,
                eventDrop: this.handleEventDrop,
                editable: true,  // Changé à true pour permettre l'édition
                droppable: true, // Ajouté pour permettre le drag & drop
                eventDraggable: true, // Ajouté pour permettre le drag des événements
                eventOverlap: true, // Permet le chevauchement temporaire
                eventResize: this.handleEventResize,
                headerToolbar: {
                    left: 'prev,next,today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                },
                height: "auto",
                allDaySlot: false,
                slotMinTime: '08:00:00',
                slotMaxTime: '21:00:00',
                slotLabelInterval: '01:00',
                className: "fc-event-success",
                dateClick: this.handleDateClick,
                events: this.fetchEvents,
                eventContent: function (info) {
                    let professeur = info.event.extendedProps.professeur || 'Pas de professeur';
                    const genre = info.event.extendedProps.genre || '';
                    const prefix = genre === 'Masculin' ? 'M.' : (genre === 'Féminin' ? 'Mme' : '');
                    let salle = info.event.extendedProps.salle || 'Pas de salle';
                    let classe = info.event.extendedProps.classe || 'Pas de classe';
                    return {
                        html: `<div>
                 <strong>${info.event.title} ${classe}</strong><br>
                 <span>${prefix} ${professeur}</span><br>
                 <span>${salle}</span>
               </div>`
                    };
                },

                // Ajout des gestionnaires d'événements pour le drag & drop
                eventDragStart: function (info) {
                    console.log('Début du drag:', info.event.title);
                },

                eventDragStop: function (info) {
                    console.log('Fin du drag:', info.event.title);
                },

                eventClick: (info) => {
                    this.listeView = true;
                }
            },
            selectedEvent: null, // Pour stocker l'événement sélectionné

            form: new Form({
                'id_cours': "",
                'id_salle': "",
                'heure_debut': "",
                'heure_fin': "",
                'date_debut': "",
                'date_fin': "",
                'id_classe': "",
                'id_semestre': "",

                'annee_academique': "",
                'id_annee_academique': "",

            }),

            id_cours_erreur: "",
            heure_debut_erreur: "",
            heure_fin_erreur: "",
            date_debut_erreur: "",
            date_fin_erreur: "",
            annee_academique_erreur: "",
            id_salle_erreur: "",

            /* id_classe_erreur: "",
            id_formateur_erreur: "",
            id_matiere_erreur: "",
            id_salle_erreur: "",
            id_semestre_erreur: "", */

            etatForm: false,
            editModal: false,
            cours: [],
            classes: [],
            formateurs: [],
            matieres: [],
            salles: [],
            semestres: [],
            annee_academiques: [],
            idCour: "",
            calendar: null,
            events: [],
            showModal: false,

        }
    },
    computed: {
        //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
        token() {
            return this.$store.state.isLoggedIn;
        },
        userNom() {
            return this.$store.state.userNom;
        },
        userPrenom() {
            return this.$store.state.userPrenom;
        },
        userIdrole() {
            return this.$store.state.userIdrole;
        },
        userPhoto() {
            return this.$store.state.userPhoto;
        },
        userRole() {
            return this.$store.state.userRole;
        },
    },

    watch: {
        'form.annee_academique': 'filterEvents',
        'form.id_classe': 'filterEvents',
        'form.id_semestre': 'filterEvents'
    },

    beforeMount() {
        /* this.$data.calendarOptions.events= {
            url: 'http://localhost:8000/api/get/emploi',
            method: 'GET',
            failure: error =>{
                console.log('erreur est: ', error.message);
            }
        } */
        this.filterEvents();
    },

    mounted() {

        this.get_classe();
        this.get_cours();
        this.get_formateur();
        this.get_salle();
        this.get_semestre();
        this.get_matiere();
        this.get_annee_academique();

        bus.on('courModifier', (eventData) => {
            this.editModal = eventData.editModal;
            this.monterToupdate(eventData.cour);
        });
        //this.fetchEvents();
        window.addEventListener("edit-event", (e) => {
            const eventId = e.detail;
            this.activateEditMode(eventId);
        });

    },

    methods: {
        ...mapActions(["login", "logout"]),

        loadEvents() {
            axios.post('/generate-schedule', {}, {  // Ajout d'un objet vide comme données
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then(response => {
                    console.log("Événements reçus du serveur:", response.data.events);
                    this.calendarOptions.events = response.data.events.map(event => ({
                        id: event.id,
                        title: event.title || 'Cours',
                        start: `${event.start}T${event.heure_debut}`,
                        end: `${event.end}T${event.heure_fin}`,
                        extendedProps: {
                            id_salle: event.id_salle
                        }
                    }));
                })
                .catch(error => {
                    console.error("Erreur détaillée:", error);
                    console.error("Erreur détaillée ici :");
                    this.showDialog('error', 'Erreur', 'Impossible de charger les événements');
                    console.log('Erreur lors de la mise à jour :', error);
                });
        },

        // Méthode utilitaire de parsing des dates
        parseEventData(event) {
            console.log("Event reçu :", event); // Log de l'événement complet

            const parsedEvent = {
                id: event.id,
                start: event.startStr ? event.startStr.split('T')[0] : null,
                end: event.endStr ? event.endStr.split('T')[0] : event.startStr?.split('T')[0],
                heure_debut: event.startStr ? event.startStr.split('T')[1]?.slice(0, 8) : null,
                heure_fin: event.endStr ? event.endStr.split('T')[1]?.slice(0, 8) :
                    event.startStr ? event.startStr.split('T')[1]?.slice(0, 8) : null,
                id_salle: event.extendedProps?.id_salle
            };

            console.log("Event parsé :", parsedEvent); // Log de l'événement parsé

            if (!parsedEvent.id) {
                console.warn("Attention: ID manquant dans l'événement"); // Warning spécifique pour l'ID
            }

            return parsedEvent;
        },
        showDialog(icon, title, text, showCancel = false) {
            return Swal.fire({
                icon: icon,
                title: title,
                text: text,
                showCancelButton: showCancel,
                confirmButtonText: showCancel ? "Échanger" : "OK",
                cancelButtonText: "Annuler"
            });
        },

        // Méthode générique de gestion des événements
        // Mise à jour de l'événement
        async handleEventUpdate(info, endpoint = '/check-and-update-event') {
            const calendar = this.$refs.fullCalendar.getApi();
            const eventId = info.event.id || info.event._def?.publicId;

            // Vérifier si l'événement a un ID valide
            if (!eventId) {
                await this.showDialog('error', 'Erreur', 'ID de l\'événement manquant');
                info.revert();
                return;
            }

            // Préparation des données de l'événement
            const updatedEvent = {
                id: eventId,
                start: info.event.startStr.split('T')[0],
                end: info.event.endStr.split('T')[0],
                heure_debut: info.event.startStr.split('T')[1].slice(0, 8),
                heure_fin: info.event.endStr.split('T')[1].slice(0, 8),
                id_salle: info.event.extendedProps?.id_salle,
            };

            // Validation des horaires localement
            const startDateTime = new Date(`${updatedEvent.start}T${updatedEvent.heure_debut}`);
            const endDateTime = new Date(`${updatedEvent.end}T${updatedEvent.heure_fin}`);

            if (endDateTime <= startDateTime) {
                await this.showDialog('error', 'Erreur', 'La date et l\'heure de fin doivent être après la date et l\'heure de début.');
                info.revert();
                return;
            }

            try {
                // Envoi des données au backend
                const response = await axios.post(endpoint, updatedEvent, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                });

                console.log('Réponse du backend :', response.data); // Log pour débogage

                if (response.data.success) {
                    // Afficher un message de succès
                    await this.showDialog('success', 'Succès', 'Événement mis à jour avec succès');

                    // Rafraîchir tous les événements dans FullCalendar
                    calendar.refetchEvents();
                } else {
                    // Si la mise à jour échoue, rétablir l'état précédent
                    info.revert();
                    await this.showDialog('error', 'Erreur', response.data.message || 'Une erreur s\'est produite lors de la mise à jour.');
                }
            } catch (error) {
                // Gestion des erreurs
                console.error('Erreur lors de la mise à jour :', error.response?.data || error);
                info.revert();
                await this.showDialog('error', 'Erreur', 'Impossible de mettre à jour l\'événement.');
            }
        }

        ,


        // Méthodes spécifiques utilisant la méthode générique
        /*    async handleEventResize(info) {
               const calendar = this.$refs.fullCalendar.getApi();
               const eventId = info.event.id || info.event._def?.publicId;
   
               if (!eventId) {
                   await this.showDialog('error', 'Erreur', 'ID de l\'événement manquant');
                   info.revert();
                   return;
               }
   
               const updatedEvent = {
                   id: info.event.id,
                   start: info.event.startStr.split('T')[0],
                   end: info.event.endStr.split('T')[0],
                   heure_debut: info.event.startStr.split('T')[1].slice(0, 8),
                   heure_fin: info.event.endStr.split('T')[1].slice(0, 8),
                   id_salle: info.event.extendedProps?.id_salle,
               };
   
               // Validation locale des dates
               const startDateTime = new Date(`${updatedEvent.start}T${updatedEvent.heure_debut}`);
               const endDateTime = new Date(`${updatedEvent.end}T${updatedEvent.heure_fin}`);
   
               if (endDateTime <= startDateTime) {
                   await this.showDialog('error', 'Erreur', 'La date de fin doit être après la date de début.');
                   info.revert();
                   return;
               }
   
               try {
                   // Envoi au backend pour vérifier le conflit et mettre à jour
                   const response = await axios.post('/verify-schedule-conflict', updatedEvent, {
                       headers: {
                           Authorization: `Bearer ${localStorage.getItem('token')}`,
                           'Content-Type': 'application/json',
                       },
                   });
   
                   console.log("Réponse du backend :", response.data); // Affichage pour débogage
   
                   if (response.data.success) {
                       await this.showDialog('success', 'Succès', 'Événement mis à jour avec succès');
   
                       // Mise à jour de l'événement localement dans FullCalendar
                       info.event.setStart(updatedEvent.start + 'T' + updatedEvent.heure_debut);
                       info.event.setEnd(updatedEvent.end + 'T' + updatedEvent.heure_fin);
   
                       // Forcer la mise à jour de l'événement dans FullCalendar
                       calendar.updateEvent(info.event);
   
                       // Rafraîchir et recharger tous les événements
                       calendar.refetchEvents();
                       calendar.rerenderEvents();
                   } else {
                       info.revert(); // Revenir à l'état précédent si le backend renvoie une erreur
                       await this.showDialog('error', 'Erreur', response.data.message || 'Conflit détecté.');
                   }
               } catch (error) {
                   console.error('Erreur lors de l\'appel API :', error.response?.data || error);
                   info.revert(); // Revenir à l'état précédent si une erreur se produit
                   await this.showDialog('error', 'Erreur', 'Impossible de mettre à jour l\'événement.');
               }
           }
   
           , */

        async handleEventResize(info) {
            const eventId = info.event.id || info.event._def?.publicId;

            if (!eventId) {
                await this.showDialog('error', 'Erreur', 'ID de l\'événement manquant');
                info.revert();
                return;
            }

            const updatedEvent = {
                id: parseInt(eventId), // Assurez-vous que l'ID est un nombre
                start: info.event.startStr.split('T')[0],
                end: info.event.endStr.split('T')[0],
                heure_debut: info.event.startStr.split('T')[1].slice(0, 8),
                heure_fin: info.event.endStr.split('T')[1].slice(0, 8),
                id_salle: parseInt(info.event.extendedProps?.id_salle) || null
            };

            // Validation locale
            const startDateTime = new Date(`${updatedEvent.start}T${updatedEvent.heure_debut}`);
            const endDateTime = new Date(`${updatedEvent.end}T${updatedEvent.heure_fin}`);

            if (endDateTime <= startDateTime) {
                await this.showDialog('error', 'Erreur', 'La date de fin doit être après la date de début.');
                info.revert();
                return;
            }

            try {
                // Envoi au backend
                const response = await axios.post('/verify-schedule-conflict', updatedEvent, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                });

                if (response.data.success) {
                    // Mise à jour dans le backend
                    const updateResponse = await axios.post('/check-and-update-event', updatedEvent, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                    });

                    if (updateResponse.data.success) {
                        await this.showDialog('success', 'Succès', 'Événement mis à jour avec succès');
                        // Rafraîchir les événements depuis le backend
                        this.$refs.fullCalendar.getApi().refetchEvents();
                    } else {
                        info.revert();
                        await this.showDialog('error', 'Erreur', updateResponse.data.message || 'Échec de la mise à jour.');
                    }
                } else {
                    info.revert();
                    await this.showDialog('error', 'Erreur', response.data.message || 'Conflit détecté.');
                }
            } catch (error) {
                console.error('Erreur lors de l\'appel API :', error.response?.data || error);
                info.revert();
                await this.showDialog('error', 'Erreur', 'Impossible de mettre à jour l\'événement.');
            }
        }


        ,
        // Mode édition
        /*  enableEditMode() {
             // Activer le mode édition pour tous les événements
             this.calendarOptions.editable = true;
 
             if (this.$refs.fullCalendar) {
                 const calendarApi = this.$refs.fullCalendar.getApi();
                 calendarApi.getEvents().forEach(event => {
                     event.setProp('editable', true);
                 });
             }
 
             this.$swal.fire({
                 icon: 'info',
                 title: 'Mode Édition Activé',
                 text: 'Vous pouvez maintenant déplacer et redimensionner les événements',
                 confirmButtonText: 'Compris'
             });
         }, */


        async handleEventDrop(info) {
            console.log('1. Début handleEventDrop');
            console.log('Info reçue:', {
                event: info.event.title,
                start: info.event.start,
                end: info.event.end
            });

            // Empêcher le déplacement par défaut
            info.revert();
            console.log('2. Déplacement par défaut empêché');

            const droppedEvent = info.event;
            const calendar = this.$refs.fullCalendar.getApi();
            const newStart = info.event.start;
            const newEnd = info.event.end;

            console.log('3. Recherche de l\'événement cible ou créneau libre');

            // Vérifier s'il y a un événement qui chevauche le nouveau créneau
            const overlappingEvent = calendar.getEvents().find(event => {
                if (event.id === droppedEvent.id) return false;

                const eventStart = event.start.getTime();
                const eventEnd = event.end.getTime();
                const newStartTime = newStart.getTime();
                const newEndTime = newEnd.getTime();

                return (newStartTime < eventEnd && newEndTime > eventStart);
            });

            let confirmMessage;
            let targetEvent = null;

            if (overlappingEvent) {
                // Cas d'un échange avec un autre événement
                confirmMessage = `Voulez-vous échanger :<br>
                         "${droppedEvent.title}"<br>
                         avec<br>
                         "${overlappingEvent.title}" ?`;
                targetEvent = overlappingEvent;
            } else {
                // Cas d'un déplacement vers un créneau libre
                confirmMessage = `Voulez-vous déplacer :<br>
                         "${droppedEvent.title}"<br>
                         vers le créneau ${newStart.toLocaleTimeString()} - ${newEnd.toLocaleTimeString()} ?`;
            }

            try {
                const confirmResult = await Swal.fire({
                    title: 'Confirmation',
                    html: confirmMessage,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Non'
                });

                if (!confirmResult.value) {
                    console.log('Opération annulée par l\'utilisateur');
                    return;
                }

                let requestData;
                let endpoint;

                if (targetEvent) {
                    // Cas d'un échange
                    endpoint = '/exchange-events';
                    requestData = {
                        event1_id: droppedEvent.id,
                        event2_id: targetEvent.id
                    };
                } else {
                    // Cas d'un déplacement simple
                    endpoint = '/emploi-du-temps/move';
                    requestData = {
                        event_id: droppedEvent.id,
                        new_start: newStart.toISOString(),
                        new_end: newEnd.toISOString()
                    };
                }

                const response = await axios.post(endpoint, requestData, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });

                if (response.data.success) {
                    if (targetEvent) {
                        // Logique d'échange existante
                        droppedEvent.remove();
                        targetEvent.remove();

                        await new Promise(resolve => setTimeout(resolve, 100));

                        calendar.addEvent({
                            id: droppedEvent.id,
                            title: droppedEvent.title,
                            start: targetEvent.start,
                            end: targetEvent.end,
                            ...droppedEvent.extendedProps
                        });

                        calendar.addEvent({
                            id: targetEvent.id,
                            title: targetEvent.title,
                            start: droppedEvent.start,
                            end: droppedEvent.end,
                            ...targetEvent.extendedProps
                        });
                    } else {
                        // Logique de déplacement simple
                        droppedEvent.remove();
                        calendar.addEvent({
                            id: droppedEvent.id,
                            title: droppedEvent.title,
                            start: newStart,
                            end: newEnd,
                            ...droppedEvent.extendedProps
                        });
                    }

                    await Swal.fire({
                        title: 'Succès',
                        text: targetEvent ? 'Les cours ont été échangés avec succès' : 'Le cours a été déplacé avec succès',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false
                    });

                    this.$router.go(0);
                }
            } catch (error) {
                console.error('ERREUR:', error);
                await Swal.fire({
                    title: 'Erreur',
                    text: error.response?.data?.message || 'Une erreur est survenue',
                    icon: 'error'
                });
            }
        },

        isSameTimeSlot(event1, event2) {
            const start1 = event1.start.getTime();
            const end1 = event1.end.getTime();
            const start2 = event2.start.getTime();
            const end2 = event2.end.getTime();

            // Vérifier le chevauchement des créneaux horaires
            return (start1 <= end2 && end1 >= start2);
        },

        // Vous pouvez supprimer la méthode isTimeOverlapping car la logique est maintenant dans handleEventDrop,

        // Méthode utilitaire pour vérifier si deux événements peuvent être échangés
        canExchangeEvents(event1, event2) {
            // Vérifier si les durées sont identiques
            const duration1 = event1.end.getTime() - event1.start.getTime();
            const duration2 = event2.end.getTime() - event2.start.getTime();
            return Math.abs(duration1 - duration2) < 1000; // Tolérance d'une seconde
        }

        ,
        isOverlapping(event1, event2) {
            // Vérifier si deux événements se chevauchent
            const start1 = event1.start.getTime();
            const end1 = event1.end.getTime();
            const start2 = event2.start.getTime();
            const end2 = event2.end.getTime();

            return start1 < end2 && end1 > start2;
        },
        async confirmExchange(event1, event2, info) {
            const confirm = await Swal.fire({
                title: "Confirmer l'échange",
                text: `Voulez-vous échanger cet événement avec le cours de ${event2.title}?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Oui, échanger",
                cancelButtonText: "Non, annuler",
            });

            if (confirm.isConfirmed) {
                console.log("Échange confirmé pour les événements : ", event1, event2);

                // Si l'utilisateur confirme, on effectue l'échange
                await this.exchangeEvents(event1, event2);
                this.$refs.fullCalendar.getApi().refetchEvents(); // Rafraîchir les événements
            } else {
                console.log("Échange annulé, retour à la position initiale.");
                // Si l'utilisateur annule, on restaure l'événement à sa position initiale
                info.revert();
            }
        }



        ,
        // Méthode d'échange d'événements (à implémenter selon vos besoins)
        async exchangeEvents(event1, event2) {
            try {
                const response = await axios.post('/api/emploi-du-temps/exchange', {
                    event1_id: event1.id,
                    event2_id: event2.id
                });

                if (response.data.success) {
                    // Mettre à jour les événements dans le calendrier
                    const calendar = this.$refs.fullCalendar.getApi();

                    // Stocker temporairement les données du premier événement
                    const temp = {
                        start: event1.start,
                        end: event1.end,
                        extendedProps: { ...event1.extendedProps }
                    };

                    // Mettre à jour le premier événement
                    event1.setStart(event2.start);
                    event1.setEnd(event2.end);
                    Object.assign(event1.extendedProps, event2.extendedProps);

                    // Mettre à jour le deuxième événement
                    event2.setStart(temp.start);
                    event2.setEnd(temp.end);
                    Object.assign(event2.extendedProps, temp.extendedProps);

                    // Rafraîchir l'affichage
                    calendar.refetchEvents();

                    // Afficher un message de succès
                    await Swal.fire({
                        title: 'Succès',
                        text: 'Les cours ont été échangés avec succès',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
            } catch (error) {
                console.error('Erreur lors de l\'échange :', error);

                // Afficher un message d'erreur
                await Swal.fire({
                    title: 'Erreur',
                    text: error.response?.data?.message || 'Une erreur est survenue lors de l\'échange des cours',
                    icon: 'error'
                });
            }
        },
        // Optionnel : Ajouter une méthode pour vérifier si l'échange est possible
        canExchangeEvents(event1, event2) {
            // Vérifier si les durées sont identiques
            const duration1 = event1.end - event1.start;
            const duration2 = event2.end - event2.start;

            return duration1 === duration2;
        },


        calculateEventDuration(start, end) {
            const startDate = new Date(start);
            const endDate = new Date(end);
            const duration = (endDate - startDate) / (1000 * 60); // Durée en minutes
            return duration;
        }




        ,
        /*  async filterEvents() {
 
             const token = localStorage.getItem("token");
             const headers = { Authorization: `Bearer ${token}` };
             
             const params = {
                 annee_academique: this.form.annee_academique,
                 id_classe: this.form.id_classe,
                 id_semestre: this.form.id_semestre
             };
 
             // Mettez à jour l'URL des événements avec les paramètres de filtre
             this.$data.calendarOptions.events = {
                 url: 'http://localhost:8000/api/get/emploi',
                 method: 'GET',
                 extraParams: params,
                 failure: error => {
                     console.log('erreur est: ', error.message);
                 }
             };
 
             // Rechargez les événements du calendrier
             this.$refs.fullCalendar.getApi().refetchEvents();
         }, */
        async filterEvents() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            const params = {
                annee_academique: this.form.annee_academique,
                id_classe: this.form.id_classe,
                id_semestre: this.form.id_semestre
            };

            // Utiliser l'API pour récupérer les événements
            this.$data.calendarOptions.events = {
                url: 'http://localhost:8000/api/get/emploi',
                method: 'GET',

                extraParams: params,
                failure: error => {
                    console.log('Erreur lors du chargement des événements : ', error.message);

                }
            };

            setTimeout(() => {
                if (this.$refs.fullCalendar && this.$refs.fullCalendar.getApi()) {
                    this.$refs.fullCalendar.getApi().refetchEvents();
                } else {
                    console.error('Le calendrier n\'est pas initialisé');
                }
            }, 500);
        }
        ,
        // Méthode pour télécharger l'emploi du temps
        downloadSchedule() {
            const url = '/api/download-schedule'; // Remplacez par l'URL de votre API
            window.open(url, '_blank'); // Ouvre le lien dans un nouvel onglet
        },






        printWeeklySchedule() {
            try {
                const calendar = this.$refs.fullCalendar?.getApi();
                if (!calendar) {
                    console.error("Le calendrier n'est pas accessible");
                    return;
                }



                // Obtenir les dates de début et fin de la semaine courante
                const currentView = calendar.view;
                const startDate = currentView.currentStart;
                const endDate = currentView.currentEnd;

                // Formater les dates en français
                const formatDate = (date) => {
                    const options = { day: 'numeric', month: 'long', year: 'numeric' };
                    return new Date(date).toLocaleDateString('fr-FR', options);
                };

                const weekStart = formatDate(startDate);
                const weekEnd = formatDate(new Date(endDate.getTime() - 24 * 60 * 60 * 1000)); // Soustraire un jour car currentEnd pointe vers le début du jour suivant
                // Récupérer la classe complète pour l'affichage



                const events = calendar.getEvents();
                console.log("Événements récupérés:", events);
                console.log("Événement :", events);


                const eventsDetails = events.map(event => {
                    const professeur = event.extendedProps.professeur || 'Pas de professeur';
                    const genre = event.extendedProps.genre || '';
                    const prefix = genre === 'Masculin' ? 'M.' : (genre === 'Féminin' ? 'Mme' : '');
                    return `${prefix} ${professeur} - ${event.title}`;
                }).join('<br>');



                // Récupérer la classe et l'année scolaire
                const firstEvent = events[0];
                const classe = firstEvent?.extendedProps?.classe || 'Non définie';
                // Récupérer la classe et l'année scolaire

                // Déclarer les variables en dehors du if
                // Récupérer les informations complètes
                const departement = firstEvent?.extendedProps?.departement || "DÉPARTEMENT";
                const uniteDeFormation = firstEvent?.extendedProps?.unite_de_formation || "UNITÉ DE FORMATION";
                // Récupérer le semestre depuis le premier événement
                const semestre = firstEvent?.extendedProps?.semestre || "Semestre en cours";

                console.log("First Event:", firstEvent);
                console.log("Classe:", firstEvent?.extendedProps?.classe);
                console.log("Unite de formation:", firstEvent?.extendedProps?.classe?.unite_de_formation);
                // Récupération de l'année académique courante
                const currentYear = new Date().getFullYear();
                const academicYearString = `${currentYear - 1} - ${currentYear}`;

                // Si vous avez accès à l'année académique dans les propriétés étendues de l'événement
                const anneeScolaire = firstEvent?.extendedProps?.annee_academique?.intitule || academicYearString;
                // Détecter la classe (FPJ ou CJ)
                const isSpecialClass = events.some(event => {
                    const classe = event.extendedProps.classe;
                    return classe && (classe.includes('FPJ') || classe.includes('CJ'));
                });

                // Définir les heures en fonction de la classe
                const hours = isSpecialClass ? [
                    '08h-09h', '09h-10h', '10h-11h', '11h-12h', '12h-13h',
                    '13h-14h', '14h-15h', '15h-16h', '16h-17h'
                ] : [
                    '17h-18h',
                    '18h-19h', '19h-20h'
                ];

                const printContent = `
                <style>
                 
    @media print {
        table { 
            width: 100%;
            border-collapse: collapse;
            table-layout: fixed;
        }
        
        th, td { 
            border: 1px solid black;
            padding: 4px;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            word-wrap: break-word;
        }

        th:first-child, td:first-child { 
            width: 8%;
        }
        
        th:not(:first-child), td:not(:first-child) { 
            width: 15.33%;
        }

        .event-cell {
            padding: 4px !important;
            print-color-adjust: exact;
            -webkit-print-color-adjust: exact;
        }

        /* Palette de 10 couleurs pastel */
        .event-cell.course-1 { background-color: #E3F2FD !important; } /* Bleu très clair */
        .event-cell.course-2 { background-color: #F3E5F5 !important; } /* Violet très clair */
        .event-cell.course-3 { background-color: #E8F5E9 !important; } /* Vert très clair */
        .event-cell.course-4 { background-color: #FFF3E0 !important; } /* Orange très clair */
        .event-cell.course-5 { background-color: #FCE4EC !important; } /* Rose très clair */
        .event-cell.course-6 { background-color: #E0F7FA !important; } /* Cyan très clair */
        .event-cell.course-7 { background-color: #FFF8E1 !important; } /* Jaune très clair */
        .event-cell.course-8 { background-color: #F1F8E9 !important; } /* Vert lime très clair */
        .event-cell.course-9 { background-color: #E8EAF6 !important; } /* Indigo très clair */
        .event-cell.course-10 { background-color: #EFEBE9 !important; } /* Marron très clair */
    
    }
        .main-title {
                    text-align: center;
                    margin-bottom: 20px;
                }
                .main-title h3 {
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                    font-weight: bold;
                }
                .main-title .semestre {
                    margin: 5px 0;
                    font-size: 14px;
                }
                .main-title .week-range {
                    margin: 5px 0;
                    font-size: 14px;
                }

  .header-info p {
            margin: 0;
            padding: 0;
            font-weight: bold;
        }
            .main-title h2 {
                text-align: center;
                margin: 5px 0;
                font-size: 10px; /* Police encore plus petite */
                font-weight: bold;
                display: inline-block;
                width: 100%;
                color: #0066cc; /* Bleu */
                /* ou vous pouvez utiliser color: blue; pour un bleu standard */
            }

            .header-info {
            position: relative;
            text-align: left;
            margin-bottom: 20px;
            font-size: 12px;
            line-height: 1.4;
        }
             .department-info {
            text-align: center;
            margin-bottom: 20px;
            font-size: 13px;
            line-height: 1.4;
        }

        .department-info p {
            margin: 5px 0;
            font-weight: bold;
        }
             .signature-block {
            text-align: right;
            margin-top: 30px;
            padding-right: 50px;
        }

        .signature-block p {
            margin: 0;
            font-weight: bold;
        }

        .signature-space {
            height: 60px; /* Espace pour la signature */
        }
                @media print {
        table { 
            width: 100%;
            border-collapse: collapse;
            table-layout: fixed;
        }
        
        th, td { 
            border: 1px solid black;
            padding: 4px;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            word-wrap: break-word;
        }

        th:first-child, td:first-child { 
            width: 8%;
        }
        
        th:not(:first-child), td:not(:first-child) { 
            width: 15.33%;
        }

        .event-cell {
            padding: 4px !important;
        }
    }
                @page {
                            size: landscape;
                            margin: 0.5cm;
                        }
                    }
                </style>
                 <div class="department-info">
                <p>${departement}</p>
                <p  >Unité Pédagogique : ${uniteDeFormation}</p>
            </div>
                <div class="header-info">
                    <p>Année Scolaire: ${anneeScolaire}</p>
                     <p>Classe: ${classe}</p>
                </div>
                 <div class="main-title">
                <h3>EMPLOI DU TEMPS PROVISOIRE DU  </h3>
                <p class="semestre"> ${semestre}</p>
                <p class="week-range">Du ${weekStart} au ${weekEnd}</p>
            </div>
                <table>
                    <thead>
                        <tr>
                            <th style="width: 8%">Horaires</th>
                            <th style="width: 15.3%">Lundi</th>
                            <th style="width: 15.3%">Mardi</th>
                            <th style="width: 15.3%">Mercredi</th>
                            <th style="width: 15.3%">Jeudi</th>
                            <th style="width: 15.3%">Vendredi</th>
                            <th style="width: 15.3%">Samedi</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${this.generateTableRows(events, hours)}
                    </tbody>
                </table>
                 <div class="signature-block">
        <p>DIRECTEUR DES ETUDES</p>
        <div class="signature-space"></div>
    </div>
            `;

                // Sauvegarder le contenu original
                const originalContent = document.body.innerHTML;

                // Remplacer pour l'impression
                document.body.innerHTML = printContent;

                // Imprimer
                window.print();

                // Restaurer le contenu original
                document.body.innerHTML = originalContent;

                // Réinitialiser le calendrier
                this.$nextTick(() => {
                    this.initializeCalendar();
                });

            } catch (error) {
                console.error("Erreur lors de l'impression:", error);
                alert("Une erreur est survenue lors de l'impression");
            }
        },

        generateTableRows(events, hours) {
            let occupiedCells = {};
            let courseColors = {}; // Pour stocker les associations matière-couleur
            let colorIndex = 1;    // Pour suivre quelle couleur utiliser ensuite

            const rows = hours.map(hour => {
                let rowContent = '<tr>';
                rowContent += `<td><strong>${hour}</strong></td>`;

                if (hour === '13h-14h') {
                    rowContent += '<td class="pause-cell" colspan="6">PAUSE</td>';
                } else {
                    for (let day = 1; day <= 6; day++) {
                        const cellKey = `${day}-${hour}`;
                        if (occupiedCells[cellKey]) {
                            continue;
                        }

                        const dayEvents = events.filter(event => {
                            const eventDate = new Date(event.start);
                            return eventDate.getDay() === day &&
                                eventDate.getHours() === parseInt(hour) &&
                                this.isEventVisible(event);
                        });

                        if (dayEvents.length > 0) {
                            const event = dayEvents[0];
                            const duration = new Date(event.end).getHours() - new Date(event.start).getHours();

                            // Attribuer une couleur si la matière n'en a pas encore
                            if (!courseColors[event.title]) {
                                courseColors[event.title] = colorIndex;
                                colorIndex = (colorIndex % 10) + 1; // Limite à 10 couleurs qui se répètent
                            }

                            const genre = event.extendedProps.genre || '';
                            const prefix = genre === 'Masculin' ? 'M.' : (genre === 'Féminin' ? 'Mme' : '');

                            rowContent += `
                        <td class="event-cell course-${courseColors[event.title]}" rowspan="${duration}">
                            <strong>${event.title}</strong><br>
                             ${prefix}  ${event.extendedProps.professeur || ''}<br>
                            ${event.extendedProps.salle || ''}
                        </td>
                    `;

                            // Marquer les cellules occupées
                            for (let i = 0; i < duration; i++) {
                                occupiedCells[`${day}-${parseInt(hour) + i}h-${parseInt(hour) + i + 1}h`] = true;
                            }
                        } else if (!occupiedCells[cellKey]) {
                            rowContent += '<td></td>';
                        }
                    }
                }
                rowContent += '</tr>';
                return rowContent;
            }).join('');

            return rows;
        },

        isEventVisible(event) {
            const currentView = this.$refs.fullCalendar.getApi().view;
            const eventStart = new Date(event.start);
            const eventEnd = new Date(event.end);

            return eventStart >= currentView.currentStart &&
                eventEnd <= currentView.currentEnd;
        },

        enableEditMode() {
            // On rend le calendrier modifiable
            this.calendarOptions.editable = true;

            // On rafraîchit le calendrier pour appliquer les changements
            this.$refs.fullCalendar.getApi().render();

            Swal.fire({
                title: "Mode modification activé",
                text: "Vous pouvez maintenant déplacer et redimensionner les événements.",
                icon: "info",
            });
        },

        initializeCalendar() {
            if (this.$refs.fullCalendar) {
                const calendar = this.$refs.fullCalendar.getApi();
                calendar.render();
            }
        }
        ,


        printSchedule() {
            const events = this.$refs.fullCalendar.getApi().getEvents();
            const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
            const hours = ['08h', '09h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h'];

            let schedule = {};

            events.forEach(event => {
                const start = new Date(event.start);
                const day = days[start.getDay()];
                const hour = `${start.getHours()}h`;

                if (!schedule[day]) schedule[day] = {};
                if (!schedule[day][hour]) schedule[day][hour] = [];

                const details = `${event.title} (${event.extendedProps.professeur || 'Pas de professeur'}, ${event.extendedProps.salle || 'Pas de salle'}, ${event.extendedProps.classe || 'Pas de classe'})`;
                schedule[day][hour].push(details);
            });

            let tableHTML = '<table><thead><tr><th>Horaires</th>';
            days.forEach(day => tableHTML += `<th>${day}</th>`);
            tableHTML += '</tr></thead><tbody>';

            hours.forEach(hour => {
                tableHTML += `<tr><td>${hour}</td>`;
                days.forEach(day => {
                    const events = schedule[day] && schedule[day][hour] ? schedule[day][hour].join('<br>') : '';
                    tableHTML += `<td>${events}</td>`;
                });
                tableHTML += '</tr>';
            });

            tableHTML += '</tbody></table>';

            const printWindow = window.open('', '_blank');
            printWindow.document.write('<html><head><title>Imprimer Emploi du Temps</title>');
            printWindow.document.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid black; padding: 8px; text-align: center; } th { background-color: #f2f2f2; }</style>');
            printWindow.document.write('</head><body>');
            printWindow.document.write(tableHTML);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        }

        ,

        // Méthode pour générer le contenu à imprimer
        generatePrintContent() {
            let content = '<table border="1" style="width: 100%; border-collapse: collapse;">';
            content += '<tr><th>Cours</th><th>Professeur</th><th>Salle</th><th>Date</th><th>Heure</th></tr>';

            // Récupérer les événements du calendrier
            const events = this.$refs.fullCalendar.getApi().getEvents();

            // Ajoutez ici la logique pour générer le contenu de l'emploi du temps
            events.forEach(event => {
                content += `<tr>
                <td>${event.title}</td>
                <td>${event.extendedProps.professeur || 'Non spécifié'}</td>
                <td>${event.extendedProps.salle || 'Non spécifiée'}</td>
                <td>${event.start.toLocaleDateString()}</td>
                <td>${event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
            </tr>`;
            });

            content += '</table>';
            return content;
        },
        printCalendar() {
            const calendarElement = this.$refs.fullCalendar.$el;
            const printWindow = window.open('', '_blank');
            printWindow.document.write('<html><head><title>Imprimer Emploi du Temps</title>');
            printWindow.document.write('<style>');
            printWindow.document.write(`
            @media print {
                .fc-toolbar, .fc-header-toolbar { display: none; }
                .fc-view-container { width: 100%; }
                .fc-event { font-size: 12px; white-space: nowrap; }
                .fc-timegrid-slot { height: auto; }
            }
        `);
            printWindow.document.write('</style>');
            printWindow.document.write('</head><body>');
            printWindow.document.write(calendarElement.innerHTML);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        },

        async soumettre() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            const formdata = new FormData();
            /*  formdata.append('intitule', this.form.intitule); */
            formdata.append('heure_debut', this.form.heure_debut);
            formdata.append('heure_fin', this.form.heure_fin);
            formdata.append('date_debut', this.form.date_debut);
            formdata.append('date_fin', this.form.date_fin);
            formdata.append('id_cour', this.form.id_cours);
            formdata.append('id_salle', this.form.id_salle);
            formdata.append('id_annee_academique', this.form.id_annee_academique);
            /*    formdata.append('id_matiere', this.form.id_matiere);
               formdata.append('id_salle', this.form.id_salle);
               formdata.append('id_semestre', this.form.id_semestre); */
            try {
                const create_store = await axios.post('/emploidutemps/store', formdata, { headers });
                this.closeModal();
                showDialog6("Emploi du temps ajouté avec succès");
                bus.emit('courAjoutee;')
                this.resetForm();
                bus.emit('courAjoutee');
                setTimeout(() => {
                    window.location.href = '/emploi-du-temps';
                }, 1500);

            }
            catch (e) {
                /* console.log(e.request.status) */
                if (e.request.status === 404) {
                    this.closeModal()
                    showDialog3("Ce cours existe déjà");
                }
                else {
                    this.closeModal();
                    showDialog3("Une erreur est survenue lors de l\'enregistrement");
                }
            }
        },

        handleDateClick(clickInfo) {
            //this.$emit('exampleModal1', clickInfo)
            //this.showModal= true;
            $('#exampleModal1').modal('show');
            this.setModalOpen(clickInfo);
        },
        closeModal() {
            $('#exampleModal1').modal('hide');
            this.isModalOpen = false;
            this.resetForm();
        },

        setModalOpen(obj) {
            $('#exampleModal1').modal('show');
            this.isModalOpen = true;
            let dateAndTime = obj.dateStr.split("T");
            this.form.date_debut = dateAndTime[0];
            this.form.date_fin = dateAndTime[0];
            this.form.heure_debut = dateAndTime[1].substr(0, 8);
            this.form.heure_fin = dateAndTime[1].substr(0, 8);
        },

        formatDateTime(dateTime) {
            // Utilisez une fonction pour formater la date
            return this.formatDate(new Date(dateTime));
        },
        formatDate(date) {
            const day = date.getDate();
            const monthNumber = date.getMonth() + 1;
            const year = date.getFullYear();

            // Tableau des trois premières lettres des mois en français
            const monthAbbreviations = [
                "Jan", "Fév", "Mar", "Avr", "Mai", "Juin",
                "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"
            ];

            // Obtenez les trois premières lettres du mois correspondant au numéro du mois
            const month = monthAbbreviations[monthNumber - 1];

            return `${day} ${month} ${year}`;

        },

        verifCaratere(nom) {
            const valeur = /^[a-zA-ZÀ-ÿ\s]*$/;
            return valeur.test(nom);
        },


        validatedata(champ) {
            var i = 0;
            switch (champ) {
                case 'id_cours':
                    this.id_cours_erreur = "";
                    // Effectuez la validation pour le champ 'nom'
                    if (this.form.id_cours === "") {
                        this.id_cours_erreur = "Ce champ est obligatoire"
                        i = 1;
                        return true
                    }

                    break;
                case 'id_salle':
                    this.id_salle_erreur = "";
                    // Effectuez la validation pour le champ 'nom'
                    if (this.form.id_salle === "") {
                        this.id_salle_erreur = "Ce champ est obligatoire"
                        i = 1;
                        return true
                    }

                    break;
                case 'heure_debut':
                    this.heure_debut_erreur = "";
                    // Effectuez la validation pour le champ 'nom'
                    if (this.form.heure_debut === "") {
                        this.heure_debut_erreur = "Ce champ est obligatoire"
                        i = 1;
                        return true
                    }

                    break;
                case 'heure_fin':
                    this.heure_fin_erreur = "";
                    // Effectuez la validation pour le champ 'nom'
                    if (this.form.heure_fin === "") {
                        this.heure_fin_erreur = "Ce champ est obligatoire"
                        i = 1;
                        return true
                    }

                    break;
                case 'date_debut':
                    this.date_debut_erreur = "";
                    // Effectuez la validation pour le champ 'nom'
                    if (this.form.date_debut === "") {
                        this.date_debut_erreur = "Ce champ est obligatoire"
                        i = 1;
                        return true
                    }

                    break;
                case 'date_fin':
                    this.date_fin_erreur = "";
                    // Effectuez la validation pour le champ 'nom'
                    if (this.form.date_fin === "") {
                        this.date_fin_erreur = "Ce champ est obligatoire"
                        i = 1;
                        return true
                    }


                    break;
                case 'annee_academique':
                    this.annee_academique_erreur = "";
                    // Effectuez la validation pour le champ 'nom'
                    if (this.form.id_annee_academique === "") {
                        this.annee_academique_erreur = "Ce champ est obligatoire"
                        i = 1;
                        return true
                    }
                    break;

                default:
                    break;
            }
        },


        validatedataOld() {


            this.id_cours_erreur = "";
            this.id_salle_erreur = "";
            this.heure_debut_erreur = "";
            this.heure_fin_erreur = "";
            this.date_debut_erreur = "";
            this.date_fin_erreur = "";
            this.annee_academique_erreur = "";

            var i = 0;

            if (this.form.id_cours === "") {
                this.id_cours_erreur = "Ce champ est obligatoire"
                i = 1;
            }
            if (this.form.id_salle === "") {
                this.id_salle_erreur = "Ce champ est obligatoire"
                i = 1;
            }
            if (this.form.id_annee_academique === "") {
                this.annee_academique_erreur = "Ce champ est obligatoire"
                i = 1;
            }

            if (this.form.heure_debut === "") {
                this.heure_debut_erreur = "Ce champ est obligatoire"
                i = 1;
            }
            if (this.form.heure_fin === "") {
                this.heure_fin_erreur = "Ce champ est obligatoire"
                i = 1;
            }
            if (this.form.date_debut === "") {
                this.date_debut_erreur = "Ce champ est obligatoire"
                i = 1;
            }
            if (this.form.date_fin === "") {
                this.date_fin_erreur = "Ce champ est obligatoire"
                i = 1;
            }

            if (i == 1) return true;

            return false;
        },

        validerAvantAjout() {
            const isNomDirectionValid = this.validatedataOld();

            console.log(isNomDirectionValid);


            if (isNomDirectionValid === true) {
                this.etatForm = false;
                this.editModal = false;
                return 0;
            } else {

                if (this.editModal === true) {
                    this.etatForm = false;
                    /* this.form.intitule = this.form.intitule.toUpperCase(); */
                    this.update_cour(this.idCour);

                    this.editModal = false;
                }
                else {
                    this.etatForm = true;
                    /*  this.form.intitule = this.form.intitule.toUpperCase(); */
                    this.soumettre();

                    this.editModal = false;
                }
            }

        },

        resetForm() {
            // this.intitule_erreur = "";

            this.id_cours_erreur = "";
            this.id_salle_erreur = "";
            this.heure_debut_erreur = "";
            this.heure_fin_erreur = "";
            this.date_debut_erreur = "";
            this.date_fin_erreur = "";
            this.annee_academique_erreur = "";

            this.form.id_cours = "";
            this.form.id_salle = "";
            this.editModal = false;
            this.form.heure_debut = "";
            this.form.heure_fin = "";
            this.form.date_debut = "";
            this.form.date_fin = "";
            this.form.annee_academique = "";



            const eventData = {
                editModal: false,
            };
            bus.emit('courDejaModifier', eventData);

        },

        async get_classe() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios.get('/classe/all', { headers })
                .then(response => {
                    this.classes = response.data.classe;
                    console.log("this.classes")
                    console.log(this.classes)

                }).catch(error => {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la recupération des derniers classes', 'error')
                });
        },
        async get_annee_academique() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios
                .get("/annee_academique/index", { headers })
                .then((response) => {
                    this.annee_academiques = response.data.annee_academique;
                })
                .catch((error) => {
                    Swal.fire(
                        "Erreur!",
                        "Une erreur est survenue lors de la recuperation des année_academiques",
                        "error"
                    );
                });
        },
        async get_formateur() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios.get('/formateur/index', { headers })
                .then(response => {
                    this.formateurs = response.data.formateur;

                }).catch(error => {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la recupération des derniers formatyeurs', 'error')
                });
        },

        get_matiere() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            axios.get('/matiere/index', { headers })
                .then(response => {
                    this.matieres = response.data.matiere;
                }).catch(error => {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la recuperation des dernieres matieres', 'error')
                });
        },
        get_semestre() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            axios.get('/semestre/index', { headers })
                .then(response => {
                    this.semestres = response.data.semestre;
                }).catch(error => {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la recuperation des dernieres matieres', 'error')
                });
        },

        async get_cours() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios.get('/cour/all', { headers })
                .then(response => {
                    this.cours = response.data.cour
                }).catch(error => {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la recuperation des derniers cours', 'error')
                });
        },
        async get_salle() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios.get('/salle/index', { headers })
                .then(response => {
                    this.salles = response.data.salle
                }).catch(error => {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la recuperation des dernières salles', 'error')
                });
        },

        async update_cour(id) {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            const formdata = new FormData();
            /*  formdata.append('intitule', this.form.intitule); */
            formdata.append('heure_debut', this.form.heure_debut);
            formdata.append('heure_fin', this.form.heure_fin);
            formdata.append('date_cour', this.form.date_cour);
            formdata.append('id_classe', this.form.id_classe);
            formdata.append('id_formateur', this.form.id_formateur);
            formdata.append('id_matiere', this.form.id_matiere);
            /*  formdata.append('id_salle', this.form.id_salle); */
            formdata.append('id_semestre', this.form.id_semestre);
            //if(this.form.nom!==""){
            try {
                await axios.post('/cour/update/' + id, formdata, { headers });
                showDialog6("Cours modifié avec succès");
                bus.emit('courAjoutee');
                const eventData = {
                    editModal: false,
                };
                bus.emit('courDejaModifier', eventData);
            }
            catch (e) {
                /* console.log(e.request.status) */
                if (e.request.status === 404) {
                    Swal.fire('Erreur !', 'Ce Cours existe déjà', 'error')
                }
                else {
                    Swal.fire('Erreur !', 'Une erreur est survenue lors de l\'enregistrement', 'error')
                }
            }
        },
        monterToupdate(direction) {
            this.idDirection = direction.id;
            this.editModal = direction.editModal;
            this.form.nom_direction = direction.direction;
            this.form.id_user = direction.id_user;
        },

        /*  fetchEvents() {
             const token = localStorage.getItem("token");
             const headers = { Authorization: `Bearer ${token}` };
       axios.get('emploidutemps/all', { headers })
         .then(response => {
           this.events = response.data; // Assuming your API returns an array of event objects
           this.initCalendar();
         })
         .catch(error => {
           console.error("There was an error fetching the events!", error);
         });
     }, */


    }
}
</script>
<style>
.event-content {
    padding: 5px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.fc-timegrid-event {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.fc-daygrid-event {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

td[rowspan] {
    vertical-align: middle;
    background-color: #f9f9f9 !important;

}

.event-cell {
    position: relative;
    padding: 4px !important;
}

@media print {

    .fc-toolbar,
    .fc-header-toolbar {
        display: none;
        /* Masquer les éléments de navigation */
    }

    .fc-view-container {
        width: 100%;
        /* Utiliser toute la largeur disponible */
    }

    @media print {
        /* ... autres styles ... */

        .event-cell {
            position: relative;
            padding: 4px !important;
            print-color-adjust: exact;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            table-layout: fixed;
            /* Forcer une largeur fixe */
        }

        th,
        td {
            border: 1px solid black;
            padding: 4px;
            text-align: center;
            overflow: hidden;
            /* Cacher le contenu qui déborde */
            white-space: normal;
            /* Permettre le retour à la ligne */
            word-wrap: break-word;
            /* Couper les mots longs */
        }

        /* Définir des largeurs fixes pour chaque colonne */
        th:first-child,
        td:first-child {
            width: 8%;
            /* Colonne des horaires */
        }

        th:not(:first-child),
        td:not(:first-child) {
            width: 15.33%;
            /* Les autres colonnes (100% - 8%) / 6 */
        }

        .event-cell.course-1 {
            background-color: #E3F2FD !important;
        }

        /* Bleu très clair */
        .event-cell.course-2 {
            background-color: #F3E5F5 !important;
        }

        /* Violet très clair */
        .event-cell.course-3 {
            background-color: #E8F5E9 !important;
        }

        /* Vert très clair */
        .event-cell.course-4 {
            background-color: #FFF3E0 !important;
        }

        /* Orange très clair */
        .event-cell.course-5 {
            background-color: #FCE4EC !important;
        }

        /* Rose très clair */
        .event-cell.course-6 {
            background-color: #E0F7FA !important;
        }

        /* Cyan très clair */
        .event-cell.course-7 {
            background-color: #FFF8E1 !important;
        }

        /* Jaune très clair */
        .event-cell.course-8 {
            background-color: #F1F8E9 !important;
        }

        /* Vert lime très clair */
        .event-cell.course-9 {
            background-color: #E8EAF6 !important;
        }

        /* Indigo très clair */
        .event-cell.course-10 {
            background-color: #EFEBE9 !important;
        }

        /* Marron très clair */

        /* ... autres styles ... */
    }

    .fc-event {
        font-size: 12px;
        /* Ajuster la taille de la police des événements */
        white-space: nowrap;
        /* Empêcher le texte de se couper */
    }

    .fc-timegrid-slot {
        height: auto;
        /* Ajuster la hauteur des créneaux horaires */
    }
}
</style>