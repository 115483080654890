<template>
    <div class="page-wrapper">
        <div class="page-header navbar navbar-fixed-top">
            <div class="page-header-inner">
                <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
            </div>
        </div>
        <div class="page-container">
            <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
                :userRole="userRole" />

            <!-- start page content -->
            <div class="page-content-wrapper">
                <!-- start page content -->
                <div class="page-content" v-if="!this.editModal">
                    <div class="page-bar">
                        <div class="page-title-breadcrumb">
                            <ol class="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i class="fa fa-home"></i>&nbsp;<a class="parent-item"
                                        :href="'/dashboard'">Accueil</a>&nbsp;<i class="fa fa-angle-right"></i>
                                </li>
                                <li class="active">
                                    Paramétres &nbsp;<i class="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <a class="parent-item" :href="'/liste-partenaire'"> Partenaire</a>&nbsp;<i
                                        class="fa fa-angle-right"></i>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tabbable-line">
                                <ul class="nav customtab nav-tabs" role="tablist">
                                    <li class="nav-item">
                                        <a href="#tab1" class="nav-link active" data-bs-toggle="tab">Partenaire</a>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane active fontawesome-demo" id="tab1">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="card card-box">
                                                    <div class="card-head">
                                                        <header>Tous les partenaires</header>
                                                        <div class="tools">
                                                            <a class="fa fa-repeat btn-color box-refresh"
                                                                href="javascript:;"></a>
                                                            <a class="t-collapse btn-color fa fa-chevron-down"
                                                                href="javascript:;"></a>
                                                            <a class="t-close btn-color fa fa-times"
                                                                href="javascript:;"></a>
                                                        </div>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-md-6 col-sm-6 col-6">
                                                                <div class="btn-group">
                                                                    <a :href="'/create-partenaire'" id="addRow"
                                                                        class="btn btn-primary">
                                                                        Ajouter
                                                                        <i class="fa fa-plus text-white"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!--  -->
                                                        <div class="row align-items-end">
                                                            <div class="col-sm-12 col-md-4">
                                                                <div class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                                                    id="example47_length">
                                                                    <label class="me-2">Afficher</label>
                                                                    <select name="example47_length"
                                                                        aria-controls="example47" class="form-select-sm"
                                                                        @change="updatePerPage" v-model="limitQuery">
                                                                        <option value="5">5</option>
                                                                        <option value="10">10</option>
                                                                        <option value="15">15</option>
                                                                    </select>
                                                                    <span class="ms-2">entrées</span>
                                                                </div>
                                                            </div>

                                                            <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                                                <div style="float: right">
                                                                    <label for="searchInput" class="me-2">Recherche
                                                                        :</label>
                                                                    <input type="text" id="searchInput" placeholder=""
                                                                        aria-controls="example47" v-model="searchQuery"
                                                                        @input="get_partenaire(currentPage)" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!--  -->
                                                        <table
                                                            class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                                                            id="example47">
                                                            <!--  description contact adresse email boite_postale date_debut date_fin id_direction type exoneration id_user -->
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Nom</th>
                                                                    <th>Contact</th>
                                                                    <th>Adresse</th>
                                                                    <th>Email</th>
                                                                    <th>Direction</th>
                                                                    <th>Exoneration</th>

                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr class="odd gradeX"
                                                                    v-for="(partenaire, index) in partenaires"
                                                                    :key="index">
                                                                    <td>{{ index + 1 }}</td>
                                                                    <td>{{ partenaire.nom_partenaire }}</td>
                                                                    <td>{{ partenaire.contact }}</td>
                                                                    <td>{{ partenaire.adresse }}</td>
                                                                    <td>{{ partenaire.email }}</td>
                                                                    <td>{{ partenaire.direction }}</td>
                                                                    <td> {{ partenaire.exoneration ? 'Exonération activée' : 'Pas d\'exonération' }}</td>

                                                                    <td>
                                                                        <a class="tblEditBtn"
                                                                            @click="openModal(partenaire)">
                                                                            <i class="fa fa-pencil"></i>
                                                                        </a>
                                                                        <a class="tblDelBtn"
                                                                            @click="deletePartenaire(partenaire)">
                                                                            <i class="fa fa-trash-o"></i>
                                                                        </a>

                                                                        <button @click="monterDetail(partenaire)" type="button" class="tblDetailBtn border-0 " data-bs-toggle="modal" data-bs-target="#exampleModal1">
                                                                            <i class="fa fa-ellipsis-h"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div class="row" v-if="this.partenaires.length > 0">
                                                            <div class="col-sm-12 col-md-5">
                                                                <div class="dataTables_info" id="datable_1_info"
                                                                    role="status" aria-live="polite">
                                                                    Affichage de {{ from_begin }} à
                                                                    {{ to_begin }} sur {{ total }} entrées
                                                                </div>
                                                            </div>

                                                            <div class="col-sm-12 col-md-7 text-right">
                                                                <div class="dataTables_paginate paging_simple_numbers"
                                                                    id="datable_1_paginate">
                                                                    <paginate-component :total-pages="total_page"
                                                                        :total="total" :per-page="per_page"
                                                                        :current-page="current_page"
                                                                        @pagechanged="pagPage"></paginate-component>
                                                                </div>
                                                            </div>
                                                        </div>

<!-- fenetre modal -->
<div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-hidden="true">
						<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
							<div class="modal-content">
								<div class="modal-header">
									<h5 class="modal-title" id="addEventTitle">Détail Partenaire</h5>
									<!-- <h5 class="modal-title" id="editEventTitle">Partenaire</h5> -->
									<button type="button" class="btn-close" data-bs-dismiss="modal"
										aria-label="Close"></button>
								</div>
								<div class="modal-body">
									<form class="">
										<input type="hidden" id="id" name="id">
										<div class="row">
											<div class="col-md-12">
												<div class="form-group">
													<label>Nom Partenaire</label>
                                                  <!--   <div class="panel">
                                                        <div class="panel-body">col-lg-12</div>
                                                    </div> -->
													<div class="input-group">
														<input type="text" class="form-control" placeholder="Title"
															name="title" id="title" v-model="form.nom_partenaire" disabled>
													</div>
												</div>
											</div>
										</div>
                                        <div class="row">
											<div class="col-md-12">
												<div class="form-group">
													<label>Boite Postal</label>
													<input type="text" class="form-control datetimepicker"
														placeholder="Start Date" name="starts_at" id="starts-at" v-model="form.boite_postale" disabled>
												</div>
											</div>
										</div>
										
										<div class="row">
											<div class="col-6">
												<div class="form-group">
													<label>Type Partenaire</label>
													<input type="text" class="form-control datetimepicker"
														placeholder="Start Date" name="starts_at" id="starts-at" v-model="form.type" disabled>
												</div>
											</div>
											<div class="col-6">

												<div class="form-group">
													<label>Exoneration</label>
													<input type="text" class="form-control datetimepicker"
														placeholder="End Date" name="ends_at" id="ends-at" v-model="form.exoneration" disabled>
												</div>
											</div>
										</div>
										
										<div class="row">
											<div class="col-6">
												<div class="form-group">
													<label>Adresse</label>
													<input type="text" class="form-control datetimepicker"
														placeholder="Start Date" name="starts_at" id="starts-at" v-model="form.adresse" disabled>
												</div>
											</div>
											<div class="col-6">

												<div class="form-group">
													<label>Direction</label>
													<input type="text" class="form-control datetimepicker"
														placeholder="End Date" name="ends_at" id="ends-at" v-model="form.direction" disabled>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-6">
												<div class="form-group">
													<label>Telephone</label>
													<input type="text" class="form-control datetimepicker"
														placeholder="Start Date" name="starts_at" id="starts-at" v-model="form.contact" disabled>
												</div>
											</div>
											<div class="col-6">

												<div class="form-group">
													<label>Email</label>
													<input type="text" class="form-control datetimepicker"
														placeholder="End Date" name="ends_at" id="ends-at" v-model="form.email" disabled>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-6">
												<div class="form-group">
													<label>Date début</label>
													<input type="text" class="form-control datetimepicker"
														placeholder="Start Date" name="starts_at" id="starts-at" v-model="form.date_debut" disabled>
												</div>
											</div>
											<div class="col-6">

												<div class="form-group">
													<label>Date Fin</label>
													<input type="text" class="form-control datetimepicker"
														placeholder="End Date" name="ends_at" id="ends-at" v-model="form.date_fin" disabled>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-12">
												<div class="form-group">
													<label>Description</label>
													<textarea id="eventDetails" name="eventDetails"
														placeholder="Enter Details" class="form-control" v-model="form.description" disabled></textarea>
												</div>
											</div>
										</div>
										<div class="modal-footer bg-whitesmoke pr-0">
										<!-- 	<button type="button" class="btn btn-round btn-primary" id="add-event">Add
												Event</button>
											<button type="button" class="btn btn-round btn-primary" id="edit-event">Edit
												Event</button> -->
											<button type="button" id="close" class="btn btn-danger"
												data-bs-dismiss="modal">Fermer</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
<!-- End fenetre modal -->

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-content-wrapper" v-show="editModal">
                <div class="page-content">
                    <div class="page-bar">
                        <div class="page-title-breadcrumb">
                            <div class="pull-left">
                                <div class="page-title">Nouveau Partenaire</div>
                            </div>
                            <ol class="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard'">Tableau
                                        de Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                                </li>
                                <li class="active">Paramétres</li>
                                <li>
                                    <a class="parent-item" :href="'/liste-partenaire'">Partenaire</a>&nbsp;<i
                                        class="fa fa-angle-right"></i>
                                </li>
                                <li class="active">Modifier Partenaire</li>
                            </ol>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card-box">
                                <div class="card-head">
                                    <header>Information</header>
                                    <button id="panel-button"
                                        class="mdl-button mdl-js-button mdl-button--icon pull-right"
                                        data-upgraded=",MaterialButton">
                                        <i class="material-icons">more_vert</i>
                                    </button>
                                    <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                                        data-mdl-for="panel-button">
                                        <li class="mdl-menu__item">
                                            <i class="material-icons">assistant_photo</i>Action
                                        </li>
                                        <li class="mdl-menu__item">
                                            <i class="material-icons">print</i>Another action
                                        </li>
                                        <li class="mdl-menu__item">
                                            <i class="material-icons">favorite</i>Something else here
                                        </li>
                                    </ul>
                                </div>
                                <div class="card-body row">
                                    <FormulaireModification />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <appafterContent />
        </div>
        <appfooter />
    </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
//import datatable from "datatables.net-bs5";
import FormulaireModification from "./editPartenaire.vue";
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";
import { mapState, mapActions } from "vuex";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

export default {
    name: "listePartenaireComponent",
    components: {
        FormulaireModification,
        appnavbar,
        appfooter,
        appheader,
        appafterContent,
        PaginateComponent,
    },
    mixins: [permissionMixin],
    data() {
        return {
            form: new Form({
                nom_partenaire: "",
                description: "",
                contact: "",
                adresse: "",
                email: "",
                boite_postale: "",
                date_debut: "",
                date_fin: "",
                type: "",
                exoneration: "",
                direction: "",
          
            }),

            etatForm: false,
            editModal: false,
            partenaires: [],
            users: [],
            idPartenaire: "",
            limitQuery: 5,
            total_page: 0,
            total: 0,
            per_page: 0,
            current_page: 1,
            from_begin: 0,
            to_begin: 0,
            is_loading: false,
            searchQuery: "",
            currentPage: 1,
            lastPage: 1,
        };
    },
    computed: {
        //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
        token() {
            return this.$store.state.isLoggedIn;
        },
        userNom() {
            return this.$store.state.userNom;
        },
        userPrenom() {
            return this.$store.state.userPrenom;
        },
        userIdrole() {
            return this.$store.state.userIdrole;
        },
        userPhoto() {
            return this.$store.state.userPhoto;
        },
        userRole() {
            return this.$store.state.userRole;
        },
    },
    async created() {
        await this.get_permission();
        console.log("this.permissions");       
        this.get_partenaire();

        bus.on("partenaireAjoutee", () => {
            this.get_partenaire();
        });
        bus.on("partenaireDejaModifier", (eventData) => {
            this.editModal = eventData.editModal;
            this.get_partenaire();
        });
    },
    /*     mounted() {
          this.get_partenaire();
          
          bus.on('partenaireAjoutee', () => { // Écouter l'événement de nouvelle utilisateur ajoutée
              this.get_partenaire(); // Mettre à jour la liste des utilisateurs
          });
          bus.on('partenaireDejaModifier', (eventData) => {
              this.editModal = eventData.editModal;
              this.get_partenaire();
          });
      },
   */
    methods: {
        ...mapActions(["login", "logout"]),

        pagPage(pagination) {
            this.current_page = pagination;
            this.get_partenaire(this.currentPage);
        },
        updatePerPage(event) {
            // Récupérer la nouvelle valeur sélectionnée
            const newPerPage = parseInt(event.target.value);

            // Appeler la méthode pour recharger les données avec la nouvelle quantité par page
            this.reloadDataWithPerPage(newPerPage);
        },
        reloadDataWithPerPage(newPerPage) {
            // Mettre à jour la limite de la requête avec la nouvelle quantité par page
            if (this.limitQuery !== 5) {
                this.limitQuery = newPerPage;
                // Recharger les données avec la nouvelle quantité par page
                this.get_partenaire(this.currentPage);
            }
        },

        async get_partenaire() {
            const token = localStorage.getItem("token");
            const filterCondition = (partenaire) => true;
            const headers = { Authorization: `Bearer ${token}` };
            const params = {
                per_page: this.limitQuery,
                page: this.current_page,
                // Ajoutez d'autres paramètres si nécessaire
            };
            if (this.hasReadPermission("Partenaire")) {
                try {
                    const response = await axios.get("/partenaire/all/paginate", {
                        headers: headers,
                        params: params, // Ajouter les paramètres ici
                    });
                    const allpartenaire = response.data.partenaire.data;

                    const filteredpartenaires = allpartenaire.filter((partenaire) => {
                        return (
                            filterCondition(partenaire) &&
                            Object.keys(partenaire)
                                .reduce((acc, key) => {
                                    const value = partenaire[key];
                                    if (typeof value === "string") {
                                        acc.push(value.toLowerCase());
                                    } else if (value && typeof value === "object") {
                                        Object.values(value).forEach((nestedValue) => {
                                            if (typeof nestedValue === "string") {
                                                acc.push(nestedValue.toLowerCase());
                                            }
                                        });
                                    }
                                    return acc;
                                }, [])
                                .some((value) => value.includes(this.searchQuery.toLowerCase()))
                        );
                    });

                    const formattedpartenaire = filteredpartenaires.map((partenaire) => {
                        return {
                            id: partenaire.id,
                            nom_partenaire: partenaire.nom_partenaire,
                            description: partenaire.description,
                            contact: partenaire.contact,
                            adresse: partenaire.adresse,
                            email: partenaire.email,
                            boite_postale: partenaire.boite_postale,
                            date_debut: partenaire.date_debut,
                            date_fin: partenaire.date_fin,
                            id_direction: partenaire.id_direction,
                            direction: partenaire.direction.nom_direction,
                            type: partenaire.type,
                            exoneration: partenaire.exoneration,

                            editModal: true,
                            /*   date: partenaire.created_at */
                        };
                    });

                    const page = {
                        total_page: response.data.partenaire.last_page,
                        total: response.data.partenaire.total,
                        per_page: response.data.partenaire.per_page,
                        current_page: response.data.partenaire.current_page,
                        from_begin: response.data.partenaire.from,
                        to_begin: response.data.partenaire.to,
                    };

                    this.partenaires = formattedpartenaire;
                    this.total_page = page.total_page;
                    this.total = page.total;
                    this.per_page = page.per_page;
                    this.current_page = page.current_page;
                    this.from_begin = page.from_begin;
                    this.to_begin = page.to_begin;

                } catch (error) {
                    Swal.fire(
                        "Erreur!",
                        "Une erreur est survenue lors de la récupération des partenaires",
                        "error"
                    );
                }
            } else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            }
        },

        resetForm() {
            this.form.nom_partenaire = "";
            this.form.description = "";
            this.form.contact = "";
            this.form.adresse = "";
            this.form.email = "";
            this.form.boite_postale = "";
            this.form.date_debut = "";
            this.form.date_fin = "";
            this.form.exoneration = "";
            this.form.id_direction = "";
            this.form.type = "";
        },

        async deletePartenaire(partenaire) {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            if (this.hasDeletePermission("Partenaire")) {
                Swal.fire({
                    title: "Voulez-vous confirmer la suppression?",
                    text: "Cette action sera irréversible!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Oui",
                    cancelButtonText: "Non",
                }).then((result) => {
                    if (result.value) {
                        axios
                            .delete(`/partenaire/delete/${partenaire.id}`, { headers })
                            .then((resp) => {
                                showDialog6("Partenaire supprimée avec succés");
                                this.get_partenaire();
                            })
                            .catch(function (error) {
                                console.log(error);
                                showDialog3("Erreur lors de la suppression du partenaire");
                            });
                    }
                });
            } else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            }
        },
        openModal(partenaire) {
            this.editModal = true;
            // Créez un objet avec les données à envoyer
            const eventData = {
                partenaire: partenaire,
                editModal: this.editModal,
                // Ajoutez d'autres propriétés si nécessaire
            };
            bus.emit("partenaireModifier", eventData);
            console.log("EventData envoyé:", eventData); // Pour vérifier les données envoyées via bus
        },

        monterDetail(partenaire) {

            this.form.id = partenaire.id;
            this.form.nom_partenaire = partenaire.nom_partenaire;
            this.form.description = partenaire.description;
            this.form.contact = partenaire.contact;
            this.form.adresse = partenaire.adresse;
            this.form.email = partenaire.email;
            this.form.boite_postale = partenaire.boite_postale;
            this.form.date_debut = partenaire.date_debut;
            this.form.date_fin = partenaire.date_fin;
            this.form.direction = partenaire.direction;
            this.form.type = partenaire.type;
            this.form.exoneration = partenaire.exoneration ? 'Exonération activée' : 'Pas d\'exonération';
           

        },
    },
};
</script>