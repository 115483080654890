<template>
    <div class="page-wrapper">
      <!-- start header -->
      <div class="page-header navbar navbar-fixed-top">
        <div class="page-header-inner">
          <appheader
            :userPhoto="userPhoto"
            :userNom="userNom"
            :userPrenom="userPrenom"
          />
        </div>
      </div>
      <div class="page-container">
        <appnavbar
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
          :userIdrole="userIdrole"
          :userRole="userRole"
        />
        <!-- start page content -->
        <div class="page-content-wrapper">
          <div class="page-content">
            <div class="page-bar">
              <div class="page-title-breadcrumb">
                <div class="pull-left">
                  <div class="page-title">{{ isEditing ? 'Modifier un Secteur d\'activité' : 'Créer un Secteur d\'activité' }}</div>
                </div>
                <ol class="breadcrumb page-breadcrumb pull-right">
                  <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="/">Accueil</a>&nbsp;<i class="fa fa-angle-right"></i></li>
                  <li class="active">{{ isEditing ? 'Modifier' : 'Créer' }}</li>
                </ol>
              </div>
            </div>
  
            <div class="card">
              <div class="card-header">
                <h4>Informations du Secteur d'activité</h4>
              </div>
              <div class="card-body">
                <form class="form-horizontal" @submit.prevent="submitForm">
                  <div class="row">
                    <div class="col-lg-12 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label">Nom *</label>
                        <input 
                          class="mdl-textfield__input" 
                          type="text" 
                          v-model="formData.nom" 
                          :class="{ 'is-invalid': errors.nom }"
                        />
                        <span class="error-message" v-if="errors.nom">{{ errors.nom }}</span>
                      </div>
                    </div>
                    <div class="col-lg-12 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label">Description</label>
                        <textarea 
                          class="mdl-textfield__input" 
                          v-model="formData.description"
                          :class="{ 'is-invalid': errors.description }"
                        ></textarea>
                        <span class="error-message" v-if="errors.description">{{ errors.description }}</span>
                      </div>
                    </div>
                    <div class="col-lg-12 p-t-20 text-center">
                      <button
                        type="submit"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                        :disabled="isLoading"
                      >
                        {{ isLoading ? "Veuillez patienter..." : (isEditing ? "Modifier" : "Ajouter") }}
                        <div v-show="isLoading" class="mdl-spinner mdl-js-spinner is-active"></div>
                      </button>
                      <button
                        type="button"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                        @click="resetForm"
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
  
            <!-- start list content -->
            <div class="card mt-4">
              <div class="card-header">
                <h4>Liste des Secteurs d'activité</h4>
              </div>
              <div class="card-body">
                <div v-if="secteurs.length === 0">Aucun secteur d'activité trouvé.</div>
                <table class="table table-striped" v-else>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nom</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="secteur in secteurs" :key="secteur.id">
                      <td>{{ secteur.id }}</td>
                      <td>{{ secteur.nom }}</td>
                      <td>{{ secteur.description }}</td>
                      <td>
                        <a class="tblEditBtn" @click="editSecteur(secteur)">
                          <i class="fa fa-pencil" title="Modifier"></i>
                        </a>
                        <a class="tblDelBtn" @click="confirmDelete(secteur.id)">
                          <i class="fa fa-trash-o" title="Supprimer" style="color: red;"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import appheader from "../layout/header.vue";
  import appnavbar from "../layout/navbar.vue";
  import axios from "@/axios";
  import Swal from 'sweetalert2';
  
  export default {
    name: "SecteurActiviteComponent",
    components: {
      appheader,
      appnavbar,
    },
    data() {
      return {
        formData: {
          nom: '',
          description: ''
        },
        errors: {
          nom: '',
          description: ''
        },
        isLoading: false,
        secteurs: [],
        isEditing: false,
        currentSecteurId: null,
      };
    },
    computed: {
      token() {
        return localStorage.getItem("token");
      },
      userNom() {
        return this.$store.state.userNom;
      },
      userPrenom() {
        return this.$store.state.userPrenom;
      },
      userIdrole() {
        return this.$store.state.userIdrole;
      },
      userPhoto() {
        return this.$store.state.userPhoto;
      },
      userRole() {
        return this.$store.state.userRole;
      },
    },
    mounted() {
      this.fetchSecteurs();
    },
    methods: {
      validateForm() {
        let isValid = true;
        this.errors = {
          nom: '',
          description: ''
        };
  
        if (!this.formData.nom) {
          this.errors.nom = 'Le nom est requis';
          isValid = false;
        }
  
        return isValid;
      },
  
      async submitForm() {
      if (!this.validateForm()) return;

      this.isLoading = true;

      try {
        const url = this.isEditing 
          ? `/secteurs-activite/${this.currentSecteurId}`  // Notez le changement ici
          : '/secteurs-activite';  // Et ici

        const response = await axios({
          method: this.isEditing ? 'put' : 'post',
          url: url,
          data: this.formData,
          headers: { Authorization: `Bearer ${this.token}` }
        });
  
          if (response.status === 201 || response.status === 200) {
            Swal.fire('Succès!', 
              this.isEditing ? 'Secteur modifié avec succès' : 'Secteur ajouté avec succès', 
              'success'
            );
            this.resetForm();
            this.fetchSecteurs();
          }
        } catch (error) {
          console.error('Erreur:', error);
          Swal.fire('Erreur!', 'Une erreur est survenue', 'error');
        } finally {
          this.isLoading = false;
        }
      },
  
      resetForm() {
        this.formData = {
          nom: '',
          description: ''
        };
        this.errors = {
          nom: '',
          description: ''
        };
        this.isEditing = false;
        this.currentSecteurId = null;
      },
  
    
    async fetchSecteurs() {
    try {
        const response = await axios.get('/secteurs-activite', {
            headers: { Authorization: `Bearer ${this.token}` }
        });
        ''
        this.secteurs = response.data.secteurs; // Modifiez cette ligne
    } catch (error) {
        console.error('Erreur lors de la récupération des secteurs:', error);
    }
},
  
      editSecteur(secteur) {
        this.formData = {
          nom: secteur.nom,
          description: secteur.description
        };
        this.currentSecteurId = secteur.id;
        this.isEditing = true;
      },
  
      async confirmDelete(id) {
        const result = await Swal.fire({
          title: 'Êtes-vous sûr?',
          text: "Cette action est irréversible!",
          icon: 'warning',
          showCancelButton: true,
          
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, supprimer!',
          cancelButtonText: 'Annuler'
        });
  
        if (result.isConfirmed) {
          await this.deleteSecteur(id);
        }
      },
  
      async deleteSecteur(id) {
  try {
    await axios.delete(`/secteurs-activite/${id}`, {  // Notez le pluriel ici aussi
      headers: { Authorization: `Bearer ${this.token}` }
    });
    Swal.fire('Supprimé!', 'Le secteur a été supprimé.', 'success');
    this.fetchSecteurs();
  } catch (error) {
    console.error('Erreur lors de la suppression:', error);
    Swal.fire('Erreur!', 'Une erreur est survenue lors de la suppression.', 'error');
  }
}
    }
  };
  </script>
  
  <style scoped>
  .error-message {
    color: #dc3545;
    font-size: 12px;
    margin-top: 4px;
  }
  
  .is-invalid {
    border-color: #dc3545 !important;
  }
  
  .mdl-textfield {
    width: 100%;
  }
  
  .mdl-textfield__input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  textarea.mdl-textfield__input {
    min-height: 100px;
    resize: vertical;
  }
  
  .tblEditBtn, .tblDelBtn {
    cursor: pointer;
    margin: 0 5px;
  }
  </style>