<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Liste des Demandes</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.html">Home</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li class="active">Liste des Demandes</li>
              </ol>
            </div>
          </div>
          <div v-if="userRole === 'Logisticien'" class="department-filters">
  <button 
    class="filter-btn" 
    :class="{ active: selectedDepartment === 'all' }"
    @click="selectedDepartment = 'all'"
  >
    Toutes les demandes
  </button>
  <button 
    v-for="dept in uniqueDepartments" 
    :key="dept.id"
    class="filter-btn"
    :class="{ active: selectedDepartment === dept.id }"
    @click="selectedDepartment = dept.id"
  >
    {{ dept.nom_departement }}
  </button>
</div>
          <div class="row">
            <div class="col-md-12">
              <div class="card card-box">
                <div class="card-head">
                  <header>Liste des demandes</header>
                  <div class="tools">
                    <input
                      type="text"
                      placeholder="Recherche..."
                      v-model="searchQuery"
                      @input="fetchDemandes"
                    />
                    <select v-model="perPage" @change="fetchDemandes">
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </select>
                  </div>
                </div>
                <div class="card-body">
                  <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Demande N°</th>
                        <th>Date de Soumission</th>
                        <th>Départment</th>
                        <th>Urgence</th>  
                        <th>Checking Status</th>
                        <th>Statut</th>
                        <th>Détails</th>
                      </tr> 
                    </thead>
                    <tbody>
  <tr v-for="demande in filteredDemandes" :key="demande.id">
    <td>{{ demande.id }}</td>
    <td>{{ formatDate(demande.created_at) }}</td>
    <td>{{ demande.user.departement.nom_departement }}</td>
    <td>
        <span :class="getUrgenceClass(demande.urgence)">
          {{ formatUrgence(demande.urgence) }}
        </span>
      </td>
   
    <td>
      <div class="checking-status-container">
        <span v-if="demande.checking_status" :class="'checking-status ' + demande.checking_status">
          {{ formatCheckingStatus(demande.checking_status) }}
        </span>
        <i v-if="demande.checking_status === 'partiellement_disponible'" 
           @click="showStockDetails(demande.id)" 
           class="fa fa-box text-warning cursor-pointer ml-2" 
           style="font-size: 16px;"
           title="Voir les détails de disponibilité du stock"
        ></i>
      </div>
    </td>

    <td>
      <div class="status-container">
          <select 
            v-model="demande.statut" 
            @change="changeStatut(demande.id, demande.statut, demande.observations)" 
            :class="getStatutClass(demande.statut)"
          >
            <option value="en_attente">En attente</option>
            <option value="validé">Validé</option>
            <option value="en_cours">En cours</option>
            <option value="reçu">Reçu</option>
            <option value="rejete">Rejeté</option>
            <option value="traitée">Traitée</option>
          </select>
          <textarea
            v-if="userRole === 'Logisticien'"
            v-model="demande.observations"
            @change="changeStatut(demande.id, demande.statut, demande.observations)"
            placeholder="Ajouter une observation..."
            class="observation-input"
          ></textarea>
        </div>
    </td>
    <td>

      <i @click="showDetails(demande.id)" class="fa fa-info-circle text-info cursor-pointer" style="font-size: 20px;"></i>
    </td>
  </tr>
</tbody>
                  </table>
                  <paginate-component
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                    @pagechanged="fetchDemandes"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <appfooter />
    </div>

    <!-- Modal pour les détails -->
    <div v-if="isModalVisible" class="modal-overlay">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Détails de la Demande N°{{ selectedDemande.id }}</h2>
          <div class="modal-actions">
            <i @click="printDetails" class="fa fa-print action-icon" title="Imprimer"></i>
          <i @click="downloadDetails" class="fa fa-download action-icon" title="Télécharger"></i>
          </div>
          <button @click="closeModal" class="close-button">&times;</button>
        </div>
        <div class="modal-body">
          <div class="modal-section">
            <div class="modal-column left-column">
              <h3>Informations de la Demande</h3>
              <p><strong>Type de demande:</strong> {{ formatTypeDemande(selectedDemande.type_demande) }}</p>
              <p><strong>Statut:</strong> <span :class="getStatutClass(selectedDemande.statut)">{{ formatStatut(selectedDemande.statut) }}</span></p>
              <p><strong>Date de soumission:</strong> {{ formattedDate }}</p>
              <p><strong>Chef de département:</strong> {{ selectedDemande.user.prenom }} {{ selectedDemande.user.nom }}</p>
              <p><strong>Département:</strong> {{ selectedDemande.user.departement.nom_departement }}</p>
             
              <div class="observations-section" v-if="selectedDemande.observations">
      <h3>Observations</h3>
      <div class="observation-box">
        {{ selectedDemande.observations }}
      </div>
    </div>
            </div>
            <div class="modal-column right-column">
              <h3>Détails des Matériels et Consommables</h3>
              <div v-if="selectedDemande.demande_materiels.length > 0">
                <strong>Matériels:</strong>
                <ul>
                  <li v-for="materiel in selectedDemande.demande_materiels" :key="materiel.id">
                    <i class="fa fa-cube"></i> {{ materiel.libelle }} - {{ materiel.quantite }} - {{ materiel.description }}
                  </li>
                </ul>
              </div>
              <div v-if="selectedDemande.demande_consommables.length > 0">
                <strong>Consommables:</strong>
                <ul>
                  <li v-for="consommable in selectedDemande.demande_consommables" :key="consommable.id">
                    <i class="fa fa-flask"></i> {{ consommable.libelle }} - {{ consommable.quantite }} - {{ consommable.description }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal pour les détails de disponibilité -->
    <div v-if="isStockModalVisible" class="modal-overlay">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Détails de Disponibilité - Demande N°{{ selectedStockDemande?.id }}</h2>
          <button @click="closeStockModal" class="close-button">&times;</button>
        </div>
        <div class="modal-body">
          <div class="stock-details">
            <div class="stock-section available">
              <h3>En Stock</h3>
              <div v-if="materielsEnStock.length > 0">
                <h4>Matériels</h4>
                <ul>
                  <li v-for="mat in materielsEnStock" :key="mat.id">
                    {{ mat.libelle }} - {{ mat.quantite }} unité(s)
                  </li>
                </ul>
              </div>
              <div v-if="consommablesEnStock.length > 0">
                <h4>Consommables</h4>
                <ul>
                  <li v-for="cons in consommablesEnStock" :key="cons.id">
                    {{ cons.libelle }} - {{ cons.quantite }} unité(s)
                  </li>
                </ul>
              </div>
            </div>
            <div class="stock-section to-order">
              <h3>À Commander</h3>
              <div v-if="materielsACommander.length > 0">
                <h4>Matériels</h4>
                <ul>
                  <li v-for="mat in materielsACommander" :key="mat.id">
                    {{ mat.libelle }} - {{ mat.quantite }} unité(s)
                  </li>
                </ul>
              </div>
              <div v-if="consommablesACommander.length > 0">
                <h4>Consommables</h4>
                <ul>
                  <li v-for="cons in consommablesACommander" :key="cons.id">
                    {{ cons.libelle }} - {{ cons.quantite }} unité(s)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "moment/locale/fr";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";

export default {
  components: {
    appheader,
    appfooter,
    appnavbar,
    PaginateComponent,
  },
  data() {
    return {
      isStockModalVisible: false,
      selectedStockDemande: null,

      demandes: [],
      is_loading: true,
      errorMessage: "",
      searchQuery: "",
      perPage: 15,
      currentPage: 1,
      totalPages: 1,
      isModalVisible: false,
      selectedDemande: null,
      formattedDate: "",
       departments: [],
    selectedDepartment: 'all',
    };
  },
  computed: {
    materielsEnStock() {
      if (!this.selectedStockDemande?.demande_materiels) return [];
      return this.selectedStockDemande.demande_materiels.filter(mat => !mat.a_commander);
    },
    materielsACommander() {
      if (!this.selectedStockDemande?.demande_materiels) return [];
      return this.selectedStockDemande.demande_materiels.filter(mat => mat.a_commander);
    },
    consommablesEnStock() {
      if (!this.selectedStockDemande?.demande_consommables) return [];
      return this.selectedStockDemande.demande_consommables.filter(cons => !cons.a_commander);
    },
    consommablesACommander() {
      if (!this.selectedStockDemande?.demande_consommables) return [];
      return this.selectedStockDemande.demande_consommables.filter(cons => cons.a_commander);
    },
    token() {
      return localStorage.getItem("token");
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
    filteredDemandes() {
    if (this.selectedDepartment === 'all') {
      return this.demandes;
    }
    return this.demandes.filter(demande => 
      demande.user.departement.id === this.selectedDepartment
    );
  },


  uniqueDepartments() {
    const departments = new Set();
    this.demandes.forEach(demande => {
      if (demande.user.departement) {
        departments.add(JSON.stringify({
          id: demande.user.departement.id,
          nom_departement: demande.user.departement.nom_departement
        }));
      }
    });
    return Array.from(departments).map(dept => JSON.parse(dept));
  },
  },
  created() {
    this.fetchDemandes();
    
  },
  methods: {
    async showStockDetails(demandeId) {
      try {
        const response = await axios.get(`http://localhost:8000/api/demandes/${demandeId}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        
        this.selectedStockDemande = response.data.demande;

        this.isStockModalVisible = true;
      } catch (error) {
        console.error('Erreur lors de la récupération des détails du stock:', error);
        Swal.fire('Erreur!', 'Impossible de récupérer les détails du stock', 'error');
      }
    },

    closeStockModal() {
      this.isStockModalVisible = false;
      this.selectedStockDemande = null;
      this.stockDetails = {
        materiels: [],
        consommables: []
      };
    },

    formatDate(date) {
      return moment(date).locale('fr').format('DD/MM/YYYY');
    },
    async fetchDemandes(page = 1) {
      this.is_loading = true;
      const headers = { Authorization: `Bearer ${this.token}` };
      const params = {
        search: this.searchQuery,
        per_page: this.perPage,
        page: page
      };

      // N'ajouter department_id que s'il est différent de 'all'
      if (this.selectedDepartment !== 'all') {
        params.department_id = this.selectedDepartment;
      }

      try {
        const response = await axios.get("http://localhost:8000/api/demandes", { 
          headers, 
          params 
        });
        if (response.data.statut === 200) {
          this.demandes = response.data.demandes.data;
          this.totalPages = response.data.demandes.last_page;
          this.currentPage = response.data.demandes.current_page;
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des demandes:", error);
        this.errorMessage = "Une erreur est survenue lors de la récupération des demandes.";
      } finally {
        this.is_loading = false;
      }
    },
    
  
  

    getStatutClass(statut) {
      switch (statut) {
        case 'en_attente':
          return 'text-warning';
        case 'validé':
          return 'text-success';
        case 'en_cours':
          return 'text-info';
        case 'reçu':
          return 'text-orange';
        case 'rejete':
          return 'text-danger';
        default:
          return '';
      }
    },
    formatUrgence(urgence) {
      const formats = {
        'basse': 'Basse',
        'moyenne': 'Moyenne',
        'haute': 'Haute'
      };
      return formats[urgence] || urgence;
    },
    getUrgenceClass(urgence) {
      return {
        'urgence-badge': true,
        'urgence-basse': urgence === 'basse',
        'urgence-moyenne': urgence === 'moyenne',
        'urgence-haute': urgence === 'haute'
      };
    },
   
    formatStatut(statut) {
      return statut.replace(/_/g, ' ').replace(/\b\w/g, char => char.toLowerCase());
    },
    formatTypeDemande(type) {
      return type === 'both' ? 'Matériel et Consommable' : type.replace(/_/g, ' ').replace(/\b\w/g, char => char.toLowerCase());
    },
    async changeStatut(id, statut, observations) {
      try {
        const response = await axios.put(
          `http://localhost:8000/api/demandes/${id}/change-statut`,
          { 
            statut,
            observations 
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        
        if (response.data.statut === 200) {
          // Si le statut est passé à "validé", on vérifie automatiquement le stock
          if (statut === 'validé') {
            await this.verifierStock(id);
          }
          this.fetchDemandes(this.currentPage);
        } else {
          this.errorMessage = "Une erreur est survenue lors de la mise à jour.";
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour:", error);
        this.errorMessage = "Une erreur est survenue lors de la mise à jour.";
      }
    },
    async verifierStock(demandeId) {
      try {
        await axios.get(
          `http://localhost:8000/api/stock/verifier/${demandeId}`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
      } catch (error) {
        console.error("Erreur lors de la vérification du stock:", error);
        this.errorMessage = "Erreur lors de la vérification du stock";
      }
    },

    formatCheckingStatus(status) {
      const statusMap = {
        'disponible': 'Disponible en stock',
        'partiellement_disponible': 'Partiellement disponible',
        'non_disponible': 'Non disponible en stock'
      };
      return statusMap[status] || status;
    },
    async showDetails(id) {
      try {
        const response = await axios.get(`http://localhost:8000/api/demandes/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        this.selectedDemande = response.data.demande;
        this.formattedDate = this.formatDate(this.selectedDemande.created_at);
        this.isModalVisible = true;
      } catch (error) {
        console.error("Erreur lors de la récupération des détails de la demande:", error);
        this.errorMessage = "Une erreur est survenue lors de la récupération des détails de la demande";
      }
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async fetchDepartments() {
      try {
        const response = await axios.get("http://localhost:8000/api/departement/all", {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        if (response.data.statut === 200) {
          this.departments = response.data.departements;
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des départements:", error);
      }
    }, 
    printDetails() {
      const printContent = this.generatePrintContent();
      const printWindow = window.open('', '_blank', 'height=600,width=800');
      printWindow.document.write(printContent);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    },
    downloadDetails() {
      const content = this.generateDownloadContent();
      const element = document.createElement('a');
      const file = new Blob([content], {type: 'text/html'});
      element.href = URL.createObjectURL(file);
      element.download = `demande_${this.selectedDemande.id}.html`;
      document.body.appendChild(element);
      element.click();
    },
    generatePrintContent() {
      return `
        <!DOCTYPE html>
        <html lang="fr">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Facture de la demande N°${this.selectedDemande.id}</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              line-height: 1.6;
              color: #333;
              max-width: 800px;
              margin: 0 auto;
              padding: 20px;
            }
            h1, h2 {
              color: #2c3e50;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 12px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
              font-weight: bold;
            }
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;
            }
            .logo {
              font-size: 24px;
              font-weight: bold;
            }
            .demande-info {
              text-align: right;
            }
            .status {
              display: inline-block;
              padding: 5px 10px;
              border-radius: 4px;
              font-weight: bold;
            }
            .status-en_attente { background-color: #ffeeba; color: #856404; }
            .status-en_cours { background-color: #b8daff; color: #004085; }
            .status-reçu { background-color: #c3e6cb; color: #155724; }
            .status-rejete { background-color: #f5c6cb; color: #721c24; }
          </style>
        </head>
        <body>
          <div class="header">
            <div class="logo">CFPT-SJ</div>
            <div class="demande-info">
              <h1>Demande N°${this.selectedDemande.id}</h1>
              <p>Date: ${this.formattedDate}</p>
            </div>
          </div>

          <h2>Informations de la Demande</h2>
          <table>
            <tr>
              <th>Type de demande</th>
              <td>${this.formatTypeDemande(this.selectedDemande.type_demande)}</td>
            </tr>
            <tr>
              <th>Statut</th>
              <td><span class="status status-${this.selectedDemande.statut}">${this.formatStatut(this.selectedDemande.statut)}</span></td>
            </tr>
            <tr>
              <th>Chef de département</th>
              <td>${this.selectedDemande.user.prenom} ${this.selectedDemande.user.nom}</td>
            </tr>
            <tr>
              <th>Département</th>
              <td>${this.selectedDemande.user.departement.nom_departement}</td>
            </tr>
          </table>

          ${this.selectedDemande.type_demande === 'materiel' || this.selectedDemande.type_demande === 'both' ? `
            <h2>Matériels</h2>
            <table>
              <thead>
                <tr>
                  <th>Libellé</th>
                  <th>Quantité</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                ${this.selectedDemande.demande_materiels.map(m => `
                  <tr>
                    <td>${m.libelle}</td>
                    <td>${m.quantite}</td>
                    <td>${m.description}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
          ` : ''}

          ${this.selectedDemande.type_demande === 'consommable' || this.selectedDemande.type_demande === 'both' ? `
            <h2>Consommables</h2>
            <table>
              <thead>
                <tr>
                  <th>Libellé</th>
                  <th>Quantité</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                ${this.selectedDemande.demande_consommables.map(c => `
                  <tr>
                    <td>${c.libelle}</td>
                    <td>${c.quantite}</td>
                    <td>${c.description}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
          ` : ''}
        </body>
        </html>
      `;
    },
    generateDownloadContent() {
      return this.generatePrintContent();
    }
  },
};
















</script>

<style scoped>
.non-specifie {
  color: gray !important; 
  font-style: italic;
}
.color {
   color: rgb(245, 210, 10) !important;
}
.cursor-pointer {
  cursor: pointer;
}
.text-danger {
  color: red;
}
.text-info {
  color: #17a2b8;
}
.text-warning {
  color: orange;
  font-weight: bold;
}
.text-success {
  color: green;
  font-weight: bold;
}

.department-filters {
  margin: 20px 0;
  padding: 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  background: #f8f9fa;
  border-radius: 8px;
}

.filter-btn {
  padding: 8px 16px;
  border: 2px solid #e9ecef;
  border-radius: 20px;
  background: white;
  color: #495057;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.filter-btn:hover {
  background: #e9ecef;
}

.filter-btn.active {
  background: #0040ffa9;
  color: white;
 
}
.status-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.urgence-badge {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: 500;
  text-align: center;
  min-width: 100px;
}

.urgence-basse {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.urgence-moyenne {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
}

.urgence-haute {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Ajoutez également dans le modal */
.modal-body p strong:has(+ span.urgence-badge) {
  display: block;
  margin-bottom: 8px;
}

.observation-input {
  width: 100%;
  min-height: 60px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  font-size: 14px;
}

.observations-section {
  margin-top: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
}

.observation-box {
  padding: 15px;
  background: white;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  margin-top: 10px;
  white-space: pre-wrap;
}
/* Styles pour le modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 1200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.modal-actions {
  display: flex;
  gap: 10px;
}

.btn-icon {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  padding: 20px;
  font-size: 16px;
}

.modal-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.modal-column {
  width: 48%;
}

.left-column {
  border-right: 1px solid #ddd;
  padding-right: 20px;
}

.right-column {
  padding-left: 20px;
}

.checking-status-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checking-status-container i {
  cursor: pointer;
  transition: transform 0.2s;
}

.checking-status-container i:hover {
  transform: scale(1.2);
}

.stock-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
}

.stock-section {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
}

.stock-section.available {
  border-left: 4px solid #28a745;
}

.stock-section.to-order {
  border-left: 4px solid #dc3545;
}

.stock-section h3 {
  color: #333;
  margin-bottom: 15px;
  font-size: 1.2em;
}

.stock-section h4 {
  color: #666;
  margin: 10px 0;
  font-size: 1.1em;
}

.stock-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.stock-section li {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
  font-size: 0.9em;
}

.stock-section li:last-child {
  border-bottom: none;
}

.modal-body strong {
  display: inline-block;
  margin-bottom: 5px;
}

.modal-body ul {
  list-style-type: none;
  padding: 0;
}

.modal-body li {
  margin-bottom: 5px;
}
.modal-actions {
  display: flex;
  gap: 20px; /* Augmentation de l'espacement entre les icônes */
}

.action-icon {
  font-size: 24px;
  cursor: pointer;
  color: #333;
}
.text-orange {
  color: blue;
  font-weight: bold;
}

.checking-status {
  margin-top: 5px;
  padding: 5px;
  border-radius: 4px;
  font-size: 0.9em;
}

.checking-status.disponible {
  background-color: #dff0d8;
  color: #3c763d;
}

.checking-status.partiellement_disponible {
  background-color: #fcf8e3;
  color: #8a6d3b;
}

.checking-status.non_disponible {
  background-color: #f2dede;
  color: #a94442;
}

.btn-primary.btn-sm {
  margin-top: 5px;
  font-size: 0.8em;
  padding: 2px 8px;
}
</style>
