<template>
  <div class="page-wrapper">
    <!-- En-tête et navigation -->
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <!-- Contenu principal -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Gestion des Dispatchings par Commande</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="/">Accueil</a>&nbsp;<i class="fa fa-angle-right"></i></li>
                <li class="active">Dispatching</li>
              </ol>
            </div>
          </div>

          <!-- Liste des demandes ou détails d'une demande -->
          <div class="row">
            <div class="col-md-12">
              <div class="card card-box">
                <div class="card-head">
                  <header>{{ selectedCommande ? `Détails de la Commande N°${selectedCommande}` : 'Liste des Commandes Livrées' }}</header>
                </div>
                <div class="card-body">
                  <!-- Liste des demandes -->
                  <div v-if="!selectedCommande">
                    <div class="demandes-list">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>Référence Commande</th>
                            <th>Date</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="commande in commandes" :key="commande.id">
                            <td>{{ commande.reference_commande }}</td>
                            <td>{{ formatDate(commande.date_commande) }}</td>
                            <td>
                              <i class="fa fa-eye" @click="showCommandeDetails(commande.id)" title="Détails" style="cursor: pointer;"></i>
                              <i class="fa fa-share" @click="dispatcherCommande(commande.id)" title="Dispatcher" style="cursor: pointer;"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <!-- Détails de la demande -->
                  <div v-else>
                    <router-view></router-view>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Swal from 'sweetalert2';
import "moment/locale/fr";
import appheader from "@/components/layout/header.vue";
import appfooter from "@/components/layout/footer.vue";
import appnavbar from "@/components/layout/navbar.vue";
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";

export default {
  name: 'DispatchingComponent',
  components: {
    appheader,
    appfooter,
    appnavbar,
    PaginateComponent,
  },
  data() {
    return {
      commandes: [],
      materiels: [],
      consommables: [],
      batiments: [],
      salles: [],
      isDispatchModalVisible: false,
      isEditMode: false,
      selectedCommande: null,
      selectedItem: null,
      selectedItemType: null,
      selectedDispatch: null,
      selectedBatiment: "",
      selectedSalle: "",
      quantiteADispatcher: 0,
      error: null,
      selectedCommandeId: null
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  methods: {
    async fetchCommandes() {
      try {
        const response = await axios.get(
          `http://localhost:8000/api/dispatchings`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        this.commandes = response.data.commandes;
      } catch (error) {
        console.error("Erreur lors de la récupération des commandes:", error);
      }
    },

    async showCommandeDetails(commandeId) {
      try {
        this.error = null;
        this.selectedCommande = commandeId;
        const response = await axios.get(
          `http://localhost:8000/api/dispatchings/${commandeId}`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );

        // Récupérer les demandes de la commande
        const demandes = response.data.demandes;
        
        // Préparer les listes de matériels et consommables
        this.materiels = [];
        this.consommables = [];

        // Parcourir chaque demande
        demandes.forEach(demande => {
          // Ajouter les matériels à commander
          demande.demandeMateriels?.forEach(materiel => {
            if (materiel.a_commander) {
              this.materiels.push({
                ...materiel,
                demande_id: demande.id,
                user: demande.user
              });
            }
          });

          // Ajouter les consommables à commander
          demande.demandeConsommables?.forEach(consommable => {
            if (consommable.a_commander) {
              this.consommables.push({
                ...consommable,
                demande_id: demande.id,
                user: demande.user
              });
            }
          });
        });

      } catch (error) {
        console.error("Erreur lors de la récupération des détails:", error);
        this.error = error.response?.data?.message || error.message || "Une erreur est survenue lors de la récupération des détails";
        this.selectedCommande = null;
      }
    },

    dispatcherCommande(commandeId) {
      this.$router.push(`/dispatching/${commandeId}`);
    },

    async fetchBatiments() {
      try {
        const response = await axios.get('http://localhost:8000/api/batiment/index', {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        console.log('Bâtiments reçus:', response.data);
        this.batiments = response.data.batiment; // Accéder au tableau dans la clé 'batiment'
      } catch (error) {
        console.error("Erreur lors de la récupération des bâtiments:", error);
      }
    },

    async fetchSalles() {
      if (!this.selectedBatiment) {
        this.salles = [];
        return;
      }
      try {
        const response = await axios.get(`http://localhost:8000/api/salle/index`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        console.log('Salles reçues:', response.data);
        // Filtrer les salles par bâtiment
        this.salles = response.data.salle ? response.data.salle.filter(salle => salle.id_batiment == this.selectedBatiment) : [];
      } catch (error) {
        console.error("Erreur lors de la récupération des salles:", error);
      }
    },

    async confirmDispatch() {
      try {
        await axios.post(
          `http://localhost:8000/api/dispatchings/${this.selectedCommandeId}/dispatcher`,
          {
            batiment_id: this.selectedBatiment,
            salle_id: this.selectedSalle
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );

        // Rafraîchir la liste des commandes
        await this.fetchCommandes();
        
        // Fermer la modale
        this.closeDispatchModal();
      } catch (error) {
        console.error("Erreur lors du dispatching:", error);
      }
    },

    closeDispatchModal() {
      this.isDispatchModalVisible = false;
      this.selectedCommandeId = null;
      this.selectedBatiment = "";
      this.selectedSalle = "";
      this.salles = [];
    },

    async showEditModal(dispatch, item, type) {
      this.isEditMode = true;
      this.selectedDispatch = dispatch;
      this.selectedItem = item;
      this.selectedItemType = type;
      this.selectedBatiment = dispatch.id_batiment;
      this.selectedSalle = dispatch.id_salle;
      this.quantiteADispatcher = dispatch.quantite;
      this.isDispatchModalVisible = true;
      await this.fetchBatiments();
      await this.fetchSalles();
    },

    showDispatchModal(item, type) {
      this.isEditMode = false;
      this.selectedItem = item;
      this.selectedItemType = type;
      this.selectedDispatch = null;
      this.selectedBatiment = "";
      this.selectedSalle = "";
      this.quantiteADispatcher = 0;
      this.isDispatchModalVisible = true;
      this.fetchBatiments();
    },

    async dispatchItem() {
      if (!this.selectedBatiment || !this.selectedSalle || !this.quantiteADispatcher) {
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Veuillez remplir tous les champs.',
        });
        return;
      }

      try {
        if (this.isEditMode) {
          const response = await axios.put(
            `http://localhost:8000/api/dispatchings/${this.selectedDispatch.id}`,
            {
              id_batiment: this.selectedBatiment,
              id_salle: this.selectedSalle,
              quantite: parseInt(this.quantiteADispatcher)
            },
            {
              headers: { 
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              }
            }
          );

          if (response.data.statut === 200) {
            Swal.fire({
              icon: 'success',
              title: 'Succès',
              text: 'Le dispatching a été modifié avec succès.',
            });
            this.closeDispatchModal();
            await this.showCommandeDetails(this.selectedCommande);
          }
        } else {
          await axios.post('http://localhost:8000/api/dispatching', {
            id_commande: this.selectedCommande, // Changé de id_demande à id_commande
            items: [{
              id: this.selectedItem.id,
              type: this.selectedItemType,
              quantite: parseInt(this.quantiteADispatcher),
              id_batiment: this.selectedBatiment,
              id_salle: this.selectedSalle
            }]
          }, {
            headers: { 
              'Authorization': `Bearer ${this.token}`,
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });

          Swal.fire({
            icon: 'success',
            title: 'Succès',
            text: 'Le dispatching a été effectué avec succès.',
          });
          this.closeDispatchModal();
          await this.showCommandeDetails(this.selectedCommande); // Changé de showDemandeDetails à showCommandeDetails
        }
      } catch (error) {
        console.error("Erreur lors du dispatching:", error);
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: error.response?.data?.message || 'Une erreur est survenue lors du dispatching.',
        });
      }
    },

    async deleteDispatch(dispatchId) {
      const result = await Swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Cette action ne peut pas être annulée!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, supprimer!',
        cancelButtonText: 'Annuler'
      });

      if (result.isConfirmed) {
        try {
          await axios.delete(`http://localhost:8000/api/dispatchings/${dispatchId}`, {
            headers: { Authorization: `Bearer ${this.token}` }
          });
          
          Swal.fire(
            'Supprimé!',
            'Le dispatching a été supprimé avec succès.',
            'success'
          );
          
          await this.showCommandeDetails(this.selectedCommande);
        } catch (error) {
          console.error("Erreur lors de la suppression du dispatching:", error);
          Swal.fire(
            'Erreur!',
            'Une erreur est survenue lors de la suppression.',
            'error'
          );
        }
      }
    },

    backToCommandeList() {
      this.selectedCommande = null;
      this.materiels = [];
      this.consommables = [];
      this.error = null;
    },

    formatDate(date) {
      return moment(date).format('LL');
    },
  },
  mounted() {
    this.fetchCommandes();
  },
};
</script>
<style>
.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.quantite-globale {
  color: #4CAF50;
  
}

.quantite-restante {
  color: #f44336;
  
}



.card-deck {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.card {
  flex: 0 0 calc(33.333% - 20px);
  margin: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.card-body {
  padding: 15px;
}

.card-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.card-content {
  display: flex;
  margin: -10px;
}

.card-left {
  flex: 1;
  padding: 10px;
  border-right: 1px solid #eee;
}

.card-right {
  flex: 1;
  padding: 10px;
}

.card-text {
  margin: 8px 0;
  line-height: 1.4;
}

.dispatch-info {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
  position: relative;
}

.dispatch-info:last-child {
  border-bottom: none;
}

.dispatch-location,
.dispatch-quantity {
  display: block;
  margin-bottom: 4px;
}

.card-actions {
  text-align: right;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #eee;
}

.dispatch-actions {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.dispatch-info:hover .dispatch-actions {
  opacity: 1;
}

.dispatch-actions i {
  margin-left: 8px;
  padding: 4px;
  cursor: pointer;
  color: #666;
  transition: all 0.2s ease;
}

.dispatch-actions i.fa-edit:hover {
  color: #2196F3;
}

.dispatch-actions i.fa-trash:hover {
  color: #f44336;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.button-group {
  text-align: right;
  margin-top: 20px;
}

.btn {
  padding: 8px 16px;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-success {
  background-color: #4CAF50;
  color: white;
  border: none;
}

.btn-secondary {
  background-color: #f44336;
  color: white;
  border: none;
}
</style>