<template>
  <div class="page-wrapper">
    <!-- start header -->
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">{{ pageTitle }}</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.html">Home</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li class="active">Faire une Demande</li>
              </ol>
            </div>
          </div>
          <!-- start form content -->
          <div class="row">
            <div class="col-md-12">
              <div class="card card-box">
                <div class="card-head">
                  <header>Formulaire de Demande</header>
                </div>
                <div class="card-body">
                  <div v-if="!typeDemandeChoisi">
                    <div class="form-group">
                      <label for="type_demande_initial">Choisissez le type de demande:</label>
                      <select v-model="typeDemandeInitial" class="form-control" required>
                        <option value="">Sélectionner le type</option>
                        <option value="materiel">Matériel</option>
                        <option value="consommable">Consommable</option>
                        <option value="both">Les deux</option>
                      </select>
                    </div>
                    <button @click="choisirTypeDemande" class="btn btn-primary">Confirmer</button>
                  </div>
                  <div v-else>
                    <div v-for="(form, index) in forms" :key="index" class="mb-4">
                      <div class="header d-flex justify-content-end align-items-center">
                        <svg class="cursor-pointer" @click="addNewForm" style="font-size: 25px; color: green;"
                          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path fill="green"
                            d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                        </svg>
                        <svg v-show="forms.length > 1" class="cursor-pointer ml-2" @click.prevent="removeForm(index)"
                          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path fill="red"
                            d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm5 13H7v-2h10v2z" />
                        </svg>
                      </div>
                      <form>
                        <div v-if="typeDemandeInitial === 'materiel' || typeDemandeInitial === 'both'" class="form-group">
                          <div class="row">
                            <div class="col-md-6">
                              <label for="libelle_materiel">Libellé du Matériel:</label>
                              <input type="text" v-model="form.libelle_materiel" class="form-control" required />
                              <span v-if="errors[index] && errors[index].libelle_materiel" class="text-danger">{{ errors[index].libelle_materiel }}</span>
                            </div>
                            <div class="col-md-6">
                              <label for="quantite_materiel">Quantité de Matériel:</label>
                              <input type="number" v-model="form.quantite_materiel" class="form-control" required min="0" />
                              <span v-if="errors[index] && errors[index].quantite_materiel" class="text-danger">{{ errors[index].quantite_materiel }}</span>
                            </div>
                          </div>
                          <label for="description_materiel">Description du Matériel:</label>
                          <textarea v-model="form.description_materiel" class="form-control" rows="3"></textarea>
                          <span v-if="errors[index] && errors[index].description_materiel" class="text-danger">{{ errors[index].description_materiel }}</span>
                        </div>
                        <div v-if="typeDemandeInitial === 'consommable' || typeDemandeInitial === 'both'" class="form-group">
                          <div class="row">
                            <div class="col-md-6">
                              <label for="libelle_consommable">Libellé du Consommable:</label>
                              <input type="text" v-model="form.libelle_consommable" class="form-control" required />
                              <span v-if="errors[index] && errors[index].libelle_consommable" class="text-danger">{{ errors[index].libelle_consommable }}</span>
                            </div>
                            <div class="col-md-6">
                              <label for="quantite_consommable">Quantité de Consommable:</label>
                              <input type="number" v-model="form.quantite_consommable" class="form-control" required min="0" />
                              <span v-if="errors[index] && errors[index].quantite_consommable" class="text-danger">{{ errors[index].quantite_consommable }}</span>
                            </div>
                          </div>
                          <label for="description_consommable">Description du Consommable:</label>
                          <textarea v-model="form.description_consommable" class="form-control" rows="3"></textarea>
                          <span v-if="errors[index] && errors[index].description_consommable" class="text-danger">{{ errors[index].description_consommable }}</span>
                        </div>
                      </form>
                    </div>
                  

                    <div class="col-lg-12 p-t-20 text-center">
                      <button @click="submitAllDemandes" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary" >
                        
                        <span>Soumettre</span>
                      </button>
                      <button
                        type="button"
                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                        @click="cancelEdit"
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end form content -->
        </div>
      </div>
      <appafterContent />
    </div>
    <appfooter />
  </div>
</template>

<script>
import axios from "axios";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import Swal from 'sweetalert2';

export default {
  components: {
    appheader,
    appfooter,
    appnavbar,
    appafterContent,
  },
  data() {
    return {
      typeDemandeInitial: "",
      typeDemandeChoisi: false,
      forms: [
        {
          libelle_materiel: "",
          quantite_materiel: "",
          libelle_consommable: "",
          quantite_consommable: "",
          description_materiel: "",
          description_consommable: "",
        }
      ],
      errors: [{}],
      is_loading: false,
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
    pageTitle() {
      return this.typeDemandeInitial === 'materiel'
        ? "Faire une Demande de Matériel"
        : this.typeDemandeInitial === 'consommable'
        ? "Faire une Demande de Consommable"
        : "Faire une Demande de Matériel et Consommable";
    },
  },
  methods: {
    choisirTypeDemande() {
      if (this.typeDemandeInitial) {
        this.typeDemandeChoisi = true;
      } else {
        Swal.fire("Erreur!", "Veuillez sélectionner un type de demande.", "error");
      }
    },
    addNewForm() {
      this.forms.push({
        libelle_materiel: "",
        quantite_materiel: "",
        libelle_consommable: "",
        quantite_consommable: "",
        description_materiel: "",
        description_consommable: "",
      });
      this.errors.push({});
    },
    removeForm(index) {
      this.forms.splice(index, 1);
      this.errors.splice(index, 1);
    },
    async submitAllDemandes() {
      this.is_loading = true;
      const headers = { Authorization: `Bearer ${this.token}` };

      try {
        // Filtrer les formulaires pour ne garder que ceux qui ont des données
        const materielForms = this.forms.filter(form => form.libelle_materiel && form.quantite_materiel);
        const consommableForms = this.forms.filter(form => form.libelle_consommable && form.quantite_consommable);

        const data = {
          type_demande: this.typeDemandeInitial,
          materiels: this.typeDemandeInitial === 'materiel' || this.typeDemandeInitial === 'both' 
            ? materielForms.map(form => ({
                libelle: form.libelle_materiel,
                quantite: parseInt(form.quantite_materiel),
                description: form.description_materiel || ''
              }))
            : [],
          consommables: this.typeDemandeInitial === 'consommable' || this.typeDemandeInitial === 'both'
            ? consommableForms.map(form => ({
                libelle: form.libelle_consommable,
                quantite: parseInt(form.quantite_consommable),
                description: form.description_consommable || ''
              }))
            : []
        };

        // Vérifier qu'il y a au moins un élément à envoyer
        if ((data.type_demande === 'materiel' && data.materiels.length === 0) ||
            (data.type_demande === 'consommable' && data.consommables.length === 0) ||
            (data.type_demande === 'both' && data.materiels.length === 0 && data.consommables.length === 0)) {
          throw new Error('Veuillez remplir au moins un formulaire');
        }

        const response = await axios.post("http://localhost:8000/api/demandes", data, { headers });

        if (response.data.statut === 200) {
          Swal.fire("Succès!", "Demande soumise avec succès", "success");
          this.resetForms();
          this.$router.push('/liste-demandes');
        } else {
          throw new Error(response.data.message || 'Une erreur est survenue');
        }
      } catch (error) {
        console.error("Erreur lors de la soumission des demandes:", error);
        
        if (error.response && error.response.status === 422) {
          this.errors = this.translateErrors(error.response.data.errors);
        } else {
          Swal.fire("Erreur!", error.message || "Une erreur est survenue lors de la soumission des demandes.", "error");
        }
      } finally {
        this.is_loading = false;
      }
    },
    translateErrors(errors) {
      const translatedErrors = [];
      errors.forEach((error, index) => {
        const translatedError = {};
        Object.keys(error).forEach(key => {
          const field = key.split('.')[0];
          const message = error[key][0];
          let translatedField = '';

          switch (field) {
            case 'libelle_materiel':
              translatedField = 'Le libellé du matériel';
              break;
            case 'quantite_materiel':
              translatedField = 'La quantité de matériel';
              break;
            case 'description_materiel':
              translatedField = 'La description du matériel';
              break;
            case 'libelle_consommable':
              translatedField = 'Le libellé du consommable';
              break;
            case 'quantite_consommable':
              translatedField = 'La quantité de consommable';
              break;
            case 'description_consommable':
              translatedField = 'La description du consommable';
              break;
            default:
              translatedField = field;
          }

          translatedError[field] = `${translatedField} est requis.`;
        });
        translatedErrors.push(translatedError);
      });

      return translatedErrors;
    },
    resetForms() {
      this.typeDemandeInitial = "";
      this.typeDemandeChoisi = false;
      this.forms = [
        {
          libelle_materiel: "",
          quantite_materiel: "",
          libelle_consommable: "",
          quantite_consommable: "",
          description_materiel: "",
          description_consommable: "",
        }
      ];
      this.errors = [{}];
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.text-danger {
  color: red;
}
</style>