<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
        :userRole="userRole" />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <!-- start page content -->
        <div class="page-content" v-if="!this.editModal">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Liste des Réservations</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard-saf'">Accueil</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" :href="'/facture-index'">Réservation</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Liste des réservations</li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="tabbable-line">
                <ul class="nav customtab nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a href="#tab1" class="nav-link active" data-bs-toggle="tab">Réservations</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active fontawesome-demo" id="tab1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Liste Réservation</header>
                            <div class="tools">
                              <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                              <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                              <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6 col-sm-6 col-6">
                               <!--  <div class="btn-group">
                                  <a :href="'/create-facture'" id="addRow" class="btn btn-primary">
                                    Ajouter
                                    <i class="fa fa-plus text-white"></i>
                                  </a>
                                </div> -->
                              </div>
                            </div>
                            <!--  -->
                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length">
                                  <label class="me-2">Afficher</label>
                                  <select name="example47_length" aria-controls="example47" class="form-select-sm"
                                    @change="updatePerPage" v-model="limitQuery">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2">Recherche
                                    :</label>
                                  <input type="text" id="searchInput" placeholder="" aria-controls="example47"
                                    v-model="searchQuery" @input="get_reservation(currentPage)" />
                                </div>
                              </div>
                            </div>

                            <!--  -->
                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="example47" style="width: 100%">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>N° Facture</th>
                                  <th>Partenaire</th>
                                  <th>Description</th>
                                  <th>Salle</th>
                                  <th>Nombre/jour</th>
                                  <th>Date Début</th>
                                  <th>Date Fin</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="odd gradeX" v-for="(
                                    reservation, index
                                  ) in listeReservation">
                                  <td class="left">{{ index + 1 }}</td>
                                  <td class="left">{{ reservation.id }}{{ formatDate(reservation.created_at) }}</td>

                                  <td class="left">
                                    {{ reservation.partenaire }}
                                  </td>

                                  <td class="left">
                                    {{ reservation.designation }}
                                  </td>

                                  <td class="left">
                                    {{ reservation.salle }}
                                  </td>

                                  <td class="left">
                                    {{ reservation.nombre_jour }}
                                  </td>

                                  <td class="left">
                                    {{ reservation.date_debut }}
                                  </td>

                                  <td class="left">
                                    {{ reservation.date_fin }}
                                  </td>

                                  <td>
                                    <a class="tblEditBtn" title="Modifier" @click="openModal(reservation)">
                                      <i class="fa fa-pencil"></i>
                                    </a>

                                    <a class="tblDelBtn" title="Supprimer" @click="deleteReservation(reservation)">
                                      <i class="fa fa-trash-o"></i>
                                    </a>

                                    <a class="btn-definitive" title="Generer Facture Définitive" @click=" reservation.facturer || reservation.acompter ? this.erreurFactureDefinitive() :
          validerFactureDefinitive(reservation)">
                                      <i class="fas fa-file-invoice"></i>
                                    </a>

                                    <a class="btn-acompte" title="Generer Facture Acompte" @click=" reservation.facturer ? this.erreurFactureDefinitive() :
          validerFactureAcompte(reservation)
          ">
                                      <i class="fas fa-file-alt"></i>
                                    </a>

                                    <!--  <a
                                      class="btn-solde"
                                      title="Generer Facture Solde"
                                      @click="validerFactureSolde(reservation)">
                                      <i class="fas fa-file"></i>
                                    </a> -->

                                  </td>
                                </tr>
                              </tbody>

                            </table>
                            <div class="row" v-if="this.listeReservation.length > 0">
                              <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info" id="datable_1_info" role="status" aria-live="polite">
                                  Affichage de {{ from_begin }} à
                                  {{ to_begin }} sur {{ total }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div class="dataTables_paginate paging_simple_numbers" id="datable_1_paginate">
                                  <paginate-component :total-pages="total_page" :total="total" :per-page="per_page"
                                    :current-page="current_page" @pagechanged="pagPage"></paginate-component>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content-wrapper" v-show="editModal">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Modifier Utilisateur</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard'">Tableau de
                    Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" :href="'/liste-utilisateur'">Utilisateur</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li class="active">Modifier Utilisateur</li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Information</header>
                  <button id="panel-button" class="mdl-button mdl-js-button mdl-button--icon pull-right"
                    data-upgraded=",MaterialButton">
                    <i class="material-icons">more_vert</i>
                  </button>
                  <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                    data-mdl-for="panel-button">
                    <li class="mdl-menu__item">
                      <i class="material-icons">assistant_photo</i>Action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">print</i>Another action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">favorite</i>Something else here
                    </li>
                  </ul>
                </div>
                <div class="card-body row">
                  <FormulaireModification />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <appafterContent />
    </div>
    <appfooter />
  </div>
</template>
<script>
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";

import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import datatable from "datatables.net-bs5";
import FormulaireModification from "./editReservationComponent.vue";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import { mapState, mapActions } from "vuex";
import permissionMixin from "@/permission";

export default {
  name: "listeUserCompenent",
  components: {
    FormulaireModification,
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    PaginateComponent
  },
  mixins: [permissionMixin],
  data() {
    return {
      form: new Form({
        designation: "",
        nombre_jour: "",
        montant_jour: "",
        date_location: "",
        id_partenaire: "",
        id_salle: "",
        id_user: "",
      }),
      listeReservation: [],

      idUser: "",
      editModal: false,

      activePhase: 1,
      idUser: "",
      userEnCoursDeModification: null,
      limitQuery: 5,
      total_page: 0,
      total: 0,
      per_page: 0,
      current_page: 1,
      from_begin: 0,
      to_begin: 0,
      is_loading: false,
      searchQuery: "",
      currentPage: 1,
      lastPage: 1,

    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  async created() {
    await this.get_permission();
    this.get_reservation();
    bus.on("factureAjoutee", () => {
      this.get_reservation();
    });

    bus.on("factureDejaModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.get_reservation();
    });
  },
  /*   mounted() {
      this.get_reservation();
      bus.on("factureAjoutee", () => {
        this.get_reservation();
      });
  
      bus.on("factureDejaModifier", (eventData) => {
        this.editModal = eventData.editModal;
        this.get_reservation();
      });
    }, */

  methods: {
    ...mapActions(["login", "logout"]),

    pagPage(pagination) {
      this.current_page = pagination;
      this.get_reservation(this.currentPage);
    },
    updatePerPage(event) {
      // Récupérer la nouvelle valeur sélectionnée
      const newPerPage = parseInt(event.target.value);

      // Appeler la méthode pour recharger les données avec la nouvelle quantité par page
      this.reloadDataWithPerPage(newPerPage);
    },

    reloadDataWithPerPage(newPerPage) {
      // Mettre à jour la limite de la requête avec la nouvelle quantité par page
      if (this.limitQuery !== 5) {
        this.limitQuery = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_reservation(this.currentPage);
      }
    },

    async get_reservation() {
      const token = localStorage.getItem("token");
      const filterCondition = (direct) => true; // Condition de filtre à définir

      const headers = { Authorization: `Bearer ${token}` };

      const params = {
        per_page: this.limitQuery,
        page: this.current_page,
        // Ajoutez d'autres paramètres si nécessaire
      };
      if (this.hasReadPermission("Reservation")) {
        try {
          // const response = await axios.get('/direction/index/paginate', { headers });
          const response = await axios.get("/reservation/index/paginate", {
            headers: headers,
            params: params,
          });

          const allReservation = response.data.reservation.data;
          const filteredReservations = allReservation.filter((reservation) => {
            return (
              filterCondition(reservation) &&
              Object.keys(reservation).reduce((acc, key) => {
                const value = reservation[key];
                if (typeof value === 'string') {
                  acc.push(value.toLowerCase());
                } else if (value && typeof value === 'object') {
                  Object.values(value).forEach((nestedValue) => {
                    if (typeof nestedValue === 'string') {
                      acc.push(nestedValue.toLowerCase());
                    }
                  });
                }
                return acc;
              }, []).some((value) => value.includes(this.searchQuery.toLowerCase()))
            );
          });

          const Reservation = filteredReservations.map((reservation) => {
            return {
              id: reservation.id,
              facturer: reservation.facturer,
              editModal: true,
              id_salle: reservation.salle.id,
              acompter: reservation.acompter,
              id_location: reservation.id_location,
              designation: reservation.location.designation,
              nombre_jour: reservation.location.nombre_jour,
              salle: reservation.salle.intitule,
              date_debut: reservation.date_debut,
              date_fin: reservation.date_fin,
              created_at: reservation.created_at,
              partenaire: reservation.location.partenaire.nom_partenaire,
            };
          });

          this.listeReservation = Reservation;


          const page = {
            total_page: response.data.reservation.last_page,
            total: response.data.reservation.total,
            per_page: response.data.reservation.per_page,
            current_page: response.data.reservation.current_page,
            from_begin: response.data.reservation.from,
            to_begin: response.data.reservation.to,
          };

          this.reservations = Reservation;
          this.total_page = page.total_page;
          this.total = page.total;
          this.per_page = page.per_page;
          this.current_page = page.current_page;
          this.from_begin = page.from_begin;
          this.to_begin = page.to_begin;

        } catch (error) {
          Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des directions', 'error');
        }
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },
    formatDate(date) {
      const dateObj = new Date(date);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      return year + month + day;
    },

    changement(event) {
      this.interesser = event;
    },



    async deleteReservation(reservation) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasDeletePermission("Reservation")) {
        Swal.fire({
          title: "Êtes-vous sûr de vouloir supprimer cette réservation ?",
          text: "Cette action sera irréversible!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
        }).then((result) => {
          if (result.value) {

            axios.delete(`/reservation/delete/${reservation.id}`, { headers }).then(resp => {
              showDialog6("Réservation supprimée avec succès");
              this.get_reservation();

            }).catch(function (error) {
              console.log(error);
            })
          }
        });
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }

    },

    openModal(reservation) {
      this.editModal = true;
      const eventData = {
        reservation: reservation,
      };
      bus.emit('reservationModifier', eventData);
    },

    validerFacture(factureId) {
      this.$router.push({
        name: "validerReservationProforma",
        params: { idlocation: factureId.id_location, id: factureId.id },
      });
    },

    erreurFactureDefinitive() {
      showDialog3("La facture Définitive ou Acompte à été déjà générer");
    },


    validerFactureDefinitive(reservation) {
      this.$router.push({
        name: "genererFactureDefinitive",
        params: { idlocation: reservation.id_location, id: reservation.id },
      });
    },
    validerFactureAcompte(factureId) {
      this.$router.push({
        name: "genererFactureAcompte",
        params: { idlocation: factureId.id_location, id: factureId.id },
      });
    },
    validerFactureSolde(factureId) {
      this.soumettre();
      //this.$router.push({ name: 'genererFactureSolde', params: { idlocation: factureId.id_location, id: factureId.id } });
    },

    getImageUrl(url) {
      //return url ? `${window.location.origin}/storage/${url}` : '';
      //return url ? `http://127.0.0.1:8000/storage/${url}` : "";
      return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
    },
  },
};
</script>
<style>
.btn-definitive {
  background-color: transparent !important;
  box-shadow: none !important;
  color: blue;
  /* Couleur pour Facture Définitive */
  padding: 0px 2px !important;
}

.btn-acompte {
  background-color: transparent !important;
  box-shadow: none !important;
  color: green;
  /* Couleur pour Facture Acompte */
  padding: 0px 2px !important;
}

.btn-solde {
  background-color: transparent !important;
  box-shadow: none !important;
  color: red;
  /* Couleur pour Facture Solde */
  padding: 0px 2px !important;
}


.btn-definitive i {
  font-size: 12px;
  position: relative;
  line-height: 1;
  background-color: rgba(0, 0, 255, 0.2);
  /* Couleur pour Facture Définitive */
  border-color: transparent;
  color: blue;
  /* Couleur pour Facture Définitive */
  padding: 8px;
  height: 100%;
  border-radius: 5px;
}

.btn-acompte i {
  font-size: 12px;
  position: relative;
  line-height: 1;
  background-color: rgba(0, 255, 0, 0.2);
  /* Couleur pour Facture Acompte */
  border-color: transparent;
  color: green;
  /* Couleur pour Facture Acompte */
  padding: 8px;
  height: 100%;
  border-radius: 5px;
}

.btn-solde i {
  font-size: 12px;
  position: relative;
  line-height: 1;
  background-color: rgba(255, 0, 0, 0.2);
  /* Couleur pour Facture Solde */
  border-color: transparent;
  color: red;
  /* Couleur pour Facture Solde */
  padding: 8px;
  height: 100%;
  border-radius: 5px;
}
</style>
