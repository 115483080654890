<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <!-- start page content -->
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a
                    class="parent-item"
                    :href="'/dashboardCaissier'"
                    >Tableau de bord</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>

                <li class="active">Historique des paiements</li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="tabbable-line">
                <ul class="nav customtab nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a href="#tab1" class="nav-link active" data-bs-toggle="tab"
                      >Historique Paiement</a
                    >
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active fontawesome-demo" id="tab1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Historique des Paiements</header>

                            <div class="tools">
                              <a
                                class="fa fa-repeat btn-color box-refresh"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-collapse btn-color fa fa-chevron-down"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-close btn-color fa fa-times"
                                href="javascript:;"
                              ></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6 col-sm-6 col-6">
                                <div class="btn-group">
                                  <a
                                    :href="'/create-paiement'"
                                    id="addRow"
                                    class="btn btn-primary"
                                  >
                                    Ajouter
                                    <i class="fa fa-plus text-white"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div
                                  class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length"
                                >
                                  <label class="me-2">Afficher</label>
                                  <select
                                    name="example47_length"
                                    aria-controls="example47"
                                    class="form-select-sm"
                                    @change="updatePerPage"
                                    v-model="limitQuery"
                                  >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2"
                                    >Recherche :</label
                                  >
                                  <input
                                    type="text"
                                    id="searchInput"
                                    placeholder=""
                                    aria-controls="example47"
                                    v-model="searchQuery"
                                    @input="get_paiement(currentPage)"
                                  />
                                </div>
                              </div>
                            </div>

                            <!--  -->

                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="example47"
                            >
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Matricule</th>
                                  <th>Nom</th>
                                  <th>Prenom</th>
                                  <th>Classe</th>
                                  <th>Mode de paiement</th>
                                  <th>Date Paiement</th>
                                  <th>Année Académique</th>
                                  <th>Mois</th>
                                  <th>Montant</th>
                                  <th>Statut</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="odd gradeX"
                                  v-for="(paiement, index) in paiements"
                                  :key="index"
                                >
                                  <td class="patient-img">
                                    <img
                                      :src="getImageUrl(paiement.photo)"
                                      alt="Etu"
                                    />
                                  </td>
                                  <td>{{ paiement.matricule }}</td>
                                  <td>{{ paiement.eleve_nom }}</td>
                                  <td>{{ paiement.eleve_prenom }}</td>
                                  <td>{{ paiement.classe }}</td>
                                  <td>{{ paiement.mode_paiement }}</td>
                                  <td>{{ paiement.date }}</td>
                                  <td>{{ paiement.annee }}</td>
                                  <td>{{ paiement.mois }}</td>
                                  <td>{{ paiement.montant }}</td>
                                  <td>{{ paiement.statut }}</td>

                                  <td>
                                    <a
                                      class="tblEditBtn"
                                      @click="openModal(paiement)"
                                    >
                                      <i class="fa fa-pencil"></i>
                                    </a>
                                    <a
                                      class="tblDelBtn"
                                      @click="deletepaiement(paiement)"
                                    >
                                      <i class="fa fa-trash-o"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="row" v-if="this.paiements.length > 0">
                              <div class="col-sm-12 col-md-5">
                                <div
                                  class="dataTables_info"
                                  id="datable_1_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Affichage de {{ from_begin }} à
                                  {{ to_begin }} sur {{ total }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div
                                  class="dataTables_paginate paging_simple_numbers"
                                  id="datable_1_paginate"
                                >
                                  <paginate-component
                                    :total-pages="total_page"
                                    :total="total"
                                    :per-page="per_page"
                                    :current-page="current_page"
                                    @pagechanged="pagPage"
                                  ></paginate-component>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <appafterContent />
      </div>
      <appfooter />
    </div>
  </div>
</template>

<script>
import bus from "../../eventBus";
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";
import html2pdf from "html2pdf.js";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5";
import axios from "@/axios";
import Form from "vform";
import { mapState, mapActions } from "vuex";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    appfooter,
    appafterContent,
    PaginateComponent,
    appheader,
  },
  name: "ListeHistoriquePaiementEleve",
  data() {
    return {
      eleves: [],
      viewbutton: false,
      nom_classe_selected: "",
      annee_selected: "",
      viewListe: false,
      paiements: [],
      editModal: false,
      limitQuery: 5,
      total_page: 0,
      total: 0,
      per_page: 0,
      current_page: 1,
      from_begin: 0,
      to_begin: 0,
      is_loading: false,
      searchQuery: "",
      currentPage: 1,
      lastPage: 1,
      /* idService: "", */
      moisScolarite: [
        { id: 1, nom: 'Octobre' },
        { id: 2, nom: 'Novembre' },
        { id: 3, nom: 'Décembre' },
        { id: 4, nom: 'Janvier' },
        { id: 5, nom: 'Février' },
        { id: 6, nom: 'Mars' },
        { id: 7, nom: 'Avril' },
        { id: 8, nom: 'Mai' },
        { id: 9, nom: 'Juin' },
        { id: 10, nom: 'Juillet' }
      ],
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  mounted() {
    bus.on("nouveauFiltre", (eventData) => {
      console.log("Données reçues dans nouveauFiltre:", eventData);
      
      
      if (eventData.eleve_non_payers) {
        // Assignation directe sans transformation
        this.paiements = eventData.eleve_non_payers;
        
        // Log pour vérifier l'assignation
        console.log("this.paiements après assignation:", this.paiements);
        
        this.total = this.paiements.length;
        this.from_begin = 1;
        this.to_begin = this.paiements.length;
        this.viewListe = true;
      }
    });
  },

  methods: {
    ...mapActions(["login", "logout"]),
    pagPage(pagination) {
      this.current_page = pagination;
      this.get_paiement(this.currentPage);
    },
    updatePerPage(event) {
      // Récupérer la nouvelle valeur sélectionnée
      const newPerPage = parseInt(event.target.value);

      // Appeler la méthode pour recharger les données avec la nouvelle quantité par page
      this.reloadDataWithPerPage(newPerPage);
    },
    reloadDataWithPerPage(newPerPage) {
      // Mettre à jour la limite de la requête avec la nouvelle quantité par page
      if (this.limitQuery !== 5) {
        this.limitQuery = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_paiement(this.currentPage);
      }
    },
    async get_paiement() {
      const token = localStorage.getItem("token");
      const filterCondition = (direct) => true; // Condition de filtre à définir
      const headers = { Authorization: `Bearer ${token}` };
      const params = {
        per_page: this.limitQuery,
        page: this.current_page,
        // Ajoutez d'autres paramètres si nécessaire
      };

      try {
        // const response = await axios.get('/direction/index/paginate', { headers });
        const response = await axios.get("/paiement/index/paginate", {
          headers: headers,
          params: params, // Ajouter les paramètres ici
        });

        const allpaiement = response.data.paiement.data;

        const filteredPaiements = allpaiement.filter((paie) => {
          return (
            filterCondition(paie) &&
            Object.keys(paie)
              .reduce((acc, key) => {
                const value = paie[key];
                if (typeof value === "string") {
                  acc.push(value.toLowerCase());
                } else if (value && typeof value === "object") {
                  Object.values(value).forEach((nestedValue) => {
                    if (typeof nestedValue === "string") {
                      acc.push(nestedValue.toLowerCase());
                    }
                  });
                }
                return acc;
              }, [])
              .some((value) => value.includes(this.searchQuery.toLowerCase()))
          );
        });

        const formattedPaiement = filteredPaiements.map((paie) => {
          return {
            id: paie.id,
            id_eleve: paie.eleve.user.id,
            matricule: paie.eleve.user.matricule,
            photo: paie.eleve.user.photo,
            eleve_prenom: paie.eleve.user.prenom,
            eleve_nom: paie.eleve.user.nom,
            date_naissance: paie.eleve.user.date_naissance,
            adresse: paie.eleve.user.adresse,
            matricule: paie.eleve.user.matricule,

            type_recouvrement: paie.type_recouvrement,
            mode_paiement: paie.mode_paiement,
            reference: paie.reference,
            classe: paie.eleve.inscription
              .map((p) => p.classe.nom_classe)
              .join(", "),
            annee: paie.concerner
              .map((p) => p.annee_academique.intitule)
              .join(", "),
            mois: paie.concerner.map((p) => p.mois.intitule).join(", "),
            id_annee: paie.concerner
              .map((p) => p.annee_academique.id)
              .join(", "),
            id_mois: paie.concerner.map((p) => p.mois.id).join(", "),
            montant: paie.montant,
            editModal: true,
          };
        });
        this.paiements = formattedPaiement;

        const page = {
          total_page: response.data.paiement.last_page,
          total: response.data.paiement.total,
          per_page: response.data.paiement.per_page,
          current_page: response.data.paiement.current_page,
          from_begin: response.data.paiement.from,
          to_begin: response.data.paiement.to,
        };

        this.paiements = formattedPaiement;
        this.total_page = page.total_page;
        this.total = page.total;
        this.per_page = page.per_page;
        this.current_page = page.current_page;
        this.from_begin = page.from_begin;
        this.to_begin = page.to_begin;

      } catch (error) {
        Swal.fire(
          "Erreur!",
          "Une erreur est survenue lors de la récupération des paiements",
          "error"
        );
      }
    },
    resetForm() {
      this.form.input = "";
      this.form.nom_paiement = "";
    },

    getImageUrl(url) {
      //return url ? `${window.location.origin}/storage/${url}` : '';
      //return url ? `http://127.0.0.1:8000/storage/${url}` : '';
      return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
    },

    imprimerEnPDF() {
      const element = document.getElementById("contenu-a-imprimer"); // Remplacez 'contenu-a-imprimer' par l'ID de votre élément
      html2pdf(element);
    },

    filtre() {
      const eventData = {
        viewListe: false,
      };

      bus.emit("ancienFiltre", eventData);
    },

    getMontantMensuel() {
      if (this.paiements && this.paiements.length > 0) {
        const classe = this.paiements[0].classe.toUpperCase();
        return classe.includes('BTS') ? 70000 : 50000;
      }
      return 0;
    },

    getPaiementPourMois(mois) {
      return this.paiements.find(p => p.mois.toLowerCase() === mois.nom.toLowerCase());
    },

    getStatutClass(mois) {
      const paiement = this.getPaiementPourMois(mois);
      if (!paiement) return 'bg-danger';
      
      const montantAttendu = this.getMontantMensuel();
      if (paiement.montant >= montantAttendu) {
        return 'bg-success';
      } else if (paiement.montant > 0) {
        return 'bg-warning';
      }
      return 'bg-danger';
    },

    getStatutText(mois) {
      const paiement = this.getPaiementPourMois(mois);
      if (!paiement) return 'NON PAYÉ';
      
      const montantAttendu = this.getMontantMensuel();
      if (paiement.montant >= montantAttendu) {
        return 'PAYÉ';
      } else if (paiement.montant > 0) {
        return 'EN ATTENTE';
      }
      return 'NON PAYÉ';
    },

    calculerTotalPaye() {
      return this.paiements.reduce((sum, p) => sum + p.montant, 0);
    },

    calculerResteAPayer() {
      const montantMensuel = this.getMontantMensuel();
      const totalAttendu = this.moisScolarite.length * montantMensuel;
      return totalAttendu - this.calculerTotalPaye();
    }
  },
};
</script>

<style scoped>
.card {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.card-head {
  background-color: #f8f9fa;
  padding: 15px 20px;
  border-bottom: 1px solid #dee2e6;
}

.badge {
  padding: 8px 12px;
  border-radius: 4px;
  font-weight: 500;
}

.bg-success {
  background-color: #28a745 !important;
  color: white;
}

.bg-danger {
  background-color: #dc3545 !important;
  color: white;
}

.table th {
  background-color: #f8f9fa;
}

.fa-calendar {
  margin-right: 8px;
  color: #6c757d;
}
</style>
