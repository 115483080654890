<template>
    <div class="page-wrapper">
      <!-- start header -->
      <div class="page-header navbar navbar-fixed-top">
        <div class="page-header-inner">
          <appheader
            :userPhoto="userPhoto"
            :userNom="userNom"
            :userPrenom="userPrenom"
          />
        </div>
      </div>
      <div class="page-container">
        <appnavbar
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
          :userIdrole="userIdrole"
          :userRole="userRole"
        />
	  
  
        
    <div class="page-content-wrapper">
				<div class="page-content">
					<div class="page-bar">
						<div class="page-title-breadcrumb">
							<div class=" pull-left">
								<div class="page-title">Tableau de bord</div>
							</div>
							<ol class="breadcrumb page-breadcrumb pull-right">
								<li><i class="fa fa-home"></i>&nbsp;<a class="parent-item"
										href="index.html">Home</a>&nbsp;<i class="fa fa-angle-right"></i>
								</li>
								<li class="active">Dashboard_</li>
							</ol>
						</div>
					</div>
					<!-- start widget -->
					<div class="row">
						<div class="col-xl-5">
							<div class="w-100">
								<div class="row">
									<div class="col-sm-6">
										<div class="card">
											<div class="card-body">
												<div class="row">
													<div class="col mt-0">
														<h4 class="info-box-title">Matériels</h4>
													</div>
													<div class="col-auto">
														<div class="l-bg-green info-icon">
															<i class="fa fa-users pull-left col-orange font-30"></i>
														</div>
													</div>
												</div>
												<h1 class="mt-1 mb-3 info-box-title">41</h1>
												<div class="mb-0">
													<span class="text-success m-r-10"><i
															class="material-icons col-green align-middle">trending_up</i>
														
													</span>
													
												</div>
											</div>
										</div>
										<div class="card">
											<div class="card-body">
												<div class="row">
													<div class="col mt-0">
														<h4 class="info-box-title">Types de matériel</h4>
													</div>
													<div class="col-auto">
														<div class="col-indigo info-icon">
															<i class="fa fa-book pull-left card-icon font-30"></i>
														</div>
													</div>
												</div>
												<h1 class="mt-1 mb-3 info-box-title">103</h1>
												<div class="mb-0">
													<span class="text-danger m-r-10"><i
															class="material-icons col-red align-middle">trending_down</i>
														
													</span>
													
												</div>
											</div>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="card">
											<div class="card-body">
												<div class="row">
													<div class="col mt-0">
														<h4 class="info-box-title">Emprunts</h4>
													</div>
													<div class="col-auto">
														<div class="col-teal info-icon">
															<i class="fa fa-user pull-left card-icon font-30"></i>
														</div>
													</div>
												</div>
												<h1 class="mt-1 mb-3 info-box-title">33</h1>
												<div class="mb-0">
													<span class="text-success m-r-10"><i
															class="material-icons col-green align-middle">trending_up</i>
														
													</span>
													
												</div>
											</div>
										</div>
										<div class="card">
											<div class="card-body">
												<div class="row">
													<div class="col mt-0">
														<h4 class="info-box-title">Retours</h4>
													</div>
													<div class="col-auto">
														<div class="col-pink info-icon">
															<i class="fa fa-coffee pull-left card-icon font-30"></i>
														</div>
													</div>
												</div>
												<h1 class="mt-1 mb-3 info-box-title">22</h1>
												<div class="mb-0">
													<span class="text-danger m-r-10"><i
															class="material-icons col-red align-middle">trending_down</i>
														
													</span>
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-7">
							<div class="card card-box">
								<div class="card-head">
									<header>Evolution des emprunts en fonction des années</header>
									<div class="tools">
										<a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
										<a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
										<a class="t-close btn-color fa fa-times" href="javascript:;"></a>
									</div>
								</div>
								<div class="card-body no-padding height-9">
									<div class="row">
										<canvas id="bar-chart" height="300"></canvas>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- end widget -->
					<!-- chart start -->
					<div class="row clearfix">
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-6">
							<div class="card">
								<div class="card-head">
									<header>Tendance des prêts au fil des années</header>
									<div class="tools">
										<a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
										<a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
										<a class="t-close btn-color fa fa-times" href="javascript:;"></a>
									</div>
								</div>
								<div class="card-body">
									<div class="recent-report__chart">
										<div id="chart1"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-6">
							<div class="card">
								<div class="card-head">
									<header>Types de matériels en vigueur</header>
									<div class="tools">
										<a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
										<a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
										<a class="t-close btn-color fa fa-times" href="javascript:;"></a>
									</div>
								</div>
								<div class="card-body">
									<div class="recent-report__chart">
										<div id="chart2"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- Chart end -->
					
					<div class="row">
						<div class="col-lg-8 col-md-12 col-sm-12 col-12">
							<div class="card card-box">
								<div class="card-head">
									<header>Assign Task</header>
									<div class="tools">
										<a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
										<a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
										<a class="t-close btn-color fa fa-times" href="javascript:;"></a>
									</div>
								</div>
								<div class="card-body ">
									<div class="table-wrap">
										<div class="table-responsive">
											<table class="table display product-overview mb-30" id="support_table">
												<thead>
													<tr>
														<th>#</th>
														<th>Task</th>
														<th>Assigned Professors</th>
														<th>status</th>
														<th>Progress</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>1</td>
														<td>Preparation for cricket team</td>
														<td>Kenny Josh</td>
														<td>
															<span class="label label-sm label-success">Done</span>
														</td>
														<td>
															<div class="progress">
																<div class="progress-bar progress-bar-success progress-bar-striped active"
																	role="progressbar" aria-valuenow="90"
																	aria-valuemin="0" aria-valuemax="100"
																	style="width:100%;"> <span class="sr-only">100%
																		Complete</span> </div>
															</div>
														</td>
													</tr>
													<tr>
														<td>2</td>
														<td>Annual function preparation</td>
														<td> Mark</td>
														<td>
															<span class="label label-sm label-warning"> Pending </span>
														</td>
														<td>
															<div class="progress">
																<div class="progress-bar progress-bar-warning progress-bar-striped active"
																	role="progressbar" aria-valuenow="90"
																	aria-valuemin="0" aria-valuemax="100"
																	style="width:70%;"> <span class="sr-only">70%
																		Complete</span> </div>
															</div>
														</td>
													</tr>
													<tr>
														<td>4</td>
														<td>Final year exam paper set</td>
														<td>Felix </td>
														<td>
															<span class="label label-sm label-danger">Suspended</span>
														</td>
														<td>
															<div class="progress">
																<div class="progress-bar progress-bar-danger progress-bar-striped active"
																	role="progressbar" aria-valuenow="90"
																	aria-valuemin="0" aria-valuemax="100"
																	style="width:50%;"> <span class="sr-only">50%
																		Complete</span> </div>
															</div>
														</td>
													</tr>
													<tr>
														<td>5</td>
														<td>Placement report</td>
														<td>Beryl</td>
														<td>
															<span class="label label-sm label-success ">Done</span>
														</td>
														<td>
															<div class="progress">
																<div class="progress-bar progress-bar-success progress-bar-striped active"
																	role="progressbar" aria-valuenow="100"
																	aria-valuemin="0" aria-valuemax="100"
																	style="width:100%;"> <span class="sr-only">100%
																		Complete</span> </div>
															</div>
														</td>
													</tr>
													<tr>
														<td>6</td>
														<td>Fees collection report</td>
														<td>Jayesh</td>
														<td>
															<span class="label label-sm label-success ">Done</span>
														</td>
														<td>
															<div class="progress">
																<div class="progress-bar progress-bar-success progress-bar-striped active"
																	role="progressbar" aria-valuenow="90"
																	aria-valuemin="0" aria-valuemax="100"
																	style="width:100%;"> <span class="sr-only">100%
																		Complete</span> </div>
															</div>
														</td>
													</tr>
													<tr>
														<td>7</td>
														<td>Library book status</td>
														<td>Sharma</td>
														<td>
															<span class="label label-sm label-danger">Suspended</span>
														</td>
														<td>
															<div class="progress">
																<div class="progress-bar progress-bar-danger progress-bar-striped active"
																	role="progressbar" aria-valuenow="90"
																	aria-valuemin="0" aria-valuemax="100"
																	style="width:20%;"> <span class="sr-only">20%
																		Complete</span> </div>
															</div>
														</td>
													</tr>
													<tr>
														<td>8</td>
														<td>Exam Paper set</td>
														<td>John Deo</td>
														<td>
															<span class="label label-sm label-warning"> Pending </span>
														</td>
														<td>
															<div class="progress">
																<div class="progress-bar progress-bar-warning progress-bar-striped active"
																	role="progressbar" aria-valuenow="90"
																	aria-valuemin="0" aria-valuemax="100"
																	style="width:80%;"> <span class="sr-only">80%
																		Complete</span> </div>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-12 col-sm-12 col-12">
							<div class="card  card-box">
								<div class="card-head">
									<header>Notifications</header>
									<div class="tools">
										<a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
										<a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
										<a class="t-close btn-color fa fa-times" href="javascript:;"></a>
									</div>
								</div>
								<div class="card-body no-padding height-9">
									<div class="row">
										<div class="noti-information notification-menu">
											<div class="notification-list mail-list not-list small-slimscroll-style">
												<a href="javascript:;" class="single-mail"> <span
														class="icon bg-primary"> <i class="fa fa-user-o"></i>
													</span> <span class="text-purple">Abhay Jani</span> Added you as
													friend
													<span class="notificationtime">
														<small>Just Now</small>
													</span>
												</a>
												<a href="javascript:;" class="single-mail"> <span
														class="icon blue-bgcolor"> <i class="fa fa-envelope-o"></i>
													</span> <span class="text-purple">John Doe</span> send you a mail
													<span class="notificationtime">
														<small>Just Now</small>
													</span>
												</a>
												<a href="javascript:;" class="single-mail"> <span
														class="icon bg-success"> <i class="fa fa-check-square-o"></i>
													</span> Success Message
													<span class="notificationtime">
														<small> 2 Days Ago</small>
													</span>
												</a>
												<a href="javascript:;" class="single-mail"> <span
														class="icon bg-warning"> <i class="fa fa-warning"></i>
													</span> <strong>Database Overloaded Warning!</strong>
													<span class="notificationtime">
														<small>1 Week Ago</small>
													</span>
												</a>
												<a href="javascript:;" class="single-mail"> <span
														class="icon bg-primary"> <i class="fa fa-user-o"></i>
													</span> <span class="text-purple">Abhay Jani</span> Added you as
													friend
													<span class="notificationtime">
														<small>Just Now</small>
													</span>
												</a>
												<a href="javascript:;" class="single-mail"> <span
														class="icon blue-bgcolor"> <i class="fa fa-envelope-o"></i>
													</span> <span class="text-purple">John Doe</span> send you a mail
													<span class="notificationtime">
														<small>Just Now</small>
													</span>
												</a>
												<a href="javascript:;" class="single-mail"> <span
														class="icon bg-success"> <i class="fa fa-check-square-o"></i>
													</span> Success Message
													<span class="notificationtime">
														<small> 2 Days Ago</small>
													</span>
												</a>
												<a href="javascript:;" class="single-mail"> <span
														class="icon bg-warning"> <i class="fa fa-warning"></i>
													</span> <strong>Database Overloaded Warning!</strong>
													<span class="notificationtime">
														<small>1 Week Ago</small>
													</span>
												</a>
												<a href="javascript:;" class="single-mail"> <span
														class="icon bg-danger"> <i class="fa fa-times"></i>
													</span> <strong>Server Error!</strong>
													<span class="notificationtime">
														<small>10 Days Ago</small>
													</span>
												</a>
											</div>
											<div class="full-width text-center p-t-10">
												<button type="button"
													class="btn purple btn-outline btn-circle margin-0">View All</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- start new student list -->
					<div class="row">
						<div class="col-md-12 col-sm-12">
							<div class="card  card-box">
								<div class="card-head">
									<header>New Student List</header>
									<div class="tools">
										<a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
										<a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
										<a class="t-close btn-color fa fa-times" href="javascript:;"></a>
									</div>
								</div>
								<div class="card-body ">
									<div class="table-wrap">
										<div class="table-responsive">
											<table class="table display product-overview mb-30">
												<thead>
													<tr>
														<th>No</th>
														<th>Name</th>
														<th>Assigned Professor</th>
														<th>Date Of Admit</th>
														<th>Fees</th>
														<th>Branch</th>
														<th>Edit</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>1</td>
														<td>Jens Brincker</td>
														<td>Kenny Josh</td>
														<td>27/05/2016</td>
														<td>
															<span class="label label-sm label-success">paid</span>
														</td>
														<td>Mechanical</td>
														<td>
															<a href="javascript:void(0)" class="tblEditBtn">
																<i class="fa fa-pencil"></i>
															</a>
															<a href="javascript:void(0)" class="tblDelBtn">
																<i class="fa fa-trash-o"></i>
															</a>
														</td>
													</tr>
													<tr>
														<td>2</td>
														<td>Mark Hay</td>
														<td> Mark</td>
														<td>26/05/2017</td>
														<td>
															<span class="label label-sm label-warning">unpaid </span>
														</td>
														<td>Science</td>
														<td>
															<a href="javascript:void(0)" class="tblEditBtn">
																<i class="fa fa-pencil"></i>
															</a>
															<a href="javascript:void(0)" class="tblDelBtn">
																<i class="fa fa-trash-o"></i>
															</a>
														</td>
													</tr>
													<tr>
														<td>3</td>
														<td>Anthony Davie</td>
														<td>Cinnabar</td>
														<td>21/05/2016</td>
														<td>
															<span class="label label-sm label-success ">paid</span>
														</td>
														<td>Commerce</td>
														<td>
															<a href="javascript:void(0)" class="tblEditBtn">
																<i class="fa fa-pencil"></i>
															</a>
															<a href="javascript:void(0)" class="tblDelBtn">
																<i class="fa fa-trash-o"></i>
															</a>
														</td>
													</tr>
													<tr>
														<td>4</td>
														<td>David Perry</td>
														<td>Felix </td>
														<td>20/04/2016</td>
														<td>
															<span class="label label-sm label-danger">unpaid</span>
														</td>
														<td>Mechanical</td>
														<td>
															<a href="javascript:void(0)" class="tblEditBtn">
																<i class="fa fa-pencil"></i>
															</a>
															<a href="javascript:void(0)" class="tblDelBtn">
																<i class="fa fa-trash-o"></i>
															</a>
														</td>
													</tr>
													<tr>
														<td>5</td>
														<td>Anthony Davie</td>
														<td>Beryl</td>
														<td>24/05/2016</td>
														<td>
															<span class="label label-sm label-success ">paid</span>
														</td>
														<td>M.B.A.</td>
														<td>
															<a href="javascript:void(0)" class="tblEditBtn">
																<i class="fa fa-pencil"></i>
															</a>
															<a href="javascript:void(0)" class="tblDelBtn">
																<i class="fa fa-trash-o"></i>
															</a>
														</td>
													</tr>
													<tr>
														<td>6</td>
														<td>Alan Gilchrist</td>
														<td>Joshep</td>
														<td>22/05/2016</td>
														<td>
															<span class="label label-sm label-warning ">unpaid</span>
														</td>
														<td>Science</td>
														<td>
															<a href="javascript:void(0)" class="tblEditBtn">
																<i class="fa fa-pencil"></i>
															</a>
															<a href="javascript:void(0)" class="tblDelBtn">
																<i class="fa fa-trash-o"></i>
															</a>
														</td>
													</tr>
													<tr>
														<td>7</td>
														<td>Mark Hay</td>
														<td>Jayesh</td>
														<td>18/06/2016</td>
														<td>
															<span class="label label-sm label-success ">paid</span>
														</td>
														<td>Commerce</td>
														<td>
															<a href="javascript:void(0)" class="tblEditBtn">
																<i class="fa fa-pencil"></i>
															</a>
															<a href="javascript:void(0)" class="tblDelBtn">
																<i class="fa fa-trash-o"></i>
															</a>
														</td>
													</tr>
													<tr>
														<td>8</td>
														<td>Sue Woodger</td>
														<td>Sharma</td>
														<td>17/05/2016</td>
														<td>
															<span class="label label-sm label-danger">unpaid</span>
														</td>
														<td>Mechanical</td>
														<td>
															<a href="javascript:void(0)" class="tblEditBtn">
																<i class="fa fa-pencil"></i>
															</a>
															<a href="javascript:void(0)" class="tblDelBtn">
																<i class="fa fa-trash-o"></i>
															</a>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- end new student list -->
				</div>
			</div>


       
       
        <!-- end new student list -->
        
      </div>
      <appfooter />
    </div>
  </template>
  
<script>
  import alerte from "../alerte/listeAlerteComponent.vue";
  import bus from "../../eventBus.js";
  import axios from "@/axios";
  import Form from "vform";
  
  import appheader from "../layout/header.vue";
  import appfooter from "../layout/footer.vue";
  import appnavbar from "../layout/navbar.vue";
  import appafterContent from "../layout/afterContent.vue";
  import permissionMixin from "@/permission";
  
  import { mapState, mapActions } from "vuex";
  
  export default {
    components: {
      appnavbar,
      appfooter,
      appheader,
      appafterContent,
      alerte,
    },
    data() {
      return {
        form: new Form({
          nom: "",
          prenom: "",
          genre: "",
          adresse: "",
          telephone: "",
          email: "",
          date_naissance: "",
          lieu_naissance: "",
          nationalite: "",
          id_role: "",
          id_specialite: "",
          id_departement: "",
          id_service: "",
          type: "",
          situation_matrimoniale: "",
        }),
        utilisateurs: [],
        paiements: [],
        roles: [],
        audits: [],
        inscriptions: [],
        countDep: null,
        countElev: null,
        countDirection: null,
        countFiliere: null,
      };
    },
    computed: {
      token() {
        return this.$store.state.isLoggedIn;
      },
      userNom() {
        return this.$store.state.userNom;
      },
      userPrenom() {
        return this.$store.state.userPrenom;
      },
      userIdrole() {
        return this.$store.state.userIdrole;
      },
      userPhoto() {
        return this.$store.state.userPhoto;
      },
      userRole() {
        return this.$store.state.userRole;
      },
    },
    mounted() {
      this.fetchUtilisateurs();
      //this.get_audit();
      //this.get_departement();
      //this.get_eleve();
      //this.get_direction();
      //this.get_filiere();
      //this.get_paiement();
      //this.getInscription();
    },
    methods: {
      ...mapActions(["login", "logout"]),
      async get_paiement() {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        await axios
          .get("/paiement/get_last", { headers })
          .then((response) => {
            const allpaiement = response.data.paiement;
  
            const formattedPaiement = allpaiement.map((paie) => {
              return {
                id: paie.id,
                id_eleve: paie.eleve.user.id,
                matricule: paie.eleve.user.matricule,
                photo: paie.eleve.user.photo,
                eleve_prenom: paie.eleve.user.prenom,
                eleve_nom: paie.eleve.user.nom,
                date_naissance: paie.eleve.user.date_naissance,
                adresse: paie.eleve.user.adresse,
                matricule: paie.eleve.user.matricule,
  
                type_recouvrement: paie.type_recouvrement,
                mode_paiement: paie.mode_paiement,
                reference: paie.reference,
                classe: paie.eleve.inscription
                  .map((p) => p.classe.nom_classe)
                  .join(", "),
                niveau: paie.eleve.inscription
                  .map((p) => p.classe.niveau)
                  .join(", "),
                type_classe: paie.eleve.inscription
                  .map((p) => p.classe.type_classe)
                  .join(", "),
                type_formation: paie.eleve.inscription
                  .map((p) => p.classe.type_formation.intitule)
                  .join(", "),
                annee: paie.concerner
                  .map((p) => p.annee_academique.intitule)
                  .join(", "),
                mois: paie.concerner.map((p) => p.mois.intitule).join(", "),
                id_annee: paie.concerner
                  .map((p) => p.annee_academique.id)
                  .join(", "),
                id_mois: paie.concerner.map((p) => p.mois.id).join(", "),
                montant: paie.montant,
                editModal: true,
              };
            });
            this.paiements = formattedPaiement;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des paiements",
              "error"
            );
          });
      },
      formatDateTime(dateTime) {
        return this.formatDate(new Date(dateTime));
      },
      formatDate(date) {
        const day = date.getDate();
        const monthNumber = date.getMonth() + 1;
        const year = date.getFullYear();
  
        const monthAbbreviations = [
          "Jan",
          "Fév",
          "Mar",
          "Avr",
          "Mai",
          "Juin",
          "Juil",
          "Aoû",
          "Sep",
          "Oct",
          "Nov",
          "Déc",
        ];
  
        const month = monthAbbreviations[monthNumber - 1];
  
        return `${day} ${month} ${year}`;
      },
      formatTime(timerecup) {
        return this.getTime(new Date(timerecup));
      },
      getTime(date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
  
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  
        return `${formattedHours}:${formattedMinutes}`;
      },
      getImageUrl(url) {
        return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
      },
      async fetchUtilisateurs() {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        await axios
          .get("/user/index", { headers })
          .then((response) => {
            this.utilisateurs = response.data.user.slice(0, 7);
          })
          .catch((error) => {
            console.error(
              "Erreur lors de la récupération des utilisateurs",
              error
            );
          });
      },
      
      countRoleOccurrences() {
        const roleOccurrences = {};
  
        this.utilisateurs.forEach((user) => {
          const roleIntitule = user.role ? user.role.intitule : "Non défini";
  
          if (!roleOccurrences[roleIntitule]) {
            roleOccurrences[roleIntitule] = 1;
          } else {
            roleOccurrences[roleIntitule]++;
          }
        });
  
        return roleOccurrences;
      },
    },
  };
  </script>
  