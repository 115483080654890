<template>
  <div class="page-wrapper">
    <!-- En-tête et navigation -->
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <!-- Contenu principal -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Gestion des Dispatchings par Commande</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="/">Accueil</a>&nbsp;<i class="fa fa-angle-right"></i></li>
                <li class="active">Dispatching</li>
              </ol>
            </div>
          </div>

          <!-- Détails de la commande -->
          <div class="row">
            <div class="col-md-12">
              <div class="card card-box">
                <div class="card-head">
                  <header>Détails de la Commande N°{{ commande?.id }}</header>
                </div>
                <div class="card-body">
                  <!-- Informations de la commande -->
                  <div class="commande-info" v-if="commande">
                    <div class="row">
                      <div class="col-md-4">
                        <p><strong>Référence:</strong> {{ commande.reference_commande }}</p>
                      </div>
                      <div class="col-md-4">
                        <p><strong>Date:</strong> {{ formatDate(commande.date_commande) }}</p>
                      </div>
                      <div class="col-md-4">
                        <p><strong>Statut:</strong> {{ commande.statut }}</p>
                      </div>
                    </div>
                  </div>

                  <!-- Liste des demandes -->
                  <div class="demandes-list" v-if="demandes && demandes.length">
                    <div v-for="demande in demandes" :key="demande.id" class="demande-card mt-4">
                      <div class="portlet box blue">
                        <div class="portlet-title">
                          <div class="caption">
                            <i class="fa fa-gift"></i>Demande #{{ demande.id }}
                          </div>
                        </div>
                        <div class="portlet-body">
                          <div class="row mb-3">
                            <div class="col-md-6">
                              <p><strong>Demandeur:</strong> {{ demande.user?.name }}</p>
                            </div>
                            <div class="col-md-6">
                              <p><strong>Département:</strong> {{ demande.user?.departement?.nom }}</p>
                            </div>
                          </div>
                          
                          <!-- Matériels à dispatcher -->
                          <div v-if="demande.demandeMateriels && demande.demandeMateriels.length" class="materiels-section">
                            <h4 class="mt-4">Matériels à dispatcher</h4>
                            <div class="table-responsive">
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Matériel</th>
                                    <th>Stock disponible</th>
                                    <th>Quantité demandée</th>
                                    <th>Quantité à commander</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="materiel in demande.demandeMateriels" :key="materiel.id">
                                    <td>{{ materiel.stockMateriel?.materiel?.libelle }}</td>
                                    <td>{{ materiel.stockMateriel?.quantite || 0 }}</td>
                                    <td>{{ materiel.quantite }}</td>
                                    <td>{{ materiel.quantite_a_commander }}</td>
                                    <td>
                                      <button class="btn btn-primary btn-sm" @click="showDispatchModal(materiel, 'materiel', demande)">
                                        <i class="fa fa-share"></i> Dispatcher
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          
                          <!-- Consommables à dispatcher -->
                          <div v-if="demande.demandeConsommables && demande.demandeConsommables.length" class="consommables-section">
                            <h4 class="mt-4">Consommables à dispatcher</h4>
                            <div class="table-responsive">
                              <table class="table table-striped">
                                <thead>
                                  <tr>
                                    <th>Consommable</th>
                                    <th>Stock disponible</th>
                                    <th>Quantité demandée</th>
                                    <th>Quantité à commander</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="consommable in demande.demandeConsommables" :key="consommable.id">
                                    <td>{{ consommable.stockConsommable?.consommable?.libelle }}</td>
                                    <td>{{ consommable.stockConsommable?.quantite || 0 }}</td>
                                    <td>{{ consommable.quantite }}</td>
                                    <td>{{ consommable.quantite_a_commander }}</td>
                                    <td>
                                      <button class="btn btn-primary btn-sm" @click="showDispatchModal(consommable, 'consommable', demande)">
                                        <i class="fa fa-share"></i> Dispatcher
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <appfooter />
    </div>

    <!-- Modal de dispatching -->
    <div class="modal" v-if="isDispatchModalVisible">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Dispatcher l'article</h4>
          <span class="close" @click="closeDispatchModal">&times;</span>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>Bâtiment</label>
            <select v-model="selectedBatiment" class="form-control" @change="fetchSalles">
              <option value="">Sélectionner un bâtiment</option>
              <option v-for="batiment in batiments" :key="batiment.id" :value="batiment.id">
                {{ batiment.intitule }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Salle</label>
            <select v-model="selectedSalle" class="form-control">
              <option value="">Sélectionner une salle</option>
              <option v-for="salle in salles" :key="salle.id" :value="salle.id">
                {{ salle.intitule }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Quantité</label>
            <input type="number" v-model="quantiteADispatcher" class="form-control" min="1" :max="selectedItem?.quantite">
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="closeDispatchModal">Annuler</button>
          <button class="btn btn-primary" @click="dispatchItem" :disabled="!isDispatchValid">
            Dispatcher
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Swal from 'sweetalert2';
import "moment/locale/fr";
import appheader from "@/components/layout/header.vue";
import appfooter from "@/components/layout/footer.vue";
import appnavbar from "@/components/layout/navbar.vue";

export default {
  name: 'DispatchingDetailsComponent',
  components: {
    appheader,
    appfooter,
    appnavbar
  },
  
  data() {
    return {
      commande: null,
      demandes: [],
      batiments: [],
      salles: [],
      isDispatchModalVisible: false,
      selectedItem: null,
      selectedItemType: null,
      selectedDemande: null,
      selectedBatiment: "",
      selectedSalle: "",
      quantiteADispatcher: 0,
      error: null
    };
  },

  computed: {
    token() {
      return localStorage.getItem("token");
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
    isDispatchValid() {
      return this.selectedBatiment && this.selectedSalle && this.quantiteADispatcher > 0;
    }
  },

  methods: {
    async loadCommandeDetails() {
      try {
        const id = this.$route.params.id;
        const response = await axios.get(`http://localhost:8000/api/dispatching/${id}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        
        if (response.data.statut === 200) {
          this.commande = response.data.commande;
          this.demandes = response.data.demandes;
          console.log('Détails chargés:', response.data);
        }
      } catch (error) {
        console.error("Erreur lors du chargement des détails:", error);
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: 'Impossible de charger les détails de la commande'
        });
      }
    },

    async fetchBatiments() {
      try {
        const response = await axios.get('http://localhost:8000/api/batiment/index', {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        this.batiments = response.data.batiment;
      } catch (error) {
        console.error("Erreur lors de la récupération des bâtiments:", error);
      }
    },

    async fetchSalles() {
      if (!this.selectedBatiment) {
        this.salles = [];
        this.selectedSalle = "";
        return;
      }
      try {
        const response = await axios.get('http://localhost:8000/api/salle/index', {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        this.salles = response.data.salle.filter(salle => salle.id_batiment == this.selectedBatiment);
      } catch (error) {
        console.error("Erreur lors de la récupération des salles:", error);
      }
    },

    showDispatchModal(item, type, demande) {
      this.selectedItem = item;
      this.selectedItemType = type;
      this.selectedDemande = demande;
      this.isDispatchModalVisible = true;
      this.fetchBatiments();
    },

    closeDispatchModal() {
      this.isDispatchModalVisible = false;
      this.selectedItem = null;
      this.selectedItemType = null;
      this.selectedDemande = null;
      this.selectedBatiment = "";
      this.selectedSalle = "";
      this.quantiteADispatcher = 0;
    },

    async dispatchItem() {
      try {
        const response = await axios.post(`http://localhost:8000/api/dispatching/${this.commande.id}/dispatcher`, {
          batiment_id: this.selectedBatiment,
          salle_id: this.selectedSalle,
          item_id: this.selectedItem.id,
          item_type: this.selectedItemType,
          demande_id: this.selectedDemande.id,
          quantite: this.quantiteADispatcher
        }, {
          headers: { Authorization: `Bearer ${this.token}` }
        });

        if (response.data.statut === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Succès',
            text: 'Dispatching effectué avec succès'
          });
          this.closeDispatchModal();
          await this.loadCommandeDetails();
        }
      } catch (error) {
        console.error("Erreur lors du dispatching:", error);
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: error.response?.data?.message || 'Une erreur est survenue lors du dispatching'
        });
      }
    },

    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    }
  },

  created() {
    this.loadCommandeDetails();
  }
};
</script>

<style scoped>
/* Styles généraux */
.page-wrapper {
  background: #f4f6f8;
  min-height: 100vh;
}

.page-content {
  padding: 20px;
  margin-top: 60px;
}

/* Styles de la carte */
.card-box {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12);
}

.card-head {
  padding: 15px;
  border-bottom: 1px solid #eef1f5;
}

.card-head header {
  font-size: 18px;
  font-weight: 600;
  color: #2b3643;
}

.card-body {
  padding: 20px;
}

/* Styles des tableaux */
.table {
  width: 100%;
  margin-bottom: 1rem;
}

.table th {
  background: #f5f5f5;
  font-weight: 600;
  padding: 12px;
}

.table td {
  padding: 12px;
  vertical-align: middle;
}

/* Styles des boutons */
.btn-primary {
  background-color: #337ab7;
  border-color: #2e6da4;
  color: white;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  color: white;
}

/* Styles du modal */
.modal {
  display: block;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}

.modal-content {
  position: relative;
  background-color: #fff;
  margin: 10% auto;
  padding: 20px;
  width: 50%;
  border-radius: 4px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.5);
}

.modal-header {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.modal-footer {
  border-top: 1px solid #e5e5e5;
  padding-top: 15px;
  margin-top: 15px;
  text-align: right;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .2;
  cursor: pointer;
}

/* Styles des formulaires */
.form-group {
  margin-bottom: 1rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

/* Utilitaires */
.mt-4 {
  margin-top: 1.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}
</style>
