<template>
    <div class="page-wrapper">
      <!-- start header -->
      <div class="page-header navbar navbar-fixed-top">
        <div class="page-header-inner">
          <appheader
            :userPhoto="userPhoto"
            :userNom="userNom"
            :userPrenom="userPrenom"
          />
        </div>
      </div>
      <div class="page-container">
        <appnavbar
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
          :userIdrole="userIdrole"
          :userRole="userRole"
        />
        <!-- start page content -->
        <div class="page-content-wrapper">
          <div class="page-content">
            <div class="page-bar">
              <div class="page-title-breadcrumb">
                <div class="pull-left">
                  <div class="page-title">Liste des Types de Matériel</div>
                </div>
                <ol class="breadcrumb page-breadcrumb pull-right">
                  <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.html">Home</a>&nbsp;<i class="fa fa-angle-right"></i></li>
                  <li class="active">Types de Matériel</li>
                </ol>
              </div>
            </div>
  
            <div class="card">
              <div class="card-header">
                <h4>Liste des Types de Matériel</h4>
              </div>
              <div class="card-body">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Intitulé</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(typeMateriel, index) in typesMateriel" :key="typeMateriel.id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ typeMateriel.intitule }}</td>
                      <td>
                        <button
                          class="btn btn-primary"
                          @click="editTypeMateriel(typeMateriel.id)"
                        >
                          Modifier
                        </button>
                        <button
                          class="btn btn-danger"
                          @click="deleteTypeMateriel(typeMateriel.id)"
                        >
                          Supprimer
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> <!-- end page content -->
      </div> <!-- end page container -->
    </div> <!-- end page wrapper -->
  </template>
  
  <script>
  import appheader from "../layout/header.vue";
  import appnavbar from "../layout/navbar.vue";
  import axios from "@/axios"; // Assurez-vous que le chemin est correct
  import Swal from 'sweetalert2';
  
  export default {
    name: "ListTypeMaterielComponent",
    components: {
      appheader,
      appnavbar,
    },
    data() {
      return {
        typesMateriel: [], // Stocker les types de matériel
        isLoading: false,
      };
    },
    computed: {
      token() {
        return localStorage.getItem("token");
      },
      userNom() {
        return this.$store.state.userNom;
      },
      userPrenom() {
        return this.$store.state.userPrenom;
      },
      userIdrole() {
        return this.$store.state.userIdrole;
      },
      userPhoto() {
        return this.$store.state.userPhoto;
      },
      userRole() {
        return this.$store.state.userRole;
      },
    },
    methods: {
      async fetchTypesMateriel() {
        this.isLoading = true;
        try {
          const response = await axios.get('http://localhost:8000/api/type_materiel', {
            headers: { Authorization: `Bearer ${this.token}` }
          });
          this.typesMateriel = response.data; // Assignez les données de l'API à la variable typesMateriel
        } catch (error) {
          console.error('Erreur lors de la récupération des types de matériel:', error);
        } finally {
          this.isLoading = false;
        }
      },
      editTypeMateriel(id) {
        this.$router.push({ path: `/type-materiel/edit/${id}` });
      },
      async deleteTypeMateriel(id) {
        const confirmation = await Swal.fire({
          title: 'Êtes-vous sûr ?',
          text: "Cette action est irréversible !",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, supprimer !'
        });
  
        if (confirmation.isConfirmed) {
          try {
            await axios.delete(`http://localhost:8000/api/type_materiel/${id}`, {
              headers: { Authorization: `Bearer ${this.token}` }
            });
            Swal.fire('Supprimé!', 'Le type de matériel a été supprimé.', 'success');
            this.fetchTypesMateriel(); // Rafraîchissez la liste après suppression
          } catch (error) {
            console.error('Erreur lors de la suppression du type de matériel:', error);
            Swal.fire('Erreur', 'Erreur lors de la suppression du type de matériel.', 'error');
          }
        }
      }
    },
    created() {
      this.fetchTypesMateriel(); // Charger les données au montage du composant
    }
  };
  </script>
  
  <style scoped>
  .table {
    width: 100%;
    margin: 20px 0;
  }
  .table th, .table td {
    text-align: left;
    padding: 10px;
  }
  </style>
  