<template>
    <div class="page-wrapper">
        <div class="page-header navbar navbar-fixed-top">
            <div class="page-header-inner">
                <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
            </div>
        </div>
        <div class="page-container">
            <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
                :userRole="userRole" />

            <!-- start page content -->
            <div class="page-content-wrapper">
                <div class="page-content">
                    <div class="page-bar">
                        <div class="page-title-breadcrumb">
                            <div class=" pull-left">
                                <div class="page-title">Nouvelle planification</div>
                            </div>
                            <ol class="breadcrumb page-breadcrumb pull-right">
                                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard'">Tableau
                                        de
                                        Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                                </li>
                                <li><a class="parent-item" :href="'/liste-planification'">Liste
                                        Planification</a>&nbsp;<i class="fa fa-angle-right"></i>
                                </li>
                                <li class="active">Nouvelle Planification &nbsp;<a class="parent-item"></a>&nbsp;<i
                                        class="fa fa-angle-right"></i> </li>

                            </ol>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card-box">
                                <div class="card-head">
                                    <header>Information</header>
                                    <button id="panel-button"
                                        class="mdl-button mdl-js-button mdl-button--icon pull-right"
                                        data-upgraded=",MaterialButton">
                                        <i class="material-icons">more_vert</i>
                                    </button>
                                    <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                                        data-mdl-for="panel-button">
                                        <li class="mdl-menu__item"><i class="material-icons">assistant_photo</i>Action
                                        </li>
                                        <li class="mdl-menu__item"><i class="material-icons">print</i>Another action
                                        </li>
                                        <li class="mdl-menu__item"><i class="material-icons">favorite</i>Something else
                                            here</li>
                                    </ul>
                                </div>
                                <div class="card-body row">
                                    <!-- start page content -->

                                    <div class="card-body row">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 col-6" v-show="!scheduleGenerated">
                                                <div class="btn-group">

                                                    <a id="addRow" class="btn btn-success" @click="showModal = true" type="button">
                                                        Generer via Système
                                                        <i class="fa fa-book text-white"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-6" v-show="scheduleGenerated">
                                                <div class="btn-group">

                                                    <button id="addRow" class="btn btn-danger" @click="retourChange()">
                                                        Retour
                                                        <i class="fa fa-book text-white"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card-body row"
                                            v-for="(planification, index) in form_planification.planifications"
                                            :key="index" v-show="!scheduleGenerated">

                                            <div class="header d-flex justify-content-end">
                                                <svg class="cursor-pointer"
                                                    @click.prevent="duplicatePlanification(index)"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                    height="24">
                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                    <path fill="green"
                                                        d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                                </svg>
                                                <svg v-show="form_planification.planifications.length > 1"
                                                    class="cursor-pointer ml-2"
                                                    @click.prevent="removePlanification(index)"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                    height="24">
                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                    <path fill="#EC4899"
                                                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                                </svg>
                                            </div>

                                            <div class="col-lg-12 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                                    <label for="categorySelect-{{ index }}" class="mdl-textfield__label"
                                                        v-show="!planification.id_cour">Choisissez
                                                        le cours</label>
                                                    <select class="mdl-textfield__input" :id="'categorySelect-' + index"
                                                        readonly tabIndex="-1" v-model="planification.id_cour"
                                                        @change="validatedata('id_cours')">
                                                        <option v-for="(cour, index) in cours" :value="cour.id"
                                                            :key="index">
                                                            {{ cour.matiere.intitule }}
                                                            {{ cour.formateur.user.nom }}
                                                            {{ cour.classe.nom_classe }}
                                                            {{ cour.semestre.intitule }}
                                                        </option>
                                                    </select>

                                                    <span class="erreur" v-if="planification.id_cours_erreur">{{
                                                        planification.id_cours_erreur
                                                    }}</span>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                                    <label for="list7-{{ index }}" class="mdl-textfield__label"
                                                        v-show="!planification.id_salle">
                                                        Choisissez la salle</label>
                                                    <select class="mdl-textfield__input" :id="'list7-' + index" readonly
                                                        tabIndex="-1" v-model="planification.id_salle"
                                                        @change="validatedata('id_salle')">
                                                        <option v-for="salle in salles" :value="salle.id"
                                                            :key="salle.id">
                                                            {{ salle.intitule }}
                                                        </option>
                                                    </select>
                                                    <span class="erreur" v-if="planification.id_salle_erreur">{{
                                                        planification.id_annee_academique_erreur
                                                    }}</span>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                                    <label for="list6-{{ index }}" class="mdl-textfield__label"
                                                        v-show="!planification.id_annee_academique">
                                                        Choisissez l'année</label>
                                                    <select class="mdl-textfield__input" :id="'list6-' + index" readonly
                                                        tabIndex="-1" v-model="planification.id_annee_academique"
                                                        @change="validatedata('id_annee_academique')">
                                                        <option v-for="annee_academique in annee_academiques"
                                                            :value="annee_academique.id" :key="annee_academique.id">
                                                            {{ annee_academique.intitule }}
                                                        </option>
                                                    </select>
                                                    <span class="erreur"
                                                        v-if="planification.id_annee_academique_erreur">{{
                                                            planification.id_annee_academique_erreur }}</span>
                                                </div>
                                            </div>


                                            <!-- Répétition -->
                                            <div class="col-lg-4 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label  getmdl-select getmdl-select__fix-height txt-full-width">
                                                    <label class="mdl-textfield__label" for="repetition">
                                                        Répétition
                                                    </label>
                                                    <select id="repetition" class="mdl-textfield__input"
                                                        v-model="planification.repetition" @change="updateEndDate">
                                                        <option value="">Sélectionner</option>
                                                        <option value="Une semaine">Une semaine</option>
                                                        <option value="Un mois">Un mois</option>
                                                        <option value="Une année">Une année</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <!-- Date Début -->
                                            <div class="col-lg-3 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label  getmdl-select getmdl-select__fix-height txt-full-width">
                                                    <label class="mdl-textfield__label" for="date_debut">
                                                        Date Début
                                                    </label>
                                                    <input type="date" class="mdl-textfield__input" id="date_debut"
                                                        placeholder="Date début" v-model="planification.date_debut"
                                                        @change="updateEndDate">
                                                    <span class="erreur" v-if="planification.date_debut_erreur">{{
                                                        planification.date_debut_erreur }}</span>
                                                </div>
                                            </div>

                                            <!-- Date Fin -->
                                            <div class="col-lg-3 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label  txt-full-width">
                                                    <label class="mdl-textfield__label" for="date_fin"
                                                        v-show="!planification.date_fin">
                                                        Date Fin
                                                    </label>
                                                    <input type="date" class="mdl-textfield__input" id="date_fin"
                                                        placeholder="Date fin"
                                                        :value="formatDateForInput(planification.date_fin)"
                                                        @input="handleDateFinInput" @change="handleDateFinChange" />
                                                </div>
                                            </div>

                                            <!--  <div class="col-lg-3 p-t-20">
                                        <div
                                            class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                            <label for="list3" class="mdl-textfield__label"
                                                v-show="!form.jour">Choisissez
                                                le jour</label>
                                            <select class="mdl-textfield__input" id="list3" readonly tabIndex="-1"
                                                v-model="form.jour" @change="validatedata('jour')">
                                                <option value="Lundi">Lundi</option>
                                                <option value="Mardi">Mardi</option>
                                                <option value="Mercredi">Mercredi</option>
                                                <option value="Jeudi">Jeudi</option>
                                                <option value="Vendredi">Vendredi</option>
                                                <option value="Samedi">Samedi</option>
                                            </select>
                                            <span class="erreur">{{ this.jour_erreur }}</span>
                                        </div> -->

                                            <!-- </div> -->
                                            <div class="col-lg-3 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                    <label class="mdl-textfield__label" for="starts-at-{{ index }}"
                                                        v-show="!planification.heure_debut">Heure
                                                        Début</label>
                                                    <input class="mdl-textfield__input" placeholder="Heure debut"
                                                        type="time" :id="'starts-at-' + index"
                                                        v-model="planification.heure_debut"
                                                        @input="validatedata('heure_debut')" />
                                                    <span class="erreur" v-if="planification.heure_debut_erreur">{{
                                                        planification.heure_debut_erreur
                                                    }}</span>
                                                </div>
                                            </div>

                                            <div class="col-lg-3 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                    <label class="mdl-textfield__label" for="starts-at-{{ index }}"
                                                        v-show="!planification.heure_fin">Heure
                                                        Fin</label>
                                                    <input class="mdl-textfield__input" placeholder="Heure Fin"
                                                        type="time" :id="'starts-at-' + index"
                                                        v-model="planification.heure_fin"
                                                        @input="validatedata('heure_fin')" />
                                                    <span class="erreur" v-if="planification.heure_fin_erreur">{{
                                                        planification.heure_fin_erreur }}</span>
                                                </div>
                                            </div>

                                        </div>



                                        <!-- Fenêtre modal pour générer un emploi du temps -->
                                        <div v-if="showModal" class="modal-overlay" @click="showModal = false">
                                            <div class="modal-wrapper" role="dialog" aria-labelledby="modalTitle" @click.stop>
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h3 class="modal-title" id="modalTitle">{{ modalTitle }}</h3>
                                                        <button type="button" class="btn-close" @click="showModal = false"
                                                            aria-label="Close">&times;</button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <!-- Étape 1 : Sélection de la classe -->
                                                        <div v-if="currentStep === 1">
                                                            <div class="form-group row">
                                                                <label class="col-sm-2 control-label">Classe:</label>
                                                                <div class="col-lg-9 col-md-8">
                                                                    <select id="classe" class="form-select"
                                                                        v-model="form.classe">
                                                                        <option value="">Sélectionner la classe</option>
                                                                        <option v-for="classe in classes"
                                                                            :key="classe.id" :value="classe.id">
                                                                            {{ classe.type_formation.intitule }} {{
                                                                                classe.nom_classe }} {{ classe.niveau }} {{
                                                                                classe.type_classe }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>


                                                            <div class="col-lg-12 p-t-20 text-center">
                                                                <button type="button"
                                                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                                                                    @click="nextStep">
                                                                    Suivant
                                                                </button>
                                                                <button type="button"
                                                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                                                                    data-bs-dismiss="modal">
                                                                    Annuler
                                                                </button>
                                                            </div>
                                                        </div>


                                                        <!-- Étape 2 et suivantes : Contenu répétitif -->
                                                        <div v-else>
                                                            <div class="form-group row">
                                                                <label class="col-sm-2 control-label">Cours:</label>
                                                                <div class="col-lg-9 col-md-8">
                                                                    <select id="cour" class="form-select"
                                                                        v-model="form.id_cour">
                                                                        <option value="">Sélectionner le cours</option>
                                                                        <option v-for="cour in cours" :key="cour.id"
                                                                            :value="cour.id"
                                                                            :disabled="isCourseSelected(cour.id)">
                                                                            {{ cour.matiere.intitule }} {{
                                                                                cour.formateur.user.nom }} {{
                                                                                cour.classe.nom_classe }} {{
                                                                                cour.semestre.intitule }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div class="form-group row">
                                                                <label class="col-sm-2 control-label">Heure
                                                                    Total:</label>
                                                                <div class="col-lg-9 col-md-8">
                                                                    <input
                                                                        placeholder="Quel est le nombre total d'heure de la planification ?"
                                                                        type="number" v-model="form.totalHours"
                                                                        class="form-control" @change="updateFields">
                                                                </div>
                                                            </div>

                                                            <div class="form-group row">
                                                                <label class="col-sm-2 control-label">Date à partir
                                                                    de:</label>
                                                                <div class="col-lg-9 col-md-8">
                                                                    <input
                                                                        placeholder="À partir de quelle date voulez-vous commencer le module ?"
                                                                        type="date" v-model="form.date_debut"
                                                                        @change="updateFields" class="form-control">
                                                                </div>
                                                            </div>

                                                            <div class="form-group row">
                                                                <label class="col-sm-2 control-label">Durée des
                                                                    Séances:</label>
                                                                <div class="col-lg-9 col-md-8">
                                                                    <input type="number"
                                                                        v-model="form.durationPerSession"
                                                                        placeholder="Combien de temps voulez-vous que vos séances durent ?"
                                                                        class="form-control" @change="updateFields">
                                                                </div>
                                                            </div>

                                                            <div class="form-group row">
                                                                <label class="col-sm-2 control-label">Décalage des
                                                                    Séances:</label>
                                                                <div class="col-lg-9 col-md-8">
                                                                    <input type="number" v-model="form.offset"
                                                                        placeholder="Vos séances doivent se séparer de combien de jours ?"
                                                                        class="form-control" @change="updateFields">
                                                                </div>
                                                            </div>

                                                            <div class="form-group row">
                                                                <label class="col-sm-2 control-label">Nombre de
                                                                    semaine:</label>
                                                                <div class="col-lg-9 col-md-8">
                                                                    <input type="number" v-model="form.numberOfWeeks"
                                                                        placeholder="Sur combien de semaines voulez-vous que l'emploi du temps dure ?"
                                                                        class="form-control" readonly>
                                                                </div>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-sm-2 control-label">Période:</label>
                                                                <div class="col-lg-9 col-md-8">
                                                                    <select class="form-select"
                                                                        v-model="form.courseTimes" :disabled="true">
                                                                        <optgroup label="Moment">
                                                                            <option value="morning" :disabled="isCS">
                                                                                Matinée</option>
                                                                            <option value="evening"
                                                                                :disabled="isFPJorCJ">Soirée</option>
                                                                        </optgroup>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 p-t-20 text-center">
                                                                <button v-if="currentStep > 1" type="button"
                                                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                                                                    @click="previousStep">
                                                                    Précédent
                                                                </button>
                                                                <button type="button"
                                                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                                                                    @click="nextStep">
                                                                    Suivant
                                                                </button>
                                                                <button v-if="currentStep > 1" type="button"
                                                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-success"
                                                                    @click="generateAndSaveSchedule">
                                                                    <!-- <div class="card-body" v-if="scheduleGenerated">
                                                                        <div class="panel-body">
                                                                            <div class="has-toolbar">
                                                                                <FullCalendar ref="fullCalendar"
                                                                                    :options="calendarOptions" />
                                                                            </div>
                                                                        </div>
                                                                    </div> -->
                                                                    Enregistrer
                                                                </button>

                                                                <button type="button"
                                                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                                                                    data-bs-dismiss="modal">
                                                                    Annuler
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- End fenetre modal pour générer un emploi du temps -->


                                    </div>

                                    <div class="col-lg-12 p-t-20 text-center" v-show="!scheduleGenerated">
                                        <button type="submit" v-if="!this.editModal"
                                            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                                            @click.prevent="validerAvantAjout()">
                                            Enregistrer
                                        </button>
                                        <button type="submit" v-if="this.editModal"
                                            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                                            @click.prevent="validerAvantAjout()">
                                            Modifier
                                        </button>
                                        <button type="button"
                                            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                                            @click="resetForm">
                                            Annuler
                                        </button>


                                    </div>

                                    <!-- <full-calendar :events="events"></full-calendar> -->
                                    <!-- Main calendar display with hours column -->
                                    <div class="card-body" v-if="scheduleGenerated">
                                        <div class="panel-body">
                                            <div class="has-toolbar">
                                                <FullCalendar   v-if="selectedClasse === form.classe"  ref="fullCalendar" :options="calendarOptions" />
                                                <!--  ref="fullCalendar" :options="calendarOptions"    -->
                                            </div>
                                        </div>
                                    </div>

                                    <button v-show="scheduleGenerated" @click="saveSchedule">Sauvegarder</button>

                                   

                                    <appafterContent />
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>

            <appfooter />
        </div>
    </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
/* import he from 'public/assets/plugins/fullcalendar/packages/core/locales/he'; */
import Form from "vform";
import { mapState, mapActions } from "vuex";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
//import interactionPlugin from '@fullcalendar/interaction';

import FullCalendar from '@fullcalendar/vue3';
import interactionPlugin from '@fullcalendar/interaction';
import dayGrid from '@fullcalendar/daygrid';
import timeGrid from '@fullcalendar/timegrid';

export default {
    components: {
        appnavbar,
        appfooter,
        appheader,
        appafterContent,
        flatPickr,
        FullCalendar
    },
    mixins: [permissionMixin],
    name: "planifierEmploiDuTempsCompenent",
    data() {
        return {
            showModal: false,
            currentStep: 1,
            selectedClassId: null,
            selectedClassName: '',
            modalTitle: 'Planifier emploi du temps', // Titre par défaut
            /*  form_repet: {
                 date_debut: '',
                 repetition: '',
                 date_fin: ''
             }, */
            calendarOptions: {
                plugins: [dayGrid, interactionPlugin, timeGrid],
                initialView: 'timeGridWeek',
                locale: 'fr',
                headerToolbar: {
                    left: 'prev,next,today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                },
                height: "auto",
                selectable: true,
                allDaySlot: false,
                slotMinTime: '08:00:00',
                slotMaxTime: '22:00:00',
                slotLabelInterval: '02:00',
                events: [],
                /* eventsSet: this.handleEventsSet, */
                eventContent: function (info) {
                    let professeur = info.event.extendedProps.professeur || 'Pas de professeur';
                    let salle = info.event.extendedProps.salle || 'Pas de salle';
                    let classe = info.event.extendedProps.classe || 'Pas de classe';
                    return {
                        html: `<div>
                     <strong>${info.event.title} ${classe}</strong><br>
                     <span>${professeur}</span><br>
                     <span>${salle}</span>
                   </div>`
                    };
                },

            },
            selectedClasse: null,
            salles: [],
            form: {
                totalHours: '',
                classe: '',
                durationPerSession: '',
                date_debut: '',
                offset: '',
                numberOfWeeks: '',
                courseTimes: '',
                periode: '',
                id_salle: '',
                id_cour: "",

            },
            selectedCourses: [],// Stocker id des cours 
            courseParameters: [],// Tableau pour stocker les paramètres de chaque cours

            events: [],
            scheduleData: [],
            selectedRoom: null,
            scheduleGenerated: false,
            entries: [],
            modalsData: [],
            formRecords: [], // Pour stocker tous les enregistrements des étapes
            loading: false,
            errors: [],



            form_planification: new Form({
                planifications: [
                    {

                        id_cour: "",
                        repetition: "",
                        id_salle: "",
                        id_annee_academique: "",
                        date_debut: "",
                        date_fin: "",
                        heure_debut: "",
                        heure_fin: "",
                        uniqueKey: Date.now() + Math.random(),
                        id_annee_academique_erreur: "",

                        id_salle_erreur: "",
                        id_cours_erreur: "",
                        heure_debut_erreur: "",
                        heure_fin_erreur: "",
                        date_debut_erreur: "",
                        date_fin_erreur: "",
                        // Tableau pour stocker les données de chaque modal
                    },
                ],
            }),
            cours: [],
            classes: [],
            annee_academiques: [],
            courseDetails: {},  // Détails du cours actuellement édité




            etatForm: false,
            editModal: false,
        };
    },

    computed: {
        isCS() {
            const classe = this.classes.find(c => c.id === this.form.classe);
            return classe && classe.type_classe.toUpperCase().includes('CS');
        },
        isFPJorCJ() {
            const classe = this.classes.find(c => c.id === this.form.classe);
            return classe && (
                classe.type_classe.toUpperCase().includes('FPJ') ||
                classe.type_classe.toUpperCase().includes('CJ')
            );
        },
        //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
        token() {
            return this.$store.state.isLoggedIn;
        },
        userNom() {
            return this.$store.state.userNom;
        },
        userPrenom() {
            return this.$store.state.userPrenom;
        },
        userIdrole() {
            return this.$store.state.userIdrole;
        },
        userPhoto() {
            return this.$store.state.userPhoto;
        },
        userRole() {
            return this.$store.state.userRole;
        },
        modalTitle() {
            if (this.currentStep === 1) {
                return 'Planifier emploi du temps';
            } else {
                return `Planifier emploi du temps pour ${this.selectedClassName}`;

            }

        }
    },
    beforeMount() {

        this.$data.calendarOptions.events = this.events;

    },

    async created() {

        // Dans la méthode created ou mounted
        console.log("Initial Course Times:", this.form.courseTimes); // Pour vérifier la valeur initiale

        console.log('Initial form data:', this.form);



        await this.get_permission();
        this.get_cours();
        this.get_classe();
        this.get_salle();
        this.get_annee_academique();
        this.initializeSelect2();

        // Debugging outputs
        console.log('Initial form data:', this.form);

        // Listen for changes to the select2 element
        $(this.$refs.select).select2().on('change', (event) => {
            console.log('Selected class ID:', event.target.value);
            this.get_cours_by_id_classe(event.target.value);
        });

        bus.on("paiementModifier", (eventData) => {
            this.editModal = eventData.editModal;
            // this.monterToupdate(eventData.paiement);
        });
    },


    watch: {

        'form.totalHours'() { this.updateFields(); },
        'form.durationPerSession'() { this.updateFields(); },
        'form.offset'() { this.updateFields(); },
        /* 'form.courseTimes'(newValue) {
            console.log('Course Times Changed:', newValue);
        }, */
        /*   events(newEvents) {
              this.calendarOptions.events = newEvents;
              this.$nextTick(() => {
                  this.focusOnFirstEvent();
              });
          }, */

        /*  'planification.date_debut': 'calculateEndDate',
         'planification.repetition': 'calculateEndDate', */
        // Other watchers...
        'form.selectedCourses'(newVal) {
            $('#multiple').val(newVal).trigger('change');
        },

        events(newEvents) {
            this.calendarOptions.events = newEvents; // update calendar options when events change
        },
        'form.classe'(newVal) {
            console.log('Valeur sélectionnée:', newVal);
            if (newVal) {
                this.get_cours_by_id_classe(newVal);
                const selectedClass = this.classes.find(classe => classe.id === newVal);
                console.log('selectedClass:', this.classe);
                console.log('selectedClass:', selectedClass);

                if (selectedClass) {
                    this.selectedClassName = `${selectedClass.type_formation.intitule} ${selectedClass.nom_classe} ${selectedClass.niveau} ${selectedClass.type_classe}`;
                    console.log('Nom de la classe sélectionnée:', this.selectedClassName);

                    // Définir automatiquement la période selon le type de classe
                    const typeClasse = selectedClass.type_classe.toUpperCase();
                    if (typeClasse.includes('CS')) {
                        this.form.courseTimes = 'evening';
                    } else if (typeClasse.includes('FPJ') || typeClasse.includes('CJ')) {
                        this.form.courseTimes = 'morning';
                    }

                    this.updateModalTitle();
                } else {
                    console.log('Classe non trouvée pour l\'ID:', newVal);
                }
            } else {
                console.log('Aucune classe sélectionnée.');
                this.selectedClassName = '';
                this.updateModalTitle();
            }
        },
    },

    methods: {
        ...mapActions(["login", "logout"]),

        async soumettre() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            const formdata = new FormData();
            formdata.append("emploiplanifiers", JSON.stringify(this.form_planification.planifications));

            if (this.hasCreatePermission("Planifier emploi du temps")) {
                try {
                    const create_store = await axios.post("/planification/emploidutemps/store", formdata, {
                        headers,
                    });
                    showDialog6("Emploi du Temps planifié avec succès");
                    bus.emit("planificationAjoutee");
                    this.resetForm();
                    setTimeout(() => {
                        window.location.href = "/emploi-du-temps";
                    }, 1500);

                    bus.emit("planificationAjoutee");
                } catch (e) {
                    if (e.response && e.response.status === 409) {
                        // Gestion des conflits de salle ou d'événements existants
                        showDialog3(e.response.data.message);
                    } else if (e.response && e.response.status === 422) {
                        // Gestion des erreurs de validation
                        showDialog3(e.response.data.message);
                    } else {
                        // Gestion des autres erreurs
                        showDialog3("Une erreur est survenue lors de l'enregistrement");
                    }
                    console.error(e);

                }
            }
            else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            }
        },

        isCourseSelected(courseId) {
            // Vérifiez si selectedCourses est défini et est un tableau
            if (this.selectedCourses && Array.isArray(this.selectedCourses)) {
                return this.selectedCourses.includes(courseId);
            }
            return false; // Si selectedCourses n'est pas défini, retourner false
        },
        resetFields() {
            this.form.totalHours = "";
            this.form.date_debut = "";
            this.form.durationPerSession = "";
            this.form.offset = "";
            this.form.numberOfWeeks = "";
            //this.form.courseTimes = "";
        },

        saveCurrentCourseDetails() {
            if (this.form.id_cour) {
                const courseDetails = {
                    id: this.form.id_cour
                };

                const existingCourseIndex = this.form.selectedCourses.findIndex(course => course.id === courseDetails.id);
                if (existingCourseIndex > -1) {
                    this.form.selectedCourses[existingCourseIndex] = courseDetails;
                } else {
                    this.form.selectedCourses.push(courseDetails);
                }
            }
        }
        ,



        /*  nextStep() {
           console.log('Méthode nextStep appelée');
           console.log('Étape actuelle:', this.currentStep);
           console.log('Valeur de form.classe:', this.form.classe);
       
           if (this.currentStep === 1) {
             if (this.form.classe === '') {
               alert('Veuillez sélectionner une classe avant de continuer.');
               return;
             }
             console.log('Classe sélectionnée:', this.form.classe);
             this.currentStep++;
             console.log('Étape après incrément:', this.currentStep);
             this.updateModalTitle();
             this.prepareNextModalData();
             return;
           }
       
           if (this.currentStep === 2) {
             const requiredFields = ['totalHours', 'date_debut', 'durationPerSession', 'id_cour', 'numberOfWeeks', 'offset'];
             const isValid = requiredFields.every(field => this.form[field] !== '');
       
             if (!isValid) {
               alert('Veuillez remplir tous les champs requis avant de continuer.');
               return;
             }
       
             this.formRecords.push({ ...this.form });
             console.log('Données après ajout:', this.formRecords);
           }
       
           this.saveCurrentModalData();
           this.currentStep++;
           console.log('Étape après incrément:', this.currentStep);
           this.updateModalTitle();
           this.prepareNextModalData();
           this.updateCalendarEvents();
         }
       , */

        // addSelectedCourse(course) {
        //     if (course && !this.form.selectedCourses.includes(course)) {
        //         this.form.selectedCourses.push(course);
        //     }

        // },
        selectCourse(courseId) {
            console.log("Avant modification de selectedCourses :", this.selectedCourses);
            const course = this.cours.find(c => c.id === courseId);

            if (course) {
                // Ajoutez le cours au tableau des cours sélectionnés
                this.selectedCourses.push(courseId);

                // Créez un objet pour les paramètres du cours
                const courseParams = {
                    id_cour: courseId,
                    totalHours: this.form.totalHours,
                    durationPerSession: this.form.durationPerSession,
                    offset: this.form.offset,
                    startDate: this.form.date_debut,
                    courseTimes: this.form.courseTimes,
                    numberOfWeeks: this.form.numberOfWeeks // Ajout de ce champ
                };

                // Ajoutez les paramètres au tableau des paramètres
                this.courseParameters.push(courseParams);

                console.log("Cours sélectionné :", course);
                console.log("Paramètres ajoutés :", courseParams);
            } else {
                console.error(`Cours non trouvé pour l'ID: ${courseId}`);
            }
        }





        ,
        prepareNextModalData() {
            console.log('Préparation des données pour le prochain modal.');
            // Réinitialisez les champs nécessaires ici
            this.form = {
                totalHours: '',
                durationPerSession: '',
                date_debut: '',
                offset: '',
                numberOfWeeks: '',
                courseTimes: '',
                periode: '',

                //id_cour: "",
                selectedCourses: [],
                classe: this.form.classe // Conserver la classe sélectionnée
            };
        },
        saveCurrentModalData() {
            const currentFormData = { ...this.form, step: this.currentStep };
            console.log('Données à ajouter à formRecords:', JSON.stringify(currentFormData));

            this.formRecords.push(currentFormData);

            console.log('Nombre d\'enregistrements dans formRecords:', this.formRecords.length);
        },

        updateCalendarEvents() {
            this.calendarEvents = this.prepareEventsForCalendar();
            console.log('Événements du calendrier mis à jour:', this.calendarEvents);
        },
        /*  prepareEventsForCalendar() {
             return this.formRecords.map(record => {
                 const endDate = this.calculateEndDate(record);
                 if (!endDate) {
                     console.warn('Fin de date invalide pour l\'enregistrement:', record);
                     return null; // Ignorer cet enregistrement ou gérer comme vous le souhaitez
                 }
                 return {
                     title: `Classe: ${record.classe}`, // Titre de l'événement
                     start: new Date(record.date_debut).toISOString(),// Date de début
                     end: endDate, // Date de fin
                 };
             }).filter(event => event !== null); // Filtrer les événements invalides
         }, */

        /*  calculateEndDate(record) {
             // Vérifiez si date_debut est défini et valide
             if (!record.date_debut) {
                 console.error('date_debut est invalide pour l\'enregistrement:', record);
                 return null; // Retourner null ou une valeur par défaut
             }
 
             const startDate = new Date(record.date_debut);
 
             // Vérifiez si la date est valide
             if (isNaN(startDate.getTime())) {
                 console.error('date_debut n\'est pas une date valide:', record.date_debut);
                 return null; // Retourner null ou une valeur par défaut
             }
 
             const endDate = new Date(startDate);
             endDate.setHours(endDate.getHours() + record.durationPerSession);
             return endDate.toISOString();
         }, */
        /*  previousStep() {
             console.log('previousStep called');
             if (this.currentStep > 1) {
                 this.currentStep--;
                 this.updateModalTitle();
             }
         }, */
        resetFormFields() {
            this.form.id_cour = "";
            //this.form.id_cour = null;
            this.form.totalHours = "";
            this.form.date_debut = "";
            this.form.durationPerSession = "";
            this.form.offset = "";
            this.form.numberOfWeeks = "";
            this.form.courseTimes = "";
        },
        formIsValid() {
            return (
                this.form.id_cour &&
                this.form.totalHours &&
                this.form.date_debut &&
                this.form.durationPerSession &&
                this.form.offset &&
                this.form.courseTimes
            );
        }



        // Pour les étapes suivantes, valider tous les autres champs

        ,

        // Fonction de validation pour les modals (sauf le premier)
        validateCurrentStep() {
            // Exemple de validation : vérifier si des cours ont été sélectionnés
            if (this.currentStep > 1 && this.form.cours.length === 0) {
                return false;  // Validation échouée si aucun cours n'est sélectionné
            }
            return true;  // Validation réussie
        },
        resetFormFields() {
            // this.form.selectedCourses = [];
            //this.form.id_cour = null;
            this.form.totalHours = "";
            this.form.date_debut = "";
            this.form.durationPerSession = "";
            this.form.offset = "";
            this.form.numberOfWeeks = "";
            this.form.courseTimes = "";
        },

        clearAll() {
            this.entries = [];
            this.currentStep = 1;
            this.clearForm();
        },

        validateFormRecords(formRecords) {
            return formRecords.filter(record => {
                return record.totalHours && record.date_debut && record.durationPerSession &&
                    record.id_cour && record.id_salle && record.numberOfWeeks && record.offset;
            });
        },

        displayAllRecords() {
            console.log('Affichage de tous les enregistrements:');
            this.formRecords.forEach((record, index) => {
                console.log(`Enregistrement ${index}:`, record);
            });
        },

        printCalendar() {
            const calendarElement = this.$refs.fullCalendar.$el; // Référence à l'élément du calendrier
            const printWindow = window.open('', '_blank');
            printWindow.document.write('<html><head><title>Imprimer Emploi du Temps</title>');
            printWindow.document.write('<style>/* Ajoutez ici vos styles CSS pour limpression */</style>');
            printWindow.document.write('</head><body>');
            printWindow.document.write(calendarElement.innerHTML);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        },
        // Ajouter cette méthode pour filtrer les événements par classe
        /*  async loadClassEvents(classId) {
             const token = localStorage.getItem("token");
             try {
                 const response = await axios.get(`/api/emploi-du-temps/classe/${classId}`, {
                     headers: {
                         'Authorization': `Bearer ${token}`,
                         'Content-Type': 'application/json',
                         'Accept': 'application/json'
                     }
                 });
     
                 // Mettre à jour les événements du calendrier pour cette classe uniquement
                 this.calendarOptions.events = response.data.events;
                 
                 // Forcer la mise à jour du calendrier
                 if (this.$refs.fullCalendar) {
                     const calendar = this.$refs.fullCalendar.getApi();
                     calendar.removeAllEvents();
                     calendar.addEventSource(response.data.events);
                 }
             } catch (error) {
                 console.error('Erreur lors du chargement des événements:', error);
             }
         }, */

        /*   async generateAndSaveSchedule() {
    
    this.saveCurrentModalData();
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    $('#exampleModal23').modal('hide');
  
    try {
     
      this.displayAllRecords();
  
      
      await this.generateSchedule();
  
      if (!Array.isArray(this.events) || this.events.length === 0) {
        console.error('Erreur: Aucun événement généré');
        throw new Error('Aucun événement généré');
      }
  
      console.log('Données à envoyer:', { events: this.events, formRecords: this.formRecords });
  
      const saveResponse = await axios.post('/save-schedule', {
        events: this.events,
        formRecords: this.formRecords
      }, { headers });
      this.enrichEvents();
  
      console.log('Réponse du serveur:', saveResponse.data);
      console.log('Événements enrichis:', this.events);
      console.log('Événements enrichis:', this.formRecords);
  
      if (saveResponse.data.success) {
        showDialog6(`Emploi du temps généré et enregistré avec succès. ${saveResponse.data.savedEvents} événements sauvegardés.`);
        this.scheduleGenerated = true;
      } else {
        showDialog3('Erreur lors de l\'enregistrement de l\'emploi du temps');
      }
    } catch (error) {
      console.error('Erreur lors de la génération ou de l\'enregistrement de l\'emploi du temps:', error);
      if (error.response) {
        console.error('Données de réponse:', error.response.data);
        console.error('Statut:', error.response.status);
        console.error('En-têtes:', error.response.headers);
      }
      showDialog3(`Erreur lors de l'enregistrement de l'emploi du temps: ${error.message}`);
    }
  }, */
        /* enrichEvents() {
               this.events = this.events.map(event => {
                 const matchingRecord = this.formRecords.find(record => record.id_cour === event.id_cour);
                 return {
                   ...event,
                   totalHours: matchingRecord ? matchingRecord.totalHours : null,
                   durationPerSession: matchingRecord ? matchingRecord.durationPerSession : null,
                 
                 };
               });
        
               console.log('Événements enrichis:', this.events);
             },
         */


        handleConflicts(conflicts) {
            let conflictMessages = conflicts.map(conflict => {
                switch (conflict.type) {
                    case 'time_conflict':
                        return `Conflit : ${conflict.message}`;
                    case 'invalid_data':
                        return `Données invalides : ${conflict.message}`;
                    case 'save_error':
                        return `Erreur d'enregistrement : ${conflict.message}`;
                    default:
                        return `Erreur inconnue : ${conflict.message}`;
                }
            });

            // Afficher les conflits à l'utilisateur
            this.showConflictsDialog(conflictMessages);
        },

        showConflictsDialog(messages) {
            let conflictMessage = "Des conflits ont été détectés :\n\n" + messages.join('\n');
            showDialog3(conflictMessage);
        },
        async downloadSchedule() {
            try {
                const response = await axios.get('/emploi-du-temps/pdf', {
                    responseType: 'blob', // Important pour le téléchargement de fichiers
                });

                // Créer un lien pour télécharger le fichier
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'emploi_du_temps.pdf'); // Nom du fichier à télécharger
                document.body.appendChild(link);
                link.click();
                link.remove();
            } catch (error) {
                console.error('Erreur lors du téléchargement du PDF:', error);
            }
        },
        printSchedule() {
            const printWindow = window.open('', '_blank');
            printWindow.document.write('<html><head><title>Impression de l\'emploi du temps</title>');
            printWindow.document.write('</head><body>');
            printWindow.document.write('<h1>Emploi du Temps</h1>');
            printWindow.document.write(this.generatePrintContent());
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
        },

        generatePrintContent() {
            let content = '<table border="1" style="width: 100%; border-collapse: collapse;">';
            content += '<tr><th>Cours</th><th>Professeur</th><th>Salle</th><th>Date</th><th>Heure</th></tr>';

            // Récupérer les événements du calendrier
            const events = this.$refs.fullCalendar.getApi().getEvents();

            // Ajoutez ici la logique pour générer le contenu de l'emploi du temps
            events.forEach(event => {
                content += `<tr>
                <td>${event.title}</td>
                <td>${event.extendedProps.professeur || 'Non spécifié'}</td>
                <td>${event.extendedProps.salle || 'Non spécifiée'}</td>
                <td>${event.start.toLocaleDateString()}</td>
                <td>${event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
            </tr>`;
            });

            content += '</table>';
            return content;
        },

        /*   updateModalTitle() {
              console.log('Titre avant mise à jour:', this.modalTitle);
              if (this.currentStep === 1) {
                  this.modalTitle = 'Planifier emploi du temps';
              } else {
                  this.modalTitle = `Planifier emploi du temps pour ${this.selectedClassName}`;
              }
              console.log('Titre après mise à jour:', this.modalTitle);
          }, */

        formatDateForInput(date) {
            if (!date) return '';
            const [day, month, year] = date.split('/');
            return `${year}-${month}-${day}`;
        },
        formatDateFromInput(date) {
            if (!date) return '';
            const [year, month, day] = date.split('-');
            return `${day}/${month}/${year}`;
        },
        updateDateFin(event) {
            this.form.date_fin = this.formatDateFromInput(event.target.value);
        },

        updateFields() {
            this.calculateWeeks();
            console.log('Form data after update:', {
                totalHours: this.form.totalHours,
                durationPerSession: this.form.durationPerSession,
                offset: this.form.offset,
                numberOfWeeks: this.form.numberOfWeeks,
                courseTimes: this.form.courseTimes
            });
            /*  console.log('Updated form data:', this.form);
             console.log('Total Hours:', this.form.totalHours);
             console.log('Duration per Session:', this.form.durationPerSession);
             console.log('Calculated Weeks:', this.calculateWeeks()); */
        },
        /*  validateForm() {
             if (!this.form.courseTimes) {
                 alert('Le champ "Période" est requis.');
                 return false;
             }
 
             if (!this.form.date_apartir) {
                 alert('Le champ "Date à partir de" est requis.');
                 return false;
             }
 
             return true;
         }, */
         async generateAndSaveSchedule() {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };

    try {
        this.selectCourse(this.form.id_cour);

        const generateResponse = await axios.post('/generate-schedule', {
            formRecord: {
                classId: this.form.classe,
                selectedCourses: this.selectedCourses.map((course, index) => ({
                    id_cour: course,
                    parameters: this.courseParameters[index],
                })),
                totalHours: this.form.totalHours,
                durationPerSession: this.form.durationPerSession,
                date_debut: this.form.date_debut,
                offset: this.form.offset,
                courseTimes: this.form.courseTimes,
                numberOfWeeks: this.calculateWeeks()
            },
        }, { headers });

        if (generateResponse.data.success) {
            // Filtrer les événements par classe
            this.events = this.enrichEvents(generateResponse.data.events)
                .filter(event => event.classId === this.form.classe);

            const saveResponse = await axios.post('/save-schedule', {
                events: this.events,
                classId: this.form.classe,
                formRecord: {
                    ...this.form,
                    selectedCourses: this.selectedCourses
                }
            }, { headers });

            if (saveResponse.data.success) {
                this.selectedClasse = this.form.classe;
                this.scheduleGenerated = true;

                if (this.$refs.fullCalendar) {
                    const calendar = this.$refs.fullCalendar.getApi();
                    calendar.removeAllEvents();
                    calendar.addEventSource(this.events);
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Succès!',
                    text: 'Emploi du temps généré et enregistré avec succès!',
                    showConfirmButton: false,
                    timer: 1500
                });

                setTimeout(() => {
                    window.location.href = `/emploi-du-temps?id_classe=${this.form.classe}`;
                }, 1500);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: saveResponse.data.message || "Erreur lors de l'enregistrement de l'emploi du temps"
                });
            }
        } else {
            if (generateResponse.data.conflicts && generateResponse.data.conflicts.length > 0) {
                const conflictsList = generateResponse.data.conflicts
                    .map(conflict => `<li>${conflict}</li>`)
                    .join('');

                Swal.fire({
                    icon: 'warning',
                    title: 'Conflits détectés',
                    html: `
                        <div class="text-left">
                            <p>Les conflits suivants ont été détectés :</p>
                            <ul class="text-left">
                                ${conflictsList}
                            </ul>
                        </div>
                    `,
                    confirmButtonText: 'OK',
                    customClass: {
                        htmlContainer: 'text-left'
                    }
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: generateResponse.data.message || "Erreur lors de la génération de l'emploi du temps"
                });
            }
        }
    } catch (error) {
        console.error("Erreur lors de la génération de l'emploi du temps :", error);
        
        Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: error.response?.data?.message || "Une erreur est survenue lors de la génération de l'emploi du temps"
        });
    }
}
        ,
        // Ajouter cette méthode pour charger les événements d'une classe spécifique
      async loadClassEvents(classeId) {
    try {
        const response = await axios.get('/api/emploi-du-temps', {
            params: {
                id_classe: classeId,
                annee_academique: this.currentAnneeAcademique // optionnel
            }
        });


        if (response.data.success) {
            // Mettre à jour le calendrier avec les événements de cette classe
            if (this.$refs.fullCalendar) {
                const calendar = this.$refs.fullCalendar.getApi();
                calendar.removeAllEvents();
                calendar.addEventSource(response.data.events);
            }
        }
    } catch (error) {
        console.error('Erreur lors du chargement des événements:', error);
    }
}


        ,

        validateFormBeforeGeneration() {
            if (!this.form.classe) {
                showDialog3('Veuillez sélectionner une classe');
                return false;
            }

            if (!this.form.selectedCourses || this.form.selectedCourses.length === 0) {
                showDialog3('Veuillez sélectionner au moins un cours');
                return false;
            }

            if (!this.form.totalHours || !this.form.durationPerSession || !this.form.date_debut) {
                showDialog3('Veuillez remplir tous les champs obligatoires');
                return false;
            }

            return true;
        },

        prepareFormData() {
            return {
                totalHours: parseFloat(this.form.totalHours),
                durationPerSession: parseFloat(this.form.durationPerSession),
                date_debut: this.form.date_debut,
                offset: parseInt(this.form.offset),
                courseTimes: this.form.courseTimes,
                selectedCourses: Array.isArray(this.form.selectedCourses)
                    ? this.form.selectedCourses
                    : [this.form.selectedCourses],
                classe: this.form.classe,
                numberOfWeeks: this.calculateWeeks()
            };
        },
        async get_emploi_du_temps(idClasse) {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };

    const params = {
        annee_academique: this.form.annee_academique || "",
        id_classe: idClasse,
        id_semestre: this.form.id_semestre || ""
    };

    // Ne charger que les événements de la classe sélectionnée
    this.calendarOptions.events = {
        url: "http://localhost:8000/api/get/emploi",
        method: "GET",
        extraParams: params,
        failure: error => {
            console.error("Erreur lors du chargement de l'emploi du temps :", error.message);
        }
    };

    // Actualiser le calendrier
    setTimeout(() => {
        if (this.$refs.fullCalendar && this.$refs.fullCalendar.getApi()) {
            this.$refs.fullCalendar.getApi().refetchEvents();
        }
    }, 500);
},

// Méthode pour rediriger vers l'emploi du temps de la classe
async redirectToEmploiDuTemps(idClasse) {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };

    try {
        const response = await axios.get(`/classes/${idClasse}/has-emploi-du-temps`, { headers });
        const hasEmploiDuTemps = response.data.hasEmploiDuTemps;

        if (hasEmploiDuTemps) {
            // Stocker la classe sélectionnée
            this.selectedClasse = idClasse;
            localStorage.setItem("selectedClasse", idClasse);

            // Rediriger vers l'emploi du temps de cette classe
            window.location.href = `/emploi-du-temps?id_classe=${idClasse}`;
        } else {
            Swal.fire({
                icon: "warning",
                title: "Pas d'emploi du temps",
                text: "Aucun emploi du temps n'est disponible pour cette classe.",
            });
        }
    } catch (error) {
        console.error("Erreur lors de la vérification de l'emploi du temps :", error.message);
        Swal.fire({
            icon: "error",
            title: "Erreur",
            text: "Impossible de vérifier la disponibilité de l'emploi du temps.",
        });
    }
},
        async saveGeneratedSchedule() {
            try {
                const response = await axios.post('/save-schedule', {
                    events: this.events,
                    formRecords: [{
                        ...this.form,
                        step: this.currentStep
                    }]
                }, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });

                if (!response.data.success) {
                    throw new Error(response.data.message);
                }

                return response.data;
            } catch (error) {
                console.error('Erreur lors de la sauvegarde:', error);
                throw new Error('Erreur lors de la sauvegarde de l\'emploi du temps');
            }
        }
        ,

        enrichEvents(events) {
            return events.map(event => {
                const course = this.cours.find(c => c.id === event.id_cour);
                return {
                    ...event,
                    title: this.formatEventTitle(course, event),
                    backgroundColor: this.getEventColor(event.id_cour),
                    extendedProps: {
                        ...event.extendedProps,
                        professeur: course?.formateur?.user?.nom || 'Non assigné',
                        salle: event.salle,
                        classe: event.classe
                    }
                };
            });
        },

        formatEventTitle(course, event) {
            if (!course) return event.title;
            return `${course.matiere.intitule} - ${course.formateur.user.nom}`;
        },

        getEventColor(courseId) {
            const colors = [
                '#3788d8', '#ff9f89', '#71c7ec', '#ffd700',
                '#98fb98', '#dda0dd', '#40e0d0', '#ff6b6b'
            ];
            return colors[courseId % colors.length];
        },

        renderEventContent(info) {
            const event = info.event;
            const props = event.extendedProps;

            return {
                html: `
          <div class="event-content">
            <div class="event-title">${event.title}</div>
            <div class="event-details">
              <div>${props.classe}</div>
              <div>${props.professeur}</div>
              <div>${props.salle}</div>
            </div>
          </div>
        `
            };
        },

        handleEventMount(info) {
            // Ajouter tooltip et interactions
            $(info.el).tooltip({
                title: this.formatTooltip(info.event),
                placement: 'top',
                html: true
            });
        },

        formatTooltip(event) {
            const props = event.extendedProps;
            return `
        <div class="tooltip-content">
          <strong>${event.title}</strong><br>
          Classe: ${props.classe}<br>
          Professeur: ${props.professeur}<br>
          Salle: ${props.salle}<br>
          ${this.formatEventTime(event)}
        </div>
      `;
        },

        formatEventTime(event) {
            const start = new Date(event.start);
            const end = new Date(event.end);
            return `${start.toLocaleTimeString()} - ${end.toLocaleTimeString()}`;
        },

        validateForm() {
            this.errors = [];

            const requiredFields = [
                { field: 'classe', message: 'Veuillez sélectionner une classe' },
                { field: 'selectedCourses', message: 'Veuillez sélectionner au moins un cours' },
                { field: 'totalHours', message: 'Veuillez spécifier le nombre total d\'heures' },
                { field: 'durationPerSession', message: 'Veuillez spécifier la durée par séance' },
                { field: 'date_debut', message: 'Veuillez sélectionner une date de début' },
                { field: 'offset', message: 'Veuillez spécifier l\'intervalle entre les séances' }
            ];

            for (const { field, message } of requiredFields) {
                if (!this.form[field] ||
                    (Array.isArray(this.form[field]) && this.form[field].length === 0)) {
                    this.errors.push(message);
                }
            }

            if (this.errors.length > 0) {
                showDialog3(this.errors.join('\n'));
                return false;
            }

            return true;
        },

        getHeaders() {
            const token = localStorage.getItem("token");
            return { Authorization: `Bearer ${token}` };
        },

        nextStep() {
            if (this.currentStep === 1) {

            }

            if (this.currentStep === 2) {
                this.selectCourse(this.form.id_cour);
                this.resetFormFields();
                return;
            }

            this.currentStep++;
            this.updateModalTitle();
        },

        previousStep() {
            if (this.currentStep > 1) {
                this.currentStep--;
                this.updateModalTitle();
            }
        },

        updateModalTitle() {
            this.modalTitle = this.currentStep === 1
                ? 'Planifier emploi du temps'
                : `Planifier emploi du temps pour ${this.selectedClassName}`;
        },
        validateCourseParameters(courseId, params) {
            if (
                !params.totalHours ||
                !params.durationPerSession ||
                !params.offset ||
                !params.startDate ||
                !params.courseTimes
            ) {
                console.error(`Paramètres invalides pour le cours ID : ${courseId}`, params);
                return false;
            }
            return true;
        }
        ,



        calculateWeeks() {
            const totalHours = parseFloat(this.form.totalHours);
            const durationPerSession = parseFloat(this.form.durationPerSession);
            const offset = parseInt(this.form.offset) || 1;

            if (totalHours && durationPerSession && offset) {
                // Calculer le nombre de sessions nécessaires
                const totalSessions = Math.ceil(totalHours / durationPerSession);

                // Calculer le nombre de sessions par semaine en tenant compte de l'offset
                const sessionsPerWeek = Math.floor(6 / offset); // 6 jours par semaine

                // Calculer le nombre de semaines nécessaires
                const weeksNeeded = Math.ceil(totalSessions / sessionsPerWeek);

                this.form.numberOfWeeks = weeksNeeded;

                console.log('Weeks calculated:', weeksNeeded);
                return weeksNeeded;
            }

            this.form.numberOfWeeks = 0;
            return 0;
        },


        updateEndDate() {
            this.form_planification.planifications.forEach((planification, index) => {
                if (planification.date_debut && planification.repetition) {
                    console.log("test")
                    // Convertir la date de début en objet Date
                    const date_debut = new Date(planification.date_debut.split('/').reverse().join('-'));
                    let date_fin;


                    // Calculer la date de fin en fonction de la période de répétition
                    switch (planification.repetition) {
                        case 'Une semaine':
                            date_fin = new Date(date_debut);
                            date_fin.setDate(date_debut.getDate() + 7);
                            console.log(date_fin)
                            break;
                        case 'Un mois':
                            date_fin = new Date(date_debut);
                            date_fin.setMonth(date_debut.getMonth() + 1);
                            break;
                        case 'Une année':
                            date_fin = new Date(date_debut);
                            date_fin.setFullYear(date_debut.getFullYear() + 1);
                            break;
                        default:
                            date_fin = date_debut;
                    }


                    // Formater la date de fin en jj/mm/aaaa
                    const day = String(date_fin.getDate()).padStart(2, '0');
                    const month = String(date_fin.getMonth() + 1).padStart(2, '0');
                    const year = date_fin.getFullYear();
                    planification.date_fin = `${day}/${month}/${year}`;
                }
            })
        },

        // Call this method whenever the repetition or start date changes
        handleRepetitionChange(index) {
            const planification = this.form_planification.planifications[index];
            this.calculateEndDate(planification);
        },

        handleStartDateChange(index) {
            const planification = this.form_planification.planifications[index];
            this.calculateEndDate(planification);
        },



        async get_cours_by_id_classe(id) {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            if (this.hasReadPermission("Planifier emploi du temps")) {
                await axios
                    .get(`/find/cours/by/classe/${id}`, { headers })
                    .then((response) => {
                        this.cours = response.data.cour;
                        //console.log('lancer avec succes')
                        //  this.validatedata("formateur");
                        this.$nextTick(() => {
                            $('#multiple').select2().trigger('change');
                            // console.log(response.data.cour);

                        });
                    })
                    .catch((error) => {
                        Swal.fire(
                            "Erreur!",
                            "une erreur est survenue lors de la recuperation des filières",
                            "error"
                        );
                    });
            }
            else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            }
        },
        async handleClassChange(id) {
            if (!id) return;

            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };

            if (this.hasReadPermission("Planifier emploi du temps")) {
                try {
                    const response = await axios.get(`/find/cours/by/classe/${id}`, { headers });
                    this.cours = response.data.cour;

                    // Mettre à jour le nom de la classe sélectionnée
                    const selectedClass = this.classes.find(c => c.id === id);
                    if (selectedClass) {
                        this.selectedClassName = this.formatClassName(selectedClass);
                        this.updateModalTitle();
                    }

                    // Initialiser select2 après la mise à jour des données
                    this.$nextTick(() => {
                        $('#multiple').select2().trigger('change');
                    });

                } catch (error) {
                    Swal.fire(
                        "Erreur!",
                        "Une erreur est survenue lors de la récupération des cours",
                        "error"
                    );
                    console.error('Erreur détaillée:', error);
                }
            } else {
                showDialog3("Vous n'avez pas la permission requise.");
            }
        },

        formatClassName(classe) {
            return [
                classe.type_formation?.intitule,
                classe.niveau,
                classe.nom_classe,
                classe.type_classe
            ].filter(Boolean).join(' ');
        },





        focusOnFirstEvent() {
            if (this.events.length > 0) {
                const firstEventDate = this.events[0].start;
                const calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.gotoDate(firstEventDate);
            }
        },
        disableCalendar() {
            const calendarApi = this.$refs.fullCalendar.getApi();
            calendarApi.destroy(); // Détruit l'instance de FullCalendar
        },
        retourChange() {
            $('#exampleModal23').modal('show');
            /*this.get_classe();
              this.get_salle();
              this.get_annee_academique();
              this.initializeSelect2(); */
            this.scheduleGenerated = false;
        },

        initializeSelect2() {
            const vm = this;
            $('#multiple').select2();
            $('#multiple').on('change', function () {
                const selectedValues = $(this).val();
                // Avoid unnecessary updates to selectedCourses
                if (JSON.stringify(vm.form.selectedCourses) !== JSON.stringify(selectedValues)) {
                    vm.form.selectedCourses = selectedValues;
                }
            });

        },

        async get_cours() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios.get('/cour/all', { headers })
                .then(response => {
                    this.cours = response.data.cour
                }).catch(error => {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la recuperation des derniers cours', 'error')
                });
        },

        async generateSchedule() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };

            // Valider que chaque cours a ses paramètres
            const validCourses = this.selectedCourses.every((course, index) => {
                const params = this.courseParameters[index];
                if (
                    !params ||
                    !params.totalHours ||
                    !params.durationPerSession ||
                    !params.offset ||
                    !params.startDate ||
                    !params.courseTimes
                ) {
                    console.error(`Paramètres manquants ou incomplets pour le cours ID : ${course.id || course}`);
                    return false;
                }
                return true;
            });

            if (!validCourses) {
                console.error("Validation échouée. Certains cours n'ont pas de paramètres valides.");
                return;
            }

            console.log("Payload validé :", {
                formRecord: {
                    selectedCourses: this.selectedCourses.map((course, index) => ({
                        id_cour: course.id || course, // ID du cours
                        parameters: this.courseParameters[index] // Paramètres associés
                    }))
                }
            });

            try {
                const response = await axios.post(
                    '/generate-schedule',
                    {
                        formRecord: {
                            selectedCourses: this.selectedCourses.map((course, index) => ({
                                id_cour: course.id || course,
                                parameters: this.courseParameters[index]
                            }))
                        }
                    },
                    { headers }
                );

                if (response.data.success) {
                    this.events = response.data.events;
                    console.log("Événements générés :", this.events);
                } else {
                    console.error("Erreur côté serveur :", response.data.message);
                }
            } catch (error) {
                if (error.response) {
                    console.error("Erreur du backend :", error.response.data);
                } else {
                    console.error("Erreur inconnue :", error.message);
                }
            }
        }

        ,

        /*  async saveSchedule() {
             const token = localStorage.getItem("token");
             const headers = { Authorization: `Bearer ${token}` };
 
           
             this.addSelectedCourse();
            
 
 
             try {
                 const response = await axios.post('/save-schedule', { events: this.events, selectedCourses: this.allSelectedCourses }, { headers });
                 console.log('Events:', this.events);
                 if (response.data.success) {
 
                     console.log(response.data);
                     showDialog6('Emploi du temps enregistré avec succès');
                    
                 } else {
                     showDialog3('Erreur lors de l\'enregistrement');
                 }
             } catch (error) {
                 console.error('Erreur lors de l\'enregistrement:', error);
             }
         },
  */


        async get_classe() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            if (this.hasReadPermission("Planifier emploi du temps")) {
                await axios
                    .get("/classe/all", { headers })
                    .then((response) => {
                        this.classes = response.data.classe;
                    })
                    .catch((error) => {
                        Swal.fire(
                            "Erreur!",
                            "Une erreur est survenue lors de la recuperation des classes",
                            "error"
                        );
                    });
            } else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            }
        },

        validerAvantAjout() {
            const isVerifIdValid = this.validatedataOld();

            if (isVerifIdValid === true) {
                this.etatForm = false;
                this.editModal = false;
                console.log("bloqué")
                return 0;
            } else {
                if (this.editModal === true) {
                    this.etatForm = true;
                    this.update_paiement(this.idPaiement);
                    this.editModal = false;
                } else {

                    this.soumettre();
                    this.etatForm = true;
                    this.editModal = false;
                }
            }
        },
        validatedata(champ) {

            // Variable pour vérifier la présence d'erreurs
            let hasError = false;

            // Validation spécifique en fonction du champ
            switch (champ) {
                case "id_cours":
                    this.form_planification.planifications.forEach((planification, index) => {
                        planification.id_cours_erreur = "";
                        if (planification.id_cour === "") {
                            planification.id_cours_erreur = "Vous avez oublié de sélectionner un cours " + (index + 1);
                            hasError = true;
                            return hasError;
                        }
                    });
                    break;
                case "id_salle":
                    this.form_planification.planifications.forEach((planification, index) => {
                        planification.id_salle_erreur = "";
                        if (planification.id_cour === "") {
                            planification.id_salle_erreur = "Vous avez oublié de sélectionner un salle " + (index + 1);
                            hasError = true;
                            return hasError;
                        }
                    });
                    break;

                case "id_annee_academique":
                    this.form_planification.planifications.forEach((planification, index) => {
                        planification.id_annee_academique_erreur = "";
                        if (planification.id_annee_academique === "") {
                            planification.id_annee_academique_erreur = "Vous avez oublié de sélectionner une Annee Academique " + (index + 1);
                            hasError = true;
                            return hasError;
                        }
                    });
                    break;

                case "date_debut":
                    this.form_planification.planifications.forEach((planification, index) => {
                        planification.date_debut_erreur = "";
                        if (planification.date_debut === "") {
                            planification.date_debut_erreur = "Vous avez oublié de sélectionner une date pour le début " + (index + 1);
                            hasError = true;
                            return hasError;
                        }
                    });
                    break;

                case "date_fin":
                    this.form_planification.planifications.forEach((planification, index) => {
                        planification.date_fin_erreur = "";
                        if (planification.date_fin === "") {
                            planification.date_fin_erreur = "Vous avez oublié de sélectionner une date de fin " + (index + 1);
                            hasError = true;
                            return hasError;
                        }
                    });
                    break;

                case "heure_fin":
                    this.form_planification.planifications.forEach((planification, index) => {
                        planification.heure_fin_erreur = "";
                        if (planification.heure_fin === "") {
                            planification.heure_fin_erreur = "Vous avez oublié de sélectionner une heure de fin " + (index + 1);
                            hasError = true;
                            return hasError;
                        }
                    });
                    break;

                case "heure_debut":
                    this.form_planification.planifications.forEach((planification, index) => {
                        planification.heure_debut_erreur = "";
                        if (planification.heure_debut === "") {
                            planification.heure_debut_erreur = "Vous avez oublié de sélectionner une heure de début " + (index + 1);
                            hasError = true;
                            return hasError;
                        }
                    });
                    break;

                default:
                    break;
            }
        },

        validatedataOld() {
            // Réinitialiser toutes les erreurs
            this.form_planification.planifications.forEach(planification => {
                planification.id_cours_erreur = "";
                planification.id_salle_erreur = "";
                planification.id_annee_academique_erreur = "";
                planification.date_debut_erreur = "";
                planification.date_fin_erreur = "";
                planification.heure_debut_erreur = "";
                planification.heure_fin_erreur = "";
            });

            // Variable pour vérifier la présence d'erreurs
            let hasError = false;

            // Validation des champs
            this.form_planification.planifications.forEach((planification, index) => {
                if (planification.id_cour === "") {
                    planification.id_cours_erreur = "Vous avez oublié de sélectionner un cours " + (index + 1) + ".";
                    hasError = true;
                }
                if (planification.id_salle === "") {
                    planification.id_salle_erreur = "Vous avez oublié de sélectionner une salle " + (index + 1) + ".";
                    hasError = true;
                }

                if (planification.id_annee_academique === "") {
                    planification.id_annee_academique_erreur = "Vous avez oublié de sélectionner l'Annee Academique pour la planification " + (index + 1) + ".";
                    hasError = true;
                }

                if (planification.date_debut === "") {
                    planification.date_debut_erreur = "Vous avez oublié de sélectionner une date pour le débutfff " + (index + 1) + ".";
                    hasError = true;
                }

                if (planification.date_fin === "") {
                    planification.date_fin_erreur = "Vous avez oublié de sélectionner une date de fin " + (index + 1) + ".";
                    hasError = true;
                }

                if (planification.heure_debut === "") {
                    planification.heure_debut_erreur = "Vous avez oublié de sélectionner l'heure de début " + (index + 1) + ".";
                    hasError = true;
                }

                if (planification.heure_fin === "") {
                    planification.heure_fin_erreur = "Vous avez oublié de sélectionner l'heure de fin " + (index + 1) + ".";
                    hasError = true;
                }
            });

            return hasError;
        },


        getImageUrl(url) {
            //return url ? `${window.location.origin}/storage/${url}` : '';
            //return url ? `http://127.0.0.1:8000/storage/${url}` : "";
            return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
        },

        async get_annee_academique() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            if (this.hasReadPermission("Paiement Scolarité")) {
                await axios
                    .get("/annee_academique/index", { headers })
                    .then((response) => {
                        this.annee_academiques = response.data.annee_academique;
                    })
                    .catch((error) => {
                        Swal.fire(
                            "Erreur!",
                            "Une erreur est survenue lors de la recuperation des année_academiques",
                            "error"
                        );
                    });
            }
            else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            }
        },


        resetForm() {

            this.form_planification.planifications.forEach(planification => {
                planification.id_cour = "";
                planification.id_salle = "";
                planification.id_annee_academique = "";
                planification.date_debut = "";
                planification.date_fin = "";
                planification.heure_debut = "";
                planification.heure_fin = "";

                planification.id_cours_erreur = "";
                planification.id_salle_erreur = "";
                planification.id_annee_academique_erreur = "";
                planification.date_debut_erreur = "";
                planification.date_fin_erreur = "";
                planification.heure_debut_erreur = "";
                planification.heure_fin_erreur = "";
            });

        },
        async get_salle() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            if (this.hasReadPermission("Cours")) {
                await axios
                    .get("/salle/index", { headers })
                    .then((response) => {
                        this.salles = response.data.salle;
                    })
                    .catch((error) => {
                        Swal.fire(
                            "Erreur!",
                            "Une erreur est survenue lors de la recuperation des dernières salles",
                            "error"
                        );
                    });
            } else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            }
        },

        rafraichissementAutomatique() {
            document.addEventListener("DOMContentLoaded", this.resetForm());
        },


        duplicatePlanification(index) {
            const planification = this.form_planification.planifications[index];
            const newPlanification = {
                ...planification,
                uniqueKey: Date.now() + Math.random(), // Assurez-vous d'une clé unique
                id_annee_academique: "",
                date_debut: "",
                id_cour: "",
                id_salle: "",
                date_fin: "",
                heure_debut: "",
                heure_fin: "",
                repetition: planification.repetition, // Copiez également la valeur de répétition
                id_annee_academique_erreur: "",
                id_cours_erreur: "",
                id_salle_erreur: "",
                heure_debut_erreur: "",
                heure_fin_erreur: "",
                date_debut_erreur: "",
                date_fin_erreur: "",
            };

            this.form_planification.planifications.splice(index + 1, 0, newPlanification);

            this.$nextTick(() => {
                this.reinitializeMDL();
            });
        },


        reinitializeMDL() {
            window.componentHandler.upgradeDom();
        },

        removePlanification(index) {
            if (this.form_planification.planifications.length > 1) {
                this.form_planification.planifications.splice(index, 1);
            }
        },

        async sendSchedule() {
            try {
                const token = localStorage.getItem("token");
                const headers = { Authorization: `Bearer ${token}` };

                // Exemple d'appel API pour envoyer l'emploi du temps
                const response = await axios.post('/api/send-schedule', {
                    classId: this.form.classe,
                    events: this.events
                }, { headers });

                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Succès!',
                        text: 'Emploi du temps envoyé avec succès!',
                        showConfirmButton: false,
                        timer: 1500
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erreur',
                        text: response.data.message || "Erreur lors de l'envoi de l'emploi du temps"
                    });
                }
            } catch (error) {
                console.error("Erreur lors de l'envoi de l'emploi du temps :", error);

                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: error.response?.data?.message || "Une erreur est survenue lors de l'envoi de l'emploi du temps"
                });
            }
        }
    },
};
</script>

<style>
.event-content {
    padding: 4px;
    font-size: 12px;
    line-height: 1.2;
}

.event-title {
    font-weight: bold;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-details {
    font-size: 11px;
}

.event-details>div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip-content {
    text-align: left;
    font-size: 12px;
    line-height: 1.4;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-wrapper {
    border-radius: 8px;
    width: 95%;
    max-width: 1800px;
}

.modal-content {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.modal-header {
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    padding: 1rem;
}

.btn-close {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
}

.form-group {
    margin-bottom: 1rem;
}

.error-message {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.25rem;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

@media print {

    /* Styles pour l'impression */
    .fc-toolbar,
    .fc-header-toolbar {
        display: none;
        /* Masquer les éléments de navigation */
    }

    .fc-view-container {
        width: 100%;
        /* S'assurer que le calendrier utilise toute la largeur */
    }
}

.calendar-container {
    position: relative;
    min-height: 600px;
}

:root {
    --fc-small-font-size: .85em;
    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
    --fc-neutral-text-color: #808080;
    --fc-border-color: #ddd;

    --fc-button-text-color: #fff;
    --fc-button-bg-color: #2C3E50;
    --fc-button-border-color: #2C3E50;
    --fc-button-hover-bg-color: #1e2b37;
    --fc-button-hover-border-color: #1a252f;
    --fc-button-active-bg-color: #1a252f;
    --fc-button-active-border-color: #151e27;

    --fc-event-bg-color: #3788d8;
    --fc-event-border-color: #3788d8;
    --fc-event-text-color: #fff;
    --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

    --fc-more-link-bg-color: #d0d0d0;
    --fc-more-link-text-color: inherit;

    --fc-event-resizer-thickness: 8px;
    --fc-event-resizer-dot-total-width: 8px;
    --fc-event-resizer-dot-border-width: 1px;

    --fc-non-business-color: rgba(215, 215, 215, 0.3);
    --fc-bg-event-color: rgb(143, 223, 130);
    --fc-bg-event-opacity: 0.3;
    --fc-highlight-color: rgba(188, 232, 241, 0.3);
    --fc-today-bg-color: rgba(255, 220, 40, 0.15);
    --fc-now-indicator-color: red;
}
</style>