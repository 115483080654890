<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
        :userRole="userRole" />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class=" pull-left">
                <div class="page-title">Paiement Hébergement</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'dashboardCaissier'">Tableau de
                    Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li><a class="parent-item" :href="'liste-hebergement'">Liste hebergement</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li class="active">Paiement Hébergement &nbsp;<a class="parent-item"></a>&nbsp;<i
                    class="fa fa-angle-right"></i> </li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Paiement Hébergement</header>
                </div>
                
                <div class="card-body">
                  <!-- Recherche d'élève -->
                  <div class="form-group" v-show="searchMatricule">
                    <label>Rechercher un élève</label>
                    <input 
                      type="text" 
                      class="form-control" 
                      v-model="search_query"
                      @input="performSearch" 
                      placeholder="Rechercher par matricule"
                    />
                  </div>
                  
                  <!-- Liste des élèves trouvés -->
                  <div 
                    v-for="(eleve, key) in eleves" 
                    :key="key" 
                    @click="selectEleve(eleve)"
                    class="mb-2"
                  >
                    <a href="#" class="btn btn-block btn-outline-primary text-left"> 
                      {{ eleve.user.nom }} {{ eleve.user.prenom }} -
                      <span class="info-supplementaire">
                        Mat: {{ eleve.user.matricule }}
                      </span> 
                    </a>
                  </div>

                  <!-- Informations de l'élève -->
                  <div v-if="selectedEleve" class="mt-4">
                    <div class="row">
                      <div class="col-md-4">
                        <img 
                          :src="getImageUrl(selectedEleve.photo)" 
                          class="img-fluid mb-3" 
                          alt="Photo de l'élève"
                        />
                      </div>
                      <div class="col-md-8">
                        <p>Matricule : {{ selectedEleve.matricule }}</p>
                        <p>Nom complet : {{ selectedEleve.nom }} {{ selectedEleve.prenom }}</p>
                        <p>Classe : {{ selectedEleve.classe }}</p>
                        <p>Date Naissance : {{ formatDateTime(selectedEleve.date_naissance) }}</p>
                        <p>Adresse : {{ selectedEleve.adresse }}</p>
                      </div>
                    </div>

                    <!-- Section de Paiement -->
                    <div class="row mt-4">
  <div class="col-md-6">
    <div class="form-group">
      <label>Mois</label>
      <select 
        class="form-control" 
        v-model="form.mois"
      >
        <option value="1">Janvier</option>
        <option value="2">Février</option>
        <option value="3">Mars</option>
        <option value="4">Avril</option>
        <option value="5">Mai</option>
        <option value="6">Juin</option>
        <option value="7">Juillet</option>
      </select>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label>Montant à Payer</label>
      <input 
        type="text" 
        class="form-control" 
        value="30000" 
        readonly
      />
    </div>
  </div>
</div>
                  

                    <!-- Boutons -->
                    <div class="row mt-3">
                      <div class="col-12 text-right">
                        <button 
                          class="btn btn-primary mr-2" 
                          @click="effectuerPaiement"
                          
                        >
                          PAYER
                        </button>
                        <button 
                          class="btn btn-danger" 
                          @click="resetForm"
                        >
                          ANNULER
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <appfooter />
    </div>
  </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import { mapState, mapActions } from "vuex";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
  },
  mixins: [permissionMixin],
  name: "paiementHebergementComponent",
  data() {
    return {
      form: new Form({
        id_eleve: "",
        mois: null,
        montant: null
      }),
      eleves: [],
      search_query: "",
      selectedEleve: null,
      hebergement: null,
      id_eleve_erreur: "",
      searchMatricule: true,
      MONTANT_MENSUEL: 30000, // Montant mensuel fixe
      listeMois: [
        { numero: 1, nom: 'Janvier' },
        { numero: 2, nom: 'Février' },
        { numero: 3, nom: 'Mars' },
        { numero: 4, nom: 'Avril' },
        { numero: 5, nom: 'Mai' },
        { numero: 6, nom: 'Juin' },
        { numero: 7, nom: 'Juillet' }
      ]
    };
  },
  computed: {
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
    pourcentagePaye() {
      if (!this.hebergement) return 0;
      return Math.min(
        Math.round((this.hebergement.montant_total_paye / this.hebergement.montant_total_requis) * 100), 
        100
      );
    },
    progressBarClass() {
      if (this.pourcentagePaye < 30) return 'bg-danger';
      if (this.pourcentagePaye < 70) return 'bg-warning';
      return 'bg-success';
    },
    montantRestant() {
      if (!this.hebergement) return 0;
      return Math.max(
        this.hebergement.montant_total_requis - this.hebergement.montant_total_paye, 
        0
      );
    },
    moisRestants() {
      if (!this.hebergement) return [];
      
      const moisPayes = Math.floor(this.hebergement.montant_total_paye / this.MONTANT_MENSUEL);
      return this.listeMois.slice(moisPayes);
    }
  },
  methods: {
    ...mapActions(["login", "logout"]),
    
    async performSearch() {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get("/recherche/eleve", {
          params: { query: this.search_query },
          headers: { Authorization: `Bearer ${token}` }
        });
        this.eleves = response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async selectEleve(eleve) {
      this.form.id_eleve = eleve.user.id;
      this.selectedEleve = {
        id: eleve.user.id,
        nom: eleve.user.nom,
        prenom: eleve.user.prenom,
        matricule: eleve.user.matricule,
        classe: eleve.inscription[0]?.classe?.type_classe + " " + 
                eleve.inscription[0]?.classe?.nom_classe + " " + 
                eleve.inscription[0]?.classe?.niveau,
        adresse: eleve.user.adresse,
        date_naissance: eleve.user.date_naissance,
        photo: eleve.user.photo
      };
      
      this.eleves = [];
      this.searchMatricule = false;

      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`/hebergement/eleve/${eleve.id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        this.hebergement = response.data.hebergement;
      } catch (error) {
        this.$toast.error('Erreur de récupération de l\'hébergement');
        console.error(error);
      }
    },

    async effectuerPaiement() {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post('/paiement/mensuel', {
      id_hebergement: this.hebergement.id,
      mois: this.form.mois,
      montant: 30000
    }, {
      headers: { Authorization: `Bearer ${token}` }
    });
    
    // Afficher un message de succès
    showDialog6("Paiement effectué avec succès");
    
    // Mettre à jour le montant total payé
    this.hebergement.montant_total_paye = response.data.montant_total_paye;
    
    this.resetForm();
  } catch (error) {
    // Afficher un message d'erreur détaillé
    if (error.response && error.response.data && error.response.data.message) {
      // Utiliser le message spécifique du backend
      showDialog3(error.response.data.message);
    } else {
      // Message d'erreur générique
      showDialog3("Une erreur est survenue lors du paiement");
    }
    console.error(error);
  }
},

    resetForm() {
      this.form.reset();
      this.selectedEleve = null;
      this.search_query = "";
      this.eleves = [];
      this.hebergement = null;
      this.searchMatricule = true;
    },

    formatDateTime(dateTime) {
      return this.formatDate(new Date(dateTime));
    },

    formatDate(date) {
      const day = date.getDate();
      const monthNumber = date.getMonth() + 1;
      const year = date.getFullYear();

      const monthAbbreviations = [
        "Jan", "Fév", "Mar", "Avr", "Mai", "Juin", 
        "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"
      ];

      const month = monthAbbreviations[monthNumber - 1];

      return `${day} ${month} ${year}`;
    },

    getImageUrl(url) {
      return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
    },
  },
}
</script>

<style scoped>
.progress {
  height: 25px;
}
</style>
