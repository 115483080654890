<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Modifier Demande</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.html">Home</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li class="active">Modifier Demande</li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card card-box">
                <div class="card-head">
                  <header>Formulaire de Modification</header>
                </div>
                <div class="card-body">
                  <form @submit.prevent="updateDemande">
                    <div class="form-group">
                      <label for="type_demande">Type de Demande:</label>
                      <select v-model="editForm.type_demande" class="form-control" required>
                        <option value="materiel">Matériel</option>
                        <option value="consommable">Consommable</option>
                        <option value="both">Les deux</option>
                      </select>
                    </div>
                    <div v-if="editForm.type_demande === 'materiel' || editForm.type_demande === 'both'" class="form-group">
                      <label>Matériels:</label>
                      <div v-for="(materiel, index) in editForm.materiels" :key="index" class="mb-4">
                        <div class="header d-flex justify-content-end align-items-center">
                          <svg class="cursor-pointer" @click="addMateriel" style="font-size: 25px; color: green;"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path fill="green"
                              d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                          </svg>
                          <svg v-show="editForm.materiels.length > 1" class="cursor-pointer ml-2" @click.prevent="removeMateriel(index)"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path fill="red"
                              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm5 13H7v-2h10v2z" />
                          </svg>
                        </div>
                        <input type="text" v-model="materiel.libelle" class="form-control" placeholder="Libellé du Matériel" required />
                        <input type="number" v-model="materiel.quantite" class="form-control" placeholder="Quantité de Matériel" required min="0" />
                        <textarea v-model="materiel.description" class="form-control" placeholder="Description du Matériel" rows="3"></textarea>
                      </div>
                    </div>
                    <div v-if="editForm.type_demande === 'consommable' || editForm.type_demande === 'both'" class="form-group">
                      <label>Consommables:</label>
                      <div v-for="(consommable, index) in editForm.consommables" :key="index" class="mb-4">
                        <div class="header d-flex justify-content-end align-items-center">
                          <svg class="cursor-pointer" @click="addConsommable" style="font-size: 25px; color: green;"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path fill="green"
                              d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                          </svg>
                          <svg v-show="editForm.consommables.length > 1" class="cursor-pointer ml-2" @click.prevent="removeConsommable(index)"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path fill="red"
                              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm5 13H7v-2h10v2z" />
                          </svg>
                        </div>
                        <input type="text" v-model="consommable.libelle" class="form-control" placeholder="Libellé du Consommable" required />
                        <input type="number" v-model="consommable.quantite" class="form-control" placeholder="Quantité de Consommable" required min="0" />
                        <textarea v-model="consommable.description" class="form-control" placeholder="Description du Consommable" rows="3"></textarea>
                      </div>
                    </div>
                    

                    <div class="col-lg-12 p-t-20 text-center">
                    <button type="submit" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary" >
                      
                      <span>Soumettre</span>
                    </button>
                    <button
                      type="button"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                      @click="cancelEdit"
                    >
                      Annuler
                    </button>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <appafterContent />
  </div>
  <appfooter />
</template>

<script>
import axios from "axios";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import Swal from 'sweetalert2';

export default {
  components: {
    appheader,
    appfooter,
    appnavbar,
    appafterContent,
  },
  data() {
    return {
      editForm: {
        id: null,
        type_demande: '',
        materiels: [],
        consommables: []
      }
    };
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  created() {
    this.fetchDemande();
  },
  methods: {
    async fetchDemande() {
      const headers = { Authorization: `Bearer ${this.token}` };
      try {
        const response = await axios.get(`http://localhost:8000/api/demandes/${this.$route.params.id}`, { headers });
        const demande = response.data.demande;
        
        // Initialiser le formulaire avec les données reçues
        this.editForm = {
          id: demande.id,
          type_demande: demande.type_demande,
          materiels: demande.demande_materiels?.map(m => ({
            libelle: m.libelle,
            quantite: m.quantite,
            description: m.description
          })) || [],
          consommables: demande.demande_consommables?.map(c => ({
            libelle: c.libelle,
            quantite: c.quantite,
            description: c.description
          })) || []
        };
        
        // Si aucun matériel ou consommable n'est présent, initialiser avec un élément vide
        if (this.editForm.materiels.length === 0 && (this.editForm.type_demande === 'materiel' || this.editForm.type_demande === 'both')) {
          this.addMateriel();
        }
        if (this.editForm.consommables.length === 0 && (this.editForm.type_demande === 'consommable' || this.editForm.type_demande === 'both')) {
          this.addConsommable();
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de la demande:", error);
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération de la demande', 'error');
      }
    },
    addMateriel() {
      this.editForm.materiels.push({ libelle: '', quantite: 0, description: '' });
    },
    removeMateriel(index) {
      this.editForm.materiels.splice(index, 1);
    },
    addConsommable() {
      this.editForm.consommables.push({ libelle: '', quantite: 0, description: '' });
    },
    removeConsommable(index) {
      this.editForm.consommables.splice(index, 1);
    },
    async updateDemande() {
      const headers = { Authorization: `Bearer ${this.token}` };
      try {
        const response = await axios.put(`http://localhost:8000/api/demandes/${this.editForm.id}`, this.editForm, { headers });
        if (response.data.statut === 200) {
          Swal.fire('Succès!', 'Demande mise à jour avec succès', 'success');
          this.$router.push({ name: 'ListeDemandes' });
        } else {
          Swal.fire('Erreur!', 'Une erreur est survenue lors de la mise à jour de la demande', 'error');
        }
      } catch (error) {
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la mise à jour de la demande', 'error');
      }
    },
    cancelEdit() {
      this.$router.push({ name: 'ListeDemandes' });
    }
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.text-danger {
  color: red;
}
</style>
