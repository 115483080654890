<template>
  <!-- Administrateur -->
  <div class="sidebar-container"
    v-show="this.userIdrole && 'administrateur'.toUpperCase() === this.userRole.toUpperCase()">
    <div class="sidemenu-container navbar-collapse collapse fixed-menu">
      <div id="remove-scroll" class="left-sidemenu">
        <ul class="sidemenu page-header-fixed slimscroll-style" data-keep-expanded="false" data-auto-scroll="true"
          data-slide-speed="200" style="padding-top: 20px">
          <li class="sidebar-toggler-wrapper hide">
            <div class="sidebar-toggler">
              <span></span>
            </div>
          </li>
          <li class="sidebar-user-panel">
            <div class="sidebar-user">
              <div class="sidebar-user-picture">
                <img alt="image" :src="getImageUrl(this.userPhoto)" />
              </div>
              <div class="sidebar-user-details">
                <div class="user-name">
                  {{ this.userNom }} {{ this.userPrenom }}
                </div>
                <div class="user-role">{{ this.userRole }}</div>
              </div>
            </div>
          </li>
          <li class="nav-item start active open">
            <!--  <a href="{{ route('admin_index') }}" class="nav-link nav-toggle"> -->
            <a :href="'/dashboard'" class="nav-link nav-toggle">
              <i class="fa fa-window-maximize"></i>
              <span class="title">Tableau de Bord</span>
              <span class="selected"></span>
            </a>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-users"></i>

              <span class="title">Utilisateurs</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/liste-utilisateur'" class="nav-link">
                  <span class="title">Liste Utilisateur</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/create-utilisateur'" class="nav-link">
                  <span class="title">Nouvel Utilisateur</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a :href="'/liste-inscription-eleve'" class="nav-link nav-toggle">
              <i class="fa fa-address-book"></i>

              <span class="title">Inscription</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fas fa-book-open"></i>

              <span class="title">Ressources Pédagogiques</span><span class="arrow"></span>
            </a>
            <ul class="sub-menu">

              <li class="nav-item">
                <a :href="'/listematiere'">
                  <i class=" fa fa-chalkboard-teacher"></i>
                  <span class="title"> Matiére</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/liste-cours'">
                  <i class=" fa fa-user-graduate"></i>
                  <span class="title"> Cours</span>
                </a>
              </li>
              <li class="nav-item">
                <a :href="'/planifier-emploi-du-temps'">
                  <i class=" fas fa-calendar-alt"></i>
                  <span class="title"> Planifier Emploi du temps </span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/liste-planifier-emploi-du-temps'">
                  <i class=" fas fa-calendar-alt"></i>
                  <span class="title"> Liste planifier emploi du temps </span>
                </a>
              </li>
              <li class="nav-item">
                <a :href="'/emploi-du-temps'">
                  <i class=" fas fa-calendar-alt"></i>
                  <span class="title"> Emploi du temps </span>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-cog"></i>

              <span class="title">Paramétres</span><span class="arrow"></span>
            </a>

            <ul class="sub-menu">

              <li class="nav-item">
                <a :href="'/liste-direction'" class="nav-link">
                  <span class="title">Direction</span>
                </a>
              </li>
              <!--  <li class="nav-item">
                <a :href="'/calendriertop'" class="nav-link">
                  <span class="title">Calendrier</span>
                </a>
              </li> -->

              <li class="nav-item">
                <a :href="'/liste-service'">
                  <span class="title">Service</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/liste-departement'">
                  <span class="title">Département</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/liste-filiere'">
                  <span class="title">Filière</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/liste-type-formation'">
                  <span class="title">Type Formation</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/liste-specialite'">
                  <span class="title">Specialite</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/liste-classe'">
                  <span class="title">Classe</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/liste-batiment'">
                  <span class="title">Batiment</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/liste-salle'">
                  <span class="title">Salle</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/liste-chambre'">
                  <span class="title">Chambre</span>
                </a>
              </li>


              <li class="nav-item">
                <a :href="'/liste-role'" class="nav-link">
                  <span class="title">Role</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/create-alerte'" class="nav-link nav-toggle">
                  <!--  <i class="fa fa-history"></i> -->

                  <span class="title"> Alerte</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/liste-fonctionnalite'" class="nav-link nav-toggle">
                  <!--  <i class="fa fa-history"></i> -->

                  <span class="title"> Fonctionnalite</span>
                </a>
              </li>

            </ul>
          </li>

          <li class="nav-item">
            <a :href="'/liste-audit'" class="nav-link nav-toggle">
              <i class="fa fa-search"></i>

              <span class="title">Audit</span>
            </a>
          </li>

          <li class="nav-item">
            <a href="#" @click="handleLogout()" class="nav-link nav-toggle">
              <i class="fa fa-sign-out"></i>
              <span class="title">Déconnexion</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--End Administrateur -->

  <!--  Service SAF -->
  <div class="sidebar-container"
    v-show="this.userIdrole && 'assistante saf'.toUpperCase() === this.userRole.toUpperCase()">
    <div class="sidemenu-container navbar-collapse collapse fixed-menu">
      <div id="remove-scroll" class="left-sidemenu">
        <ul class="sidemenu page-header-fixed slimscroll-style" data-keep-expanded="false" data-auto-scroll="true"
          data-slide-speed="200" style="padding-top: 20px">
          <li class="sidebar-toggler-wrapper hide">
            <div class="sidebar-toggler">
              <span></span>
            </div>
          </li>
          <li class="sidebar-user-panel">
            <div class="sidebar-user">
              <div class="sidebar-user-picture">
                <img alt="image" :src="getImageUrl(this.userPhoto)" />
              </div>
              <div class="sidebar-user-details">
                <div class="user-name">
                  {{ this.userNom }} {{ this.userPrenom }}
                </div>
                <div class="user-role">{{ this.userRole }}</div>
              </div>
            </div>
          </li>
          <li class="nav-item start active open">
            <a :href="'/dashboardCaissier'" class="nav-link nav-toggle">
              <i class="fa fa-window-maximize"></i>
              <span class="title">Tableau de Bord</span>
              <span class="selected"></span>
            </a>
          </li>

          <li class="nav-item">
            <a :href="'/liste-facture'" class="nav-link nav-toggle">
              <i class="fa fa-id-card-o"></i>

              <span class="title"> Facture</span>
            </a>
          </li>
          <li class="nav-item">
            <a :href="'/liste-reservation'" class="nav-link nav-toggle">
              <i class="fa fa-address-book-o"></i>

              <span class="title"> Réservation</span>
            </a>
          </li>
          <li class="nav-item">
            <a :href="'/liste-partenaire'" class="nav-link nav-toggle">
              <i class="fa fa-child"></i>

              <span class="title"> Partenaire</span>
            </a>
          </li>

          <li class="nav-item">
            <a href="#" @click="handleLogout()" class="nav-link nav-toggle">
              <i class="fa fa-sign-out"></i>
              <span class="title">Déconnexion</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Service SAF -->

  <!-- ASSIstante DG -->
  <div class="sidebar-container"
    v-show="this.userIdrole && 'Assistante DG'.toUpperCase() === this.userRole.toUpperCase()">
    <div class="sidemenu-container navbar-collapse collapse fixed-menu">
      <div id="remove-scroll" class="left-sidemenu">
        <ul class="sidemenu page-header-fixed slimscroll-style" data-keep-expanded="false" data-auto-scroll="true"
          data-slide-speed="200" style="padding-top: 20px">
          <li class="sidebar-toggler-wrapper hide">
            <div class="sidebar-toggler">
              <span></span>
            </div>
          </li>
          <li class="sidebar-user-panel">
            <div class="sidebar-user">
              <div class="sidebar-user-picture">
                <img alt="image" :src="getImageUrl(this.userPhoto)" />
              </div>
              <div class="sidebar-user-details">
                <div class="user-name">
                  {{ this.userNom }} {{ this.userPrenom }}
                </div>
                <div class="user-role">{{ this.userRole }}</div>
              </div>
            </div>
          </li>
          <li class="nav-item start active open">
            <!--  <a href="{{ route('admin_index') }}" class="nav-link nav-toggle"> -->
            <a :href="'/dashboardCaissierDAF'" class="nav-link nav-toggle">
              <i class="fa fa-window-maximize"></i>
              <span class="title">Tableau de Bord</span>
              <span class="selected"></span>
            </a>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-users"></i>

              <span class="title">Personnels</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/liste-utilisateur'" class="nav-link">
                  <span class="title">Liste</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/create-utilisateur'" class="nav-link">
                  <span class="title">Nouveau</span>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a href="#" @click="handleLogout()" class="nav-link nav-toggle">
              <i class="fa fa-sign-out"></i>
              <span class="title">Déconnexion</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- END ASSIstante DG -->
  <!-- ASSIstante DG -->
  <div class="sidebar-container" v-show="this.userIdrole && 'Logisticien'.toUpperCase() === this.userRole.toUpperCase()">
    <div class="sidemenu-container navbar-collapse collapse fixed-menu">
      <div id="remove-scroll" class="left-sidemenu">
        <ul class="sidemenu page-header-fixed slimscroll-style" data-keep-expanded="false" data-auto-scroll="true"
          data-slide-speed="200" style="padding-top: 20px">
          <li class="sidebar-toggler-wrapper hide">
            <div class="sidebar-toggler">
              <span></span>
            </div>
          </li>
          <li class="sidebar-user-panel">
            <div class="sidebar-user">
              <div class="sidebar-user-picture">
                <img alt="image" :src="getImageUrl(this.userPhoto)" />
              </div>
              <div class="sidebar-user-details">
                <div class="user-name">
                  {{ this.userNom }} {{ this.userPrenom }}
                </div>
                <div class="user-role">{{ this.userRole }}</div>
              </div>
            </div>
          </li>
          <li class="nav-item start active open">
            <!--  <a href="{{ route('admin_index') }}" class="nav-link nav-toggle"> -->
            <a :href="'/dashboardCaissierDAF'" class="nav-link nav-toggle">
              <i class="fa fa-window-maximize"></i>
              <span class="title">Tableau de Bord</span>
              <span class="selected"></span>
            </a>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-cart-arrow-down"></i>
              <span class="title">Matériels</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/materiels'" class="nav-link">
                  <span class="title">Liste</span>
                </a>
              </li>
              <li class="nav-item">
                <a :href="'/materiel-create'" class="nav-link">
                  <span class="title">Nouveau</span>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-tint"></i>
              <span class="title">Consommables</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/consommables'" class="nav-link">
                  <span class="title">Liste</span>
                </a>
              </li>
              <li class="nav-item">
                <a :href="'/consommable-create'" class="nav-link">
                  <span class="title">Nouveau</span>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-envelope"></i>
              <span class="title">Demandes</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/demandes-toutes'" class="nav-link"> <!-- Lien mis à jour -->
                  <span class="title">Toutes les demandes</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-shopping-basket"></i>
              <span class="title">Commandes</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/commandes/create'" class="nav-link"> <!-- Lien mis à jour -->
                  <span class="title">Faire une commande</span>
                </a>
              </li>
              <li class="nav-item">
                <a :href="'/commandes'" class="nav-link"> <!-- Lien mis à jour -->
                  <span class="title">Liste des commandes</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-archive"></i> <!-- Icône pour les stocks -->
              <span class="title">Stocks</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/stocks-materiels'" class="nav-link"> <!-- Lien vers les stocks de matériels -->
                  <span class="title">Stocks de Matériels</span>
                </a>
              </li>
              <li class="nav-item">
                <a :href="'/stocks-consommables'" class="nav-link"> <!-- Lien vers les stocks de consommables -->
                  <span class="title">Stocks de Consommables</span>
                </a>
              </li>
              <li class="nav-item">
                <a :href="'/stock/transactions'" class="nav-link"> <!-- Lien vers les transactions de stock -->
                  <span class="title">Transactions de Stock</span>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-truck"></i>
              <span class="title">Fournisseurs</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/fournisseurs/create'" class="nav-link"> <!-- Lien mis à jour -->
                  <span class="title">Ajouter un fournisseur</span>
                </a>
              </li>
              <li class="nav-item">
                <a :href="'/fournisseurs'" class="nav-link"> <!-- Lien mis à jour -->
                  <span class="title">Liste des fournisseurs</span>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-hand-holding
              "></i>

              <span class="title">Emprunts</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/liste-utilisateur'" class="nav-link">
                  <span class="title">Liste</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/create-utilisateur'" class="nav-link">
                  <span class="title">Nouveau</span>
                </a>
              </li>
              <li class="nav-item">
                <a :href="'/create-utilisateur'" class="nav-link">
                  <span class="title">Ajouter un retour</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-cog"></i>

              <span class="title">Paramètres</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">

              <li class="nav-item">
                <a :href="'/secteur-activites'" class="nav-link"> <!-- Ajout du lien vers le composant Statut -->
                  <span class="title">Secteur d'activité</span>
                </a>
              </li>


              <li class="nav-item">
                <a :href="'/etat'" class="nav-link"> <!-- Ajout du lien vers le composant Statut -->
                  <span class="title">Statut</span>
                </a>
              </li>

              <li class="nav-item">

                <a :href="'/createTypeMateriel'" class="nav-link">
                  <span class="title">Type de matériel</span>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a href="#" @click="handleLogout()" class="nav-link nav-toggle">
              <i class="fa fa-sign-out"></i>
              <span class="title">Déconnexion</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- END ASSIstante DG -->

  <div class="sidebar-container"
    v-show="this.userIdrole && 'gestionnaire de stock'.toUpperCase() === this.userRole.toUpperCase()">
    <div class="sidemenu-container navbar-collapse collapse fixed-menu">
      <div id="remove-scroll" class="left-sidemenu">
        <ul class="sidemenu page-header-fixed slimscroll-style" data-keep-expanded="false" data-auto-scroll="true"
          data-slide-speed="200" style="padding-top: 20px">
          <li class="sidebar-toggler-wrapper hide">
            <div class="sidebar-toggler">
              <span></span>
            </div>
          </li>
          <li class="sidebar-user-panel">
            <div class="sidebar-user">
              <div class="sidebar-user-picture">
                <img alt="image" :src="getImageUrl(this.userPhoto)" />
              </div>
              <div class="sidebar-user-details">
                <div class="user-name">
                  {{ this.userNom }} {{ this.userPrenom }}
                </div>
                <div class="user-role">{{ this.userRole }}</div>
              </div>
            </div>
          </li>
          <li class="nav-item start active open">
            <!--  <a href="{{ route('admin_index') }}" class="nav-link nav-toggle"> -->
            <a :href="'/dashboardCaissierDAF'" class="nav-link nav-toggle">
              <i class="fa fa-window-maximize"></i>
              <span class="title">Tableau de Bord</span>
              <span class="selected"></span>
            </a>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-chalkboard-teacher"></i>

              <span class="title">Materiels</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/liste-utilisateur'" class="nav-link">
                  <span class="title">Liste</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/create-utilisateur'" class="nav-link">
                  <span class="title">Nouveau</span>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-address-book"></i>

              <span class="title">Demandes</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/liste-utilisateur'" class="nav-link">
                  <span class="title">Toutes les demandes</span>
                </a>
              </li>


            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-user-graduate"></i>

              <span class="title">Emprunts</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/liste-utilisateur'" class="nav-link">
                  <span class="title">Liste</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/create-utilisateur'" class="nav-link">
                  <span class="title">Nouveau</span>
                </a>
              </li>
              <li class="nav-item">
                <a :href="'/create-utilisateur'" class="nav-link">
                  <span class="title">Ajouter un retour</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-cog"></i>

              <span class="title">Paramètres</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/liste-utilisateur'" class="nav-link">
                  <span class="title">Date Emprunt</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/create-utilisateur'" class="nav-link">
                  <span class="title">Statut</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/create-utilisateur'" class="nav-link">
                  <span class="title">Type Materiel</span>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a href="#" @click="handleLogout()" class="nav-link nav-toggle">
              <i class="fa fa-sign-out"></i>
              <span class="title">Déconnexion</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!--   Bibliothecaire -->
  <div class="sidebar-container"
    v-show="this.userIdrole && 'bibliothecaire'.toUpperCase() === this.userRole.toUpperCase()">
    <div class="sidemenu-container navbar-collapse collapse fixed-menu">
      <div id="remove-scroll" class="left-sidemenu">
        <ul class="sidemenu page-header-fixed slimscroll-style" data-keep-expanded="false" data-auto-scroll="true"
          data-slide-speed="200" style="padding-top: 20px">
          <li class="sidebar-toggler-wrapper hide">
            <div class="sidebar-toggler">
              <span></span>
            </div>
          </li>
          <li class="sidebar-user-panel">
            <div class="sidebar-user">
              <div class="sidebar-user-picture">
                <img alt="image" :src="getImageUrl(this.userPhoto)" />
              </div>
              <div class="sidebar-user-details">
                <div class="user-name">
                  {{ this.userNom }} {{ this.userPrenom }}
                </div>
                <div class="user-role">{{ this.userRole }}</div>
              </div>
            </div>
          </li>
          <li class="nav-item start active open">
            <a :href="'dashboard'" class="nav-link nav-toggle">
              <i class="fa fa-window-maximize"></i>
              <span class="title">Tableau de Bord</span>
              <span class="selected"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item active">
                <a :href="'dashboard'" class="nav-link">
                  <span class="title">Dashboard</span>
                  <span class="selected"></span>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i data-feather="user"></i> <span class="title">Livre</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/list-livre'" class="nav-link">
                  <span class="title">Liste Livre</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/create-livre'" class="nav-link">
                  <span class="title">Nouveau livre</span>
                </a>
              </li>
            </ul>

            <a href="#" class="nav-link nav-toggle">
              <i data-feather="user"></i>
              <span class="title">Livre Emprunter</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a href="{{ route('emprunter_livre_index') }}" class="nav-link">
                  <span class="title">Liste des livres emprunter</span>
                </a>
              </li>

              <li class="nav-item">
                <a href="{{ route('emprunter_livre_create') }}" class="nav-link">
                  <span class="title">Nouveau demande livre</span>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a href="/auteur/accueil" class="nav-link">
              <span class="title">Auteur</span>
            </a>
          </li>

          <a href="#" class="nav-link nav-toggle"><i data-feather="users"></i> <span
              class="title">Paramétres</span><span class="arrow"></span></a>

          <ul class="sub-menu">
            <li class="nav-item">
              <a href="{{ route('rayon_accueil') }}" class="nav-link">
                <span class="title">Rayon</span>
              </a>
            </li>

            <li class="nav-item">
              <a href="{{ route('categorie_accueil') }}" class="nav-link">
                <span class="title">Categorie</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="/auteur/create" class="nav-link">
                <span class="title">Auteur</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="/editeur/create" class="nav-link">
                <span class="title">Editeur</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="/edition/create" class="nav-link">
                <span class="title">Edition</span>
              </a>
            </li>
          </ul>

          <li class="nav-item">
            <a href="#" @click="handleLogout()" class="nav-link nav-toggle">
              <i class="fa fa-sign-out"></i>
              <span class="title">Déconnexion</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--  End Bibliothecaire -->

  <!-- Caisse -->
  <div class="sidebar-container" v-show="this.userIdrole && 'caissier'.toUpperCase() === this.userRole.toUpperCase()">
    <div class="sidemenu-container navbar-collapse collapse fixed-menu">
      <div id="remove-scroll" class="left-sidemenu">
        <ul class="sidemenu page-header-fixed slimscroll-style" data-keep-expanded="false" data-auto-scroll="true"
          data-slide-speed="200" style="padding-top: 20px">
          <li class="sidebar-toggler-wrapper hide">
            <div class="sidebar-toggler">
              <span></span>
            </div>
          </li>
          <li class="sidebar-user-panel">
            <div class="sidebar-user">
              <div class="sidebar-user-picture">
                <img alt="image" :src="getImageUrl(this.userPhoto)" />
              </div>
              <div class="sidebar-user-details">
                <div class="user-name">
                  {{ this.userNom }} {{ this.userPrenom }}
                </div>
                <div class="user-role">{{ this.userRole }}</div>
              </div>
            </div>
          </li>
          <li class="nav-item start active open">
            <a :href="'/dashboardCaissier'" class="nav-link nav-toggle">
              <i class="fa fa-window-maximize"></i>
              <span class="title">Tableau de Bord</span>
              <span class="selected"></span>
            </a>
          </li>

          <li class="nav-item">
            <a :href="'/liste-valide-inscription'" class="nav-link nav-toggle">
              <i class="fa fa-check"></i>

              <span class="title">Valider Inscription</span>
            </a>
          </li>

          <li>
            <a :href="'/liste-paiement'" class="nav-link nav-toggle">
              <i class="fa fa-credit-card"></i>

              <span class="title">Paiement</span>
            </a>
          </li>

          <li class="nav-item">
            <a :href="'recouvrement-create'" class="nav-link nav-toggle"><i class="fa fa-money"></i>

              <span class="title">Recouvrement</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="#" @click="handleLogout()" class="nav-link nav-toggle">
              <i class="fa fa-sign-out"></i>
              <span class="title">Déconnexion</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--END Caisse -->

  <!-- Service recouvrement -->
  <div class="sidebar-container" v-show="this.userIdrole && 'recouvrement'.toUpperCase() === this.userRole.toUpperCase()">
    <div class="sidemenu-container navbar-collapse collapse fixed-menu">
      <div id="remove-scroll" class="left-sidemenu">
        <ul class="sidemenu page-header-fixed slimscroll-style" data-keep-expanded="false" data-auto-scroll="true"
          data-slide-speed="200" style="padding-top: 20px">
          <li class="sidebar-toggler-wrapper hide">
            <div class="sidebar-toggler">
              <span></span>
            </div>
          </li>
          <li class="sidebar-user-panel">
            <div class="sidebar-user">
              <div class="sidebar-user-picture">
                <img alt="image" :src="getImageUrl(this.userPhoto)" />
              </div>
              <div class="sidebar-user-details">
                <div class="user-name">
                  {{ this.userNom }} {{ this.userPrenom }}
                </div>
                <div class="user-role">{{ this.userRole }}</div>
              </div>
            </div>
          </li>
          <li class="nav-item start active open">
            <a :href="'/dashboardCaissier'" class="nav-link nav-toggle">
              <i class="fa fa-window-maximize"></i>
              <span class="title">Tableau de Bord</span>
              <span class="selected"></span>
            </a>
          </li>

          <li class="nav-item">
            <a :href="'/liste-valide-inscription'" class="nav-link nav-toggle">
              <i class="fa fa-check"></i>

              <span class="title">Valider Inscription</span>
            </a>
          </li>

          <li>
            <a :href="'/liste-paiement'" class="nav-link nav-toggle">
              <i class="fa fa-credit-card"></i>

              <span class="title">Paiement Scolarité</span>
            </a>
          </li>

          <li class="nav-item">
            <a :href="'recouvrement-create'" class="nav-link nav-toggle"><i class="fa fa-money"></i>

              <span class="title">Recouvrement Scolarité</span>
            </a>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-shopping-cart"></i>

              <span class="title">Paiement Produit/Service</span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/liste-paiement-facture'" class="nav-link">
                  <span class="title">Liste Paiement</span>
                </a>
              </li>

              <li class="nav-item">
                <a :href="'/create-paiement-facture'" class="nav-link">
                  <span class="title">Paiement</span>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a :href="'/create-recouvrement-saf'" class="nav-link nav-toggle">
              <i class="fa fa-handshake-o"></i>

              <span class="title"> Recouvrement Produit/Service</span>
            </a>
          </li>

          <li class="nav-item">
            <a :href="'/liste-hebergement'" class="nav-link nav-toggle">
              <i class="fa fa-building"></i> <!-- Icône de bâtiment pour l'hébergement -->
              <span class="title"> Hebergement</span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'/create-hebergement'" class="nav-link">
                  <i class="fa fa-plus-circle"></i> <!-- Icône pour nouveau -->
                  <span class="title"> Nouveau Hebergement</span>
                </a>
              </li>
              <li class="nav-item">
                <a :href="'/paiement-hebergement'" class="nav-link">
                  <i class="fa fa-money"></i> <!-- Icône pour paiement -->
                  <span class="title"> Paiement Hebergement</span>
                </a>
              </li>
              <li class="nav-item">
                <a :href="'/historique-hebergement'" class="nav-link">
                  <i class="fa fa-history"></i> <!-- Icône pour historique -->
                  <span class="title"> Historique Paiement Hebergement</span>
                </a>
              </li>
              <li class="nav-item">
                <a :href="'/recouvrement-hebergement'" class="nav-link">
                  <i class="fa fa-refresh"></i> <!-- Icône pour recouvrement -->
                  <span class="title"> Recouvrement Hebergement</span>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <a :href="'/historique-eleve'" class="nav-link nav-toggle">
              <i class="fa fa-history"></i>

              <span class="title"> Historique Paiements Eléves</span>
            </a>
          </li>
          <li class="nav-item">
            <a :href="'/etat-encaissement'" class="nav-link nav-toggle">
              <i class="fa fa-chart-line"></i>

              <span class="title"> État Encaissement</span>
            </a>
          </li>


          <li class="nav-item">
            <a href="#" @click="handleLogout()" class="nav-link nav-toggle">
              <i class="fa fa-sign-out"></i>

              <span class="title">Déconnexion</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--End Service recouvrement -->

  <!--  Surveillant -->
  <div class="sidebar-container" v-show="this.userIdrole && 'surveillant'.toUpperCase() === this.userRole.toUpperCase()">
    <div class="sidemenu-container navbar-collapse collapse fixed-menu">
      <div id="remove-scroll" class="left-sidemenu">
        <ul class="sidemenu page-header-fixed slimscroll-style" data-keep-expanded="false" data-auto-scroll="true"
          data-slide-speed="200" style="padding-top: 20px">
          <li class="sidebar-toggler-wrapper hide">
            <div class="sidebar-toggler">
              <span></span>
            </div>
          </li>
          <li class="sidebar-user-panel">
            <div class="sidebar-user">
              <div class="sidebar-user-picture">
                <img alt="image" :src="getImageUrl(this.userPhoto)" />
              </div>
              <div class="sidebar-user-details">
                <div class="user-name">
                  {{ this.userNom }} {{ this.userPrenom }}
                </div>
                <div class="user-role">{{ this.userRole }}</div>
              </div>
            </div>
          </li>
          <li class="nav-item start active open">
            <a :href="'/dashboardCaissier'" class="nav-link nav-toggle">
              <i class="fa fa-window-maximize"></i>
              <span class="title">Tableau de Bord</span>
              <span class="selected"></span>
            </a>
          </li>

          <li class="nav-item">
            <a :href="'/liste-inscription-eleve'" class="nav-link nav-toggle">
              <i data-feather="user"></i>
              <span class="title"> Inscription</span>
            </a>
          </li>

          <li class="nav-item">
            <a href="#" @click="handleLogout()" class="nav-link nav-toggle">
              <i class="fa fa-sign-out"></i>
              <span class="title">Déconnexion</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--End Surveillant -->

  <!--  Agent Comptable -->
  <div class="sidebar-container"
    v-show="this.userIdrole && 'Chef Agence Comptable'.toUpperCase() === this.userRole.toUpperCase()">
    <div class="sidemenu-container navbar-collapse collapse fixed-menu">
      <div id="remove-scroll" class="left-sidemenu">
        <ul class="sidemenu page-header-fixed slimscroll-style" data-keep-expanded="false" data-auto-scroll="true"
          data-slide-speed="200" style="padding-top: 20px">
          <li class="sidebar-toggler-wrapper hide">
            <div class="sidebar-toggler">
              <span></span>
            </div>
          </li>
          <li class="sidebar-user-panel">
            <div class="sidebar-user">
              <div class="sidebar-user-picture">
                <img alt="image" :src="getImageUrl(this.userPhoto)" />
              </div>
              <div class="sidebar-user-details">
                <div class="user-name">
                  {{ this.userNom }} {{ this.userPrenom }}
                </div>
                <div class="user-role">{{ this.userRole }}</div>
              </div>
            </div>
          </li>
          <li class="nav-item start active open">
            <a :href="'/dashboardCaissier'" class="nav-link nav-toggle">
              <i class="fa fa-window-maximize"></i>
              <span class="title">Tableau de Bord</span>
              <span class="selected"></span>
            </a>
          </li>

          <li class="nav-item">
            <a :href="'/liste-inscription-eleve'" class="nav-link nav-toggle">
              <i class="fa fa-money-bill"></i> <!-- ou "fa fa-money-bill" -->
              <span class="title">État Encaissement</span>
            </a>
          </li>

          <li class="nav-item">
            <a href="#" @click="handleLogout()" class="nav-link nav-toggle">
              <i class="fa fa-sign-out"></i>
              <span class="title">Déconnexion</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!--End Agent Comptable -->

  <!--  Service SAF -->

  <div class="sidebar-container" v-show="this.userIdrole && 'formateur'.toUpperCase() === this.userRole.toUpperCase()">
    <div class="sidemenu-container navbar-collapse collapse fixed-menu">
      <div id="remove-scroll" class="left-sidemenu">
        <ul class="sidemenu page-header-fixed slimscroll-style" data-keep-expanded="false" data-auto-scroll="true"
          data-slide-speed="200" style="padding-top: 20px">
          <li class="sidebar-toggler-wrapper hide">
            <div class="sidebar-toggler">
              <span></span>
            </div>
          </li>
          <li class="sidebar-user-panel">
            <div class="sidebar-user">
              <div class="sidebar-user-picture">
                <img alt="image" :src="getImageUrl(this.userPhoto)" />
              </div>
              <div class="sidebar-user-details">
                <div class="user-name">
                  {{ this.userNom }} {{ this.userPrenom }}
                </div>
                <div class="user-role">{{ this.userRole }}</div>
              </div>
            </div>
          </li>
          <li class="nav-item start active open">
            <a :href="'/dashboardFormateur'" class="nav-link nav-toggle">
              <i class="fa fa-window-maximize"></i>
              <span class="title">Tableau de Bord</span>
              <span class="selected"></span>
            </a>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-book"></i> <span class="title">Ressources pédagogiques</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <a :href="'emploi-du-temps-formateur'" class="nav-link">
                  <i class="fas fa-calendar-alt"></i>
                  <span class="title"> Emploi du temps</span>
                </a>
              </li>
              <li class="nav-item">
                <a :href="'/accueilClasseFormateur'" class="nav-link">
                  <i class="fa fa-journal-whills"></i>
                  <span class="title"> Cahier de texte</span>
                </a>
              </li>
              <li class="nav-item">
                <a href="{{ route('liste_presence') }}" class="nav-link">
                  <i class="fa fa-check-square"></i>
                  <span class="title"> Liste de présence</span>
                </a>
              </li>
              <li class="nav-item">
                <a href="{{ route('liste_cours') }}" class="nav-link">
                  <i class="fa fa-file-alt"></i>
                  <span class="title"> Mes cours</span>
                </a>
              </li>
              <li class="nav-item">
                <a href="{{ route('liste-Cours') }}" class="nav-link">
                  <i class="fa fa-pencil-alt"></i>
                  <span class="title"> Notes</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item" v-show="isChefDepartement">
            <a href="#" class="nav-link nav-toggle">
              <i class="fa fa-cogs"></i> <span class="title">Matériels et <br> Équipements</span>
              <span class="arrow"></span>
            </a>
            <ul class="sub-menu">
              <li class="nav-item">
                <router-link to="/demande-materiel" class="nav-link">
                  <i class="fa fa-add"></i>
                  <span class="title">Faire une demande</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/liste-demandes" class="nav-link">
                  <i class="fa fa-list"></i>
                  <span class="title">Liste des demandes</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/dispatching" class="nav-link">
                  <i class="fa fa-share"></i>
                  <span class="title">Dispatching demandes recues</span>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" @click="handleLogout()" class="nav-link nav-toggle">
              <i class="fa fa-sign-out"></i>
              <span class="title">Déconnexion</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>




  <!-- End Service SAF -->

  <!--   Formateur -->



  <!--  End Formateur -->

</template>
<script>
import { mapState, mapActions } from "vuex";
import axios from "@/axios";
import IdleTimeoutMixin from "@/IdleTimeoutMixin.js";

export default {
  mixins: [IdleTimeoutMixin],
  props: ["userNom", "userPrenom", "userIdrole", "userPhoto", "userRole"],
  data() {
    return {
      isChefDepartement: false,
    };
  },
  methods: {
    ...mapActions(["login", "logout"]),
    getImageUrl(url) {
      return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
    },
    async checkIfChefDepartement() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const response = await axios.get(`http://localhost:8000/api/departement/find-chef`, { headers });
        if (response.data.isChef) {
          this.isChefDepartement = true;
        }
      } catch (error) {
        //console.log(error.response.data.message);
      }
    },
    async handleLogout() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      try {
        await axios.post("/logout", null, { headers });
        localStorage.clear();
        this.$router.push("/login");
      } catch (error) {
        Swal.fire("Erreur!", "Une erreur est survenue lors de la déconnexion", "error");
      }
    },
  },
  created() {
    this.checkIfChefDepartement();
  },
};
</script>
