<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userPhoto="userPhoto"
          :userNom="userNom"
          :userPrenom="userPrenom"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Créer une Commande</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="/">Accueil</a>&nbsp;<i class="fa fa-angle-right"></i></li>
                <li><a class="parent-item" href="/commandes">Commandes</a>&nbsp;<i class="fa fa-angle-right"></i></li>
                <li class="active">Créer</li>
              </ol>
            </div>
          </div>

          <!-- Formulaire de création -->
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-head">
                  <header>Nouvelle Commande</header>
                </div>
                <div class="card-body">
                  <form @submit.prevent="submitForm">
                    <div class="form-group">
                      <label for="statut">Statut</label>
                      <select 
                        v-model="formData.statut" 
                        class="form-control"
                        required
                        @change="logStatutChange"
                      >
                        <option value="">Sélectionnez un statut</option>
                        <option value="envoyé">Envoyé</option>
                        <option value="livré">Livré</option>
                        <option value="non livré">Non livré</option>
                      </select>
                    </div>

                    <div class="form-group text-center">
                      <button 
                        type="submit" 
                        class="btn btn-primary btn-circle"
                        :disabled="isButtonDisabled"
                      >
                        {{ isLoading ? 'Création en cours...' : 'Créer la commande' }}
                      </button>
                      <router-link 
                        to="/commandes"
                        class="btn btn-danger btn-circle"
                      >
                        Annuler
                      </router-link>
                    </div>
                  </form>

                  <!-- Liste des demandes validées -->
                  <div class="demandes-validees mt-4">
                    <h3>Demandes validées en attente de commande</h3>
                    <div v-if="demandesACommander.length === 0" class="alert alert-info">
                      Aucune demande en attente de commande
                    </div>
                    <div v-else class="demandes-grid">
                      <div v-for="demande in demandesACommander" 
                           :key="demande.id" 
                           class="demande-card"
                           :class="{ 'selected': formData.demandes_selectionnees.includes(demande.id) }"
                      >
                        <div class="demande-header">
                          <div class="demande-title">
                            <input 
                              type="checkbox"
                              :checked="formData.demandes_selectionnees.includes(demande.id)"
                              @change="toggleDemandeSelection(demande.id)"
                              class="demande-checkbox"
                            >
                            Demande #{{ demande.id }}
                          </div>
                          <div class="demande-date">
                            {{ formatDate(demande.created_at) }}
                          </div>
                        </div>

                        <div class="demande-body">
                          <div class="departement">
                            Département: {{ demande.user?.departement?.nom_departement }}
                          </div>

                          <!-- Articles à commander -->
                          <div class="items-section items-to-order">
                            <h4 class="text-warning">Articles à Commander</h4>
                            
                            <!-- Matériels à commander -->
                            <div v-if="demande.demande_materiels && demande.demande_materiels.length > 0" class="material-section">
                              <h5>Matériels</h5>
                              <ul>
                                <li v-for="materiel in demande.demande_materiels" :key="materiel.id">
                                  {{ materiel.libelle }} 
                                  <div class="quantities">
                                    <span class="quantity-requested">Demandé: {{ materiel.quantite }}</span>
                                    <span class="quantity-available">Disponible: {{ materiel.quantite_disponible }}</span>
                                    <span class="quantity-to-order">À commander: {{ materiel.quantite_a_commander }}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            
                            <!-- Consommables à commander -->
                            <div v-if="demande.demande_consommables && demande.demande_consommables.length > 0" class="consumable-section">
                              <h5>Consommables</h5>
                              <ul>
                                <li v-for="consommable in demande.demande_consommables" :key="consommable.id">
                                  {{ consommable.libelle }}
                                  <div class="quantities">
                                    <span class="quantity-requested">Demandé: {{ consommable.quantite }}</span>
                                    <span class="quantity-available">Disponible: {{ consommable.quantite_disponible }}</span>
                                    <span class="quantity-to-order">À commander: {{ consommable.quantite_a_commander }}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appheader from "../layout/header.vue";
import appnavbar from "../layout/navbar.vue";
import axios from "@/axios";
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/fr';

export default {
  name: "CommandeCreateComponent",
  components: {
    appheader,
    appnavbar
  },
  data() {
    return {
      formData: {
        statut: '',
        demandes_selectionnees: []
      },
      demandesValidees: [],
      isLoading: false,
      errorMessage: null
    };
  },
  
  computed: {
    token() {
      return localStorage.getItem("token");
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
    demandesACommander() {
      return this.demandesValidees.filter(demande => {
        const materielsACommander = demande.demande_materiels || [];
        const consommablesACommander = demande.demande_consommables || [];
        
        // Ne retourner que les demandes qui ont au moins un article à commander
        const hasMaterielsACommander = materielsACommander && materielsACommander.length > 0;
        const hasConsommablesACommander = consommablesACommander && consommablesACommander.length > 0;
        
        return hasMaterielsACommander || hasConsommablesACommander;
      });
    },
    isButtonDisabled() {
      return this.isLoading || !this.formData.statut || this.formData.demandes_selectionnees.length === 0;
    }
  },

  created() {
    this.fetchDemandesValidees();
  },

  methods: {
    formatDate(date) {
      return moment(date).locale('fr').format('DD/MM/YYYY');
    },

    async fetchDemandesValidees() {
      try {
        console.log('Fetching demandes à commander...');
        const response = await axios.get(
          'http://localhost:8000/api/demandes-a-commander',
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        
        if (response.data.statut === 200 && response.data.demandes) {
          this.demandesValidees = response.data.demandes.data || [];
          console.log('Demandes reçues:', this.demandesValidees);
          
          // Log pour chaque demande
          this.demandesValidees.forEach(demande => {
            console.log(`Demande #${demande.id}:`, {
              statut: demande.statut,
              checking_status: demande.checking_status,
              materiels: demande.demande_materiels,
              consommables: demande.demande_consommables
            });
          });
        } else {
          this.demandesValidees = [];
          console.log('Aucune demande trouvée');
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des demandes:", error);
        this.errorMessage = "Erreur lors de la récupération des demandes";
        this.demandesValidees = [];
      }
    },

    toggleDemandeSelection(demandeId) {
      try {
        console.log('Toggle demande:', demandeId);
        if (this.formData.demandes_selectionnees.includes(demandeId)) {
          this.formData.demandes_selectionnees = this.formData.demandes_selectionnees.filter(id => id !== demandeId);
        } else {
          this.formData.demandes_selectionnees = [...this.formData.demandes_selectionnees, demandeId];
        }
        console.log('Demandes sélectionnées:', this.formData.demandes_selectionnees);
      } catch (error) {
        console.error('Erreur lors de la sélection:', error);
      }
    },
    
    logStatutChange() {
      console.log('Statut changé:', this.formData.statut);
      console.log('demandes_selectionnees:', this.formData.demandes_selectionnees);
      console.log('isLoading:', this.isLoading);
      console.log('Le bouton devrait être désactivé:', this.isLoading || !this.formData.statut || this.formData.demandes_selectionnees.length === 0);
    },

    materielsFiltres(materiels) {
      return materiels.filter(materiel => 
        materiel.quantite > (materiel.quantite_disponible || 0)
      );
    },
    
    consommablesFiltres(consommables) {
      return consommables.filter(consommable => 
        consommable.quantite > (consommable.quantite_disponible || 0)
      );
    },

    async submitForm() {
      if (this.formData.demandes_selectionnees.length === 0) {
        this.errorMessage = "Veuillez sélectionner au moins une demande";
        return;
      }

      if (!this.formData.statut) {
        this.errorMessage = "Veuillez sélectionner un statut";
        return;
      }

      this.isLoading = true;
      try {
        const response = await axios.post(
          'http://localhost:8000/api/commandes',
          {
            statut: this.formData.statut,
            id_demande: this.formData.demandes_selectionnees
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );

        if (response.data.statut === 200) {
          // Rediriger vers la liste des commandes
          this.$router.push('/commandes');
        } else {
          this.errorMessage = "Erreur lors de la création de la commande";
        }
      } catch (error) {
        console.error("Erreur lors de la création de la commande:", error);
        this.errorMessage = error.response?.data?.message || "Erreur lors de la création de la commande";
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.demandes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.demande-card {
  background: white;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.demande-card:hover {
  border-color: #4e73df;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.demande-card.selected {
  background-color: #ebf4ff;
  border-color: #4e73df;
}

.demande-checkbox {
  margin-right: 8px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.demande-title {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #2d3748;
}

.demande-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e3e6f0;
}

.demande-date {
  font-size: 0.9em;
  color: #858796;
}

.items-section {
  margin-top: 1rem;
}

.items-to-order h4 {
  color: #e74a3b;
  margin-bottom: 1rem;
}

.material-section, .consumable-section {
  margin-top: 1rem;
}

.material-section h5, .consumable-section h5 {
  color: #5a5c69;
  margin-bottom: 0.5rem;
}

ul {
  list-style: none;
  padding-left: 0;
}

li {
  padding: 0.5rem;
  border-radius: 4px;
  background-color: #f8f9fc;
  margin-bottom: 0.5rem;
}

.quantities {
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
  margin-top: 0.25rem;
}

.quantity-requested {
  color: #4e73df;
}

.quantity-available {
  color: #1cc88a;
}

.quantity-to-order {
  color: #e74a3b;
  font-weight: bold;
}

.alert {
  padding: 1rem;
  border-radius: 4px;
  margin: 1rem;
}

.alert-info {
  background-color: #f8f9fc;
  border: 1px solid #e3e6f0;
  color: #5a5c69;
}

/* Styles de base */
.page-wrapper {
  background: #f8f9fa;
}

.card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin-bottom: 2rem;
}

.card-head {
  padding: 1.5rem;
  border-bottom: 1px solid #e2e8f0;
}

.card-head header {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
}

.card-body {
  padding: 1.5rem;
}

/* Formulaire */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #4a5568;
  font-weight: 500;
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  transition: all 0.2s;
}

.form-control:focus {
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.2);
  outline: none;
}

/* Container des demandes */
.demandes-validees {
  margin-top: 30px;
}

.demandes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.demande-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.demande-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.demande-card.selected {
  border-color: #4CAF50;
  background-color: #E8F5E9;
}

.demande-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.demande-title {
  font-weight: bold;
  font-size: 1.1em;
}

.demande-date {
  color: #666;
  font-size: 0.9em;
}

.demande-body {
  font-size: 0.9em;
}

.departement {
  margin-bottom: 10px;
  color: #555;
}

.items-section {
  margin-top: 10px;
}

.items-to-order h4 {
  font-size: 1em;
  margin-bottom: 5px;
  color: #333;
}

.items-to-order ul {
  margin-bottom: 8px;
}

.items-to-order li {
  padding: 3px 0;
  color: #666;
}

.items-to-order .text-muted {
  font-size: 0.85em;
}

/* Boutons */
.btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s;
}

.btn:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.btn-primary {
  color: #fff;
  background-color: #4e73df;
  border-color: #4e73df;
}

.btn-primary:not(:disabled):hover {
  background-color: #2e59d9;
  border-color: #2653d4;
}

.btn-circle {
  border-radius: 10rem;
  padding: 0.75rem 1.5rem;
}

.btn-danger {
  color: #fff;
  background-color: #e74a3b;
  border-color: #e74a3b;
}

.btn-danger:hover {
  background-color: #be2617;
  border-color: #be2617;
}

/* Responsive Design */
@media (max-width: 768px) {
  .demandes-grid {
    grid-template-columns: 1fr;
  }
}
</style>